<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <section *ngIf="currentStep === 'SECRET_CODE'">
    <app-secret-code (onCompleted)="onSecretCodeSubmit($event)" [input]="{secretCode: modelData.secret_code}"></app-secret-code>
  </section>

  <section *ngIf="currentStep === 'LANGUAGE_PREFERENCE'">
    <app-language-preference (onCompleted)="onLanguageSubmit($event)" (onCancelled)="goToSecretCode()"
      [input]="{language: modelData.language}"></app-language-preference>
  </section>

  <section *ngIf="currentStep === 'REG_FORM_1'">
    <app-registration-form-1 (onCompleted)="onRegForm1Submit($event)" (onCancelled)="goToLanguagePreference()"
      [input]="{market: modelData.market, partnerId: modelData.partner_id, email: modelData.email}"
      (selectedMarket)="selectedMarketName = $event"></app-registration-form-1>
  </section>

  <section *ngIf="currentStep === 'REG_FORM_2'">
    <app-registration-form-2 (onCompleted)="onRegForm2Submit($event)" (onCancelled)="goToRegForm1()"
      [input]="{firstName: modelData.first_name, lastName: modelData.last_name, nickName: modelData.nick_name}"></app-registration-form-2>
  </section>

  <section *ngIf="currentStep === 'REG_FORM_3'">
    <app-registration-form-3 (onCompleted)="onRegForm3Submit($event)" (onCancelled)="goToRegForm2()"
      [input]="{password: modelData.password, email: modelData.email}"></app-registration-form-3>
  </section>

  <section *ngIf="currentStep === 'REG_FORM_4'">
    <app-registration-form-4 (onCompleted)="onRegForm4Submit($event)" (onCancelled)="goToRegForm3()"
      [input]="{lv300: modelData.certified_l300}"></app-registration-form-4>
  </section>

  <section *ngIf="currentStep === 'MORE_QUESTIONS'">
    <app-more-questions (onCompleted)="onMoreQuestionSubmit($event)"></app-more-questions>
  </section>

  <section *ngIf="currentStep === 'QUESTION'">
    <app-question (onCompleted)="onQuestionSubmit($event)" (onCancelled)="goToMoreQuestions()"
      [input]="getSavedQuestionnairesAnswer()"></app-question>
  </section>

  <section *ngIf="currentStep === 'PROFILE_PICTURE'">
    <app-profile-picture (onSkip)="goToReviewProfile()" (onCancelled)="returnFromProfilePicture()"
      (onUpload)="goToUploadProfilePicture()" (onCreate)="goToCreateAvatar()"></app-profile-picture>
  </section>

  <section *ngIf="currentStep === 'CREATE_AVATAR'">
    <app-create-avatar (onCompleted)="goToEditAvatar()"></app-create-avatar>
  </section>

  <section *ngIf="currentStep === 'EDIT_AVATAR'">
    <app-edit-avatar (onCompleted)="onAvatarEditSubmit($event)"></app-edit-avatar>
  </section>

  <section *ngIf="currentStep === 'REVIEW_AVATAR'">
    <app-review-avatar (onCompleted)="onProfilePictureSubmit()" (onCancelled)="goToEditAvatar()"
      [avatarUri]="selectedProfileImageUrl"></app-review-avatar>
  </section>

  <section *ngIf="currentStep === 'UPLOAD_PROFILE_PICTURE'">
    <app-upload-profile-picture (onCompleted)="onProfilePictureSubmit()" (onCancelled)="goToProfilePicture()"
      (selectedProfileImage)="selectedProfileImageUrl = $event"></app-upload-profile-picture>
  </section>

  <section *ngIf="currentStep === 'REVIEW_PROFILE'">
    <app-review-profile (onCompleted)="goToRegistrationSuccessfulPage()" (onCancelled)="goToProfilePicture()"
      [input]="modelData" [selectedMarket]="selectedMarketName"></app-review-profile>
  </section>
</div>