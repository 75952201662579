import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {
    excludeList: string[] = ['/reset-password-successful'];

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.userIsLoggedIn() && !this.excludeList.some(url => state.url.includes(url))) {
            this.router.navigate(['/home']);
            return false;
        }
        return true;
    }
}

export const clientAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
};