<div [@fadeInOut] class="inner-body p-0 pb-5 page-with-top-full-banner">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner">
    <app-fullscreen-banner [data]="fullscreenBannerData"></app-fullscreen-banner>
  </section>

  <section class="listing">
    <div class="container mw-lg">
      <app-video-list [data]="videoListData"></app-video-list>
    </div>
  </section>

  <!-- <div class="d-flex align-items-center justify-content-center mb-5">
    <button type="button" class="btn btn-white btn-sm">Load more</button>
  </div> -->

  <!-- Come Back Later -->
  <!-- <section class="quote">
    <div class="container mw-lg">
      <h2 class="fw-light text-center px-4">"Sip, savor, and tell the tale—one cup at a time. Your journey from
        bean to
        brew
        is
        now an artisan's odyssey, where every pour is a story, and every story, a masterpiece."</h2>
    </div>
  </section> -->
</div>