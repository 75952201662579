import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { AnalyticsService } from '../../services/analytics.service';
import { GridList3Model, ImageBanner2Model } from '../../models';
import { ConfigService } from '../../services/config.service';
import { UnlockBapContentModalComponent } from '../../components/unlock-bap-content-modal/unlock-bap-content-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BapFlashCardModalComponent } from '../../components/bap-flash-card-modal/bap-flash-card-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bap-topic',
  standalone: true,
  templateUrl: './bap-topic.component.html',
  styleUrl: './bap-topic.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner2Component, GridList3Component, CommonModule, TranslateModule]
})

export class BapTopicComponent implements OnInit, OnDestroy {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "/bap",
      "label": "Black Apron Program"
    },
    {
      "link": "",
      "label": "BAP Topic"
    }
  ]

  imageBanner2DataUrl = '/assets/data/bap-topic-banner.json';
  imageBanner2Data !: ImageBanner2Model;
  gridList3Data: GridList3Model[] = [];
  bapTopic1 = '/assets/data/bap-topic-1.json';

  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicIdentifier!: string;
  bapTopicTitle!: string;
  bapTopicDescription!: string;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translation: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private title: Title
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Translation
    this.translation.get(['GENERAL.MENU.HOME', 'BAP.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['BAP.HEADER'];
      }
    });

    this.route.params.subscribe(params => {
      this.bapTopicIdentifier = params['contentId'];

      // Get BAP Topic Data
      this.contentService.getPartnerContent(this.bapTopicContentType, this.bapTopicIdentifier).subscribe({
        next: res => {
          if (res.length === 0) {
            this.router.navigate(['/404']);
          }

          const topic = res[0];

          // Set Breadcrumb Data
          this.breadcrumbsData[2].label = topic.title;

          // Filter Active Topic Item
          const currentDate = new Date();
          topic.topicItem = topic.topicItem.filter((item: any) => {
            const publishStartDate = new Date(item.publishStartDate);
            const publishEndDate = new Date(item.publishEndDate);
            return (!publishStartDate || publishStartDate <= currentDate) &&
              (!publishEndDate || currentDate <= publishEndDate);
          });

          // Set BAP Topic Item Data
          this.bapTopicTitle = topic.title;
          this.bapTopicDescription = topic.descriptions;

          // Set BAP Banner Data
          this.imageBanner2Data = {
            image_src: {
              lg: topic.bannerImage,
              sm: topic.bannerImageMobile,
            }
          }

          // Set title
          this.title.setTitle(topic.title);

          // Set BAP Topic Item Data
          topic.topicItem.forEach((topicItem: any) => {
            const bapType = topicItem.type || topicItem.type1;

            this.gridList3Data.push({
              "content_id": `${topicItem.title}-content-id`,
              "identifier": topicItem.identifier,
              "type": bapType.toUpperCase() === "FLASH CARD" ? "FLASHCARD" : "REGULAR",
              "image_src": topicItem.coverImage,
              "link": `${this.router.url}/${this.bapTopicItemNavigationUrl(bapType, topicItem.identifier)}`,
              "headline": topicItem.title,
              "descriptions": topicItem.descriptions,
              "level_requirement": topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase(),
              "disabled": this.configService.isPreview ? false : !this.contentService.canViewContent(topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase())
            })
          });
        },
        error: err => {
          console.log(err);
        }
      });

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.bapTopicIdentifier, this.bapTopicContentType, this.router.url);
      }
    })
  }

  bapTopicItemNavigationUrl(type: string, identifier: string) {
    switch (type.toUpperCase()) {
      case 'ARTICLE':
        return `bap-articles/${identifier}`;
      case 'WORKSHOP':
        return `bap-workshop-details/${identifier}`;
      case 'FLASH CARD':
      default:
        return '#';
    }
  }

  openBapFlashCardModal(data: { identifier: string, tier: string }) {
    // Check tier access
    const canViewContent = this.contentService.canViewContent(data.tier);
    if (!canViewContent && !this.configService.isPreview) {
      this.toastr.error('Partner does not have tier required to access the content.');
      return;
    }

    const modalRef = this.modalService.open(BapFlashCardModalComponent, {
      centered: true,
      windowClass: 'bap-flash-card-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openUnlockBAPContentModal() {
    const modalRef = this.modalService.open(UnlockBapContentModalComponent, {
      centered: true,
      windowClass: 'unlock-bap-content-modal',
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }
}
