import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { fadeInOut } from '../../../../animation';

@Component({
  selector: 'app-edit-avatar',
  standalone: true,
  imports: [],
  templateUrl: './edit-avatar.component.html',
  styleUrl: './edit-avatar.component.scss',
  animations: [fadeInOut],
})
export class EditAvatarComponent implements OnInit {
  @Output() onCompleted = new EventEmitter<{ avatarUri: string }>();

  avatarUri: string = "";

  ngOnInit(): void {
    window.addEventListener('message', (event: MessageEvent) => {
      // Avatar created
      if (event.data.type === 'avatarUri') {
        this.onSubmit(event);
      }
    })
  }

  onSubmit(event: MessageEvent) {
    this.onCompleted.emit({ avatarUri: event.data.data });
  }
}
