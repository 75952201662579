import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../animation';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password-unsuccessful',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './reset-password-unsuccessful.component.html',
  styleUrl: './reset-password-unsuccessful.component.scss',
  animations: [fadeInOut]
})
export class ResetPasswordUnsuccessfulComponent {
  @Input() errMessage!: string;

  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['ONBOARDING.RESET_PASSWORD_FAIL.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['ONBOARDING.RESET_PASSWORD_FAIL.HEADER']);
      }
    })
  }
}