import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-register-workshop-modal',
  standalone: true,
  imports: [XmarkIcon, TranslateModule],
  templateUrl: './register-workshop-modal.component.html',
  styleUrl: './register-workshop-modal.component.scss'
})
export class RegisterWorkshopModalComponent {
  constructor(
    private modalService: NgbModal
  ) { }

  @Input() modal: any;
  @Input()
  workshopID!: string;

  dismissModal() {
    this.modal.dismiss();
  }
}
