import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { BreadcrumbsComponent } from "../../../components/breadcrumbs/breadcrumbs.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-latte-art-championship',
  standalone: true,
  imports: [CommonModule, BreadcrumbsComponent, TranslateModule],
  templateUrl: './latte-art-championship.component.html',
  styleUrl: './latte-art-championship.component.scss',
  animations: [fadeInOut]
})
export class LatteArtChampionshipComponent implements OnInit, OnDestroy {
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/competitions',
      label: 'Competitions'
    }
  ]

  constructor(
    private renderer: Renderer2,
    private translation: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title
  ) {
    this.translation.get(['GENERAL.MENU.COMPETITIONS', 'COMPETITIONS.INFORMATIONS.LATTE_ART.TITLE']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.COMPETITIONS'];
        titleService.setTitle(res['COMPETITIONS.INFORMATIONS.LATTE_ART.TITLE']);
      }
    })
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'light-green-theme');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'light-green-theme');
  }
}
