<div class="component-grid-list-2">
  <div class="container">
    <div class="row">
      <div *ngFor="let item of data" class="col-12 col-sm-6">
        <div class="list-item">
          <figure [title]="item.headline">
            <a [href]="item.link">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
            </a>

          </figure>
          <div class="info">
            <div *ngIf="item.top_label" class="top-label fs-small">{{item.top_label}}</div>
            <div class="headline">
              <h6>{{item.headline}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>