import { Component, OnInit, OnDestroy, Renderer2, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { HtmlContentsComponent } from "../../components/html-contents/html-contents.component";
import { WorkshopIcon } from "../../icons";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterWorkshopModalComponent } from '../../components/register-workshop-modal/register-workshop-modal.component';
import { UnregisterWorkshopModalComponent } from '../../components/unregister-workshop-modal/unregister-workshop-modal.component';
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageBanner1Model } from '../../models';
import { ContentService } from '../../services/content.service';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-bap-workshop-details',
  standalone: true,
  templateUrl: './bap-workshop-details.component.html',
  styleUrl: './bap-workshop-details.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, HtmlContentsComponent, BreadcrumbsComponent, WorkshopIcon, ImageBanner1Component, TranslateModule, ClickableEnlargeImageDirective]
})
export class BapWorkshopDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/bap',
      label: 'Black Apron Program'
    }
  ]

  workshopContent !: SafeHtml;
  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicItemContentType: string = 'SbuxBapTopicItem';
  bapTopicItemWorkshopIdentifier!: string;
  bapTopicItemWorkshopData: { title: string, descriptions: string, content: string, start_date: number } = {
    title: '',
    descriptions: '',
    content: '',
    start_date: 0,
  };
  parentIdentifier!: string;

  toastrErrorHeader!: string;
  toastrErrorDesc!: string;

  isEnrolled: boolean = false;
  workshopIdentifier!: string;
  workshopDetailsBannerData!: ImageBanner1Model;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private translation: TranslateService,
    private contentService: ContentService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private title: Title,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Translation
    this.translation.get(['BAP.HEADER', 'GENERAL.ERROR_HEADER', 'GENERAL.ERROR_DESC']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['BAP.HEADER'];
        this.toastrErrorHeader = res['GENERAL.ERROR_HEADER'];
        this.toastrErrorDesc = res['GENERAL.ERROR_DESC'];
      }
    })

    this.route.params.subscribe(params => {
      this.parentIdentifier = params['pId'];
      this.bapTopicItemWorkshopIdentifier = params['contentId'];

      // Set Breadcrumb
      const segmentUrl = this.router.url.split('/');
      segmentUrl.pop();
      segmentUrl.pop();
      this.breadcrumbsData[0].link = segmentUrl.join('/');

      // Workshop Details Data
      this.contentService.getPartnerContent(this.bapTopicItemContentType, this.bapTopicItemWorkshopIdentifier).subscribe({
        next: res => {
          if (res.length === 0) {
            this.router.navigate(['/404']);
          }

          const topicItem = res[0];

          // Check tier access
          const canViewContent = this.contentService.canViewContent(topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase());
          if (!canViewContent && !this.configService.isPreview) {
            this.router.navigate(['/bap']).then(() => {
              this.toastr.error('Partner does not have tier required to access the content.');
            });
          }

          // Set Data            
          if (topicItem.workshop[0]) {
            this.title.setTitle(topicItem.title);
            this.workshopIdentifier = topicItem.workshop[0].identifier;
            this.bapTopicItemWorkshopData = {
              title: topicItem.workshop[0].title,
              descriptions: topicItem.workshop[0].descriptions,
              content: topicItem.workshop[0].bodyContents,
              start_date: topicItem.workshop[0].startDateTime,
            };
            this.workshopDetailsBannerData = {
              content_id: `${topicItem.workshop[0].title}-banner-id`,
              image_src: {
                lg: topicItem.workshop[0].bannerImage,
                sm: topicItem.workshop[0].bannerImageMobile
              }
            };

            const content = (topicItem.workshop[0].bodyContents ?? '').replace(/<img/g, '<img class="clickable-enlarge-image"');
            this.workshopContent = this.sanitizeStyle(content);
          }
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          // Add Content Analytic
          if (!this.configService.isPreview) {
            this.analyticsService.createContentAnalytic(this.bapTopicItemWorkshopIdentifier, this.bapTopicItemContentType, this.router.url);
          }

          if (this.parentIdentifier) {
            this.contentService.getPartnerContent(this.bapTopicContentType, this.parentIdentifier).subscribe({
              next: res => {
                // Breadcrumb label
                this.breadcrumbsData[0].label = res[0].title;
              },
              error: err => {
                console.log(err);
              }
            });
          }
        }
      });
    })
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }

  openRegisteredWorkshopModal(workshop_id: string) {
    const modalRef = this.modalService.open(RegisterWorkshopModalComponent, {
      centered: true,
      windowClass: 'register-workshop-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopID = workshop_id;
  }

  openUnregisteredWorkshopModal(workshop_id: string) {
    const modalRef = this.modalService.open(UnregisterWorkshopModalComponent, {
      centered: true,
      windowClass: 'unregister-workshop-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopID = workshop_id;
  }

  sanitizeStyle(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }

  toggleEnrollment() {
    if (this.configService.isPreview) {
      this.isEnrolled = !this.isEnrolled;
      this.isEnrolled ? this.openRegisteredWorkshopModal('') : this.openUnregisteredWorkshopModal('');
      return;
    }

    if (this.isEnrolled === false) {

      this.accountService.registerWorkshop(this.workshopIdentifier).subscribe({
        next: res => {
          this.isEnrolled = true;
          this.openRegisteredWorkshopModal(this.workshopIdentifier);
        },
        error: err => {
          console.log(err);
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      })
    } else {
      this.accountService.unregisterWorkshop(this.workshopIdentifier).subscribe({
        next: res => {
          this.isEnrolled = false;
          this.openUnregisteredWorkshopModal(this.workshopIdentifier);
        },
        error: err => {
          console.log(err);
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      })
    }
  }

  ngAfterViewInit() {
    // Initialize the directive for dynamically added content
    const directive = new ClickableEnlargeImageDirective(this.elementRef, this.ngZone);
    directive.ngOnInit();
  }
}
