import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { GridList3Model } from '../../models';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-bookmark',
  standalone: true,
  templateUrl: './my-bookmark.component.html',
  styleUrl: './my-bookmark.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, GridList3Component, BreadcrumbsComponent, TranslateModule]
})
export class MyBookmarkComponent implements OnInit {
  bookmarkData: GridList3Model[] = [];
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "My Bookmarks"
    }
  ]

  constructor(
    private accountService: AccountService,
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'MY_BOOKMARK.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['MY_BOOKMARK.HEADER'];

        this.titleService.setTitle(res['MY_BOOKMARK.HEADER']);
      }
    })

    // Get Bookmarked Content
    this.accountService.getBookmarkContents().subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.bookmarkData.push({
            content_id: item.identifier,
            type: 'REGULAR', // Ask Bilal, Is this needed?
            image_src: item.coverImage,
            link: `/${this.getContentTypeTopLabelData(item.contentType)}/${this.getContentTypeLinkData(item.contentType, item.type)}/${item.identifier}`,
            top_label: this.getContentTypeTopLabelData(item.contentType),
            headline: item.title,
            descriptions: item.descriptions
          })
        });

      },
      error: err => {
        console.log(err);
      }
    })
  }

  getContentTypeTopLabelData(contentType: string): string {
    switch (contentType.toUpperCase()) {
      case 'SBUXLEARNINGLESSON':
        // has bookmark datas
        // two types: article & video (it matters when constructing url)
        return 'HOME.SHORTCUT.LEARNINGS';
      case 'SBUXWORKSHOPARCHIVE':
        // has bookmark datas
        return 'HOME.SHORTCUT.WORKSHOPS';
      case 'SBUXCOMPETITIONVIDEO':
        // has bookmark datas
        return 'HOME.SHORTCUT.COMPETITIONS';
      case 'SBUXEVENTVIDEO':
        //has bookmark datas
        return 'HOME.SHORTCUT.EVENTS';
      default:
        return '';
    }
  }

  getContentTypeLinkData(contentType: string, type: string = ''): string {
    if (contentType.toUpperCase() === 'SBUXLEARNINGLESSON' && type.toUpperCase() === 'ARTICLE') {
      return 'articles';
    }
    return 'videos';
  }
}
