
import { Component } from '@angular/core';

@Component({
  selector: 'icon-folder',
  standalone: true,
  template: `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 29.2 29" style="enable-background:new 0 0 29.2 29;" xml:space="preserve">
    <path id="Path_376" d="M25.6,7.2H15.4l-3.2-4.3c-0.2-0.3-0.6-0.5-1-0.5H4c-0.7,0-1.2,0.5-1.2,1.2v21.6c0,0.7,0.5,1.2,1.2,1.2h21.6
    c0.7,0,1.2-0.5,1.2-1.2V8.4C26.8,7.7,26.3,7.2,25.6,7.2 M24.4,24H5.2V4.8h5.4l3.2,4.3c0.2,0.3,0.6,0.5,1,0.5h9.6L24.4,24z"/>
  </svg>
  `,
  styles: [],
})
export class FolderIcon { }


