import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCarousel1Model } from '../../models';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, StarOutlineIcon } from "../../icons";
import { ContentService } from '../../services/content.service';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModel } from '../../models/calendar.model';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-list-carousel-1',
  standalone: true,
  templateUrl: './list-carousel-1.component.html',
  styleUrl: './list-carousel-1.component.scss',
  imports: [CommonModule, CarouselModule, NgbDropdownModule, AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, TranslateModule, StarOutlineIcon]
})

export class ListCarousel1Component implements OnInit {
  @Input({ required: true }) data!: ListCarousel1Model[];
  @Input() slideHeaderCopy?: string;
  @Output() onFlashCardClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onQuizClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onQuestionnaireClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onUnlockBAPContentClicked = new EventEmitter<string>();

  playButton: boolean = false;

  calendarUrl!: string;
  calendarField: CalendarModel = {
    title: '',
    startDateTime: '',
    endDateTime: '',
    location: ''
  }

  // Owl carousel config
  listCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    margin: 25,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 5000,
    responsive: {
      0: {
        items: 1,
        loop: false,
        rewind: true
      },
      768: {
        items: 2,
        loop: false,
        rewind: true
      },
      992: {
        items: 3,
        loop: false,
        rewind: true
      }
    },
    nav: true,
    skip_validateItems: true
  }

  constructor(
    private contentService: ContentService,
    private router: Router,
    private toastr: ToastrService,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    const currentUrl = this.router.url;

    // Play button icon
    switch (true) {
      case currentUrl.toUpperCase().split('/').includes('EVENTS'):
        this.playButton = true;
        break;
      // Only has 1 for now, in future might have more..?
      case currentUrl.toUpperCase().split('/').includes('HOME'):
      default:
        this.playButton = false;
        break;
    }
  }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }


  onCalendarClick(event: Event, type: string, contentId: string) {
    event.preventDefault();
    const selectedBanner = this.data.find((obj: any) => obj.content_id === contentId);

    this.calendarField.title = selectedBanner!.headline;
    this.calendarField.description = selectedBanner!.descriptions;
    this.calendarField.startDateTime = this.contentService.formatDate3(selectedBanner!.start_date!.toString(), type);
    this.calendarField.endDateTime = this.contentService.formatDate3(selectedBanner!.end_date!.toString(), type);
    this.calendarField.link = selectedBanner!.link;

    this.contentService.createCalendarDeepLink(this.calendarField, type);
  }

  onFlashCard(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onFlashCardClicked.emit({ identifier, tier });
  }

  onQuiz(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onQuizClicked.emit({ identifier, tier });
  }

  onQuestionnaire(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onQuestionnaireClicked.emit({ identifier, tier });
  }

  onDisabledContent(event: any) {
    event.preventDefault();
    this.toastr.error('Partner does not have tier required to access the content.');
    return;
  }

  onUnlockBAPContentClick() {
    this.onUnlockBAPContentClicked.emit();
  }
}
