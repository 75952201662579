<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-4-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-4-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="step-indicator">
      <div class="data-label">{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2"></span>
        <span class="step step-3 current"></span>
        <!-- <span class="step step-4 current"></span> -->
      </div>
    </div>

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.DESC" | translate}}</p>
    </div>

    <div class="inner-body-contents">
      <div class="profile-picture-wrapper mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center">
        <input type="file" #fileInput hidden (change)="onFileSelected($event)" accept="image/jpeg,image/png,image/webp">
        <button *ngIf="!selectedProfileImageUrl" type="button" class="btn btn-file-upload-toggle"
          (click)="fileInput.click()" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)">

          <span class="icon-img">
            <icon-image-landscape></icon-image-landscape>
          </span>
          <div class="mt-1 text-center">{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.UPLOAD_DESC_1" | translate}}
            <span class="text-primary">{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.UPLOAD_SPAN_1" | translate}}</span>
            {{"ONBOARDING.UPLOAD_PROFILE_PICTURE.UPLOAD_DESC_2" | translate}}
          </div>
          <div class="fw-light fs-smaller">{{"ONBOARDING.UPLOAD_PROFILE_PICTURE.UPLOAD_DESC_3" | translate}}</div>
        </button>
        <div *ngIf="selectedProfileImageUrl" class="profile-picture-preview">
          <img [src]="selectedProfileImageUrl" class="selected-image">
          <button type="button" class="btn btn-circle btn-remove-image" *ngIf="selectedProfileImageUrl"
            (click)="removeImage()">
            <span class="icon-img">
              <icon-xmark></icon-xmark>
            </span>
          </button>
        </div>
      </div>

    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()" [disabled]="!uploaded">
        {{"ONBOARDING.UPLOAD_PROFILE_PICTURE.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.UPLOAD_PROFILE_PICTURE.BACK" | translate}}
      </button>
    </div>
  </div>
</div>