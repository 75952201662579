<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div class="modal-inner-header">
      <figure>
        <img src="/assets/images/workshops/img-workshop-registration-sm.webp" class="d-lg-none">
        <img src="/assets/images/workshops/img-workshop-registration-lg.webp" class="d-none d-lg-block">
      </figure>
    </div>
    <div class="modal-inner-body">

      <div class="inner-body-heading">
        <h2>{{"WORKSHOPS.REGISTER.HEADER" | translate}}</h2>

      </div>

      <div class="inner-body-contents">
        <p>{{"WORKSHOPS.REGISTER.DESC_1" | translate}}</p>
        <p>{{"WORKSHOPS.REGISTER.DESC_2" | translate}}<br>{{"WORKSHOPS.REGISTER.DESC_3" | translate}}</p>
      </div>

      <div class="inner-body-footer mt-3">
        <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="dismissModal()">
          {{"WORKSHOPS.REGISTER.DISMISS" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>