import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environment/environment';
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, map } from "rxjs";
import { CalendarModel } from "../models/calendar.model";
import { ConfigService } from "./config.service";
import { LanguageService } from "./language.service";
import { AccountService } from "./account.service";

interface postContentBodyModel {
    content_type: string;
    depth: number;
    identifier?: string;
    language_id: number;
    limit: number;
    offset: number;
    related?: string;
    order_by?: string;
    search?: string;
}
@Injectable({
    providedIn: 'root',
})
export class ContentService {
    partnerRank: string = '';

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private languageService: LanguageService,
        private accountService: AccountService
    ) { }


    async canViewContent(contentRank: string) {
        const rankingArr = ["GREEN_APRON", "SCA_CERTIFIED_COFFEE_MASTER", "STAR_1", "STAR_2", "STAR_3"];
        const contentRanking = rankingArr.findIndex(rank => rank === contentRank);

        // Partner rank not set
        if (!this.partnerRank) {
            const partnerProfile = await firstValueFrom(this.accountService.getMyProfile());
            this.partnerRank = partnerProfile.tier;
        }
        const partnerRanking = rankingArr.findIndex(rank => rank === this.partnerRank);
        return partnerRanking >= contentRanking;
    }

    // Calculate content publish date time, only TRUE of its within 7 days from now()
    calcIsNewContent(publishDate: string): boolean {
        const now = new Date().getTime(); // Current time in milliseconds
        const sevenDays = 7 * 60 * 60 * 24 * 1000; // 7 days in milliseconds 

        const startDate = new Date(publishDate).getTime();
        const calc = now - startDate; // dateTime milliseconds difference

        if (calc < sevenDays) {
            return true;
        }
        return false;
    }

    async createCalendarDeepLink(data: CalendarModel, calendarType: string) {
        let calendarUrl!: string;

        // Detect link is relative or absolute.
        const currentUrl = window.location.href;
        const { origin } = new URL(currentUrl);
        const contentLink = data.link ? (data.link.includes('http') || data.link.includes('www.') ? data.link : origin + data.link) : '';

        switch (calendarType.toUpperCase()) {
            case 'GOOGLE':
                // const googleDescription = (data.description ? data.description!.replace(/\n/g, "%0A") : '--') + (data.link ? `%0A%0ALink: ` + contentLink : '');
                const googleDescription = (data.description ? data.description.replace(/\n/g, "%0A") : '--') + (data.link ? `%0A%0ALink: ` + contentLink : '');
                calendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=%5BStarbuck Coffee Community%5D%20${data.title ?? ''}&details=${googleDescription ?? '--'}&dates=${data.startDateTime ?? ''}/${data.endDateTime ?? ''}&location=${data.location ?? ''}`;
                break;
            case 'YAHOO':
                const yahooDescription = (data.description ? data.description.replace(/\n/g, "%0A") : '--') + (data.link ? `%0A%0ALink: ` + contentLink : '');
                calendarUrl = `https://calendar.yahoo.com/?title=%5BStarbuck Coffee Community%5D%20${data.title ?? ''}&desc=${yahooDescription ?? ''}&st=${data.startDateTime ?? '--'}&et=${data.endDateTime ?? ''}&in_loc=${data.location}&v=60`;
                break;
            case 'MICROSOFT':
                const microsoftDescription = (data.description ? data.description.replace(/\n/g, "%20%3Cbr%3E%20") : '--') + (data.link ? `%20%3Cbr%3E%20%20%3Cbr%3E%20Link: ` + contentLink : '');
                calendarUrl = `https://outlook.live.com/calendar/0/deeplink/compose?subject=%5BStarbuck Coffee Community%5D%20${data.title ?? ''}&body=${microsoftDescription}&startdt=${data.startDateTime ?? ''}&enddt=${data.endDateTime ?? ''}&location=${data.location ?? ''}`;
                break;
            case 'ICAL':
            case 'APPLE':
                const body = {
                    "title": data.title,
                    "description": data.description ? data.description.replace(/\n/g, "\\n") : null,
                    "start_date_time": data.startDateTime,
                    "end_date_time": data.endDateTime,
                    "location": data.location,
                    "link": data.link ? (data.link.includes('http') || data.link.includes('www.') ? data.link : origin + data.link) : null
                }
                const response = await this.http.post<any>(`${environment.domain}${environment.prefixPath}/cms/calendar`, body, { headers: new HttpHeaders({ 'Content-Type': 'application/JSON' }) }).toPromise()
                calendarUrl = response.data;
                break;
            default:
                break;
        }

        if (calendarType.toUpperCase() === 'ICAL') {
            return;
        }

        // Runtime <a> construct, click, delete
        const calendarTag = document.createElement('a');
        calendarTag.href = calendarUrl;
        calendarTag.target = '_blank';
        document.body.appendChild(calendarTag);
        calendarTag.click();
        document.body.removeChild(calendarTag);
    }


    // Fetch public content data (Public Home Banner, Public Home Article)
    getPublicContent(data: string, identifier: string = '', limit: number = 20, depth: number = 1): Observable<any> {
        const isPreview = this.configService.isPreview;

        if (!data.match("SbuxPublicHome")) {
            throw new Error("Invalid Content Type");
        }

        const languageId: number = Number(isPreview ? this.configService.previewLanguage : this.languageService.getLanguage()) ?? 1;
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON',
        });

        const body: postContentBodyModel = {
            "content_type": data,
            "depth": depth,
            "language_id": languageId,
            "limit": limit,
            "offset": 0,
        }

        // Return specific content
        if (identifier) {
            body.identifier = identifier;
        }

        return this.http.post<any>(isPreview ? `${environment.domain}${environment.prefixPath}/admin/content/search` : `${environment.domain}${environment.prefixPath}${environment.cmsContentPath}/public/search`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // Fetch partner content data
    getPartnerContent(data: string, identifier: string = '', limit: number = 20, depth: number = 1): Observable<any> {
        const isPreview = this.configService.isPreview;

        const languageId: number = Number(isPreview ? this.configService.previewLanguage : this.languageService.getLanguage()) ?? 1;
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON',
        });

        const body: postContentBodyModel = {
            "content_type": data,
            "depth": depth,
            "language_id": languageId,
            "limit": limit,
            "offset": 0,
        }

        // Return specific content
        if (identifier) {
            body.identifier = identifier;
        }

        return this.http.post<any>(isPreview ? `${environment.domain}${environment.prefixPath}/admin/content/search` : `${environment.domain}${environment.prefixPath}${environment.cmsContentPath}/search`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // Search content data by keyword
    searchContent(searchTerm: string, offset: number = 0, limit: number = 9): Observable<any> {
        const isPreview = this.configService.isPreview;

        const languageId: number = Number(isPreview ? this.configService.previewLanguage : this.languageService.getLanguage()) ?? 1;
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON',
        });

        const body: postContentBodyModel = {
            "content_type": "",
            "depth": 0,
            "language_id": languageId,
            "limit": limit,
            "offset": offset,
            "search": searchTerm
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.cmsContentPath}/search-keyword`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // DRAFT
    // NEW Fetch content data (Version 2)
    getContent(data: string, identifier: string = '', related: string = '', limit: number = 20, depth: number = 1): Observable<any> {
        const languageId: number = Number(this.languageService.getLanguage()) ?? 1;
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        const body: postContentBodyModel = {
            content_type: data,
            depth: depth,
            language_id: languageId,
            limit: limit,
            offset: 0
            // related: related
        }


        // Return specific content
        if (identifier) {
            body.identifier = identifier;
        }

        // Return (child) related content
        if (related) {

            body.related = related;
        }
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.cmsContentPath}/query`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // Format from seconds to 1h 30m
    formatSecToHM(value: number, timeShortformUnit: { hour: string, minute: string }): string {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);

        const hoursStr = hours > 0 ? hours + timeShortformUnit.hour + ' ' : '';
        const minutesStr = minutes > 0 ? minutes + timeShortformUnit.minute : '';

        return hoursStr + minutesStr;
    }

    // Format from seconds to 11:22:30 (HH:MM:SS)
    formatSecToHMS(value: string): string | undefined {
        if (!value || value === '0') {
            return;
        }

        const hours: number = Math.floor(Number.parseInt(value) / 3600);
        const minutes: number = Math.floor((Number.parseInt(value) % 3600) / 60);
        const seconds: number = Number.parseInt(value) % 60;

        const hoursStr: string = hours > 0 ? hours.toString() : '0';
        const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString();
        const secondsStr: string = seconds < 10 ? '0' + seconds : seconds.toString();

        return `${hoursStr !== '0' ? hoursStr + ':' : ''}${minutesStr}:${secondsStr}`;
    }

    // Filter Competition(will be another post request later), Learnings
    filterContent(filter: { name: string, value: string }[], contentData: any, filterField: { [key: string]: string }) {
        var filteredContentData = contentData;
        filter.forEach(item => {
            if (!item.value) {
                return;
            }

            const filterObjKey = item.name;
            const filterFieldName = filterField[filterObjKey];
            const filteredObj = filteredContentData.filter((obj: any) => {

                // Filter: If field is ARRAY (Learning Topic with different category data in array)
                if (Array.isArray(obj[filterFieldName])) {
                    // Check value exist in array. (UpperCase)
                    return obj[filterFieldName].map((name: string) => name && name.toUpperCase()).includes(item.value.toUpperCase());
                }

                // Filter: If field is STRING (Competition Videos with year and name)
                return obj[filterFieldName] && obj[filterFieldName].toUpperCase() === item.value.toUpperCase();
            });

            // Assign value
            filteredContentData = filteredObj;
        });
        return filteredContentData;
    }

    // Formatting the date to "Jan 23rd 2024 | 10am"
    formatDate(timestamp: number): string {
        const date = new Date(timestamp);
        const day = date.getDate();
        let daySuffix = '';
        if (day > 3 && day < 21) daySuffix = 'th';
        else if (day % 10 === 1) daySuffix = 'st';
        else if (day % 10 === 2) daySuffix = 'nd';
        else if (day % 10 === 3) daySuffix = 'rd';
        else daySuffix = 'th';
        const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${day}${daySuffix} ${date.getFullYear()} <span class="px-2">|</span> ${date.getHours() % 12 || 12}${date.getMinutes() > 0 ? ':' + date.getMinutes() : ''}${date.getHours() >= 12 ? 'pm' : 'am'}`;
        return formattedDate;
    }

    // Formatting the date to "23 Jan 2024"
    formatDate2(timestamp: number): string {
        const date = new Date(timestamp);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
        return formattedDate;
    }

    // Formatting the date to 20240123T100000Z (Google & Yahoo) || 2024-01-23T10:00:00Z (Microsoft)
    formatDate3(dateTimeString: string, calendarType: string): string {
        const dateTime = new Date(dateTimeString);
        const isoString = dateTime.toISOString();

        switch (calendarType.toUpperCase()) {
            case 'YAHOO':
            case 'GOOGLE':
                return isoString.substring(0, 4) + // Year
                    isoString.substring(5, 7) + // Month
                    isoString.substring(8, 10) + // Day
                    'T' + // Time separator
                    isoString.substring(11, 13) + // Hours
                    isoString.substring(14, 16) + // Minutes
                    isoString.substring(17, 19) + // Seconds
                    'Z'; // UTC indicator
            case 'MICROSOFT':
                return isoString.substring(0, 4) + '-' + // Year
                    isoString.substring(5, 7) + '-' +// Month
                    isoString.substring(8, 10) + // Day
                    'T' + // Time separator
                    isoString.substring(11, 13) + ':' +// Hours
                    isoString.substring(14, 16) + ':' +// Minutes
                    isoString.substring(17, 19) +// Seconds
                    'Z'; // UTC indicator
            case 'APPLE':
            case 'ICAL':
                return isoString.substring(0, 4) + // Year
                    isoString.substring(5, 7) + // Month
                    isoString.substring(8, 10) + // Day
                    'T' + // Time separator
                    isoString.substring(11, 13) + // Hours
                    isoString.substring(14, 16) + // Minutes
                    isoString.substring(17, 19); // Seconds
            default:
                return '';
        }
    }

    // Formatting the date to "January 23, 2024"
    formatDate4(timestamp: Date): string {
        const date = new Date(timestamp);
        const formattedDate = `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
        return formattedDate;
    }

    // Not used for now.
    // // Format first char in text into Uppercase, the rest Lowercase "Hello World" 
    // formatTitleCase(marketName: string) {
    //     return marketName.toLowerCase().replace(/\b\w/g, function (char) {
    //         return char.toUpperCase();
    //     });
    // }

    // Sanitise Video Url
    formatVideoUrl(videoUrl: string): string {
        let VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)|l(?:ive)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const cleanUrl = videoUrl.match(VID_REGEX);
        return `https://www.youtube.com/embed/${cleanUrl![1]}`;
    }

    // Event Status: Live, Upcoming, Regular
    getEventStatus(start_date: number, end_date: number): string {
        const now = new Date().getTime();
        const timestampStart = new Date(start_date).getTime();
        const timestampEnd = new Date(end_date).getTime();

        if (timestampStart <= now && timestampEnd >= now) {
            return 'LIVE';
        } else if (timestampStart > now) {
            return 'UPCOMING';
        } else {
            return 'REGULAR';
        }
    }

    imageUrlToBase64(imageUrl: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous';
            img.src = imageUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                context!.drawImage(img, 0, 0);
                const base64Data = canvas.toDataURL('image/png');
                resolve(base64Data);
            };
            img.onerror = () => {
                reject(new Error('Failed to load image'));
            };
        });
    }
}