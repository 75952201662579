import { Component } from '@angular/core';
import { fadeInOut } from '../../animation';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  animations: [fadeInOut]
})
export class FaqComponent {

  constructor(
    private titleService: Title
  ) {
    titleService.setTitle('FAQ');
  }
}
