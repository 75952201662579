import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AccountService } from './account.service';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(
        private authService: AuthService,
        private accountService: AccountService,
        private configService: ConfigService
    ) { }

    // ========================= index.html ==============================
    public languageHTMLMapping: { [key: string]: string } = {
        "1": "en-us", // English
        "4852764": "zh", // Chinese
        "4938438": "th", // Thai
        "5017431": "ja", // Japanese
        "4561158": "id", // Indonesian
        "5028697": "ko", // Korean
        "5146990": "vi" // Vietnamese
    }

    // index.html "lang" attribute
    public languageHTMLMap(id: string) {
        return this.languageHTMLMapping[id];
    }

    // ====================================================================


    public languageMapping: { [key: string]: string } = {
        "1": "en", // English
        "4852764": "zh", // Chinese
        "4938438": "th", // Thai
        "5017431": "ja", // Japanese
        "4561158": "id", // Indonesian
        "5028697": "ko", // Korean
        "5146990": "vi" // Vietnamese
    }

    public languageMap(id: string): string {
        return this.languageMapping[id];
    };

    public switchLanguage(language: string): void {
        localStorage.setItem("languageId", language);
        window.location.reload();
    }

    public serverSwitchLanguage(language: string): void {
        if (this.authService.userIsLoggedIn()) {
            this.accountService.updateLanguage(language).subscribe({
                next: res => {
                    this.switchLanguage(language);
                },
                error: err => {
                    console.log(err);
                }
            });
        }
    }

    getLanguage(): string {
        return this.configService.isPreview ? String(this.configService.previewLanguage) : localStorage.getItem('languageId') ?? '1';
    }
}
