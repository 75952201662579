import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SpinnerIcon } from "../../../icons";
import { PublicService } from '../../../services/public.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-language-preference',
  standalone: true,
  templateUrl: './language-preference.component.html',
  styleUrl: './language-preference.component.scss',
  animations: [fadeInOut],
  imports: [SpinnerIcon, CommonModule, ReactiveFormsModule, TranslateModule]
})
export class LanguagePreferenceComponent implements OnInit {
  @Input() input?: { language: string };
  @Output() onCompleted = new EventEmitter<{ language: string }>();
  @Output() onCancelled = new EventEmitter<void>();
  @Output() selectedLanguage = new EventEmitter<string>();

  isLoading: boolean = true;
  languageForm!: FormGroup;
  languages!: [];

  constructor(
    private publicService: PublicService,
  ) { }

  ngOnInit(): void {
    this.languageForm = new FormGroup({
      language: new FormControl<string>(this.input?.language ?? 'en', [Validators.required])
    });

    this.publicService.getLanguage().subscribe({
      next: res => {
        this.languages = res;
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.onCompleted.emit({ language: this.languageForm.controls['language'].value });
  }
}
