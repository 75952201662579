import { Component, Input, ViewChild, Output, ElementRef, EventEmitter } from '@angular/core';
import { XmarkIcon, DocumentIcon, SpinnerGreenIcon, UploadIcon, UploadCloudIcon } from "../../icons";
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ImageLandscapeIcon } from "../../icons/icon-image-landscape";
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface UploadFile {
  file: File;
  id: string;
  uploading: boolean;
}

@Component({
  selector: 'app-upload-proof-modal',
  standalone: true,
  imports: [XmarkIcon, TranslateModule, CommonModule, ImageLandscapeIcon, DocumentIcon, SpinnerGreenIcon, UploadIcon, UploadCloudIcon],
  templateUrl: './upload-proof-modal.component.html',
  styleUrl: './upload-proof-modal.component.scss'
})
export class UploadProofModalComponent {
  @Input() modal!: NgbActiveModal;
  @Input() upgradeType: string = 'SCA';
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() onCompleted = new EventEmitter<void>();
  @Output() onCancelled = new EventEmitter<void>();
  isUploadCompleted: boolean = false;
  isLoading: boolean = false;
  failedUpload: boolean = false;
  files: UploadFile[] = [];

  readonly maxFiles = 5;

  private readonly allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'image/heic'];

  constructor(
    private toastr: ToastrService
  ) { }

  get hasReachedMaxFiles(): boolean {
    return this.files.length >= this.maxFiles;
  }

  onBack() {
    this.onCancelled.emit();
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  private isValidFileType(file: File): boolean {
    return this.allowedFileTypes.includes(file.type.toLowerCase());
  }

  private isFileAlreadySelected(file: File): boolean {
    return this.files.some(existingFile =>
      existingFile.file.name === file.name &&
      existingFile.file.size === file.size
    );
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (!event.dataTransfer?.files) return;

    this.failedUpload = false; // Reset failed upload state
    const newFiles = Array.from(event.dataTransfer.files);
    const availableSlots = this.maxFiles - this.files.length;

    if (availableSlots <= 0) {
      this.toastr.error(`Maximum ${this.maxFiles} files allowed`);
      return;
    }

    // Only take as many files as we have slots for
    const filesToAdd = newFiles.slice(0, availableSlots);

    filesToAdd.forEach(file => {
      if (this.isFileAlreadySelected(file)) {
        this.toastr.warning(`File already selected: ${file.name}`);
        return;
      }

      if (!this.isValidFileType(file)) {
        this.toastr.error(`File type not allowed: ${file.name}`);
        return;
      }

      if (file.size > 10 * 1024 * 1024) { // 10MB
        this.toastr.error(`File too large: ${file.name}`);
        return;
      }

      this.files.push({
        file,
        id: crypto.randomUUID(),
        uploading: true
      });
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    this.failedUpload = false; // Reset failed upload state
    const newFiles = Array.from(input.files);
    const availableSlots = this.maxFiles - this.files.length;

    if (availableSlots <= 0) {
      this.toastr.error(`Maximum ${this.maxFiles} files allowed`);
      input.value = ''; // Reset input
      return;
    }

    // Only take as many files as we have slots for
    const filesToAdd = newFiles.slice(0, availableSlots);

    filesToAdd.forEach(file => {
      if (this.isFileAlreadySelected(file)) {
        this.toastr.warning(`File already selected: ${file.name}`);
        return;
      }

      if (!this.isValidFileType(file)) {
        this.toastr.error(`File type not allowed: ${file.name}`);
        return;
      }

      if (file.size > 10 * 1024 * 1024) { // 10MB
        this.toastr.error(`File too large: ${file.name}`);
        return;
      }

      this.files.push({
        file,
        id: crypto.randomUUID(),
        uploading: true
      });
    });

    // Reset input to allow selecting the same file again
    input.value = '';
  }

  onSubmit() {
    this.failedUpload = false; // Reset failed upload state
    this.isLoading = true;
    setTimeout(() => {
      this.isUploadCompleted = true;
      this.isLoading = false;
    }, 1000);
  }

  onStimulateFailedUpload() {
    this.isLoading = true;
    setTimeout(() => {
      this.failedUpload = true;
      this.isLoading = false;
    }, 1000);
  }

  removeFile(fileId: string): void {
    this.failedUpload = false; // Reset failed upload state
    const index = this.files.findIndex(f => f.id === fileId);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
