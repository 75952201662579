<div class="verification-notice" [ngClass]="{
    'theme-black': data.upgradeType === 'SCA',
    'theme-green': data.upgradeType === 'STAR_PATH'
  }" (click)="openUploadProofModal()">
  <div class="wrapper">
    <ng-container *ngIf="data.upgradeType === 'SCA' && data.currentTier === 'GREEN_APRON'">
      <h2>Completed the SCA Level 300 program and certified as an SCA Coffee Master?</h2>
      <p>Upload your proof now and view the exclusive Black Apron Program content!</p>
      <div class="button-wrapper">
        <button class="btn btn-primary">Upload Now!</button>
      </div>
    </ng-container>

    <ng-container *ngIf="blackApronNeedsSCAVerification">
      <h2>Verify your SCA Level 300 now!</h2>
      <p>Just {{data.remainingDays}} day(s) left to submit your proof documents. Don't risk being downgraded to a Green
        Apron—submit now to secure
        your status!</p>
      <div class="button-wrapper">
        <button class="btn btn-primary">Upload Now!</button>
      </div>
    </ng-container>

    <ng-container *ngIf="data.upgradeType === 'STAR_PATH'">
      <h2>Congratulations on making an excellent achivement in the star path! </h2>
      <p>Please remember to upload your proof through within the next 30 days to secure your achivement.</p>
      <div class="button-wrapper">
        <button class="btn btn-primary">Upload Now!</button>
      </div>
    </ng-container>
  </div>
</div>