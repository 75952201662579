import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { CircleInfoIcon } from "../../../icons/icon-circle-info";
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-form-4',
  standalone: true,
  templateUrl: './registration-form-4.component.html',
  styleUrl: './registration-form-4.component.scss',
  animations: [fadeInOut],
  imports: [CircleInfoIcon, NgbTooltipModule, ReactiveFormsModule, CommonModule, TranslateModule]
})
export class RegistrationForm4Component implements OnInit {

  @Input() input?: { lv300: boolean };
  @Output() onCompleted = new EventEmitter<{ lv300: boolean }>();
  @Output() onCancelled = new EventEmitter<void>();

  regForm4!: FormGroup;

  ngOnInit(): void {
    this.regForm4 = new FormGroup({
      lv300: new FormControl<boolean>(this.input?.lv300 ?? false, [Validators.required])
    })
  }
  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.onCompleted.emit({ lv300: this.regForm4.controls['lv300'].value });
  }
}
