<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-body px-4 px-lg-8">
    <div class="inner-body-contents">
      <iframe *ngIf="!isCreated" src="/assets/avatar-module/index.html?v=1" width="100%" height="100%"></iframe>
      <div *ngIf="isCreated" class="loading-wrapper">
        <figure class="spinner">
          <icon-spinner></icon-spinner>
        </figure>
      </div>
    </div>
  </div>
</div>