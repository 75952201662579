<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-5">
      <figure class="error-500-figure">
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" version="1.1" viewBox="0 0 512 512">
          <path
            d="M501.8 140.4c-4.4 0-7.9-3.5-7.9-7.9V74c0-6.6-5.3-11.9-11.9-11.9H30.1c-6.6 0-11.9 5.3-11.9 11.9v58.5c0 4.4-3.5 7.9-7.9 7.9s-7.9-3.5-7.9-7.9V74c0-15.3 12.5-27.7 27.7-27.7H482c15.3 0 27.7 12.5 27.7 27.7v58.5c0 4.4-3.5 7.9-7.9 7.9Z" />
          <path
            d="M481.9 465.8H30.1c-15.3 0-27.7-12.5-27.7-27.7V132.5c0-4.4 3.5-7.9 7.9-7.9h491.5c4.4 0 7.9 3.5 7.9 7.9V438c0 15.3-12.5 27.7-27.7 27.7ZM18.2 140.4V438c0 6.6 5.3 11.9 11.9 11.9H482c6.6 0 11.9-5.3 11.9-11.9V140.4H18.2ZM72 104.9c-6.4 0-11.6-5.2-11.6-11.6S65.6 81.7 72 81.7s11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Zm45.6 0c-6.4 0-11.6-5.2-11.6-11.6s5.2-11.6 11.6-11.6 11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Zm45.6 0c-6.4 0-11.6-5.2-11.6-11.6s5.2-11.6 11.6-11.6 11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Z" />
          <path
            d="M167.3 320.9c0 30.8-21.5 49-52.2 49s-41.5-11.5-49.2-17.4l8.7-15.6c7.3 5.5 21.7 16 40.3 16s31.2-11.5 31.2-30.6-10.7-28.1-26.5-28.1-27.7 10.3-33 16l-11.9-5.5 8.7-78h74.7v17.2H98.2l-5.3 46.4c6.5-5.9 17.2-12.1 33-12.1 24.3 0 41.3 14.4 41.3 42.7ZM188.6 296.8c0-41.7 23.5-73.1 59.7-73.1s60.1 31.4 60.1 73.1-23.7 73.1-60.1 73.1-59.7-31.4-59.7-73.1Zm98.4 0c0-33.2-14.4-55.5-38.7-55.5s-38.7 22.3-38.7 55.5 14.4 55.5 38.7 55.5S287 330 287 296.8ZM330.3 296.8c0-41.7 23.5-73.1 59.7-73.1s60.1 31.4 60.1 73.1-23.7 73.1-60.1 73.1-59.7-31.4-59.7-73.1Zm98.4 0c0-33.2-14.4-55.5-38.7-55.5s-38.7 22.3-38.7 55.5 14.4 55.5 38.7 55.5 38.7-22.3 38.7-55.5Z" />
        </svg>
      </figure>
      <h1>Oops... Something went wrong</h1>
      <p>We're experiencing technical difficulties. Please try again later.</p>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto" [routerLink]="['/home']">
        Go to homepage
      </button>
    </div>

  </div>
</div>