<section *ngIf="data" class="fullscreen-banner">
  <div class="banner-wrapper">
    <img *ngIf="data.image_src.sm" [src]="data.image_src.sm" class="d-md-none" alt="{{data.overlay_headline}}-img" />
    <img [src]="data.image_src.lg" [ngClass]="{'d-none d-md-block': data.image_src.sm}" alt="{{data.overlay_headline}}-img" />
  </div>
  <div *ngIf="data.overlay_headline || data.overlay_logo_src" class="banner-overlay" [class.with-logo]="data.overlay_logo_src">
    <div class="container d-flex flex-column align-items-start">
      <figure *ngIf="data.overlay_logo_src" class="event-logo">
        <img [src]="data.overlay_logo_src" alt="{{data.overlay_headline}}-img" />
      </figure>
      <h1 *ngIf="data.overlay_headline">{{data.overlay_headline}}</h1>
      <h4 *ngIf="data.overlay_subheadline" class="fw-normal text-dark">{{data.overlay_subheadline}}</h4>
    </div>
  </div>
</section>