<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <h1>Email templates</h1>
    <p class="text-danger mb-5"><i>Notes: Email template size is NOT responsive</i></p>

    <div class="mb-5">
      <h4 class="text-center text-dark">Registration successful</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Welcome
                                </h1>
                                Dear <strong>[user nickname]</strong>,<br><br>

                                Start exploring the Starbucks Coffee Community Website with your email
                                <strong>johndoe&#64;email.com</strong> by clicking the activation button below, valid
                                for 24 hours only!

                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td height="40" align="left">
                                <a href="#"
                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px; "
                                  target="_blank" rel="nofollow noopener noreferrer"
                                  title="Activate My Starbucks Coffee Community Account">Activate My Starbucks Coffee
                                  Community Account</a>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team</td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Reset password request</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Reset
                                  password</h1>
                                Dear <strong>[user nickname]</strong>,<br><br>
                                To reset your password, please click the button below:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td height="40" align="left">
                                <a href="#"
                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000;min-width: 120px;"
                                  target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Reset
                                  Password</a>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                Please copy the link to your browser if you are unable to click the button
                                above.<br><br>

                                <a href="#"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">https://xxx.xxx.xxx.xxxxxxxxx</a><br><br>

                                If you didn't request password reset, you can safely ignore this email.<br><br>

                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br><br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Reset password verification </h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Your
                                  password has been reset</h1>
                                Dear <strong>[user nickname]</strong>,<br><br>
                                You've successfully reset your password. Please login using your new password.
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td height="40" align="left">
                                <a href="#"
                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                  target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Log in</a>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you don't make this request, please contact us at <a
                                  href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br><br>


                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br><br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team</td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Account upgrade notification</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Congratulation,you're Black Apron 1 star </h1>
                                Dear <strong>[user nickname]</strong>,<br><br>
                                Logged into Black Apron Program page to explore the new content!
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td height="40" align="left">
                                <a href="#"
                                  style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                  target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Go to Black
                                  Apron Program</a>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br><br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team</td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop enrollment (online workshop)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop Enrollment</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                You have successfully enrolled in the following workshop:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Add to
                                          calendar</a>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop enrollment (offline workshop)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop Enrollment</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                You have successfully enrolled in the following workshop:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <span style="font-size: 11px; color: #999999;">Location:</span><br>
                                        <strong style="font-size: 15px">Grand Ballroom, Regent Hong Kong, 18 Salisbury
                                          Road Regent, Tsim Sha Tsui, Hong Kong</strong>
                                        <br><br>
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Add to
                                          calendar</a>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop enrollment cancellation (online workshop)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop Enrollment Cancellation</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                You have successfully cancelled your enrollment for the following workshop:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop enrollment cancellation (offline workshop)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop Enrollment Cancellation</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                You have successfully cancelled your enrollment for the following workshop:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <span style="font-size: 11px; color: #999999;">Location:</span><br>
                                        <strong style="font-size: 15px">Grand Ballroom, Regent Hong Kong, 18 Salisbury
                                          Road Regent, Tsim Sha Tsui, Hong Kong</strong>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop reminder (online)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop starting soon</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                The following workshop is starting soon:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Join
                                          workshop</a>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Workshop reminder (offline)</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Workshop starting soon</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                The following workshop is starting soon:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <span style="font-size: 11px; color: #999999;">Location:</span><br>
                                        <strong style="font-size: 15px">Grand Ballroom, Regent Hong Kong, 18 Salisbury
                                          Road Regent, Tsim Sha Tsui, Hong Kong</strong>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate yo
                                contact us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>

    <div class="mb-5 border-top pt-5">
      <h4 class="text-center text-dark">Event start</h4>

      <div class="email-template" style="margin: 0 auto; padding: 30px 0; width: 480px;">
        <table width="480" border="0" cellspacing="0" cellpadding="0" align="center"
          style="margin: 0 auto; table-layout:fixed;">
          <tbody>

            <!-- header -->
            <tr>
              <td height="80" bgcolor="#ffffff" style="border-bottom:1px solid #eeeeee"><img
                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-logo-left-2.gif"
                  alt="Shoppertise Cafe" width="480" height="100" style="display:block;" /></td>
            </tr>
            <!-- header ends -->

            <!-- Body Content -->
            <tr>
              <td bgcolor="#ffffff">
                <table width="480" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>

                      <!-- Inner Content -->
                      <td>
                        <table width="420" border="0" cellspacing="0" cellpadding="0">
                          <tbody>
                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <!-- Contents -->
                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                <h1
                                  style="margin-top: 0; margin-bottom: 20px; color: #00754A; font-weight: 600; font-size: 32px;">
                                  Event is LIVE!</h1>
                                Dear <strong>[user nickname]</strong>,<br>
                                <br>
                                The following workshop/event is currently live:
                              </td>
                            </tr>
                            <!-- Contents ends -->

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td>
                                <table width="420" border="0" cellspacing="0" cellpadding="0" bgcolor="#f4f4f4"
                                  style="border: 1px solid #dddddd;">
                                  <tbody>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20">&nbsp;</td>
                                      <td
                                        style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                        <span style="font-size: 11px; color: #999999;">Workshop / event:</span><br>
                                        <strong style="font-size: 15px">Advanced Coffee Brewing
                                          Workshop</strong><br><br>
                                        <span style="font-size: 11px; color: #999999;">Date &amp; time:</span><br>
                                        <strong style="font-size: 15px">3:00 PM, 12 May 2024 (GMT+8)</strong>
                                        <br><br>
                                        <a href="#"
                                          style="font-family:Helvetica, Arial, 'sans-serif'; font-size:13px; font-weight: 600; color: #000000; line-height: 1.4em; text-align:center; background-color:#3ECEA9; display:inline-block; padding:10px 30px; text-decoration:none; border-radius: 20px; border: 1px solid #000000; min-width: 120px;"
                                          target="_blank" rel="nofollow noopener noreferrer" title="Verify now">Join
                                          now</a>
                                      </td>
                                      <td width="20">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td width="20" height="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                      <td>&nbsp;</td>
                                      <td width="20">
                                        <img
                                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                          width="20" height="20" style="display:block;" />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                            <tr>
                              <td align="left" valign="top"
                                style="font-family:Helvetica, Arial, 'sans-serif'; font-size: 13px; text-align: left; color: #333333; line-height: 1.35;">
                                If you require any assistance on how to use our services, please do not hesitate to
                                contact
                                us at <a href="mailto:starbuckscoffeecommunity@starbucks.com"
                                  style="color:#00754A; font-weight: 600; outline: none; text-decoration: none;"
                                  rel="nofollow noopener noreferrer">starbuckscoffeecommunity&#64;starbucks.com</a><br>
                                <br>
                                Thank you.<br>
                                Starbucks Coffee &amp; Partner Engagement Team
                              </td>
                            </tr>

                            <!-- Empty space -->
                            <tr>
                              <td height="30"><img
                                  src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif"
                                  width="30" height="30" style="display:block;" /></td>
                            </tr>
                            <!-- Empty space ends -->

                          </tbody>
                        </table>
                      </td>
                      <!-- Inner Content 560px ends -->

                      <td width="30" valign="top"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Body Content ends-->

            <!-- Footer -->
            <tr>
              <td>
                <table width="480" border="0" cellspacing="0" cellpadding="0" class="footer-table" bgcolor="#00754A">
                  <tbody>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="440" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                    <tr>
                      <td width="20" height="20">&nbsp;</td>
                      <td align="center"
                        style="font-family: Tahoma, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:11px; color: #ffffffbf; line-height: 1.5em;">
                        Starbucks Coffee Community Website<br>
                        Copyright &copy; 2024. All rights reserved. </td>
                      <td width="20" height="20">&nbsp;</td>
                    </tr>
                    <tr>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                      <td width="560" height="20">&nbsp;</td>
                      <td width="20" height="20"><img
                          src="https://sbux-public-img.s3.us-west-2.amazonaws.com/email/img-blank.gif" width="20"
                          height="20" style="display:block;" /></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Footer ends -->

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>