import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { SpinnerIcon } from '../../../icons';
import { AuthService } from '../../../services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, take } from 'rxjs';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SpinnerIcon, TranslateModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  animations: [fadeInOut]
})
export class ForgotPasswordComponent implements OnInit {
  @Output() onCompleted = new EventEmitter<{ email: string }>();

  forgetPasswordForm!: FormGroup;
  isSubmitting: boolean = false;
  emailPrevValue: string = '';

  frontendError: boolean = false;
  frontendErrorMessage!: string;
  serverError: boolean = false;
  serverErrorMessage!: string;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.forgetPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required])
    })

    this.forgetPasswordForm.controls['email'].valueChanges
      .pipe(
        debounceTime(500),
        // take(1)
      )
      .subscribe(
        (val: string) => {
          // same value, do not trigger event
          if (val === this.emailPrevValue) {
            return;
          }

          // different value, trigger event
          this.forgetPasswordForm.controls['email'].patchValue(val.replace(/\s/g, "").toLowerCase(), { emitEvent: false });
          this.emailPrevValue = this.forgetPasswordForm.controls['email'].value;
        }
      )
  }

  onSubmit() {
    this.forgetPasswordForm.disable();
    this.isSubmitting = true;

    // ========================= Validate Email ========================= 
    if (!this.authService.validateEmail(this.forgetPasswordForm.controls["email"].value)) {
      this.frontendError = true;
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.isSubmitting = false;
      this.forgetPasswordForm.enable();
      return;
    }

    // ========================== POST REQUEST SWITCHMAP() ========================
    // Request Header
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Email Verification Body
    const emailBody = {
      'token': this.forgetPasswordForm.controls['email'].value
    };

    // Request Reset Password Body 
    const requestBody = {
      'username': this.forgetPasswordForm.controls['email'].value
    };

    this.authService.requestResetPassword(requestBody, reqHeader).subscribe({
      next: res => {
        if (!res.data) {
          this.serverErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
          this.serverError = true;
          return;
        }

        this.onCompleted.emit({ email: this.forgetPasswordForm.controls["email"].value });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.isSubmitting = false;
        this.forgetPasswordForm.enable();
      }
    })
  }

}
