
import { Component } from '@angular/core';

@Component({
  selector: 'icon-exclamation-circle-solid',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16Zm20,368H236V344h40Zm12-152-14,72H238l-14-72V120h64Z"/>
</svg>
  `,
  styles: [],
})
export class ExclamationCircleSolidIcon { }