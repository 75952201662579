import { Component } from '@angular/core';
import { fadeInOut } from '../../animation';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activation-successful',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './activation-successful.component.html',
  styleUrl: './activation-successful.component.scss',
  animations: [fadeInOut]
})
export class ActivationSuccessfulComponent {
  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) {
    this.translation.get(['ONBOARDING.ACTIVATE_SUCCESS.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['ONBOARDING.ACTIVATE_SUCCESS.HEADER']);
      }
    })
  }
}
