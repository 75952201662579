<div class="activity-history">
  <div *ngIf="activityHistory.length === 0" class="empty-state">
    <h3 class="text-dark fw-normal">No recent activities yet!</h3>
    <p>Start exploring learning materials, attending events, or participating in competitions to build your coffee
      expertise.</p>
  </div>
  <ng-container *ngIf="activityHistory.length > 0">
    <div class="activity-history-group" *ngFor="let group of activityHistory">
      <h4 class="text-dark fw-normal mb-3">{{ group.date }}</h4>

      <ul class="activity-history-list list-unstyled">
        <li class="activity-history-item" *ngFor="let activity of group.activities" [ngClass]="{
        'title-path-yellow': activity.theme === 'YELLOW',
        'title-path-green': activity.theme === 'GREEN',
        'title-path-red': activity.theme === 'RED',
        'title-path-blue': activity.theme === 'BLUE'
      }">
          <div class="title-path mb-2">
            <span>{{ activity.title }}</span>
          </div>

          <div class="activity-details d-flex flex-wrap align-items-center gap-2 gap-md-3">
            <div class="status">
              <span *ngIf="activity.status === 'FINISHED'" class="status-completed">Finished</span>
              <span *ngIf="activity.status === 'IN_PROGRESS'" class="status-in-progress">In Progress</span>
            </div>

            <div class="code-points d-flex flex-wrap">
              <div class="code label label-bap-topic-item label-bap-topic-item-code">
                {{ activity.code }}
              </div>
              <div class="points label label-bap-topic-item label-bap-topic-item-points">
                {{ activity.points }}
              </div>
            </div>
            <div class="name flex-1 truncate-line-clamp lc-2">
              {{ activity.name }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</div>