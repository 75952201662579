<div class="event-video-list">
  <ul class="list-unstyled">
    <li *ngFor="let item of data" class="list-item">
      <figure class="cover-image" [title]="item.video_title ?? item.headline">
        <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline)"
          [routerLink]="[item.link]" class="d-block">
          <img [src]="item.cover_image_src" [alt]="item.headline+'-img'">
          <div class="cover-image-overlay">
            <a *ngIf="getEventType(item.start_date, item.end_date) !== 'UPCOMING'" [routerLink]="[item.link]"
              class="btn btn-square btn-white btn-play">
              <span class="icon-img">
                <icon-play></icon-play>
              </span>
            </a>
            <div class="bookmark"> <!-- TODO: Come back later. Click on bookmark should not navigate.-->
              <app-bookmark (click)="$event.stopPropagation()" [data]=" {content_type: item.content_type, content_id:item.content_id, content_name:
                item.headline}"></app-bookmark>
            </div>
          </div>
          <div *ngIf="item.video_duration && getEventType(item.start_date, item.end_date) !== 'UPCOMING'"
            class="label label-video-duration">{{item.video_duration}}</div>
        </a>
      </figure>
      <div class="info d-flex flex-column align-items-start">
        <h3 class="text-dark fw-normal">
          {{item.headline}}
          <div *ngIf="item.headline_2">{{item.headline_2}}</div>
        </h3>
        <h5 *ngIf="item.start_date" [innerHTML]="getFormatDate(item.start_date)" class="text-dark">
        </h5>
        <div *ngIf="item.descriptions" class="descriptions ">{{item.descriptions}}</div>

        <div class="action mt-3 mt-md-auto w-100">
          <a *ngIf="getEventType(item.start_date, item.end_date) === 'LIVE'"
            (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline, 'LIVE')"
            [routerLink]="[item.button.link]"
            class="btn btn-primary-live btn-sm w-100 w-md-auto">{{"EVENTS.BUTTON.LIVE_NOW" | translate}}</a>

          <a *ngIf="getEventType(item.start_date, item.end_date) === 'REGULAR'"
            (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline, 'REGULAR')"
            [routerLink]="[item.button.link]"
            class="btn btn-primary-recap btn-sm w-100 w-md-auto">{{"EVENTS.BUTTON.WATCH_RECAPS" | translate}}</a>

          <span *ngIf="getEventType(item.start_date, item.end_date) === 'UPCOMING'" ngbDropdown
            #addToCalendarDropDown="ngbDropdown" placement="bottom-start"
            class="custom-dropdown add-to-calendar-dropdown">
            <button
              (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline, 'UPCOMING')"
              type="button" class="btn btn-primary btn-sm btn-add-to-calendar w-100 w-md-auto"
              id="addToCalendarDropDown" ngbDropdownToggle>{{"GENERAL.BUTTON.ADD_TO_CALENDAR" | translate}}</button>
            <div ngbDropdownMenu aria-labelledby="addToCalendarDropDown">
              <a ngbDropdownItem (click)="onCalendarClick($event,'apple',item.content_id)">
                <span class="icon-img">
                  <icon-apple></icon-apple>
                </span>
                Apple Calendar
              </a>
              <a ngbDropdownItem (click)="onCalendarClick($event,'google',item.content_id)">
                <span class="icon-img">
                  <icon-google></icon-google>
                </span>
                Google Calendar
              </a>
              <!-- <a ngbDropdownItem (click)="onCalendarClick($event,'ical',item.content_id)">
                <span class="icon-img">
                  <icon-calendar></icon-calendar>
                </span>
                iCal</a> -->
              <a ngbDropdownItem (click)="onCalendarClick($event,'microsoft',item.content_id)">
                <span class="icon-img">
                  <icon-microsoft-365></icon-microsoft-365>
                </span>
                Microsoft Outlook Calendar</a>
              <a ngbDropdownItem (click)="onCalendarClick($event,'yahoo',item.content_id)">
                <span class="icon-img">
                  <icon-yahoo></icon-yahoo>
                </span>
                Yahoo Calendar</a>
            </div>
          </span>

        </div>
      </div>
    </li>
  </ul>
</div>