<div [@fadeInOut] class="inner-body">
  <div class="container">
    <section class="home-hero-banner mb-5">
      <app-single-banner [data]="bannerData"
        (openSignUpModalClick)="configService.isPreview ? undefined : openSignUpModal()"
        (openSignInModalClick)="configService.isPreview ? undefined : openSignInModal()"></app-single-banner>
    </section>

    <section *ngIf="articleData.length > 0" class="features">
      <h2 class="mb-4">{{"PUBLIC_HOME.CONNECTING_PARTNER" | translate}}</h2>
      <app-grid-list-3 [data]="articleData"></app-grid-list-3>
    </section>
    <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openSignInModal()">
      Sign in
    </button>
    <button class="btn btn-outline-primary mb-2 me-2" (click)="openSignUpModal()">
      Sign up
    </button>
    <button class="btn btn-outline-primary mb-2 me-2" (click)="openAvatarCreatorModal()">
      Avatar Creator
    </button> -->
  </div>
</div>