import { Component, OnInit, Input } from '@angular/core';
import { ImageBanner2Service } from '../../services';
import { CommonModule } from '@angular/common';
import { ImageBanner2Model } from '../../models';

@Component({
  selector: 'app-image-banner-2',
  standalone: true,
  templateUrl: './image-banner-2.component.html',
  styleUrl: './image-banner-2.component.scss',
  imports: [CommonModule],
  providers: [ImageBanner2Service]
})

export class ImageBanner2Component implements OnInit {
  //@Input({ required: true }) data!: ImageBannerModel[];
  @Input({ required: true }) dataUrl!: string | object;
  data!: ImageBanner2Model;

  constructor(
    private imageBanner2Service: ImageBanner2Service
  ) { }

  ngOnInit(): void {
    if (typeof this.dataUrl === 'string') {
      this.imageBanner2Service.getImageBanner2Data(this.dataUrl as string).subscribe((data: ImageBanner2Model) => {
        this.data = data;
      });
    } else if (typeof this.dataUrl === 'object') {
      this.data = this.dataUrl as ImageBanner2Model;
    } else {
      console.error('No dataUrl provided.');
    }
  }
}
