
import { Component } from '@angular/core';

@Component({
  selector: 'icon-bap-star-filled',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100"><path fill="#bda882" d="M99.88 38.281H61.689L49.888.086 38.087 38.281H-.108L30.79 61.89l-11.8 38.196 30.896-23.612 30.903 23.612-11.806-38.201L99.88 38.281z"/></svg>
  `,
  styles: [],
})
export class BapStarFilledIcon { }