import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StarUpgradedNoticeModalComponent } from '../../components/star-upgraded-notice-modal/star-upgraded-notice-modal.component';
import { TitleEarnedNoticeModalComponent } from '../../components/title-earned-notice-modal/title-earned-notice-modal.component';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';

@Component({
  selector: 'app-updates-list',
  standalone: true,
  imports: [ClickableEnlargeImageDirective],
  templateUrl: './updates-list.component.html',
  styleUrl: './updates-list.component.scss'
})
export class UpdatesListComponent {
  constructor(
    private modalService: NgbModal
  ) { }

  openStarUpgradedNoticeModal() {
    const modalRef = this.modalService.open(StarUpgradedNoticeModalComponent, {
      centered: true,
      windowClass: 'star-upgraded-notice-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  openTitleEarnedNoticeModal() {
    const modalRef = this.modalService.open(TitleEarnedNoticeModalComponent, {
      centered: true,
      windowClass: 'title-earned-notice-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}
