
import { Component } from '@angular/core';

@Component({
  selector: 'icon-arrow-up',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M411.843 234.75C408.686 238.25 404.343 240 399.999 240C396.155 240 392.296 238.625 389.233 235.844L239.999 100.172V464C239.999 472.844 232.843 480 223.999 480S207.999 472.844 207.999 464V100.172L58.764 235.844C52.249 241.781 42.139 241.375 36.155 234.75C30.218 228.219 30.702 218.094 37.233 212.156L213.233 52.156C219.327 46.594 228.671 46.594 234.764 52.156L410.764 212.156C417.296 218.094 417.78 228.219 411.843 234.75Z"/></svg>
  `,
  styles: [],
})
export class ArrowUpIcon { }