export const environment = {
    production: false,

    domain: "https://scc-dev-api.shoppertise.com",
    clientAuthEnabled: true,
    clientApiKey: "c2J1eC1jb21tdW5pdHktd2ViOjFxYXpaQVEh",

    authPath: "/auth",
    analyticsPath: "/analytics",
    bookmarkPath: "/bookmark",
    cmsContentPath: "/cms/content",
    partnerPath: "/partner",
    partnerNotificationPath: "/partner-notification",
    profilePath: "/profile",
    prefixPath: "/api/v1",
    workshopPath: "/workshops",
    accountPath: "/account",

    RBC2024: "/events/9adf0e27b7dface53ac3f71d320874dd",

    captchaSitekey: "0x4AAAAAAAR-hj1sIQll7qvH",

    gaTrackingId: "G-KESCS641H8"
}