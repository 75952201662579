import { Component, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { HTMLContentsService } from '../../services';
import { HTMLContentsModel } from '../../models';
import { CommonModule } from '@angular/common';
import { MarkdownPipe } from '../../markdown.pipe';

@Component({
  selector: 'app-html-contents',
  standalone: true,
  imports: [CommonModule, MarkdownPipe],
  templateUrl: './html-contents.component.html',
  styleUrl: './html-contents.component.scss',
  animations: [fadeInOut],
  providers: [HTMLContentsService]
})
export class HtmlContentsComponent implements OnInit {
  @Input({ required: true }) dataUrl!: string;
  data?: HTMLContentsModel;

  constructor(
    private HTMLContentsService: HTMLContentsService
  ) { }

  ngOnInit(): void {
    if (this.dataUrl) {
      this.HTMLContentsService.getHTMLContentsData(this.dataUrl).subscribe((data: HTMLContentsModel) => {
        this.data = data;
      });
    } else {
      console.error('No dataUrl provided.');
    }
  }
}
