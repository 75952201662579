<div class="component-banner-carousel-1">
  <owl-carousel-o *ngIf="data" [options]="bannerCarouselOptions"
    [ngClass]="{ 'single-slide-carousel': data && data.length === 1 }">
    <ng-container *ngFor="let slide of data; let i = index">
      <ng-template carouselSlide>
        <div class="banner-wrapper" [ngClass]="{
            'banner-live': slide.type.toUpperCase() === 'LIVE',
            'banner-upcoming': slide.type.toUpperCase() === 'UPCOMING',
            'banner-regular': slide.type.toUpperCase() === 'REGULAR'
          }">
          <figure class="banner-image" [title]="slide.headline">
            <img *ngIf="slide.image_src.sm" [src]="slide.image_src.sm" class="d-md-none"
              alt="{{ slide.headline }}-img" />
            <img [src]="slide.image_src.lg" [ngClass]="{ 'd-none d-md-block': slide.image_src.sm }"
              alt="{{ slide.headline }}-img" />
          </figure>
          <div class="carousel-slide-inner-wrapper">
            <div class="content-wrapper">
              <div class="flex-md-1 pe-md-3">
                <div class="d-flex flex-wrap align-items-center mb-2">
                  <div *ngIf="slide.type.toUpperCase() === 'LIVE'" class="banner-top-label label-live">
                    {{"GENERAL.BUTTON.LIVE" | translate}}
                  </div>
                  <div *ngIf="slide.type.toUpperCase() !== 'LIVE'" class="banner-top-label">
                    {{ slide.top_label }}
                  </div>
                </div>

                <h2 class="banner-headline">{{ slide.headline }}</h2>
                <h5 class="banner-subheadline text-white">
                  {{ slide.subheadline }}
                </h5>
                <div class="banner-descriptions ">{{ slide.descriptions }}</div>
              </div>
              <div *ngIf="slide.type.toUpperCase() === 'UPCOMING'" class="countdown-container">
                <countdown [config]="countdownConfigArr[i]" />
              </div>
              <div class="banner-actions flex-md-100">
                <a *ngIf="slide.type.toUpperCase() === 'LIVE'"
                  (click)="
                  handleLink((slide.button?.link || ''), slide.content_id, slide.content_type);
                  $event.preventDefault();
                  this.analyticsService.sendClickEventAnalytic('CONTENT', slide.content_type, slide.content_id, slide.headline, 'LIVE');" href="javascript:void(0)"
                  class="btn btn-primary-live w-100 w-md-auto">{{"GENERAL.BUTTON.JOIN_NOW" | translate}}</a>

                <!-- <a *ngIf="slide.type.toUpperCase() === 'REGULAR'" [href]="slide.button?.link"
                  class="btn btn-alert w-100 w-md-auto">{{ slide.button?.label }}</a> -->

                <a *ngIf="slide.type.toUpperCase() === 'REGULAR'" (click)="
                  this.analyticsService.sendClickEventAnalytic('CONTENT', slide.content_type, slide.content_id, slide.headline, 'REGULAR');
                  handleLink((slide.button?.link || ''), slide.content_id, slide.content_type);
                  $event.preventDefault();" href="javascript:void(0)" class="btn btn-alert w-100 w-md-auto">
                  {{ slide.button?.label }}
                </a>

                <span *ngIf="slide.type.toUpperCase() === 'UPCOMING'" ngbDropdown #addToCalendarDropDown="ngbDropdown"
                  placement="bottom-start" class="custom-dropdown add-to-calendar-dropdown">
                  <button
                    (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', slide.content_type, slide.content_id, slide.headline, 'UPCOMING');"
                    type="button" class="btn btn-primary btn-add-to-calendar w-100 w-md-auto" id="addToCalendarDropDown"
                    ngbDropdownToggle>
                    {{"GENERAL.BUTTON.ADD_TO_CALENDAR" | translate}}
                  </button>

                  <div ngbDropdownMenu aria-labelledby="addToCalendarDropDown">
                    <a ngbDropdownItem (click)="onCalendarClick($event,'apple',slide.content_id)">
                      <span class=" icon-img">
                        <icon-apple></icon-apple>
                      </span>
                      Apple Calendar
                    </a>
                    <a ngbDropdownItem (click)="onCalendarClick($event,'google',slide.content_id)">
                      <span class="icon-img">
                        <icon-google></icon-google>
                      </span>
                      Google Calendar
                    </a>
                    <!-- <a ngbDropdownItem (click)="onCalendarClick($event,'ical',slide.content_id)">
                        <span class="icon-img">
                          <icon-calendar></icon-calendar>
                        </span>
                        iCal</a> -->
                    <a ngbDropdownItem (click)="onCalendarClick($event,'microsoft',slide.content_id)">
                      <span class="icon-img">
                        <icon-microsoft-365></icon-microsoft-365>
                      </span>
                      Microsoft Outlook Calendar</a>
                    <a ngbDropdownItem (click)="onCalendarClick($event,'yahoo',slide.content_id)">
                      <span class="icon-img">
                        <icon-yahoo></icon-yahoo>
                      </span>
                      Yahoo Calendar</a>
                  </div>
                </span>
              </div>
            </div>




          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>