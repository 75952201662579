<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-5">
      <p *ngIf="!isLoggedIn" class="fs-4 ">{{"ONBOARDING.RESET_PASSWORD_SUCCESS.DESC" | translate}}
      </p>
      <p *ngIf="isLoggedIn" class="fs-4 ">{{"UPDATE_PASSWORD_SUCCESS.DESC" | translate}}</p>
    </div>

    <div class="call-to-actions">
      <button *ngIf="!isLoggedIn" class="btn btn-secondary w-100 w-lg-auto" [routerLink]="['/home']">
        {{"ONBOARDING.RESET_PASSWORD_SUCCESS.NEXT" | translate}}
      </button>
      <button *ngIf="isLoggedIn" class="btn btn-secondary w-100 w-lg-auto" [routerLink]="['/my-profile']">
        {{"UPDATE_PASSWORD_SUCCESS.NEXT" | translate}}
      </button>
    </div>

  </div>
</div>