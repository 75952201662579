
import { Component } from '@angular/core';

@Component({
  selector: 'icon-notification',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 20 20"><path d="M16.8 12.6V8.8c0-2.8-1.7-5.2-4.1-6.2C12.6 1.1 11.4 0 10 0S7.4 1.1 7.4 2.5C4.9 3.5 3.3 6 3.3 8.7v3.8c-1.1 0-2 1-2 2.1s1 2.1 2.2 2.1H7c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1h3.5c1.2 0 2.2-1 2.2-2.1s-.9-2.1-2-2.1ZM10 18.4c-.8 0-1.5-.7-1.5-1.5h3c0 .8-.7 1.5-1.5 1.5Zm6.6-3.2H3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c.8 0 1.5-.7 1.5-1.5v-4c0-2.2 1.5-4.2 3.6-4.9l.6-.2V2.5c0-.5.4-1 1-1s1 .4 1 1v1.1l.6.2c2.1.7 3.6 2.6 3.6 4.9v4c0 .8.7 1.5 1.5 1.5s.5.2.5.5-.2.5-.5.5Z" style="stroke-width:0"/></svg>
  `,
  styles: [],
})
export class NotificationIcon { }