export enum ContentTypeName {
    SBUXPUBLICHOMEBANNER = 'Public Home Banner',
    SBUXPUBLICHOMEARTICLE = 'PUBLIC_HOME.CONNECTING_PARTNER',
    SBUXHOMEBANNER = 'Home Banner',
    SBUXHOMERECOMMENDEDLIST = 'HOME.RECOMMENDED',
    SBUXHOMEEXPLORELIST = 'HOME.EXPLORE.HEADER',
    SBUXHOMESPECIALTOPIC = 'HOME.SPECIAL_TOPIC',
    SBUXHOMENOTICE = 'Notice',
    SBUXHOMENOTIFICATION = 'Notification',
    SBUXEVENT = 'GENERAL.MENU.EVENTS',
    SBUXEVENTACTIVITY = 'GENERAL.MENU.EVENTS',
    SBUXEVENTVIDEO = 'HOME.EXPLORE.EVENT_VIDEO',
    SBUXEVENTBANNER = 'Event Banner',
    SBUXFEATUREDEVENT = 'Featured Event',
    SBUXEVENTHIGHLIGHT = 'Event Highlight',
    SBUXEVENTARCHIVE = 'EVENTS.ARCHIVE',
    SBUXEVENTSCHEDULE = 'EVENT_SCHEDULE.HEADER',
    SBUXLEARNINGTOPIC = 'HOME.EXPLORE.LEARNING_TOPIC',
    SBUXLEARNINGLESSON = 'HOME.EXPLORE.LEARNING_LESSON',
    SBUXLEARNINGFILTER = 'Learning Filter',
    SBUXCOMPETITION = 'GENERAL.MENU.COMPETITIONS',
    SBUXCOMPETITIONINFORMATION = 'COMPETITIONS.ABOUT.HEADER',
    SBUXCOMPETITIONVIDEO = 'COMPETITIONS.SEARCH.HEADER',
    SBUXCOMPETITIONSTEERINGCOMMITTEE = 'COMPETITIONS.COMMITTEE.HEADER',
    SBUXCOMPETITIONYEARFILTER = 'Competition Year Filter',
    SBUXBAPTOPIC = 'Bap Topic',
    SBUXBAPTOPICITEM = 'Bap Topic Item',
    SBUXWORKSHOP = 'GENERAL.MENU.WORKSHOPS',
    SBUXWORKSHOPBANNER = 'Workshop Banner',
    SBUXWORKSHOPARCHIVE = 'WORKSHOPS.ARCHIVE',
    SBUXPERSON = 'Person'
}

export const ContentTypeSegment = [
    { segment: 'PUBLICHOME', contentType: ['SBUXPUBLICHOMEBANNER', 'SBUXPUBLICHOMEARTICLE'] },
    { segment: 'HOME', contentType: ['SBUXHOMERECOMMENDEDLIST', 'SBUXHOMEEXPLORELIST', 'SBUXHOMESPECIALTOPIC', 'SBUXHOMENOTIFICATION', 'SBUXHOMENOTICE', 'SBUXHOMEBANNER'] },
    { segment: 'LEARNING', contentType: ['SBUXLEARNINGTOPIC', 'SBUXLEARNINGLESSON', 'SBUXLEARNINGFILTER'] },
    { segment: 'COMPETITIONS', contentType: ['SBUXCOMPETITION', 'SBUXCOMPETITIONINFORMATION', 'SBUXCOMPETITIONVIDEO', 'SBUXCOMPETITIONSTEERINGCOMMITTEE', 'SBUXCOMPETITIONYEARFILTER'] },
    { segment: 'EVENTS', contentType: ['SBUXEVENT', 'SBUXEVENTACTIVITY', 'SBUXEVENTVIDEO', 'SBUXEVENTBANNER', 'SBUXFEATUREDEVENT', 'SBUXEVENTHIGHLIGHT', 'SBUXEVENTARCHIVE', 'SBUXEVENTSCHEDULE'] },
    { segment: 'BAP', contentType: ['SBUXBAPTOPIC', 'SBUXBAPTOPICITEM'] },
    { segment: 'WORKSHOPS', contentType: ['SBUXWORKSHOP', 'SBUXWORKSHOPBANNER', 'SBUXWORKSHOPARCHIVE'] },
    { segment: 'PERSON', contentType: ['SBUXPERSON'] }
]

export enum ContentTypeSegmentName {
    PUBLICHOME = 'Public Home',
    HOME = 'GENERAL.MENU.HOME',
    LEARNING = 'GENERAL.MENU.LEARNING_CENTRE',
    COMPETITIONS = 'GENERAL.MENU.COMPETITIONS',
    EVENTS = 'GENERAL.MENU.EVENTS',
    BAP = 'GENERAL.MENU.BAP',
    WORKSHOPS = 'GENERAL.MENU.WORKSHOPS',
    PERSON = 'Person'
}

export enum ContentTypeGroupLink {
    SBUXPUBLICHOMEBANNER = '/public',
    SBUXPUBLICHOMEARTICLE = '/public',
    SBUXHOMEBANNER = '/home',
    SBUXHOMERECOMMENDEDLIST = '/home',
    SBUXHOMEEXPLORELIST = '/home',
    SBUXHOMESPECIALTOPIC = '/home',
    SBUXHOMENOTICE = '/home',
    SBUXHOMENOTIFICATION = '/home',
    SBUXEVENT = '/events',
    SBUXEVENTACTIVITY = '/events/activities',
    SBUXEVENTVIDEO = '/events/videos',
    SBUXEVENTBANNER = '/events',
    SBUXFEATUREDEVENT = '/events',
    SBUXEVENTHIGHLIGHT = '/events',
    SBUXEVENTARCHIVE = '/events',
    SBUXEVENTSCHEDULE = '/events/event-schedule',
    SBUXLEARNINGTOPIC = '/learn',
    SBUXLEARNINGLESSON = '/learn',
    SBUXLEARNINGFILTER = '/learn',
    SBUXCOMPETITION = '/competitions',
    SBUXCOMPETITIONINFORMATION = '/competitions',
    SBUXCOMPETITIONVIDEO = '/competitions/videos',
    SBUXCOMPETITIONSTEERINGCOMMITTEE = '/competitions/competition-steering-committee',
    SBUXCOMPETITIONYEARFILTER = '/competitions',
    SBUXBAPTOPIC = '/bap',
    SBUXBAPTOPICITEM = '/bap',
    SBUXWORKSHOP = '/workshops/articles',
    SBUXWORKSHOPBANNER = '/workshops',
    SBUXWORKSHOPARCHIVE = '/workshops/videos',
    SBUXPERSON = '/person',
}