<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-4">
      <h1>{{"ONBOARDING.RESET_PASSWORD.HEADER" | translate}}</h1>
      <p>{{"ONBOARDING.RESET_PASSWORD.DESC" | translate}}</p>
    </div>

    <div class="mb-5">
      <fieldset class="mb-4" [formGroup]="resetPasswordForm">
        <div class="form-group">
          <label for="partner-new-password-input"
            class="form-label">{{"ONBOARDING.PASSWORD.PASSWORD" | translate}}</label>

          <div class="d-flex flex-wrap">
            <input type="password" class="form-control flex-1" id="partner-new-password-input"
              [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="password"
              (ngModelChange)="frontendError = false; serverError = false; matchPassword = true;">
            <button #tooltipButton="ngbTooltip" class="btn btn-circle btn-password-tooltips" placement="auto"
              [ngbTooltip]="passwordTooltip" tooltipClass="password-tooltips">
              <span class="icon-img" [ngClass]="{'danger':errorList.length > 0}">
                <icon-circle-info></icon-circle-info>
              </span>
            </button>
          </div>

          <div *ngIf="frontendError" class="invalid-message">
            {{frontendErrorMessage | translate}}
          </div>

          <!-- Pending copy translate -->
          <div *ngIf="serverError" class="invalid-message">
            {{serverErrorMessage | translate}}
          </div>

          <ng-template #passwordTooltip>
            {{"ONBOARDING.RESET_PASSWORD.CRITERIA.TITLE" | translate}}
            <ul class="list-unstyled">
              <li [ngClass]="{'text-danger':errorList.includes('invalidCharLength')}">
                {{"ONBOARDING.RESET_PASSWORD.CRITERIA.LENGTH" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidLowercase')}">
                {{"ONBOARDING.RESET_PASSWORD.CRITERIA.LOWER_CASE" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidUppercase')}">
                {{"ONBOARDING.RESET_PASSWORD.CRITERIA.UPPER_CASE" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidNumber')}">
                {{"ONBOARDING.RESET_PASSWORD.CRITERIA.NUMBER" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidSpecialChar')}">
                {{"ONBOARDING.RESET_PASSWORD.CRITERIA.SPECIAL_CHARACTER" | translate}}
              </li>
            </ul>
            {{"ONBOARDING.RESET_PASSWORD.TIPS.TITLE" | translate}}
            <ul class="list-unstyled mb-0">
              <li>{{"ONBOARDING.RESET_PASSWORD.TIPS.CONSECUTIVE" | translate}}</li>
              <li>{{"ONBOARDING.RESET_PASSWORD.TIPS.USERNAME" | translate}}</li>
              <li>{{"ONBOARDING.RESET_PASSWORD.TIPS.COMMON_WORD" | translate}}</li>
            </ul>
          </ng-template>
        </div>

        <div class="form-group">
          <label for="partner-confirm-password-input"
            class="form-label">{{"ONBOARDING.PASSWORD.CONFIRM_PASSWORD" | translate}}</label>
          <input type="password" class="form-control" name="partner-confirm-password-input"
            id="partner-confirm-password-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="confirmPassword"
            (ngModelChange)="frontendError = false; serverError = false; matchPassword = true;">
          <div *ngIf="!matchPassword" class="invalid-message">
            {{frontendErrorMessage | translate}}
          </div>
        </div>
      </fieldset>
    </div>

    <div class="call-to-actions">
      <button type="button" *ngIf="!isSubmitting" class="btn btn-primary w-100 w-lg-auto"
        (click)="onSubmit()">{{"ONBOARDING.RESET_PASSWORD.SUBMIT" | translate}}</button>
      <button type="button" *ngIf="isSubmitting" class="btn btn-spinner w-100 w-lg-auto" disabled>
        <span class="icon-img">
          <icon-spinner></icon-spinner>
        </span>
      </button>
    </div>
  </div>
</div>