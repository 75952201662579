
import { Component } from '@angular/core';

@Component({
  selector: 'icon-circle-star',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_469" data-name="Rectangle 469" width="15.204" height="14.534" transform="translate(0 0)" fill="#00754a"/>
    </clipPath>
  </defs>
  <g id="Ellipse_31" data-name="Ellipse 31" fill="#fff" stroke="#000" stroke-width="2">
    <circle cx="16" cy="16" r="16" stroke="none"/>
    <circle cx="16" cy="16" r="15" fill="none"/>
  </g>
  <g id="Group_1164" data-name="Group 1164" transform="translate(8.397 8.732)">
    <g id="Group_1163" data-name="Group 1163" transform="translate(0 0.001)" clip-path="url(#clip-path)">
      <path id="Path_479" data-name="Path 479" d="M15.163,5.508a.723.723,0,0,0-.586-.492L10.2,4.379,8.248.409a.723.723,0,0,0-1.3,0L4.993,4.379.617,5.016A.714.714,0,0,0,.212,6.231L3.38,9.32l-.723,4.34a.723.723,0,0,0,1.049.759L7.6,12.394l3.913,2.061a.725.725,0,0,0,1.049-.781l-.723-4.34L15,6.246a.723.723,0,0,0,.159-.738Z" transform="translate(0 -0.001)" fill="#00754a"/>
    </g>
  </g>
</svg>
  `,
  styles: [],
})
export class CircleStarIcon { }