<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div class="modal-inner-header">
      <figure>
        <img src="/assets/images/profile/img-banner-star-upgraded.png">
      </figure>
    </div>
    <div class="modal-inner-body">

      <div class="inner-body-heading">
        <h2>Double Congratulations!</h2>
      </div>

      <div class="inner-body-contents ">
        <p>You've just earned a new Star Path milestone AND unlocked a Title Path achievement!</p>
        <p>Check your profile to see your latest achievements.</p>
      </div>

      <div class="inner-body-footer mt-3">
        <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="dismissModal()">OK</button>
      </div>
    </div>
  </div>
</div>