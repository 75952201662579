<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="video-container">
    <iframe id="youtube-video-iframe" width="100%" height="100%" [src]="videoSrc" title="YouTube video player"
      frameborder="0" referrerpolicy="no-referrer-when-downgrade"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen></iframe>
  </div>
</div>