import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventVideoListModel } from '../../models';
import { PlayIcon } from "../../icons/icon-play";
import { BookmarkComponent } from "../bookmark/bookmark.component";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from '../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { AppleIcon } from "../../icons/icon-apple";
import { GoogleIcon } from "../../icons/icon-google";
import { Microsoft365Icon } from "../../icons/icon-microsoft-365";
import { YahooIcon } from "../../icons/icon-yahoo";
import { CalendarModel } from '../../models/calendar.model';
import { RouterLink } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-event-video-list',
  standalone: true,
  templateUrl: './event-video-list.component.html',
  styleUrl: './event-video-list.component.scss',
  imports: [CommonModule, PlayIcon, BookmarkComponent, NgbDropdownModule, TranslateModule, AppleIcon, GoogleIcon, Microsoft365Icon, YahooIcon, RouterLink]
})
export class EventVideoListComponent {
  @Input({ required: true }) data!: EventVideoListModel[];
  calendarField: CalendarModel = {
    title: '',
    startDateTime: '',
    endDateTime: '',
    location: ''
  }

  constructor(
    private contentService: ContentService,
    public analyticsService: AnalyticsService
  ) { }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }

  getEventType(start_date: number, end_date: number): string {
    return this.contentService.getEventStatus(start_date, end_date);
  }

  onCalendarClick(event: Event, type: string, contentId: string) {
    event.preventDefault();
    const selectedBanner = this.data.find((obj: any) => obj.content_id === contentId);

    this.calendarField.title = selectedBanner!.headline;
    this.calendarField.description = selectedBanner!.descriptions;
    this.calendarField.startDateTime = this.contentService.formatDate3(selectedBanner!.start_date!.toString(), type);
    this.calendarField.endDateTime = this.contentService.formatDate3(selectedBanner!.end_date!.toString(), type);
    this.calendarField.link = selectedBanner!.button?.link

    this.contentService.createCalendarDeepLink(this.calendarField, type);
  }
}
