import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProofModalComponent } from '../upload-proof-modal/upload-proof-modal.component';

interface VerifyUpgradeNoticeData {
  currentTier: string;
  upgradeType: string;
  remainingDays?: number;
}

@Component({
  selector: 'app-verify-upgrade-notice',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './verify-upgrade-notice.component.html',
  styleUrl: './verify-upgrade-notice.component.scss'
})
export class VerifyUpgradeNoticeComponent {
  @Input() data: VerifyUpgradeNoticeData = {
    currentTier: '',
    remainingDays: 0,
    upgradeType: ''
  };

  private readonly blackArponTier = ['SCA_CERTIFIED_COFFEE_MASTER', 'STAR_1', 'STAR_2', 'STAR_3'];

  constructor(
    private modalService: NgbModal
  ) { }

  get blackApronNeedsSCAVerification(): boolean {
    return this.data.upgradeType === 'SCA' && this.blackArponTier.includes(this.data.currentTier);
  }

  openUploadProofModal() {
    const modalRef = this.modalService.open(UploadProofModalComponent, {
      centered: true,
      windowClass: 'upload-proof-modal',
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.upgradeType = this.data.upgradeType;
  }
}
