<div [@fadeInOut] class="inner-body page-competition-info p-0 pb-5">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="heading">
    <div class="container d-flex flex-column align-items-center justify-content-center mw-lg">
      <figure class="logo mb-4">
        <img src="/assets/images/competitions/img-barista-championships-logo.webp"
          [alt]="'COMPETITIONS.INFORMATIONS.BARISTA.TITLE' | translate" />
      </figure>
      <h1 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.TITLE' | translate}}</h1>
    </div>
  </section>

  <section class="content-body">
    <div class="container mw-lg">
      <div class="overview mb-6">
        <div class="mb-6">
          <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.OBJECTIVE' | translate}}</h3>
          <p>{{'COMPETITIONS.INFORMATIONS.BARISTA.DESC_1' | translate}}</p>
        </div>

        <div class="mastery mb-4">
          <div class="row justify-content-center">
            <div class=" col-12 col-sm-6 col-lg-4">
              <div class="mastery-item mb-5 d-flex flex-column align-items-center">
                <figure>
                  <img src="/assets/images/competitions/img-coffee-knowledge.webp" alt="Coffee Knowledge" />
                </figure>
                <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.TAG_1' | translate}}</h3>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
              <div class="mastery-item mb-5 d-flex flex-column align-items-center">
                <figure>
                  <img src="/assets/images/competitions/img-beverage-mastery.webp" alt="Beverage Mastery" />
                </figure>
                <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.TAG_2' | translate}}</h3>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
              <div class="mastery-item mb-5 d-flex flex-column align-items-center">
                <figure>
                  <img src="/assets/images/competitions/img-customer-connection.webp" alt="Customer Connection" />
                </figure>
                <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.TAG_3' | translate}}</h3>
              </div>
            </div>
          </div>
          <p>{{'COMPETITIONS.INFORMATIONS.BARISTA.DESC_2' | translate}}</p>
          <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.BARISTA.COMPETITION_TIME' | translate}}: {{'COMPETITIONS.INFORMATIONS.BARISTA.MINUTES' | translate}}</h3>
        </div>
      </div>

      <div class="competition-parts">
        <div class="part">
          <div class="row">
            <div class="col-12 mb-4">
              <h3 class="text-primary-dark part-name">{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_1.HEADER' | translate}}</h3>
            </div>

            <div class="col-12 col-md-6 text-center mb-4 mb-md-0">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">⭐️</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.OBJECTIVE' | translate}}</h3>
              <div class="px-md-3 px-lg-4"><strong>{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_1.OBJECTIVE' | translate}}</strong></div>
            </div>

            <div class="col-12 col-md-6 text-center">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">🎯</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.SCORING_CRITERIA' | translate}}</h3>
              <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.BARISTA.PART_1.SCORING_CRITERIA' | translate"></div>
            </div>
          </div>
        </div>

        <div class="part">
          <div class="row">
            <div class="col-12 mb-4">
              <h3 class="text-primary-dark part-name">{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_2.HEADER' | translate}}</h3>
            </div>

            <div class="col-12 col-md-6 text-center mb-4 mb-md-0">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">⭐️</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.OBJECTIVE' | translate}}</h3>
              <div class="px-md-3 px-lg-4"><strong>{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_2.OBJECTIVE' | translate}}</strong></div>
            </div>

            <div class="col-12 col-md-6 text-center">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">🎯</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.SCORING_CRITERIA' | translate}}</h3>
              <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.BARISTA.PART_2.SCORING_CRITERIA' | translate">
              </div>
            </div>
          </div>
        </div>

        <div class="part">
          <div class="row">
            <div class="col-12 mb-4">
              <h3 class="text-primary-dark part-name">{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_3.HEADER' | translate}}</h3>
            </div>

            <div class="col-12 col-md-6 text-center mb-4 mb-md-0">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">⭐️</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.OBJECTIVE' | translate}}</h3>
              <div class="px-md-3 px-lg-4"><strong>{{'COMPETITIONS.INFORMATIONS.BARISTA.PART_3.OBJECTIVE' | translate}}</strong></div>
            </div>

            <div class="col-12 col-md-6 text-center">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">🎯</span><br>{{'COMPETITIONS.INFORMATIONS.BARISTA.SCORING_CRITERIA' | translate}}</h3>
              <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.BARISTA.PART_3.SCORING_CRITERIA' | translate">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>