<footer>
  <div class="container">
    <div class="footer-top">
      <div class="footer-logo">
        <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'LOGO')"
          routerLink="/" class="footer-logo-link">
          <logo-scc></logo-scc>
        </a>
      </div>
      <div class="footer-nav">
        <ul class="list-unstyled footer-nav-list">
          <h6>{{"GENERAL.FOOTER.EXPLORE" | translate}}</h6>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'LEARING_CENTRE')"
              routerLink="/learn">{{"GENERAL.FOOTER.LEARNING_CENTRE" | translate}}</a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'COMPETITIONS')"
              routerLink="/competitions">{{"GENERAL.FOOTER.COMPETITIONS" | translate}}</a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'EVENTS')"
              routerLink="/events">{{"GENERAL.FOOTER.EVENTS" | translate}}</a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'BAP')"
              routerLink="/bap">
              {{"GENERAL.MENU.BAP" | translate}}
            </a>
          </li>
          <!-- <li>
            <a 
            (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'WORKSHOPS')"
            routerLink="/workshops">{{"GENERAL.FOOTER.WORKSHOPS" | translate}}</a>
          </li> -->
        </ul>

        <ul class="list-unstyled footer-nav-list">
          <h6>{{"GENERAL.FOOTER.COMPANY" | translate}}</h6>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'ABOUT_US')"
              routerLink="/about-us">{{"GENERAL.FOOTER.ABOUT_US" | translate}}</a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'PRIVACY_STATEMENT')"
              routerLink="/privacy">{{"GENERAL.FOOTER.PRIVACY_STATEMENT" | translate}}</a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'TERMS_OF_USE')"
              routerLink="/terms">{{"GENERAL.FOOTER.TERMS_OF_USE" | translate}}</a>
          </li>
        </ul>

        <ul class="list-unstyled footer-nav-list">
          <h6>{{"GENERAL.FOOTER.SUPPORT" | translate}}</h6>
          <!-- <li>
            <a 
            (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'FAQ')"
            routerLink="/faq">{{"GENERAL.FOOTER.FAQ" | translate}}</a>
          </li> -->
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('FOOTER', undefined, undefined, undefined, 'CONTACT_US')"
              href=" mailto:starbuckscoffeecommunity@starbucks.com"
              target="_blank">{{"GENERAL.FOOTER.CONTACT_US" | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom">
      <small>{{"GENERAL.FOOTER.TRADEMARK" | translate}}</small>
    </div>
  </div>

  <button *ngIf="showScrollToTopButton" class="btn btn-circle btn-back-to-top d-lg-none" (click)="scrollToTop()"
    [@fadeIn]>
    <span class="icon-img">
      <icon-arrow-up></icon-arrow-up>
    </span>
  </button>
</footer>