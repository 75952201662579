<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="home-hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="eventBannerData"></app-image-banner-1>
    </div>
  </section>

  <section class="event-features mb-5">
    <div class="container">
      <div class="mb-4">
        <h2>{{eventData.title}}</h2>
        <p *ngIf="eventData.descriptions">{{eventData.descriptions}}</p>
      </div>
      <app-grid-list-1 [data]="eventActivityData" [styleVariant]="2"></app-grid-list-1>
    </div>
  </section>

  <section *ngIf="eventHighlightActivitiesData.length > 0" class="event-archive">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"EVENT.HIGHLIGHT" | translate}}</h2>
      <app-list-carousel-1 [data]="eventHighlightActivitiesData"></app-list-carousel-1>
    </div>
  </section>
</div>