<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section *ngIf="bannerData.length > 0" class="hero-banner mb-5">
    <div class="container">
      <app-banner-carousel-1 [data]="bannerData"></app-banner-carousel-1>
    </div>
  </section>

  <section class="mb-5 text-center">
    <div class="container">
      <h1 class="headline mb-3">{{"WORKSHOPS.HEADER" | translate}}</h1>
      <div class="descriptions">{{"WORKSHOPS.DESC" | translate}}</div>
    </div>
  </section>

  <section *ngIf="upcomingWorkshopData.length > 0" class="upcoming-workshop mb-4">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"WORKSHOPS.UPCOMING" | translate}}</h2>
      <app-list-carousel-2 [data]="upcomingWorkshopData"></app-list-carousel-2>
    </div>
  </section>

  <section *ngIf="pastWorkshopData.length > 0" class="mb-5 ">
    <div class="container">
      <h2 class="headline mb-4">{{"WORKSHOPS.ARCHIVE" | translate}}</h2>

      <div class="video-list">
        <app-grid-list-with-date-time [data]="pastWorkshopData"></app-grid-list-with-date-time>

        <!-- <div class="d-flex align-items-center justify-content-center mb-5">
          <button type="button" class="btn btn-white btn-sm">Load more</button>
        </div> -->
      </div>
    </div>
  </section>


</div>