<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-in/img-signin-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-in/img-signin-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.WELCOME_BACK.HEADER" | translate}}</h2>
    </div>

    <div class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="loginForm">
        <div class="form-group">
          <label for="partner-email-input" class="form-label">{{"ONBOARDING.WELCOME_BACK.EMAIL" | translate}}</label>
          <input type="email" class="form-control" id="partner-email-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="email" (ngModelChange)="frontendEmailError = false; serverPasswordError = false;">

          <div *ngIf="frontendEmailError" class="invalid-message">
            {{frontendEmailErrorMessage | translate}}
          </div>
        </div>

        <div class="form-group">
          <label for="partner-password-input" class="form-label">{{"ONBOARDING.WELCOME_BACK.PASSWORD" | translate}}</label>
          <input type="password" class="form-control" id="partner-password-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="password" (ngModelChange)="serverPasswordError = false;">

          <div *ngIf="serverPasswordError" class="invalid-message">
            {{serverPasswordErrorMessage | translate}}
          </div>

        </div>
      </fieldset>

      <p>{{"ONBOARDING.WELCOME_BACK.DESC" | translate}}<a (click)="onSignUpAction()">{{"ONBOARDING.WELCOME_BACK.SIGN_UP" | translate}}</a></p>
      <p><a (click)="onForgetPasswordAction()">{{"ONBOARDING.WELCOME_BACK.FORGOT_PASSWORD" | translate}}</a></p>
    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()"
        [disabled]="!loginForm.valid">{{"ONBOARDING.WELCOME_BACK.NEXT" | translate}}
      </button>
    </div>
  </div>
</div>