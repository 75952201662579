import { Component } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SpeakerSoundIcon } from "../../icons";

@Component({
  selector: 'app-video-language',
  standalone: true,
  templateUrl: './video-language.component.html',
  styleUrl: './video-language.component.scss',
  imports: [NgbDropdownModule, SpeakerSoundIcon]
})
export class VideoLanguageComponent {
  selectedLanguage = 'ENGLISH';

  changeLanguage(language: string) {
    this.selectedLanguage = language;
  }
}
