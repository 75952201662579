<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-avatar-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-avatar-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body justify-content-center">
    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.CREATE_AVATAR.HEADER" | translate}}</h2>
    </div>
    <div class="inner-body-contents flex-auto">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()">
        {{"ONBOARDING.CREATE_AVATAR.NEXT" | translate}}
      </button>
    </div>
  </div>
</div>