import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompetitionInformationModel } from '../models';

@Injectable({
  providedIn: 'any'
})

export class CompetitionInformationService {
  //private dataUrl = '/assets/data/banner-carousel-1.json';

  constructor(private http: HttpClient) { }

  getCompetitionInformationData(dataUrl: string): Observable<CompetitionInformationModel> {
    return this.http.get<CompetitionInformationModel>(dataUrl);
  }
}
