
import { Component } from '@angular/core';

@Component({
  selector: 'icon-coffee-bean-bag',
  standalone: true,
  template: `
  <svg version="1.1" id="Group_1168" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 46.6 56.2" enable-background="new 0 0 46.6 56.2" xml:space="preserve">
<path id="Path_480" d="M41.8,11V1.8C41.8,0.8,41,0,40,0H6.6c-1,0-1.8,0.8-1.8,1.8V11C1.6,19,0,27.6,0,36.2v13.4
	c0,3.6,2.9,6.6,6.6,6.6c0,0,0,0,0,0H40c3.6,0,6.6-2.9,6.6-6.6c0,0,0,0,0,0V36.2C46.6,27.6,45,19,41.8,11 M32.3,3.6h6v6h-6L32.3,3.6z
	 M17.9,3.6h10.8v10.2c0,3-2.3,5.4-5.3,5.5s-5.4-2.3-5.5-5.3c0,0,0-0.1,0-0.1L17.9,3.6z M8.4,3.6h6v6h-6L8.4,3.6z M43,49.6
	c0,1.6-1.3,3-3,3H6.6c-1.6,0-3-1.3-3-3V36.2c0-7.9,1.4-15.7,4.2-23h6.5v0.6c0.1,4.9,4.1,8.9,9.1,8.9c4.9-0.1,8.8-4,8.9-8.9v-0.6h6.5
	c2.8,7.4,4.2,15.2,4.2,23V49.6z"/>
<path id="Path_481" d="M23.3,26.3c-4.9,0-9,5.1-9,11.3s4,11.4,9,11.4s9-5.1,9-11.4S28.2,26.3,23.3,26.3 M22.9,42.7l-0.8,2.5
	c-2.3-0.8-4.2-3.9-4.2-7.5c-0.1-2.4,0.8-4.7,2.5-6.5c-1,2.7-0.3,5.7,1.7,7.7C23,39.9,23.4,41.3,22.9,42.7 M26.2,44.1
	c1-2.7,0.3-5.7-1.6-7.7c-1-1-1.3-2.4-0.9-3.8l0.8-2.5c2.3,0.8,4.2,3.9,4.2,7.5C28.8,40,27.9,42.4,26.2,44.1"/>
<path id="Path_482" d="M23.3,15.5c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8c0,0,0,0,0,0
	C21.5,14.7,22.3,15.5,23.3,15.5L23.3,15.5"/>
</svg>
  `,
  styles: [],
})
export class CoffeeBeanBagIcon { }