<div [@fadeInOut] class="inner-body">
  <figure>
    <img src="https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741861438786-star-upgrade.png"
      alt="Updates List" class="clickable-enlarge-image">
  </figure>
  <section class="update-list mb-4">
    <div class="container">
      <h2 class="mb-4">Updates</h2>

      <ol>
        <li class="mb-3"><strong>Home banner carousel - If the call-to-action likink is Youtube link, will open the
            video in
            modal</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/home" target="_blank">Home page</a>, click on the video link in the banner, it will open
              the
              video in modal.</i>
          </div>
        </li>

        <li class="mb-3"><strong>Top navigation profile dropdown - Updated with BAP information</strong>
          <div class="mt-1 text-info"><i>
              Click on the avatar icon in the top navigation, it will display the dropdown menu with BAP information.
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>Top navigation - Added 'Partner Notification'</strong>
          <div class="mt-1 text-info"><i>
              Located at the top right corner of the page, next to the Avatar icon, it will display a red dot if there
              is any unreadnotification.
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>New 'Partner Notification' page</strong>
          <div class="mt-1 text-info"><i>
              <p>Go to <a href="/partner-notifications" target="_blank">Partner Notification</a> page, it will display
                the list of notifications.</p>
              <p>Click on "Mark all as read" to mark all notifications as read.</p>
              <p>Click on the 'X' icon on each notification to remove it.</p>
              <p>Try remove all the notifications to see the empty state.</p>
              <p>Refresh the page to reset the changes.</p>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>Competition page - Added 'Featured Videos' list carousel</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/competitions" target="_blank">Competition page</a>, scroll
              down to the 'Featured Videos' section, it will display the list of videos.
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>New 500 error page</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/500" target="_blank">500 error page</a>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>404 error page</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/404" target="_blank">404 error page</a>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>BAP Topic page - Added new status filter and sorting filter: </strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/bap/edfb7eae23fe9e7a55a7e5c09c2e87d5" target="_blank">BAP Topic page</a>, the filters are
              located at the top of the page after the banner and page title.
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>BAP Topic Item List updates on BAP landing topic list carousel</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/bap/" target="_blank">BAP page</a>, the first 'Origin & Ethical Sourcing' list carousel
              will display the list of items with updated UI on BAP related information
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>BAP Topic Item List updates on BAP Topic page</strong>
          <div class="mt-1 text-info"><i>
              Go to <a href="/bap/edfb7eae23fe9e7a55a7e5c09c2e87d5" target="_blank">BAP Topic page</a>, it will display
              the list of items with updated UI on BAP related information
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>New BAP Topic Item Cards - Flash Card, Questionnaire, Quiz</strong>
          <div class="mt-1 text-info"><i>
              <p>Go to <a href="/bap/edfb7eae23fe9e7a55a7e5c09c2e87d5" target="_blank">BAP Topic page</a>, click on the
                'Flash Card' / 'Questionnaire' / 'Quiz' item, it will display the modal with the respective item.</p>
              <p>Click on the 4 different theme of Flash Card to view the different theme</p>
              <p>The title of the item also indicates the status of the item</p>
              <p>For 'Questionnaire' and 'Quiz', click on 'Submit' button to view the results page</p>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>Earn Title path notification - This modal will show up when user has earned a new
            title</strong>
          <div class="mt-1 text-info"><i>
              <p>Click on the button below to view the modal.</p>
              <button type="button" class="btn btn-white btn-sm" (click)="openTitleEarnedNoticeModal()">
                Open Title Earned Notice Modal
              </button>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong>Star path upgraded notification - This modal will show up when user has upgraded to a
            new star path</strong>
          <div class="mt-1 text-info"><i>
              <p>Click on the button below to view the modal.</p>
              <button type="button" class="btn btn-white btn-sm" (click)="openStarUpgradedNoticeModal()">
                Open Star Upgraded Notice Modal
              </button>
            </i>
          </div>
        </li>

        <li class="mb-3"><strong><a href="/my-profile" target="_blank">My Profile</a> page updates</strong>
          <ol class="list-st">
            <li class="mb-3">
              <strong>Top member card updates</strong>
              <div class="mt-1 text-info"><i>
                  Member card section: Added title path and updated star path icons. Updated the UI in small screen
                  view.
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new verification notice - SCA verification notice (When Green Apron) & Upload Proof
                Modal</strong>
              <div class="mt-1 text-info"><i>
                  <p>Located after the user profile information section - The first notice.</p>
                  <p>Click on the notice to view the Upload Proof Modal</p>
                  <p>Select and drop files to view the file selected state</p>
                  <p>Select 5 files to view the file limit reached state</p>
                  <p>Select 1 file with invalid file type to view the invalid file type state</p>
                  <p>Select 1 file with invalid file size to view the invalid file size state</p>
                  <p>The are 2 'Submit' buttons in the modal to stimule upload successful and failed state</p>
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new verification notice - SCA verification reminder notice & Upload Proof Modal</strong>
              <div class="mt-1 text-info"><i>
                  <p>Located after the user profile information section - The second notice.</p>
                  <p>Click on the notice to view the Upload Proof Modal</p>
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new verification notice - Star Path verification notice & Upload Proof Modal (Dark
                theme)</strong>
              <div class="mt-1 text-info"><i>
                  <p>Located after the user profile information section - The third notice.</p>
                  <p>Click on the notice to view the Upload Proof Modal in dark theme</p>
                  <p>Select and drop files to view the file selected state</p>
                  <p>Select 5 files to view the file limit reached state</p>
                  <p>Select 1 file with invalid file type to view the invalid file type state</p>
                  <p>Select 1 file with invalid file size to view the invalid file size state</p>
                  <p>The are 2 'Submit' buttons in the modal to stimule upload successful and failed state</p>
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new module - Star Path progress</strong>
              <div class="mt-1 text-info"><i>
                  Located after the verification notice section, it will display the star path progress of the user.
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new module - Title Path progress</strong>
              <div class="mt-1 text-info"><i>
                  <p>Located after the star path progress section, it will display the title path progress of the user.
                  </p>
                  <p>Click on the 'Claim Title Path' button below to view the modal.</p>
                </i>
              </div>
            </li>

            <li class="mb-3">
              <strong>Added new module - BAP Activity History</strong>
              <div class="mt-1 text-info"><i>
                  Located after the title path progress section, it will display the BAP activity history of the user.
                </i>
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </div>

  </section>
</div>