import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { RouterLink, RouterModule } from '@angular/router';
import { NgbModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { LanguageService } from '../../services/language.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-public-header',
  standalone: true,
  imports: [RouterModule, NgbDropdownModule, TranslateModule, RouterLink],
  templateUrl: './public-header.component.html',
  styleUrl: './public-header.component.scss',
  animations: [fadeInOut]
})
export class PublicHeaderComponent implements OnInit {
  languageShortForm!: string;

  constructor(
    private modalService: NgbModal,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageShortForm = this.languageService.languageMap(this.languageService.getLanguage() ?? '1');
  }
  public switchLanguage(language: string): void {
    this.languageService.switchLanguage(language);
  }

  openSignInModal() {
    const modalRef = this.modalService.open(SignInModalComponent, {
      centered: true,
      windowClass: 'sign-in-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}
