<button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
  <span class="icon-img">
    <icon-xmark></icon-xmark>
  </span>
</button>
<owl-carousel-o *ngIf="data" [options]="bannerCarouselOptions"
  [ngClass]="{'single-slide-carousel': data && data.length === 1}" (translated)="getCarouselData($event)">
  <ng-container *ngFor="let slide of data">
    <ng-template carouselSlide [id]="slide.content_id">
      <div class="modal-header">
        <figure>
          <img *ngIf="slide.image_src.sm" [src]="slide.image_src.sm" class="d-lg-none" alt="{{slide.headline}}" />
          <img [src]="slide.image_src.lg" [ngClass]="{'d-none d-lg-block': slide.image_src.sm}"
            alt="{{slide.headline}}" />
        </figure>
      </div>
      <div class="modal-body custom-scrollbar">
        <div class="heading">
          <div class="mb-3">
            <div class="label label-category label-competitions"
              *ngIf="slide.category.toUpperCase() === mainCategories.competitions">
              {{"HOME.NOTIFICATION.COMPETITIONS" | translate}}
            </div>
            <div class="label label-category label-events"
              *ngIf="slide.category.toUpperCase() === mainCategories.events">
              {{"HOME.NOTIFICATION.EVENT" | translate}}
            </div>
            <div class="label label-category label-learning"
              *ngIf="slide.category.toUpperCase() === mainCategories.learning">
              {{"HOME.NOTIFICATION.LEARNINGS" | translate}}
            </div>
            <div class="label label-category label-workshops"
              *ngIf="slide.category.toUpperCase() === mainCategories.workshops">
              {{"HOME.NOTIFICATION.WORKSHOP" | translate}}
            </div>
          </div>
          <h2 class="headline">{{slide.headline}}</h2>
          <h5 *ngIf="slide.subheadline" class="subheadline">{{slide.subheadline}}</h5>
        </div>

        <div class="contents ">
          {{slide.body}}
        </div>
        <div *ngIf="slide.button?.label" class="actions">
          <a [href]="slide.button?.link" class="btn btn-primary">{{slide.button?.label}}</a>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>