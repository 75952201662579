<div [@fadeInOut] class="inner-body page-competition-info p-0 pb-5">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="heading">
    <div class="container d-flex flex-column align-items-center justify-content-center mw-lg">
      <figure class="logo mb-4">
        <img src="/assets/images/competitions/img-latte-art-championships-logo.webp" alt="Latte Art Championship" />
      </figure>
      <h1 class="text-white">{{'COMPETITIONS.INFORMATIONS.LATTE_ART.TITLE' | translate}}</h1>
    </div>
  </section>

  <section class="content-body">
    <div class="container mw-lg">
      <div class="overview mb-6">
        <div class="mb-6">
          <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.LATTE_ART.OBJECTIVE' | translate}}</h3>
          <p>{{'COMPETITIONS.INFORMATIONS.LATTE_ART.DESC' | translate}}</p>
        </div>

        <div class="mastery mb-4">
          <div class="row justify-content-center">
            <div class=" col-12 col-sm-6 col-lg-4">
              <div class="mastery-item mb-5 d-flex flex-column align-items-center">
                <figure>
                  <img src="/assets/images/competitions/img-beverage-artistry.webp" alt="Beverage Artistry" />
                </figure>
                <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.LATTE_ART.TAG_1' | translate}}</h3>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-4">
              <div class="mastery-item mb-5 d-flex flex-column align-items-center">
                <figure>
                  <img src="/assets/images/competitions/img-skill-creativity.webp" alt="Skills and Creativity" />
                </figure>
                <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.LATTE_ART.TAG_2' | translate}}</h3>
              </div>
            </div>
          </div>
          <h3 class="text-white">{{'COMPETITIONS.INFORMATIONS.LATTE_ART.COMPETITION_TIME' | translate}}: {{'COMPETITIONS.INFORMATIONS.LATTE_ART.MINUTES' | translate}}</h3>
        </div>
      </div>

      <div class="competition-parts">
        <div class="part">
          <div class="row justify-content-center">

            <div class="col-12 text-center">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">💥</span><br>{{'COMPETITIONS.INFORMATIONS.LATTE_ART.COMPETITION_REQUIREMENT' | translate}}</h3>
              <div class="row">
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.LATTE_ART.PART_1.COMPETITION_REQUIREMENT_1' | translate">
                  </div>
                </div>

                <div class="col-12 col-md-6 mb-3 mb-md-0">
                  <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.LATTE_ART.PART_1.COMPETITION_REQUIREMENT_2' | translate">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="part bg-white">
          <div class="row justify-content-center">

            <div class="col-12 col-md-6 text-center">
              <h3 class="text-primary-dark mb-4"><span class="fs-1">🎯</span><br>{{'COMPETITIONS.INFORMATIONS.LATTE_ART.SCORING_CRITERIA' | translate}}</h3>
              <div class="list-centerized px-md-3 px-lg-4" [innerHTML]="'COMPETITIONS.INFORMATIONS.LATTE_ART.PART_2.SCORING_CRITERIA' | translate">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>