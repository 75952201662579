<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="home-hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="articleBannerData"></app-image-banner-1>
    </div>
  </section>

  <section class="content-heading mb-4">
    <div class="container mw-lg">
      <div class="row mb-3">
        <div class="col-12 col-md-9 mb-3 mb-md-0 pe-4">
          <h3 class="fw-normal text-dark mb-0">{{articleContentData.headline}}</h3>
        </div>
        <div *ngIf="articleContentData.content_id" class="col-12 col-md-3">
          <div class="d-flex flex-wrap align-items-center justify-content-md-end">
            <div class="item me-2">
              <app-bookmark
                [data]="{content_type: articleContentData.content_type, content_id: articleContentData.content_id, content_name: articleContentData.headline ?? ''}"></app-bookmark>
            </div>

            <div class="item">
              <app-like
                [data]="{content_type: articleContentData.content_type, content_id: articleContentData.content_id, content_name: articleContentData.headline ?? ''}"></app-like>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div *ngFor="let item of articleContentData.category" class="label label-category">{{item}}</div>
      </div>
    </div>
  </section>

  <!-- <figure>
    <img src="https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741861438786-star-upgrade.png"
      alt="Updates List" class="clickable-enlarge-image">
  </figure> -->

  <section class="content-body mb-5">
    <div class="container mw-lg">
      <div [innerHTML]="articleContent"></div>
    </div>
  </section>

  <section *ngIf="articleDocuments && articleDocuments.length > 0" class="downloads">
    <div class="container mw-lg">
      <div class="download-materials d-flex flex-wrap">
        <figure class="mt-1">
          <span class="icon-img">
            <icon-folder></icon-folder>
          </span>
        </figure>
        <div class="content">
          <h4 class="fw-normal text-dark">{{"LEARNING_CENTRE.TOPIC.DOWNLOAD" | translate}}</h4>

          <ul *ngFor="let item of articleDocuments" class="list-unstyled mb-0">
            <li class="list-item">
              <a [href]="item.link" target="_blank">→ {{item.display_name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>


  </section>
</div>