import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListCarousel2Model } from '../../models';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from '../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-list-carousel-2',
  standalone: true,
  templateUrl: './list-carousel-2.component.html',
  styleUrl: './list-carousel-2.component.scss',
  imports: [CommonModule, CarouselModule, NgbDropdownModule, TranslateModule, RouterLink],
})
export class ListCarousel2Component {
  @Input({ required: true }) data!: ListCarousel2Model[];
  @Input()
  slideHeaderCopy?: string;

  constructor(
    private contentService: ContentService
  ) { }

  // Owl carousel config
  listCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    margin: 25,
    responsive: {
      0: {
        items: 1,
        loop: false
      },
      768: {
        items: 2,
        loop: false
      }
    },
    nav: true
  }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }
}
