import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ClaimTitlePathModalComponent } from '../claim-title-path-modal/claim-title-path-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface TitlePathProgress {
  title: string;
  points: number;
  totalPoints: number;
  progress: number;
  theme: string;
  iconURL: string;
  isClaimable: boolean;
}

@Component({
  selector: 'app-title-path',
  standalone: true,
  imports: [CommonModule, DecimalPipe],
  templateUrl: './title-path.component.html',
  styleUrl: './title-path.component.scss'
})
export class TitlePathComponent {
  @Input() partnerTitlePathProgressData: TitlePathProgress[] = [];
  @Output() claimTitlePathEvent = new EventEmitter<void>();

  claimTitlePath() {
    this.claimTitlePathEvent.emit();
  }
}
