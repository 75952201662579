<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="imageBanner1DataUrl"></app-image-banner-1>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <div class="mb-5 text-center">
        <h2 class="text-dark mb-4">{{"BAP.HEADER" | translate}}</h2>
        <p>{{"BAP.DESC_1" | translate}}<br>{{"BAP.DESC_2" | translate}}</p>
        <p>{{"BAP.DESC_3" | translate}}</p>
        <p>{{"BAP.DESC_4" | translate}}</p>
      </div>

      <div class="video d-flex justify-content-center">
        <div class="video-wrapper">
          <iframe src="https://www.youtube.com/embed/lNnqOgsoRIE?si=lu4MIsfm1y5XNoDq&rel=0" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>

    </div>
  </section>
</div>