<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-4">
      <h1>{{"UPDATE_PASSWORD.HEADER" | translate}}</h1>
      <p>{{"UPDATE_PASSWORD.DESC" | translate}}</p>
    </div>

    <div class="mb-5">
      <fieldset class="mb-4" [formGroup]="updatePasswordForm">
        <div class="form-group">
          <label for="partner-confirm-password-input"
            class="form-label">{{"UPDATE_PASSWORD.CURRENT_PASSWORD" | translate}}</label>
          <input type="password" class="form-control" id="partner-current-password-input"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="currentPassword"
            (ngModelChange)="frontendError = false; serverError = false; matchPassword = true; currentPasswordError = false">
          <div *ngIf="serverError && currentPasswordError" class="invalid-message">
            {{serverErrorMessage | translate}}
          </div>
        </div>

        <div class="form-group">
          <label for="partner-new-password-input" class="form-label">{{"UPDATE_PASSWORD.PASSWORD" | translate}}</label>

          <div class="d-flex flex-wrap">
            <input type="password" class="form-control flex-1" id="partner-new-password-input"
              [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="password"
              (ngModelChange)="frontendError = false; serverError = false; matchPassword = true; currentPasswordError = false">
            <button #tooltipButton="ngbTooltip" class="btn btn-circle btn-password-tooltips" placement="auto"
              [ngbTooltip]="passwordTooltip" tooltipClass="password-tooltips">
              <span class="icon-img" [ngClass]="{'danger':errorList.length > 0}">
                <icon-circle-info></icon-circle-info>
              </span>
            </button>
          </div>

          <div *ngIf="frontendError && !currentPasswordError" class="invalid-message">
            {{frontendErrorMessage | translate}}
          </div>

          <div *ngIf="serverError && !currentPasswordError" class="invalid-message">
            {{serverErrorMessage | translate}}
          </div>

          <ng-template #passwordTooltip>
            {{"UPDATE_PASSWORD.CRITERIA.TITLE" | translate}}
            <ul class="list-unstyled">
              <li [ngClass]="{'text-danger':errorList.includes('invalidCharLength')}">
                {{"UPDATE_PASSWORD.CRITERIA.LENGTH" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidLowercase')}">
                {{"UPDATE_PASSWORD.CRITERIA.LOWER_CASE" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidUppercase')}">
                {{"UPDATE_PASSWORD.CRITERIA.UPPER_CASE" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidNumber')}">
                {{"UPDATE_PASSWORD.CRITERIA.NUMBER" | translate}}
              </li>
              <li [ngClass]="{'text-danger':errorList.includes('invalidSpecialChar')}">
                {{"UPDATE_PASSWORD.CRITERIA.SPECIAL_CHARACTER" | translate}}
              </li>
            </ul>
            {{"UPDATE_PASSWORD.TIPS.TITLE" | translate}}
            <ul class="list-unstyled mb-0">
              <li>{{"UPDATE_PASSWORD.TIPS.CONSECUTIVE" | translate}}</li>
              <li>{{"UPDATE_PASSWORD.TIPS.USERNAME" | translate}}</li>
              <li>{{"UPDATE_PASSWORD.TIPS.COMMON_WORD" | translate}}</li>
            </ul>
          </ng-template>
        </div>

        <div class="form-group">
          <label for="partner-confirm-password-input"
            class="form-label">{{"UPDATE_PASSWORD.CONFIRM_PASSWORD" | translate}}</label>
          <input type="password" class="form-control" id="partner-confirm-password-input"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="confirmPassword"
            (ngModelChange)="frontendError = false; serverError = false; matchPassword = true; currentPasswordError = false">
          <div *ngIf="!matchPassword" class="invalid-message">
            {{frontendErrorMessage | translate}}
          </div>
        </div>
      </fieldset>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto"
        [disabled]="updatePasswordForm.controls['password'].errors?.['required'] || updatePasswordForm.controls['confirmPassword'].errors?.['required'] || updatePasswordForm.controls['currentPassword'].errors?.['required']"
        (click)="onSubmit()">{{"UPDATE_PASSWORD.SUBMIT" | translate}}</button>
    </div>

  </div>
</div>