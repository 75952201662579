<div class="component-grid-list-4">
  <div class="container">
    <div class="row">
      <div *ngFor="let item of data" class="col-6 col-md-4 col-lg-2">
        <div class="list-item">
          <figure [title]="item.headline">
            <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline)"
              [routerLink]="[currentUrl+item.image_link]">
              <img [src]="item.image_src" [alt]="item.image_alt+'-img'">
            </a>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>