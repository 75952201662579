import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, StarOutlineIcon } from "../../icons";
import { GridList3Model } from '../../models';
import { Router, RouterLink } from '@angular/router';
import { GridList3Service } from '../../services';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsService } from '../../services/analytics.service';


@Component({
  selector: 'app-grid-list-3',
  standalone: true,
  templateUrl: './grid-list-3.component.html',
  styleUrl: './grid-list-3.component.scss',
  providers: [GridList3Service],
  imports: [CommonModule, NgbDropdownModule, AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, StarOutlineIcon, TranslateModule, RouterLink]
})
export class GridList3Component implements OnInit {
  @Input({ required: true }) data!: GridList3Model[];
  @Output() onFlashCardClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onQuizClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onQuestionnaireClicked = new EventEmitter<{ identifier: string, tier: string }>();
  @Output() onUnlockBAPContentClicked = new EventEmitter<string>();
  currentUrl!: string;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }

  onFlashCard(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onFlashCardClicked.emit({ identifier, tier });

  }

  onQuiz(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onQuizClicked.emit({ identifier, tier });
  }

  onQuestionnaire(event: any, identifier: string, tier: string) {
    event.preventDefault();
    this.onQuestionnaireClicked.emit({ identifier, tier });
  }

  onDisabledContent(event: any) {
    event.preventDefault();
    this.toastr.error('Partner does not have tier required to access the content.');
    return;
  }

  onUnlockBAPContentClick() {
    this.onUnlockBAPContentClicked.emit();
  }
}
