<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section *ngIf="video_src" class="video mb-4">
    <div class="container custom-container mw-lg">
      <div class="video-container">
        <iframe id="youtube-iframe" width="100%" height="100%" [src]="video_src" [title]="videoData.headline"
          frameborder="0" referrerpolicy="no-referrer-when-downgrade"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
    </div>
  </section>

  <section *ngIf="videoData" class="info mb-4">
    <div class="container custom-container mw-lg">
      <div class="row">
        <div class="col-12 col-lg-8 mb-3 heading">
          <div *ngIf="videoData.top_subtitle || videoData.is_live" class="d-flex flex-wrap align-items-center mb-2">
            <h5 *ngIf="videoData.top_subtitle" class="top-label mb-0 pe-2 ">{{videoData.top_subtitle}}</h5>
            <div *ngIf="videoData.is_live" class="label label-live m-1 ms-0">{{"GENERAL.BUTTON.LIVE" | translate}}</div>
          </div>

          <h3 class="fw-normal text-dark">{{videoData.headline}}</h3>
        </div>

        <div *ngIf="videoData.content_id" class="col-12 col-lg-4 mb-4 tools">
          <div class="d-flex flex-wrap align-items-center">
            <!-- Comment for now -->
            <!-- <div class="item video-language-select me-sm-auto me-lg-4">
              <app-video-language></app-video-language>
            </div> -->

            <div class="item me-2">
              <app-bookmark [data]="videoData.content_id"></app-bookmark>
            </div>

            <div class="item">
              <app-like [data]="videoData.content_id"></app-like>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8 mb-5 description ">
          <div *ngIf="videoData.descriptions" class="video-description"
            [innerHtml]="sanitizeStyle(videoData.descriptions)"></div>
        </div>

        <div *ngIf="baristaData.length > 0" class="col-12 person mb-4">
          <h3 class="fw-normal text-dark mb-5">{{"GENERAL.BARISTA" | translate}}</h3>
          <app-person-list [data]="baristaData"></app-person-list>
        </div>

        <div *ngIf="videoDocuments.length > 0" class="col-12 col-lg-4 mb-5 document">
          <div class="document-wrapper ms-lg-auto">
            <figure>
              <icon-folder></icon-folder>
            </figure>

            <ul class="list-unstyled mb-0">
              <li *ngFor="let document of videoDocuments">
                <a [href]="document.link" target="_blank">→ {{document.display_name}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>