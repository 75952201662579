import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageBanner1Model } from '../../models';

@Component({
  selector: 'app-image-banner-1',
  standalone: true,
  templateUrl: './image-banner-1.component.html',
  styleUrl: './image-banner-1.component.scss',
  imports: [CommonModule],
})

export class ImageBanner1Component {
  @Input({ required: true }) data?: ImageBanner1Model;
}

