
import { Component } from '@angular/core';

@Component({
  selector: 'icon-bap-star-circled',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100"><path d="M50 0C22.386 0 0 22.386 0 50s22.386 50 50 50 50-22.386 50-50S77.614 0 50 0Zm15.451 75L50 63.196 34.549 75l5.902-19.099L25 44.098h19.098L50 25l5.901 19.098H75L59.549 55.901 65.451 75Z"/><path fill="#bda882" d="M55.901 44.098 50 25l-5.902 19.098H25l15.451 11.803L34.549 75 50 63.196 65.451 75l-5.902-19.099L75 44.098H55.901z"/></svg>
  `,
  styles: [],
})
export class BapStarCircledIcon { }