import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImageBanner1Model } from '../models';

@Injectable({
  providedIn: 'any'
})

export class ImageBanner1Service {
  //private dataUrl = '/assets/data/banner-carousel-1.json';

  constructor(private http: HttpClient) { }

  getImageBanner1Data(dataUrl: string): Observable<ImageBanner1Model> {
    return this.http.get<ImageBanner1Model>(dataUrl);
  }
}
