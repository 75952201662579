import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { AnalyticsService } from '../../services/analytics.service';
import { ContentService } from '../../services/content.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VideoPlayState } from '../../enum/video-playState.enum';
import { environment } from '../../../environment/environment';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-person-video-bio-modal',
  standalone: true,
  templateUrl: './person-video-bio-modal.component.html',
  styleUrl: './person-video-bio-modal.component.scss',
  imports: [XmarkIcon]
})
export class PersonVideoBioModalComponent implements OnInit, AfterViewInit, OnDestroy {
  // Video Analytics variable
  video_src!: SafeResourceUrl;
  rawVideoSrc!: string;
  bioPlayer: any;
  setPlayerInterval: any;
  videoAnalyticsInterval!: any;

  constructor(
    private analyticsService: AnalyticsService,
    private contentService: ContentService,
    private sanitizer: DomSanitizer,
    private configService: ConfigService
  ) { }

  @Input() modal: any;
  @Input() personVideoBioUrl!: string;
  @Input() personId!: string;
  @Input() personContentType!: string;

  ngOnInit(): void {
    this.sanitizeVideoUrl(this.personVideoBioUrl);
  }

  ngAfterViewInit() {
    if (!this.configService.isPreview) {
      this.loadYouTubeAPI();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.setPlayerInterval);
    clearInterval(this.videoAnalyticsInterval);
    this.videoAnalyticsInterval = null;
  }

  dismissModal() {
    this.modal.dismiss();
  }

  sanitizeVideoUrl(videoUrl: string) {
    const vidUrl = this.contentService.formatVideoUrl(videoUrl) + '?enablejsapi=1';
    this.video_src = this.sanitizer.bypassSecurityTrustResourceUrl(vidUrl);
  }

  loadYouTubeAPI() {
    (window as any).onYouTubeIframeAPIReady = this.onBioYouTubeIframeAPIReady();
  }

  onBioYouTubeIframeAPIReady() {
    this.setPlayerInterval = setInterval(() => {
      this.bioPlayer = new (window as any).YT.Player('youtube-bioVideo-iframe', {
        events: {
          'onReady': (e: any) => this.onBioPlayerReady(e),
          'onStateChange': (e: any) => this.onBioPlayerStateChange(e)
        }
      });
      if (this.bioPlayer) {
        clearInterval(this.setPlayerInterval);
      }
    }, 1000)
  }

  onBioPlayerReady(event: any) {
    clearInterval(this.setPlayerInterval);
  }

  onBioPlayerStateChange(event: any) {
    clearInterval(this.setPlayerInterval);
    if (event.target.playerInfo.playerState === VideoPlayState.PLAYING || event.target.playerInfo.playerState === VideoPlayState.BUFFERING) {
      if (!this.videoAnalyticsInterval) {
        this.analyticsService.createVideoAnalytic(this.personId, this.personContentType, event.target.playerInfo.playerState, this.personVideoBioUrl, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        this.videoAnalyticsInterval = setInterval(() => {
          this.analyticsService.createVideoAnalytic(this.personId, this.personContentType, event.target.playerInfo.playerState, this.personVideoBioUrl, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        }, 30000)
      }
    } else if (event.target.playerInfo.playerState === VideoPlayState.ENDED || event.target.playerInfo.playerState === VideoPlayState.PAUSED) {
      this.analyticsService.createVideoAnalytic(this.personId, this.personContentType, event.target.playerInfo.playerState, this.personVideoBioUrl, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
      clearInterval(this.videoAnalyticsInterval);
      this.videoAnalyticsInterval = null;
    }
  }
}