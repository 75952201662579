<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>
  <div class="container">
    <h1 class="mb-5">Notifications</h1>

    <div class="inner-wrapper">
      <div class="empty-notification border py-5 px-4 mb-5"
        *ngIf="(unreadNotifications.length === 0 && readNotifications.length === 0) || this.partnerNotificationData.length === 0">
        <p class="text-center">You're all caught up! Check back later for new updates.</p>
      </div>

      <div class="notification-container">
        <!-- <div class="d-flex align-items-center justify-content-between mb-4">
          <a href="#" (click)="markAllAsRead($event)" class="ms-auto">Mark all as read</a>
        </div> -->
        <ul class="list-unstyled notification-list">
          <li class="notification-list-item d-flex align-items-center"
            *ngFor="let notification of activeNotifications; let i = index" [class.unread]="!notification.readAt">
            <div class="notification-indicator">
              <span class="indicator"></span>
            </div>
            <div class="notification-icon d-none d-md-inline-block">
              <figure class="mb-0">
                <img src="assets/images/notifications/img-notifications.png" alt="Notification Icon">
              </figure>
            </div>
            <div class="notification-content flex-1">
              <div class="notification-title">
                {{ notification.title }}
              </div>
              <div class="notification-body" [innerHTML]="notification.body"></div>
              <div class="notification-date">
                {{ notification.createdAt }}
              </div>
            </div>
            <div class="notification-action">
              <button type="button" class="btn btn-ghost-white btn-circle" (click)="removeNotification(notification)">
                <span class="icon-img">
                  <icon-xmark></icon-xmark>
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>

      <!-- <div class="unread-notifications border-bottom pb-3 mb-5" *ngIf="unreadNotifications.length > 0">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h3 class="fw-normal text-dark mb-0">Unread</h3>
          <a href="#" (click)="markAllAsRead($event)">Mark all as read</a>
        </div>

        <ul class="list-unstyled notification-list">
          <li class="notification-list-item d-flex align-items-center"
            *ngFor="let notification of unreadNotifications; let i = index">
            <div class="notification-indicator d-none d-md-inline-block">
              <span class="indicator"></span>
            </div>
            <div class="notification-icon">
              <figure class="mb-0">
                <img src="assets/images/notifications/img-notifications.png" alt="Notification Icon">
              </figure>
            </div>
            <div class="notification-content flex-1">
              <div class="notification-title">
                {{ notification.title }}
              </div>
              <div class="notification-body" [innerHTML]="notification.body"></div>
              <div class="notification-date">
                {{ notification.createdAt }}
              </div>
            </div>
            <div class="notification-action">
              <button type="button" class="btn btn-ghost-white btn-circle" (click)="removeNotification(notification)">
                <span class="icon-img">
                  <icon-xmark></icon-xmark>
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>

      <div class="read-notifications" *ngIf="readNotifications.length > 0">
        <h3 class="fw-normal text-dark mb-4">Read</h3>

        <ul class="list-unstyled notification-list">
          <li class="notification-list-item d-flex align-items-center"
            *ngFor="let notification of readNotifications; let i = index">
            <div class="notification-indicator d-none d-md-inline-block">
              <span class="indicator"></span>
            </div>
            <div class="notification-icon">
              <figure class="mb-0">
                <img src="assets/images/notifications/img-notifications.png" alt="Notification Icon">
              </figure>
            </div>
            <div class="notification-content flex-1">
              <div class="notification-title">
                {{ notification.title }}
              </div>
              <div class="notification-body" [innerHTML]="notification.body"></div>
              <div class="notification-date">
                {{ notification.createdAt }}
              </div>
            </div>
            <div class="notification-action">
              <button type="button" class="btn btn-ghost-white btn-circle" (click)="removeNotification(notification)">
                <span class="icon-img">
                  <icon-xmark></icon-xmark>
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</div>