<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-2-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-2-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="step-indicator">
      <div class="data-label">{{"ONBOARDING.LV300.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2 current"></span>
        <span class="step step-3"></span>
        <!-- <span class="step step-4"></span> -->
      </div>
    </div>

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.LV300.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.LV300.DESC" | translate}}</p>
    </div>

    <div class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="regForm4">
        <div class="radio-group">
          <div class="form-check mb-3">
            <input class="form-check-input" type="radio" name="lv300" id="sb-level-300-yes" [value]="true"
              formControlName="lv300">
            <label class="form-check-label" for="sb-level-300-yes">
              {{"ONBOARDING.LV300.OPTIONS.YES" | translate}}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="lv300" id="sb-level-300-no" [value]="false"
              formControlName="lv300" checked>
            <label class="form-check-label" for="sb-level-300-no">
              {{"ONBOARDING.LV300.OPTIONS.NO" | translate}}
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" [disabled]="!regForm4.valid" (click)="onSubmit()">
        {{"ONBOARDING.LV300.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.LV300.BACK" | translate}}
      </button>
    </div>
  </div>
</div>