<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-in/img-signin-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-in/img-signin-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">

    <div class="inner-body-heading">
      <h2>
        {{"ONBOARDING.REQUEST_RESET_PASSWORD.HEADER" | translate}}
      </h2>
      <p>
        {{"ONBOARDING.REQUEST_RESET_PASSWORD.DESC_1" | translate}}
        <br>
        {{"ONBOARDING.REQUEST_RESET_PASSWORD.DESC_2" | translate}}
      </p>
    </div>

    <div class="inner-body-contents">
      <fieldset class="mb-5" [formGroup]="forgetPasswordForm">
        <div class="form-group">
          <label for="partner-email-input" class="form-label">{{"ONBOARDING.REQUEST_RESET_PASSWORD.EMAIL" | translate}}</label>
          <input type="email" class="form-control" id="partner-email-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="email" (ngModelChange)="frontendError = false; serverError = false;">

          <div *ngIf="frontendError" class="invalid-message">
            {{frontendErrorMessage | translate}}
          </div>
          <div *ngIf="serverError" class="invalid-message">
            {{serverErrorMessage | translate}}
          </div>

        </div>
      </fieldset>
    </div>
    <div class="inner-body-footer">
      <button type="button" *ngIf="!isSubmitting" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()"
        [disabled]="!forgetPasswordForm.valid">
        {{"ONBOARDING.REQUEST_RESET_PASSWORD.SUBMIT" | translate}}
      </button>
      <button type="button" *ngIf="isSubmitting" class="btn btn-spinner w-100 w-lg-auto" disabled>
        <span class="icon-img">
          <icon-spinner></icon-spinner>
        </span>
      </button>
    </div>
  </div>
</div>