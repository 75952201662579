import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { ImageLandscapeIcon, SpinnerIcon, XmarkIcon } from "../../../icons";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-picture-upload',
  standalone: true,
  imports: [ImageLandscapeIcon, CommonModule, XmarkIcon, TranslateModule, SpinnerIcon],
  templateUrl: './profile-picture-upload.component.html',
  styleUrl: './profile-picture-upload.component.scss',
  animations: [fadeInOut]
})
export class ProfilePictureUploadComponent {
  @Input() disableSubmitButton!: boolean;
  @Output() goToReviewProfileClick = new EventEmitter<void>();
  @Output() goToChangeProfilePictureClick = new EventEmitter<void>();
  @Output() selectedProfileImage = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  selectedProfileImageUrl!: string;

  goToReviewProfile() {
    this.goToReviewProfileClick.emit();
  }

  goToChangeProfilePicture() {
    this.goToChangeProfilePictureClick.emit();
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file.size > 10 * 1024 * 1024) { // 10MB
      alert('File is too large!');
      return;
    }
    const reader = new FileReader();
    reader.onload = e => {
      this.selectedProfileImageUrl = reader.result as string;
      this.selectedProfileImage.emit(this.selectedProfileImageUrl);
    };
    reader.readAsDataURL(file);
  }

  removeImage() {
    this.selectedProfileImageUrl = '';
    this.fileInput.nativeElement.value = '';
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  onDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file.size > 10 * 1024 * 1024) { // 10MB
      alert('File is too large!');
      return;
    }
    const reader = new FileReader();
    reader.onload = e => {
      this.selectedProfileImageUrl = reader.result as string;
      this.selectedProfileImage.emit(this.selectedProfileImageUrl);
    };
    reader.readAsDataURL(file);
  }
}
