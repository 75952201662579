import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleBannerModel } from '../../models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-single-banner',
  standalone: true,
  templateUrl: './single-banner.component.html',
  styleUrl: './single-banner.component.scss',
  imports: [CommonModule, TranslateModule],
})

export class SingleBannerComponent {
  [x: string]: any;
  @Input({ required: true }) data!: SingleBannerModel;
  @Output() openSignUpModalClick = new EventEmitter<void>();
  @Output() openSignInModalClick = new EventEmitter<void>();

  openSignUpModal() {
    this.openSignUpModalClick.emit();
  }

  openSignInModal() {
    this.openSignInModalClick.emit();
  }
}

