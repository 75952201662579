import { Component, Input } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BapStarCircledIcon } from "../../icons/icon-bap-star-circled";

interface StarPathProgress {
  currentPoints: number;
  progress: number;
}

interface StarPathPoint {
  points: number;
  label: string;
}

@Component({
  selector: 'app-star-path',
  standalone: true,
  imports: [CommonModule, DecimalPipe, BapStarCircledIcon],
  templateUrl: './star-path.component.html',
  styleUrl: './star-path.component.scss'
})
export class StarPathComponent {
  @Input() partnerStarPathProgressData!: StarPathProgress;
  @Input() starPathPointsConfig!: StarPathPoint[];
}
