import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { CommonModule } from '@angular/common';
import { FullscreenBannerComponent } from "../../components/fullscreen-banner/fullscreen-banner.component";
import { VideoListComponent } from "../../components/video-list/video-list.component";
import { FullScreenBannerModel, VideoListModel } from '../../models';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-competition-videos',
  standalone: true,
  templateUrl: './competition-videos.component.html',
  styleUrl: './competition-videos.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, CommonModule, FullscreenBannerComponent, VideoListComponent, RouterLink]
})
export class CompetitionVideosComponent implements OnInit {
  videoListData: VideoListModel[] = [];
  competitionIdentifier!: string;
  currentUrl!: string;
  competitionVideoListContentType: string = 'SbuxCompetition';
  breadcrumbsLabelTranslate: string = '';
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/home',
      label: 'Home'
    }
  ]

  fullscreenBannerData: FullScreenBannerModel = {
    content_id: "",
    image_src: {
      sm: "",
      lg: ""
    },
    overlay_logo_src: "", // Ask Bilal
    overlay_headline: ""
  };
  additionalListData = [
    {
      "content_type": "meet-the-barista-and-judges",
      "cover_image_src": "/assets/images/competitions/img-meet-the-barista.webp",
      "link": "/meet-the-barista-and-judges",
      "headline": "Meet the Barista and Judges",
      "subheadline": "",
      // "descriptions": "Explore the significance of coffee origin, tracing its history, introducing key producing regions, and understanding basic coffee plant varieties."
      "descriptions": ""
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    public analyticsService: AnalyticsService,
    private configService: ConfigService,
  ) {
    // Get Translation
    this.translation.get(['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE']).subscribe({
      next: res => {
        this.additionalListData[0].headline = res['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE'];
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.competitionIdentifier = params['pId'];

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.competitionIdentifier, this.competitionVideoListContentType, this.router.url);
      }

      this.currentUrl = this.router.url;
      const segmentUrl = this.currentUrl.split('/');

      // Get Translation
      this.translation.get(['GENERAL.MENU.COMPETITIONS', 'GENERAL.MENU.HOME']).subscribe({
        next: res => {
          // this.breadcrumbsData[0].label = res['GENERAL.MENU.COMPETITIONS']

          if (this.currentUrl.toUpperCase().split('/').includes('COMPETITIONS')) {
            // Construct return url
            segmentUrl.pop();
            const updatedUrl = segmentUrl.join('/');
            this.breadcrumbsData[0].link = updatedUrl;
            this.breadcrumbsData[0].label = res['GENERAL.MENU.COMPETITIONS'];
          } else {
            this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
          }
        }
      })
    })

    this.contentService.getPartnerContent(this.competitionVideoListContentType, this.competitionIdentifier).subscribe({
      next: res => {
        if (res.length === 0) {
          this.router.navigate(['/404']);
        }

        const data = res[0];

        // Page Title
        this.titleService.setTitle(res[0].title);

        // Fullscreen Banner Data
        this.fullscreenBannerData["content_id"] = res[0].identifier;
        this.fullscreenBannerData["overlay_headline"] = res[0].title;
        this.fullscreenBannerData["overlay_logo_src"] = res[0].icon;
        this.fullscreenBannerData["image_src"]["sm"] = res[0].bannerImageMobile;
        this.fullscreenBannerData["image_src"]["lg"] = res[0].bannerImage;

        // Video List Data
        data.competitionVideos.forEach((item: any) => {
          const splitHeadline = item.videoTitle.split('|');
          this.videoListData.push({
            content_type: item.contentType,
            content_id: item.identifier,
            cover_image_src: item.coverImage,
            type: item.videoUrl ? 'Video' : 'Article',
            link: `/videos/${item.identifier}`,
            headline: splitHeadline[0],
            headline_2: splitHeadline[1] ?? '',
            descriptions: item.descriptions,
            video_duration: this.contentService.formatSecToHMS(item.videoDuration),
            mark_as_completed: false, // TBC
            video_title: item.videoTitle,
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
