import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { fadeInOut } from '../../animation';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CircleInfoIcon } from "../../icons/icon-circle-info";
import { CommonModule } from '@angular/common';
import { SpinnerIcon } from '../../icons';
import { HttpHeaders } from '@angular/common/http';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import passwordValidatorCriteria from '../../validators/password-validator';
import { switchMap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../services/error.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-setup-new-password',
  standalone: true,
  imports: [RouterModule, NgbTooltipModule, CircleInfoIcon, CommonModule, SpinnerIcon, ReactiveFormsModule, TranslateModule],
  templateUrl: './setup-new-password.component.html',
  styleUrl: './setup-new-password.component.scss',
  animations: [fadeInOut]
})
export class SetupNewPasswordComponent implements OnInit, AfterViewInit {
  @Input() email!: string;
  @ViewChild('tooltipButton') tooltipEl!: NgbTooltip;
  errorList!: string[];
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  validToken: boolean = true;
  matchPassword: boolean = true;
  resetPasswordForm!: FormGroup;
  tokenParam!: string;
  username!: string;
  usernameList: string[] = [];
  validPassword: boolean = true;
  submitPasswordServerResponse!: string;
  serverError: boolean = false;
  serverErrorMessage!: string;
  frontendError: boolean = false;
  frontendErrorMessage!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['ONBOARDING.RESET_PASSWORD.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['ONBOARDING.RESET_PASSWORD.HEADER']);
      }
    })
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', (event) => {
      if (event.data === 'invalidPassword') {
        this.tooltipEl.open();
      }
    })
  }

  ngOnInit(): void {
    // Get token from url parameter
    this.route.queryParams.subscribe(params => {
      this.tokenParam = params['t'];
    });

    // ============================== CREATE FORM GROUP ======================================
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl('', [Validators.required]),
    })

    // Get username in a list
    this.username = this.email.split('@')[0];
    this.usernameList = this.username.split(/[^\w\s]|_/);

    // Only return characters length > 3
    this.usernameList = this.usernameList.filter(function (item) {
      return item.length >= 3;
    });
  }

  onSubmit() {
    this.resetPasswordForm.disable();
    this.isSubmitting = true;

    //  if confirmPassword does not match password
    if (this.resetPasswordForm.controls['password'].value !== this.resetPasswordForm.controls['confirmPassword'].value) {
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.matchPassword = false;
      this.isSubmitting = false;
      this.resetPasswordForm.enable();
      return;
    }

    // ============================= POST REQUEST SWITCHMAP() =============================
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Password Validation Body
    const validateBody = {
      'email': this.email,
      'password': this.resetPasswordForm.controls['password'].value
    }

    // Submit Reset Password Body
    const submitBody = {
      'new_password': this.resetPasswordForm.controls['password'].value,
      'token': this.tokenParam
    }

    // Test call from AuthService
    this.authService.validatePassword(validateBody).pipe(
      switchMap((result1) => {
        if (!result1.data) {
          this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
          // this.validPassword = false;
          this.frontendError = true;
          window.postMessage('invalidPassword');
          this.isSubmitting = false;
          this.resetPasswordForm.enable();
          return '';
        }

        return this.authService.submitResetPassword(submitBody, reqHeader);
      })
    ).subscribe({
      next: result2 => {

        if (result2.data) {
          this.router.navigate(['/reset-password-successful']);
        } else if (result2.error_code !== '-1') {
          this.serverErrorMessage = this.errorService.getServerErrorMapping(result2.error_code);
          this.serverError = true;
        } else {
          this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
          this.frontendError = true;
          window.postMessage('invalidPassword');
        }
      },
      complete: () => {
        this.isSubmitting = false;
        this.resetPasswordForm.enable();
        // this.router.navigate(['/reset-password-successful']);
      }
    })
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;

      // Password validator criteria
      this.errorList = passwordValidatorCriteria(password, this.usernameList);

      if (this.errorList.length >= 1) {
        const firstError = this.errorList[0];
        return {
          [firstError]: true
        }
      }
      return null;
    }
  }

}
