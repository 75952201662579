<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-1-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-1-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div *ngIf="isLoading" class="loading-wrapper">
      <figure class="spinner">
        <icon-spinner></icon-spinner>
      </figure>
    </div>
    <div *ngIf="!isLoading" class="step-indicator">
      <div class="data-label">{{"ONBOARDING.LANGUAGE.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1 current"></span>
        <span class="step step-2"></span>
        <span class="step step-3"></span>
        <!-- <span class="step step-4"></span> -->
      </div>
    </div>

    <div *ngIf="!isLoading" class="inner-body-heading">
      <h2>
        {{"ONBOARDING.LANGUAGE.HEADER" | translate}}
      </h2>
      <p>
        {{"ONBOARDING.LANGUAGE.DESC_1" | translate}}<br>
        {{"ONBOARDING.LANGUAGE.DESC_2" | translate}}
      </p>
    </div>

    <div *ngIf="!isLoading" class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="languageForm">
        <div class="form-group">
          <select class="form-select" formControlName="language">
            <option *ngFor="let language of languages" [value]="language['code']">
              {{"ONBOARDING.LANGUAGE.OPTIONS."+language['label'] | translate}}
            </option>
          </select>
        </div>
      </fieldset>
    </div>

    <div *ngIf="!isLoading" class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" [disabled]="!languageForm.valid"
        (click)="onSubmit()">
        {{"ONBOARDING.LANGUAGE.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.LANGUAGE.BACK" | translate}}
      </button>
    </div>
  </div>
</div>