import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-registration-successful',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './registration-successful.component.html',
  styleUrl: './registration-successful.component.scss',
  animations: [fadeInOut],
})

export class RegistrationSuccessfulComponent implements OnInit, OnDestroy {
  messages = ['Thank you!', '謝謝!', 'ありがとう!', '감사합니다!', 'Terima Kasih!', 'Cảm Ơn!', 'ขอบคุณ!'];
  thankYouHeadlineCopy = '';
  private intervalId: any;
  email: string = '';

  constructor(
    private route: ActivatedRoute,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['GENERAL.PAGE_TITLE.REGISTRATION_SUCCESSFUL']).subscribe({
      next: res => {
        titleService.setTitle(res['GENERAL.PAGE_TITLE.REGISTRATION_SUCCESSFUL']);
      }
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['e']) {
        this.email = atob(params['e']);
      }
    })

    let i = 0;
    this.intervalId = setInterval(() => {
      const vnThankYou = document.getElementById('thank-you');
      if (this.messages[i] === 'Cảm Ơn!') {
        vnThankYou!.style.fontFamily = 'SoDo Sans Vn, SoDo Sans, sans-serif';
      } else {
        vnThankYou!.style.fontFamily = 'inherit';
      }
      this.thankYouHeadlineCopy = this.messages[i];
      i = (i + 1) % this.messages.length;
    }, 600);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
