import { Component, Output, EventEmitter, Input, OnInit, AfterContentChecked, AfterViewInit, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators, ValidatorFn } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CircleInfoIcon } from "../../../icons/icon-circle-info";
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import passwordValidatorCriteria from '../../../validators/password-validator';
import { SpinnerIcon } from '../../../icons';
import { AuthService } from '../../../services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorService } from '../../../services/error.service';


@Component({
  selector: 'app-registration-form-3',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, CircleInfoIcon, NgbTooltipModule, SpinnerIcon, TranslateModule],
  templateUrl: './registration-form-3.component.html',
  styleUrl: './registration-form-3.component.scss',
  animations: [fadeInOut],
})
export class RegistrationForm3Component implements OnInit, AfterViewInit {
  @Input() input?: { password: string, email: string };
  @Output() onCompleted = new EventEmitter<{ password: string }>();
  @Output() onCancelled = new EventEmitter<void>();
  @ViewChild('tooltipButton') tooltipEl!: NgbTooltip;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  errorList!: string[];
  email?: string;
  isSubmitting: boolean = false;
  matchPassword: boolean = true;
  regForm3!: FormGroup;
  username!: string; // will remove once server implemented
  usernameList: string[] = []; // will remove once server implemented
  validPassword: boolean = true;

  serverError: boolean = false;
  serverErrorMessage!: string;
  frontendError: boolean = false;
  frontendErrorMessage!: string;

  ngAfterViewInit(): void {
    window.addEventListener('message', (event) => {
      if (event.data === 'invalidPassword') {
        this.tooltipEl.open();
      }
    })
  }

  ngOnInit(): void {
    this.regForm3 = new FormGroup({
      password: new FormControl(this.input?.password ?? '', [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl('', [Validators.required]),
    })

    // Get username in a list
    this.email = this.input?.email ?? "";
    this.username = this.email.split('@')[0];
    this.usernameList = this.username.split(/[^\w\s]|_/);

    // Only return characters length > 3
    this.usernameList = this.usernameList.filter(function (item) {
      return item.length >= 3;
    });
  }

  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.regForm3.disable();
    this.isSubmitting = true;

    //  if confirmPassword does not match password
    if (this.regForm3.controls['password'].value !== this.regForm3.controls['confirmPassword'].value) {
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.matchPassword = false;
      this.isSubmitting = false;
      this.regForm3.enable();
      return;
    }

    // if frontend validation fail
    if (this.errorList.length > 0) {
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.frontendError = true;
      window.postMessage('invalidPassword');
      this.isSubmitting = false;
      this.regForm3.enable();
      return;
    }

    // =================================== Validate Password ========================================
    const body = {
      'email': this.email,
      'password': this.regForm3.controls['password'].value
    }
    this.authService.validatePassword(body).subscribe({
      next: res => {

        if (res.data) {
          this.onCompleted.emit({ password: this.regForm3.controls['password'].value });
        } else if (res.error_code !== '-1') {
          this.serverErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
          this.serverError = true;
        } else {
          this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
          this.frontendError = true;
          window.postMessage('invalidPassword');
        }

        this.isSubmitting = false;
        this.regForm3.enable();
      }
    });
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;

      // Password validator criteria
      this.errorList = passwordValidatorCriteria(password, this.usernameList);

      if (this.errorList.length >= 1) {
        const firstError = this.errorList[0];
        return {
          [firstError]: true
        }
      }
      return null;
    }
  }
}
