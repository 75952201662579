import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  standalone: true,
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner1Component, TranslateModule]
})
export class AboutUsComponent implements OnInit {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "About us"
    }
  ]

  imageBanner1Data = {
    "content_id": "content-id-1",
    "image_src": {
      "sm": "",
      "lg": "/assets/images/about-us/img-banner-about-us.webp"
    }
  };

  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'GENERAL.FOOTER.ABOUT_US']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['GENERAL.FOOTER.ABOUT_US'];

        this.titleService.setTitle(res['GENERAL.FOOTER.ABOUT_US']);
      }
    })
  }
}
