<div class="video-list">
  <ul class="list-unstyled">
    <li *ngFor="let item of data" class="list-item">
      <figure class="cover-image" [title]="item.video_title ?? item.headline">
        <a [routerLink]="[currentUrl + item.link]" class="d-block">
          <img [src]="item.cover_image_src" alt="{{item.headline}}-img" />
          <div *ngIf="item.type.toUpperCase() === 'VIDEO'" class="cover-image-overlay">
            <a [routerLink]="[currentUrl + item.link]" class="btn btn-square btn-white btn-play">
              <span class="icon-img">
                <icon-play></icon-play>
              </span>
            </a>
            <div class="bookmark">
              <app-bookmark [data]="item.content_id"></app-bookmark>
            </div>
          </div>
          <div *ngIf="item.type.toUpperCase() === 'VIDEO' && item.video_duration" class="label label-video-duration">
            {{item.video_duration}}
          </div>
        </a>
      </figure>
      <div class="info d-flex flex-column align-items-start">
        <h3 class="text-dark fw-normal" [class.headline-middle]="!item.subheadline && !item.descriptions">
          {{item.headline}}
          <div *ngIf="item.headline_2">{{item.headline_2}}</div>
        </h3>
        <h5 *ngIf="item.subheadline" class="text-dark">{{item.subheadline}}</h5>
        <div *ngIf="item.descriptions" class="descriptions ">{{item.descriptions}}</div>
        <div *ngIf="item.mark_as_completed" class="label-container">
          <div class="label label-completed">{{"GENERAL.CONTENT.COMPLETED" | translate}}</div>
        </div>

      </div>
    </li>
  </ul>
</div>