import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { BannerCarousel1Component } from "../../components/banner-carousel-1/banner-carousel-1.component";
import { ListCarousel2Component } from "../../components/list-carousel-2/list-carousel-2.component";
import { GridListWithDateTimeComponent } from "../../components/grid-list-with-date-time/grid-list-with-date-time.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BannerCarousel1Model, GridListWithDateTimeModel, ListCarousel2Model } from '../../models';
import { ContentService } from '../../services/content.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshops',
  standalone: true,
  templateUrl: './workshops.component.html',
  styleUrl: './workshops.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, GridList3Component, CommonModule, BannerCarousel1Component, ListCarousel2Component, GridListWithDateTimeComponent, TranslateModule]
})
export class WorkshopsComponent implements OnInit {
  bannerData: BannerCarousel1Model[] = [];
  pastWorkshopContentType: string = 'SbuxWorkshopArchive';
  pastWorkshopData: GridListWithDateTimeModel[] = [];
  upcomingWorkshopContentType: string = 'SbuxWorkshop';
  upcomingWorkshopData: ListCarousel2Model[] = [];
  workshopBannerContentType: string = 'SbuxWorkshopBanner';

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Workshops"
    }
  ]

  constructor(
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.WORKSHOPS']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.WORKSHOPS'];

        this.titleService.setTitle(res['HOME.SHORTCUT.WORKSHOPS']);
      }
    })

    // Banner POST Request
    this.contentService.getPartnerContent(this.workshopBannerContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.bannerData.push({
            content_id: item.identifier,
            type: item.type,
            image_src: {
              sm: item.bannerImageMobile,
              lg: item.bannerImage
            },
            headline: item.title,
            subheadline: item.subtitle,
            black_apron_exclusive: item.blackApronExclusive,
            descriptions: item.description,
            top_label: item.topSubtitle,
            start_date: item.startDateTime,
            end_date: item.EndDateTime,
            button: {
              label: item.ctaLabel,
              link: item.ctaLink
            }
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Upcoming Workshop POST Request
    this.contentService.getPartnerContent(this.upcomingWorkshopContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.upcomingWorkshopData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            link: `/workshops/articles/${item.identifier}`,
            headline: item.title,
            start_date: item.startDateTime,
            end_date: item.EndDateTime,
            descriptions: item.descriptions,
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Past Workshops POST Request
    this.contentService.getPartnerContent(this.pastWorkshopContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.pastWorkshopData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            link: `/workshops/videos/${item.identifier}`,
            headline: item.title,
            start_date: item.startDateTime,
            end_date: item.endDateTime,
            descriptions: item.descriptions,
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
