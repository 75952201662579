import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, retry, throwError, timer } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environment/environment';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    retryDelay = 1000;
    maxRetryCount = 120;
    clientAuthEndpoints = [
        `${environment.prefixPath}/languages`,
        `${environment.prefixPath}/markets`,
        `${environment.prefixPath}/questionnaires`,
        `${environment.prefixPath}/auth`,
        `${environment.prefixPath}/cms`
    ];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // send cookies in request
        request = request.clone({
            withCredentials: true
        });

        if (request.url.includes("logout")) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            retry({
                count: this.maxRetryCount,
                delay: (err, retryCount) => {
                    if (request.url.includes('/oauth/token') && err.status === 429) {
                        return timer(this.retryDelay)
                    }
                    return throwError(() => err);
                }
            }),
            catchError(err => {
                console.log(`[ApiInterceptor] error`, err);

                if (err.status === 400) {
                    return throwError(() => err);
                }

                if (err.status === 401 && request.url.includes("/admin/content")) {
                    return throwError(() => err);
                }

                if (err.status === 401 && request.url.includes("/oauth/token")) {
                    return throwError(() => err);
                }

                if (err.status === 401 && request.body && request.body.content_type !== "SbuxPublicHomeBanner" && request.body.content_type !== "SbuxPublicHomeArticle") {
                    this.authService.userLogout();
                }

                if (err.status === 403 && request.url.includes("/admin/content")) {
                    this.router.navigate(['/']);
                    return throwError(() => err);
                }

                if (err.status === 403) {
                    this.authService.userLogout();
                }

                if (err.status === 500) {
                    this.router.navigate(['/500']);
                    return throwError(() => err);
                }

                if (err.error != null) {
                    const error = err.error.message || err.statusText;
                    return throwError(() => error);
                }
                return throwError(() => err);
            })
        );
    }
}