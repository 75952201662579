
import { Component } from '@angular/core';

@Component({
  selector: 'icon-star-outline',
  standalone: true,
  template: `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 15.3 14" enable-background="new 0 0 15.3 14" xml:space="preserve">
	<path d="M11.2,10c-0.1-0.5-0.2-1-0.3-1.4l4.5-3.6H9.5L7.8,0L7.6,0.5C7.3,1.2,7,1.9,6.8,2.5C6.4,3.4,6.1,4.2,5.7,5.1
		c-0.8,0-1.6,0-2.4,0c-0.8,0-1.7,0-2.6,0l-0.6,0l0.4,0.4C1.3,6.2,2.2,6.8,3,7.4c0.5,0.4,1,0.7,1.5,1.1l-1.5,5.1l0.5-0.2
		c0.8-0.4,1.5-0.8,2.1-1.2c0.7-0.5,1.4-0.9,2.2-1.3l4.4,3L12,13.3C11.7,12.1,11.4,11,11.2,10z M7.8,10.4l-0.1,0.1
		c-0.9,0.4-1.6,0.9-2.4,1.4c-0.5,0.3-1,0.6-1.6,1L5,8.4L4.9,8.3C4.4,7.9,3.8,7.5,3.3,7.1c-0.7-0.5-1.3-1-2-1.5c0.7,0,1.4,0,2,0
		c0.8,0,1.7,0,2.6,0l0.1,0l0.1-0.1c0.4-0.9,0.8-1.8,1.1-2.7c0.2-0.5,0.4-0.9,0.6-1.4l1.4,4.1H14l-3.7,3l0,0.1c0.1,0.5,0.2,1,0.4,1.5
		c0.2,0.8,0.4,1.8,0.7,2.8L7.8,10.4z"/>
</svg>
  `,
  styles: [],
})
export class StarOutlineIcon { }