import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HTMLContentsModel } from '../models';

@Injectable({
  providedIn: 'any'
})

export class HTMLContentsService {
  //private dataUrl = '/assets/data/banner-carousel-1.json';

  constructor(private http: HttpClient) { }

  getHTMLContentsData(dataUrl: string): Observable<HTMLContentsModel> {
    return this.http.get<HTMLContentsModel>(dataUrl);
  }
}
