import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environment/environment';
import { SpinnerIcon } from '../../../icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SpinnerIcon, TranslateModule],
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss',
  animations: [fadeInOut],
})
export class QuestionComponent implements OnInit {
  @Input() input!: { q: string, a: string }[];
  @Output() onCompleted = new EventEmitter<{ q: string, a: string }[]>();
  @Output() onCancelled = new EventEmitter<void>();

  currentIndex: number = 0;
  isLoading: boolean = true;
  isModalOpen: boolean = false;
  moreQuestions!: FormGroup;
  questions!: [];
  formControlObject!: any;


  constructor(
    private http: HttpClient
  ) { }


  ngOnInit(): void {
    this.http.get<any>(`${environment.domain}${environment.prefixPath}/questionnaires`).subscribe({
      next: result => {
        this.moreQuestions = new FormGroup({});

        // Dynamically create formControl
        result.data.forEach((item: { id: string; }) => {
          this.moreQuestions.addControl(item.id, new FormControl(this.findSavedAnswer(item.id), [Validators.required]));
        });

        // console.log("QUESTIONNAIRES SUCCESS");
        this.questions = result.data;
      },
      error: err => {
        // console.log("QUESTIONNAIRES FAIL");
        console.log(err);

      },
      complete: () => {
        this.isLoading = false;
      }
    })

    this.isModalOpen = true;
  }

  findSavedAnswer(id: string) {
    const answerExist: number = this.input.findIndex((questionId: { q: string; }) => questionId.q === id);

    // If exist, use answer.
    if (answerExist !== -1) {
      return this.input[answerExist].a;
    } else {
      return '';
    }
  }

  onSubmit() {
    if (this.currentIndex < this.questions.length - 1) {
      this.isModalOpen = false;
      this.currentIndex++;
      this.isModalOpen = true;
      return;
    }

    var emitObject: { 'q': string, 'a': string }[] = [];

    const formControlKeys = Object.keys(this.moreQuestions.controls);

    for (let j = 0; j < formControlKeys.length; j++) {
      emitObject.push({
        'q': formControlKeys[j],
        'a': this.moreQuestions.controls[formControlKeys[j]].value
      })
    };

    this.onCompleted.emit(emitObject);
  }

  onBack() {
    if (this.currentIndex != 0) {
      this.currentIndex--;
      return;
    }

    this.onCancelled.emit();
  }
}