<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 [data]="competitionSteeringCommitteeBannerData"></app-image-banner-2>
    </div>
  </section>

  <section class="mb-5 text-center">
    <div class="container">
      <h1 class="headline mb-3">{{"COMPETITIONS.COMMITTEE.HEADER" | translate}}</h1>
      <div class="descriptions ">{{"COMPETITIONS.COMMITTEE.DESC" | translate}}</div>
      <div class="mt-5">
        <app-person-grid-list [data]="competitionSteeringCommitteeData"></app-person-grid-list>
      </div>
    </div>
  </section>
</div>