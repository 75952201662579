import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { ImageLandscapeIcon, SpinnerIcon, XmarkIcon } from "../../../icons";

@Component({
  selector: 'app-profile-picture-avatar-creator',
  standalone: true,
  imports: [ImageLandscapeIcon, CommonModule, XmarkIcon, SpinnerIcon],
  templateUrl: './profile-picture-avatar-creator.component.html',
  styleUrl: './profile-picture-avatar-creator.component.scss',
  animations: [fadeInOut]
})
export class ProfilePictureAvatarCreatorComponent implements OnInit {
  @Output() goToReviewProfileClick = new EventEmitter<void>();
  @Output() goToChangeProfilePictureClick = new EventEmitter<void>();
  @Output() selectedProfileImage = new EventEmitter<string>();
  isCreated: boolean = false;

  ngOnInit(): void {
    window.addEventListener('message', (event: MessageEvent) => {
      // Avatar created
      if (event.data.type === 'avatarUri') {
        this.goToReviewProfile(event);
      }
    });
  }

  goToReviewProfile(event: MessageEvent) {
    this.isCreated = true;
    this.selectedProfileImage.emit(event.data.data);
    this.goToReviewProfileClick.emit();
  }

  goToChangeProfilePicture() {
    this.goToChangeProfilePictureClick.emit();
  }
}
