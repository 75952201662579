import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListFiltersModel } from '../../models';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-list-filters',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './list-filters.component.html',
  styleUrl: './list-filters.component.scss',
})
export class ListFiltersComponent {
  [x: string]: any;
  @Output() onCompleted = new EventEmitter<{ name: string, value: string }[]>();
  @Input({ required: true }) data!: ListFiltersModel;
  selectionForm!: FormGroup;
  filterValue: { name: string, value: string }[] = [];

  ngOnInit(): void {

    // In each filter group, find which value is selected
    this.data.select_menu_group?.forEach((item: any) => {
      const selectedOption = item.options.find((object: any) => object.is_selected);
      var obj: { name: string, value: string } = {
        name: '',
        value: ''
      };

      obj['name'] = item.id;
      obj['value'] = selectedOption.value;
      this.filterValue.push(obj);

      this.onCompleted.emit(this.filterValue);
    });

    // MIGHT NEED IT IN FUTURE (Revisit on Competition)
    // this.selectionForm = new FormGroup({}); //parent Group
    // this.data.select_menu_group?.forEach((item: any) => {
    //   // Dynamically add child formGroup into parent formGroup
    //   this.selectionForm.addControl(item.id, new FormGroup({})) //child Group

    //   // Add formControl into child formGroup
    //   const childGroup = this.selectionForm.get(item.id) as FormGroup;
    //   childGroup.addControl('filter', new FormControl(item.options[0].value, [Validators.required]))
    // })
  }

  onSelectFilter(event: any) {
    // Find specific object in this.filterValue
    const findObject = this.filterValue.find(obj => obj.name === event.target.name);
    if (findObject) {
      findObject.value = event.target.value;
    }

    // Emit array of object
    this.onCompleted.emit(this.filterValue);
  }
}
