import { Component, Input, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { CommonModule } from '@angular/common';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-person-text-bio-modal',
  standalone: true,
  imports: [XmarkIcon, CommonModule, ClickableEnlargeImageDirective],
  templateUrl: './person-text-bio-modal.component.html',
  styleUrl: './person-text-bio-modal.component.scss'
})
export class PersonTextBioModalComponent implements AfterViewInit {
  @Input() modal: any;
  @Input() personTextBio!: string;
  @Input() personAvatarImageSrc!: string;
  @Input() personName!: string;
  @Input() personTitle!: string;
  @Input() personMarket!: string;

  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  ngAfterViewInit() {
    // Remove any aria-hidden attributes that might be added by the modal
    const modalElement = this.elementRef.nativeElement;
    if (modalElement) {
      modalElement.removeAttribute('aria-hidden');
    }

    // Initialize PhotoSwipe for dynamically added images
    const images = modalElement.querySelectorAll('img.clickable-enlarge-image');
    images.forEach((img: Element) => {
      const directive = new ClickableEnlargeImageDirective(new ElementRef(img), this.ngZone);
      directive.ngOnInit();
    });
  }
}
