<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-5">
      <figure class="error-404-figure">
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" version="1.1" viewBox="0 0 512 512">
          <path
            d="M501.8 140.4c-4.4 0-7.9-3.5-7.9-7.9V74c0-6.6-5.3-11.9-11.9-11.9H30.1c-6.6 0-11.9 5.3-11.9 11.9v58.5c0 4.4-3.5 7.9-7.9 7.9s-7.9-3.5-7.9-7.9V74c0-15.3 12.5-27.7 27.7-27.7H482c15.3 0 27.7 12.5 27.7 27.7v58.5c0 4.4-3.5 7.9-7.9 7.9Z" />
          <path
            d="M481.9 465.8H30.1c-15.3 0-27.7-12.5-27.7-27.7V132.5c0-4.4 3.5-7.9 7.9-7.9h491.5c4.4 0 7.9 3.5 7.9 7.9V438c0 15.3-12.5 27.7-27.7 27.7ZM18.2 140.4V438c0 6.6 5.3 11.9 11.9 11.9H482c6.6 0 11.9-5.3 11.9-11.9V140.4H18.2ZM72 104.9c-6.4 0-11.6-5.2-11.6-11.6S65.6 81.7 72 81.7s11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Zm45.6 0c-6.4 0-11.6-5.2-11.6-11.6s5.2-11.6 11.6-11.6 11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Zm45.6 0c-6.4 0-11.6-5.2-11.6-11.6s5.2-11.6 11.6-11.6 11.6 5.2 11.6 11.6-5.2 11.6-11.6 11.6Z" />
          <path
            d="M155.2 223.7h-19.1l-68.2 87.4v14.8h67.2v39.5h20.1v-39.5h24.3v-15.4h-24.3v-86.8ZM135 310.4H86.5l48.5-61.8v61.8ZM256.5 220.7c-36.5 0-60.2 31.7-60.2 73.8s23.7 73.8 60.2 73.8 60.6-31.7 60.6-73.8-23.9-73.8-60.6-73.8Zm0 129.8c-24.5 0-39.1-22.5-39.1-56s14.6-56 39.1-56 39.1 22.5 39.1 56-14.6 56-39.1 56ZM422.9 310.4v-86.8h-19.1L335.6 311v14.8h67.2v39.5h20.1v-39.5h24.3v-15.4h-24.3Zm-20.2 0h-48.5l48.5-61.8v61.8Z" />
        </svg>
      </figure>
      <h1>Ooops... There's an error</h1>
      <p>The page you are looking for does not exist.</p>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto" [routerLink]="['/home']">
        Go to homepage
      </button>
    </div>

  </div>
</div>