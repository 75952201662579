import { Component, Input, OnInit } from '@angular/core';
import { LikeIcon } from "../../icons/icon-like";
import { AccountService } from '../../services/account.service';
import { ConfigService } from '../../services/config.service';
import { LikeModel } from '../../models/like.model';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-like',
  standalone: true,
  templateUrl: './like.component.html',
  styleUrl: './like.component.scss',
  imports: [LikeIcon]
})
export class LikeComponent implements OnInit {
  @Input({ required: true }) data!: LikeModel;
  isActive = false;
  likedCount!: number;

  constructor(
    private accountService: AccountService,
    public configService: ConfigService,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.getLikedCount()
  }

  toggleLike(event: Event) {
    event?.preventDefault();

    if (this.configService.isPreview) {
      return;
    }

    if (this.isActive) {
      this.accountService.removeLikes(this.data.content_id).subscribe({
        next: res => {
          this.isActive = false;
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          this.getLikedCount();
        }
      });
    } else {
      this.accountService.createLikes(this.data.content_id).subscribe({
        next: res => {
          this.isActive = true;
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          this.getLikedCount();
        }
      });
    }
  }

  getLikedCount(): void {
    if (this.configService.isPreview) {
      this.likedCount = 0;
    } else {
      this.accountService.getTotalLikes(this.data.content_id).subscribe({
        next: res => {
          this.isActive = res.liked;
          this.likedCount = res.total_likes;
        }
      })
    }
  }
}
