<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="content-heading mb-5">
    <div class="container">
      <h1 class="mb-3">{{"MY_PROFILE.HEADER" | translate}}</h1>
      <div class="descriptions ">
        {{"MY_PROFILE.DESC" | translate}}
      </div>
    </div>
  </section>

  <section *ngIf="isObjectEmpty(data)">
    <div class="container mw-lg">
      <div class="profile mb-5">
        <!-- Black Apron Card -->
        <div
          *ngIf="data.tier.toUpperCase() === 'SCA_CERTIFIED_COFFEE_MASTER' || data.tier.toUpperCase() === 'STAR_1' || data.tier.toUpperCase() === 'STAR_2' || data.tier.toUpperCase() === 'STAR_3'; else greenApronCard"
          class="partner-profile-card mb-5 black-card">
          <div class="d-flex flex-wrap h-100">
            <figure class="profile-picture">
              <div class="profile-picture-wrapper">
                <img *ngIf="data.photo" [src]="data.photo">
                <img *ngIf="!data.photo" src="/assets/images/avatar/img-avatar-placeholder.png">
              </div>
              <button class="btn btn-white btn-circle btn-edit-avatar"
                (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_PROFILE_PICTURE'); openChangeProfilePictureModal();">
                <span class="icon-img">
                  <icon-edit></icon-edit>
                </span>
              </button>
            </figure>
            <div class="d-flex flex-column profile-info">
              <h2 class="nickname truncate-line-clamp lc-2">{{data.nick_name}}</h2>
              <h5 class="name truncate-line-clamp lc-2">{{data.first_name}} {{data.last_name}}</h5>
              <div class="market truncate-line-clamp lc-2">{{data.market_name}}</div>
              <div class="email truncate-line-clamp lc-2">{{data.email}}</div>
              <div class="title-path d-flex flex-wrap gap-2 mt-2">
                <div class="title-path-item">
                  <img src="assets/images/bap/ico-bap-title-oes.png" alt="Origin & Ethical Sourcing Lead"
                    title="Origin & Ethical Sourcing Lead">
                </div>
                <div class="title-path-item">
                  <img src="assets/images/bap/ico-bap-title-bc.png" alt="Brew & Craft Lead" title="Brew & Craft Lead">
                </div>
                <div class="title-path-item">
                  <img src="assets/images/bap/ico-bap-title-rb.png" alt="Roast & Blend Lead" title="Roast & Blend Lead">
                </div>
                <div class="title-path-item">
                  <img src="assets/images/bap/ico-bap-title-cl.png" alt="Coffee Leadership Lead"
                    title="Coffee Leadership Lead">
                </div>
              </div>

              <div class="black-level-star">
                <div class="d-inline-flex flex-column">
                  <div class="black-level-label text-center">Black Apron</div>

                  <div class="star-wrapper d-inline-flex flex-wrap align-items-center justify-content-center gap-1">
                    <!-- Show 3 empty stars for SCA_CERTIFIED_COFFEE_MASTER -->
                    <ng-container *ngIf="data.tier.toUpperCase() === 'SCA_CERTIFIED_COFFEE_MASTER'">
                      <span class="icon-img" *ngFor="let star of [1,2,3]">
                        <icon-bap-star></icon-bap-star>
                      </span>
                    </ng-container>

                    <!-- Original star logic for other tiers -->
                    <ng-container *ngIf="data.tier.toUpperCase() !== 'SCA_CERTIFIED_COFFEE_MASTER'">
                      <ng-container *ngFor="let star of [1,2,3]">
                        <span class="icon-img">
                          <icon-bap-star-filled
                            *ngIf="star <= +data.tier.charAt(data.tier.length-1)"></icon-bap-star-filled>
                          <icon-bap-star *ngIf="star > +data.tier.charAt(data.tier.length-1)"></icon-bap-star>
                        </span>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="join-date mt-auto">
                {{"MY_PROFILE.JOINED_ON_1" | translate}}{{formatDate(data.created_at)}}{{"MY_PROFILE.JOINED_ON_2" | translate}}
              </div>
            </div>
            <div class="logo">
              <img src="/assets/images/global/img-logo-sbx-white.svg" alt="">
            </div>

          </div>
        </div>



        <!-- Green Apron Card -->
        <ng-template #greenApronCard>
          <div class="partner-profile-card mb-5">
            <div class="d-flex flex-wrap h-100">
              <figure class="flex-auto profile-picture">
                <div class="profile-picture-wrapper">
                  <img *ngIf="data.photo" [src]="data.photo">
                  <img *ngIf="!data.photo" src="/assets/images/avatar/img-avatar-placeholder.png">
                </div>
                <button class="btn btn-white btn-circle btn-edit-avatar"
                  (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_PROFILE_PICTURE'); openChangeProfilePictureModal();">
                  <span class="icon-img">
                    <icon-edit></icon-edit>
                  </span>
                </button>
              </figure>
              <div class="d-flex flex-column profile-info">
                <h2 class="nickname truncate-line-clamp lc-2">{{data.nick_name}}</h2>
                <h5 class="name truncate-line-clamp lc-2">{{data.first_name}} {{data.last_name}}</h5>
                <div class="market truncate-line-clamp lc-2">{{data.market_name}}</div>
                <div class="email truncate-line-clamp lc-2">{{data.email}}</div>
                <div class="join-date mt-auto">
                  {{"MY_PROFILE.JOINED_ON_1" | translate}}{{formatDate(data.created_at)}}{{"MY_PROFILE.JOINED_ON_2" | translate}}
                </div>
              </div>
              <div class="logo">
                <img src="/assets/images/global/img-logo-sbx-white.svg" alt="">
              </div>
            </div>
          </div>
        </ng-template>

        <div class="tools mb-4">
          <div class="row justify-content-center">
            <!-- <div class="col-12 col-md-6 mb-3 mb-md-0">
              <button type="button" class="btn btn-secondary w-100 text-uppercase fw-bold"
                (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_PROFILE_PICTURE'); openChangeProfilePictureModal();">{{"MY_PROFILE.CTA_PP_CHANGE" | translate}}</button>
            </div>
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <button type="button" class="btn btn-secondary w-100 text-uppercase fw-bold"
                (click)="downloadProfilePicture()"
                [disabled]="!profilePictureUrl">{{"MY_PROFILE.CTA_PP_DOWNLOAD" | translate}}</button>
            </div> -->

            <div class="col-12 col-md-6 mb-3">
              <a (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_NICKNAME')"
                href="/update-nickname" class="btn btn-white w-100 text-uppercase fw-bold">Change Nickname</a>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <a (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_PASSWORD')"
                [href]="'/update-password?e='+encodedEmail" class="btn btn-white w-100 text-uppercase fw-bold">Change
                Password</a>
            </div>

            <div class="col-12 col-md-6 mb-3">
              <button type="button" class="btn btn-white w-100 text-uppercase fw-bold"
                (click)="downloadProfilePicture()" [disabled]="!profilePictureUrl">Download Profile Photo</button>
            </div>
          </div>
        </div>

        <!-- <div class="form">
          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-market-input" class="form-label">{{"MY_PROFILE.MARKET" | translate}}</label>
                <input type="text" class="form-control" id="partner-market-input" [value]="data.market_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-id-input" class="form-label">{{"MY_PROFILE.PARTNER_ID" | translate}}</label>
                <input type="text" class="form-control" id="partner-id-input" [value]="data.partner_id" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-fullname-input" class="form-label">{{"MY_PROFILE.FIRST_NAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-fullname-input" [value]="data.first_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-lastname-input" class="form-label">{{"MY_PROFILE.LAST_NAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-lastname-input" [value]="data.last_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-email-input" class="form-label">{{"MY_PROFILE.EMAIL" | translate}}</label>
                <input type="email" class="form-control" id="partner-email-input" [value]="data.email" disabled>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <div class="form-group mb-3">
                <label for="partner-nickname-input" class="form-label">{{"MY_PROFILE.NICKNAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-nickname-input" [value]="data.nick_name" disabled>
              </div>
              <a (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_NICKNAME')"
                href="/update-nickname"
                class="btn btn-sm btn-white w-100 w-md-auto">{{"MY_PROFILE.CTA_NICKNAME_CHANGE" | translate}}</a>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group mb-3">
                <label for="partner-password-input"
                  class="form-label">{{"MY_PROFILE.CURRENT_PASSWORD" | translate}}</label>
                <input type="password" class="form-control" id="partner-password-input" value="********" disabled>
              </div>
              <a (click)="this.analyticsService.sendClickEventAnalytic('PROFILE', undefined, undefined, undefined, 'UPDATE_PASSWORD')"
                [href]="'/update-password?e='+encodedEmail"
                class="btn btn-sm btn-white w-100 w-md-auto">{{"MY_PROFILE.CTA_PASSWORD_CHANGE" | translate}}</a>
            </div>
          </div>
        </div> -->

      </div>

      <div class="upgrade-verification-notices">
        <div class="mb-4">
          <app-verify-upgrade-notice [data]="greenToScaData"></app-verify-upgrade-notice>
        </div>
        <div class="mb-4">
          <app-verify-upgrade-notice [data]="verifyScaData"></app-verify-upgrade-notice>
        </div>
        <div class="mb-4">
          <app-verify-upgrade-notice [data]="verifyStarPathData"></app-verify-upgrade-notice>
        </div>
      </div>

      <div class="star-path-container mt-4">
        <app-star-path [partnerStarPathProgressData]="starPathProgressData" [starPathPointsConfig]="starPathConfig">
        </app-star-path>
      </div>

      <div class="title-path-container mt-4">
        <app-title-path [partnerTitlePathProgressData]="titlePathProgressData"
          (claimTitlePathEvent)="openClaimTitlePathModal()">
        </app-title-path>
      </div>

      <div class="activity-history-container mt-5">
        <h2 class="mb-4">BAP Activity History</h2>
        <app-bap-activity-history [activityHistory]="activityHistory"></app-bap-activity-history>
      </div>

      <!-- <div class="profile-completeness">
        <h3 class="text-dark fw-normal mb-4">{{"MY_PROFILE.PROFILE_COMPLETENESS.TITLE" | translate}}
          {{"MY_PROFILE.PROFILE_COMPLETENESS."+profileCompletenessLevel | translate}}
        </h3>
        <div class="profile-progress">
          <div class="progress-bar">
            <div class="progress-layer" [class.beginner]="profileCompletenessLevel === '25'"
              [class.intermediate]="profileCompletenessLevel === '50'"
              [class.advanced]="profileCompletenessLevel === '75'"
              [class.expert]="profileCompletenessLevel === '100'">
            </div>
            <span class="breakpoint-mark breakpoint-1"></span>
            <span class="breakpoint-mark breakpoint-2"></span>
            <span class="breakpoint-mark breakpoint-3"></span>
          </div>
          <span class="circle-star">
            <icon-circle-star></icon-circle-star>
          </span>
          <span class="circle-star" [class.beginner]="profileCompletenessLevel === '25'"
            [class.intermediate]="profileCompletenessLevel === '50'"
            [class.advanced]="profileCompletenessLevel === '75'" [class.expert]="profileCompletenessLevel === '100'">
            <icon-circle-star></icon-circle-star>
          </span>
        </div>

        <div class="d-none d-md-flex flex-wrap text-center mt-3 fs-small fw-semibold">
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.25" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.50" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.75" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.100" | translate}}</div>
        </div>
      </div>



      <div *ngIf="activityData" class="activity mt-5 pt-4 mb-4">
        <h5 class="mb-4 pe-8 text-dark">
          {{"MY_PROFILE.PROFILE_COMPLETENESS.DESC" | translate}}
        </h5>
        <div class="activity-slider">
          <owl-carousel-o [options]="activityCarouselOptions">
            <ng-container *ngFor="let item of activityData">
              <ng-template carouselSlide>
                <div class="activity-item d-flex flex-wrap">
                  <figure class="mt-1">
                    <span class="icon-img">
                      <icon-coffee-bean-bag></icon-coffee-bean-bag>
                    </span>
                  </figure>

                  <div class="content">
                    <h4 class="fw-normal text-dark">{{item.action_name}}</h4>
                    <div class="row align-items-center">
                      <div class="col-12 col-md-8 mb-4 mb-md-0">
                        {{item.description}}
                      </div>
                      <div class="col-12 col-md-4 text-end">
                        <a [href]="item.button.link"
                          class="btn btn-alert btn-sm mt-0 ms-auto w-100 w-md-auto">{{item.button.label}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

            </ng-container>
          </owl-carousel-o>
        </div>
      </div> -->
    </div>
  </section>
</div>