
import { Component } from '@angular/core';

@Component({
  selector: 'icon-upload',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 20 20"><path d="M5.7 11c.5 0 .9.4.9.9s-.4.9-.9.9h-3C1.2 12.7 0 11.6 0 10.1V2.6C0 1.2 1.2 0 2.6 0h14.7c1.5 0 2.6 1.2 2.6 2.6v7.5c0 1.5-1.2 2.6-2.6 2.6h-3c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h3c.5 0 .9-.4.9-.9V2.6c0-.5-.4-.9-.9-.9H2.6c-.5 0-.9.4-.9.9v7.5c0 .5.4.9.9.9h3Zm1.2-1.9 2.3-2.6v12.7c0 .5.4.9.9.9s.9-.4.9-.9V6.5l2.3 2.6c.3.4.8.4 1.2 0 .4-.3.4-.8 0-1.2l-3.7-4.3c-.2-.2-.4-.3-.7-.3s-.5.1-.7.3L5.7 7.9c-.3.4-.3.9 0 1.2.4.3.9.3 1.2 0Z" style="fill-rule:evenodd;stroke-width:0"/></svg>
  `,
  styles: [],
})
export class UploadIcon { }