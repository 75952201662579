import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { Title } from '@angular/platform-browser';
import { XmarkIcon } from "../../icons/icon-xmark";
import { ContentService } from '../../services/content.service';
import { AccountService } from '../../services/account.service';
import { PartnerNotificationModel } from '../../models/partner-notification.model';

@Component({
  selector: 'app-partner-notifications',
  standalone: true,
  imports: [BreadcrumbsComponent, TranslateModule, XmarkIcon, CommonModule],
  templateUrl: './partner-notifications.component.html',
  styleUrl: './partner-notifications.component.scss',
  animations: [fadeInOut]
})

export class PartnerNotificationsComponent implements OnInit {
  partnerNotificationContentType: string = 'SbuxPartnerNotification';
  partnerNotificationData: PartnerNotificationModel[] = [];
  languageId: string = localStorage.getItem("languageId") ?? "1";

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Notifications"
    }
  ]

  constructor(
    private translation: TranslateService,
    private titleService: Title,
    private contentService: ContentService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    // TODO: Translation for breadcrumbs

    // // Learning Centre Home Banner POST Request
    // this.contentService.getPartnerContent(this.partnerNotificationContentType).subscribe({
    //   next: res => {
    //     res.forEach(noti => {
    //       this.partnerNotificationData.push({
    //         title: res.title,
    //         body: res.bodyContents,
    //         date: 
    //       })
    //     });
    //   }
    // })

    // fetch partner notification from db
    this.accountService.getPartnerNotification().subscribe({
      next: res => {
        if (res.length > 0) {
          res.forEach((pn: any) => {
            this.partnerNotificationData.push({
              id: pn.id,
              title: pn.title ?? "",
              body: pn.body ?? "",
              createdAt: this.contentService.formatDate4(new Date(pn.created_at)),
              readAt: pn.read_at ? this.contentService.formatDate4(new Date(pn.read_at)) : undefined,
              deletedAt: pn.deleted_at ? this.contentService.formatDate4(new Date(pn.deleted_at)) : undefined
            })
          });
        }
      }
    })
  }

  removeNotification(notification: PartnerNotificationModel) {
    const index = this.partnerNotificationData.findIndex(n => n.id === notification.id);
    if (index > -1) {
      this.accountService.deletePartnerNotification(notification.id).subscribe({
        next: res => {
          this.partnerNotificationData[index].deletedAt = "true"; // dummy value to hide from display
        }
      });
    }
  }

  get activeNotifications() {
    return this.partnerNotificationData.filter(n => !n.deletedAt);
  }

  get unreadNotifications() {
    return this.partnerNotificationData.filter(n => n.readAt == null && n.deletedAt == null);
  }

  get readNotifications() {
    return this.partnerNotificationData.filter(n => n.readAt != null && n.deletedAt == null);
  }

  markAsRead(notification: PartnerNotificationModel) {
    this.accountService.updatePartnerNotification(notification.id).subscribe({
      next: res => {
        notification.readAt = "true"; // dummy value to hide from display
      }
    });
  }

  markAllAsRead(event: Event) {
    event.preventDefault();
    this.unreadNotifications.forEach((n: PartnerNotificationModel) => {
      this.markAsRead(n);
    });
    this.accountService.updateData(false);
  }
}
