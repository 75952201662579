import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { ContentService } from '../../services/content.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';
import { VideoPlayState } from '../../enum/video-playState.enum';

@Component({
  selector: 'app-video-modal',
  standalone: true,
  imports: [XmarkIcon],
  templateUrl: './video-modal.component.html',
  styleUrl: './video-modal.component.scss'
})
export class VideoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  videoSrc!: SafeResourceUrl;

  // Video Analytics variable
  rawVideoSrc!: string;
  player: any;
  setPlayerInterval: any;
  videoAnalyticsInterval: any;

  constructor(
    private contentService: ContentService,
    private sanitizer: DomSanitizer,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) { }

  @Input() modal: any;
  @Input() videoUrl!: string;
  @Input() videoIdentifier!: string;
  @Input() contentType!: string;

  ngAfterViewInit() {
    if (!this.configService.isPreview) {
      this.loadYouTubeAPI();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.setPlayerInterval);
    clearInterval(this.videoAnalyticsInterval);
    this.videoAnalyticsInterval = null;
  }

  ngOnInit(): void {
    // Clear previous video sources
    this.videoSrc = '';
    this.rawVideoSrc = '';

    if (this.videoUrl) {
      this.sanitizeVideoUrl(this.videoUrl);
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  sanitizeVideoUrl(videoUrl: string) {
    try {
      const vidUrl = this.contentService.formatVideoUrl(videoUrl) + '?enablejsapi=1';
      this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(vidUrl);
      this.rawVideoSrc = vidUrl;
    } catch (error) {
      console.error('Error formatting video URL:', error);
      this.modal.dismiss();
    }
  }

  loadYouTubeAPI() {
    (window as any).onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady();
  }

  onYouTubeIframeAPIReady() {
    this.setPlayerInterval = setInterval(() => {
      this.player = new (window as any).YT.Player('youtube-video-iframe', {
        events: {
          'onReady': (e: any) => this.onPlayerReady(e),
          'onStateChange': (e: any) => this.onPlayerStateChange(e)
        }
      });
      if (this.player) {
        clearInterval(this.setPlayerInterval);
      }
    }, 1000)
  }

  onPlayerReady(event: any) {
    clearInterval(this.setPlayerInterval);
  }

  onPlayerStateChange(event: any) {
    clearInterval(this.setPlayerInterval);
    if (event.target.playerInfo.playerState === VideoPlayState.PLAYING || event.target.playerInfo.playerState === VideoPlayState.BUFFERING) {
      if (!this.videoAnalyticsInterval) {
        this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.contentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        this.videoAnalyticsInterval = setInterval(() => {
          this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.contentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        }, 30000)
      }
    } else if (event.target.playerInfo.playerState === VideoPlayState.ENDED || event.target.playerInfo.playerState === VideoPlayState.PAUSED) {
      this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.contentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
      clearInterval(this.videoAnalyticsInterval);
      this.videoAnalyticsInterval = null;
    }
  }
}
