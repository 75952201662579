import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { CommonModule } from '@angular/common';
import { FullscreenBannerComponent } from "../../components/fullscreen-banner/fullscreen-banner.component";
import { EventVideoListComponent } from "../../components/event-video-list/event-video-list.component";
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { EventVideoListModel, FullScreenBannerModel } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-event-videos',
  standalone: true,
  templateUrl: './event-videos.component.html',
  styleUrl: './event-videos.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, CommonModule, FullscreenBannerComponent, EventVideoListComponent, RouterLink]
})
export class EventVideosComponent implements OnInit {
  activityIdentifier!: string;
  currentUrl!: string;
  eventActivityContentType: string = 'SbuxEventActivity';
  eventBannerData: FullScreenBannerModel = {
    content_id: '',
    image_src: {
      sm: '',
      lg: ''
    }
  };
  eventContentType: string = 'SbuxEvent';
  eventIdentifier!: string;
  eventVideosData: EventVideoListModel[] = [];

  additionalListData: {
    content_type: string,
    cover_image_src: string,
    link: string,
    headline: string,
    subheadline: string,
    descriptions: string
  }[] = [];
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/events',
      label: 'Events'
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    public analyticsService: AnalyticsService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventIdentifier = params['pId'];
      this.activityIdentifier = params['aId'];

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.activityIdentifier, this.eventActivityContentType, this.router.url);
      }

      // Construct return url
      this.currentUrl = this.router.url;
      const segmentUrl = this.currentUrl.split('/');
      segmentUrl.pop();
      segmentUrl.pop();
      const updatedUrl = segmentUrl.join('/');

      // Meet the barista and judges
      this.additionalListData.push({
        "content_type": "meet-the-barista-and-judges",
        cover_image_src: "/assets/images/competitions/img-meet-the-barista.webp", //CMS: d4239e62-829a-48b6-bc63-b574fd191ed6
        link: `${this.currentUrl}/meet-the-barista-and-judges`,
        headline: "Meet the Barista and Judges",
        subheadline: "",
        // descriptions: "Explore the significance of coffee origin, tracing its history, introducing key producing regions, and understanding basic coffee plant varieties."
        descriptions: ""
      })

      // Get Translation
      this.translation.get(['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE']).subscribe({
        next: res => {
          this.additionalListData[0].headline = res['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE'];
        }
      })

      // For Breadcrumb
      if (!this.configService.isPreview) {
        this.contentService.getPartnerContent(this.eventContentType, this.eventIdentifier).subscribe({
          next: res => {
            const data = res[0];
            this.breadcrumbsData[0].label = data.title;
          },
          error: err => {
            console.log(err);
          }
        });
      }
      this.breadcrumbsData[0].link = updatedUrl;

      // For current page data
      this.contentService.getPartnerContent(this.eventActivityContentType, this.activityIdentifier).subscribe({
        next: res => {
          if (res.length === 0) {
            this.router.navigate(['/404']);
          }

          const data = res[0];

          // Page Title
          this.titleService.setTitle(data.title);

          // Banner Data
          this.eventBannerData.content_id = data.identifier;
          this.eventBannerData.image_alt = `${data.title}-img`;
          this.eventBannerData.image_src.sm = data.bannerImageMobile;
          this.eventBannerData.image_src.lg = data.bannerImage;
          this.eventBannerData.overlay_headline = data.title;
          this.eventBannerData.overlay_logo_src = data.icon;

          // Video List Data
          data.eventVideos.forEach((item: any) => {
            const splitHeadline = item.videoTitle.split('|');
            this.eventVideosData.push({
              content_type: item.contentType,
              content_id: item.identifier,
              cover_image_src: item.coverImage,
              link: `${this.currentUrl}/videos/${item.identifier}`,
              video_title: item.videoTitle,
              video_duration: this.contentService.formatSecToHMS(item.videoDuration),
              headline: splitHeadline[0],
              headline_2: splitHeadline[1] ?? '',
              start_date: item.startDateTime,
              end_date: item.endDateTime,
              button: {
                link: `${this.currentUrl}/videos/${item.identifier}`,
              }
            })
          });
        },
        error: err => {
          console.log(err);
        }
      });
    })
  }
}
