import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-title-earned-notice-modal',
  standalone: true,
  imports: [XmarkIcon, TranslateModule],
  templateUrl: './title-earned-notice-modal.component.html',
  styleUrl: './title-earned-notice-modal.component.scss'
})
export class TitleEarnedNoticeModalComponent {
  @Input() modal: any;

  dismissModal() {
    this.modal.dismiss();
  }
}
