
import { Component } from '@angular/core';

@Component({
  selector: 'icon-upload-cloud',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 40 29"><path d="M38.057 16.2a7.733 7.733 0 0 0-5.586-2.414h-1.143c.057-.457.086-.914.086-1.372.029-5.643-4.143-10.529-9.686-11.357a12.032 12.032 0 0 0-1.743-.129c-5.814 0-10.629 4.371-11.343 10h-.086c-4.727.001-8.57 3.844-8.57 8.572s3.843 8.572 8.571 8.572h24.286a7.16 7.16 0 0 0 5.057-2.1 7.17 7.17 0 0 0 2.071-4.643c.114-1.886-.571-3.714-1.929-5.143l.014.014ZM35.9 23.957a4.306 4.306 0 0 1-3.043 1.257H8.571c-3.157 0-5.714-2.557-5.714-5.714s2.557-5.714 5.714-5.714H10c.786 0 1.429-.643 1.429-1.429 0-4.729 3.843-8.571 8.571-8.571.443 0 .886.029 1.329.1 4.086.6 7.257 4.343 7.243 8.514a8.32 8.32 0 0 1-.357 2.4c-.129.429-.043.9.214 1.271.271.357.7.571 1.143.571h2.9c1.314 0 2.6.557 3.5 1.514.8.857 1.214 1.914 1.157 3.014a4.317 4.317 0 0 1-1.243 2.786h.014ZM25.3 12.772a1.423 1.423 0 0 1-1.014 2.428c-.371 0-.729-.143-1.014-.414l-1.843-1.843v7.986c0 .786-.643 1.429-1.429 1.429s-1.429-.643-1.429-1.429v-7.986l-1.843 1.843c-.557.557-1.457.557-2.014 0s-.557-1.457 0-2.014L19 8.486a1.423 1.423 0 0 1 2.014 0l4.286 4.286Z"/></svg>
  `,
  styles: [],
})
export class UploadCloudIcon { }