<div [@fadeInOut] class="inner-body p-0 pb-5 page-with-top-full-banner">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>


  <section class="hero-banner">
    <app-fullscreen-banner [data]="fullscreenBannerData"></app-fullscreen-banner>
  </section>

  <section class="listing">
    <div class="container mw-lg">
      <app-video-list [data]="videoListData"></app-video-list>

      <div *ngIf="additionalListData" class="additional-list">
        <ul class="list-unstyled">
          <li *ngFor="let item of additionalListData" class="list-item">
            <figure class="cover-image" [title]="item.headline">
              <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, competitionIdentifier, item.headline)"
                [routerLink]="[currentUrl+item.link]" class="d-block">
                <img [src]="item.cover_image_src" [alt]="item.headline">
              </a>
            </figure>
            <div class="info d-flex flex-column align-items-start">
              <h3 class="text-dark fw-normal">{{item.headline}}</h3>
              <h5 *ngIf="item.subheadline" class="text-dark">{{item.subheadline}}</h5>
              <div *ngIf="item.descriptions" class="descriptions ">{{item.descriptions}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>