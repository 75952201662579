import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenBannerModel } from '../../models';

@Component({
  selector: 'app-fullscreen-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fullscreen-banner.component.html',
  styleUrl: './fullscreen-banner.component.scss'
})
export class FullscreenBannerComponent {
  @Input({ required: true }) data!: FullScreenBannerModel;
}
