<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div class="modal-inner-body">

      <div class="inner-body-heading">
        <h2 class="text-dark">
          {{"BAP.UNLOCK_CONTENT.HEADING" | translate}}
        </h2>

      </div>

      <div class="inner-body-contents ">
        <p>{{"BAP.UNLOCK_CONTENT.BODY_1" | translate}}</p>
        <p>{{"BAP.UNLOCK_CONTENT.BODY_2" | translate}}</p>
      </div>

      <div class="inner-body-footer mt-3">
        <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="openRegisterForm()">
          <p>{{"BAP.UNLOCK_CONTENT.CONTINUE_BUTTON_LABEL" | translate}}</p>
        </button>
      </div>
    </div>
  </div>
</div>