
import { Component } from '@angular/core';

@Component({
  selector: 'icon-download',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 20 19.99"><path fill-rule="evenodd" d="m13.124 10.899-2.267 2.613V.857a.856.856 0 1 0-1.714 0v12.655l-2.267-2.613a.855.855 0 1 0-1.292 1.12l3.724 4.297a.86.86 0 0 0 1.382 0l3.724-4.297a.854.854 0 1 0-1.292-1.12h.004Zm1.223-1.922a.858.858 0 1 1 0-1.714h3.008a2.647 2.647 0 0 1 2.644 2.644v7.453a2.647 2.647 0 0 1-2.644 2.644H2.644A2.648 2.648 0 0 1 0 17.36V9.907a2.647 2.647 0 0 1 2.644-2.644h3.013a.857.857 0 0 1 0 1.714H2.644a.93.93 0 0 0-.928.928v7.451a.93.93 0 0 0 .928.928h14.714a.93.93 0 0 0 .928-.928V9.903a.93.93 0 0 0-.928-.928h-3.011v.002Z"/></svg>
  `,
  styles: [],
})
export class DownloadIcon { }