import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridList1Model } from '../models';

@Injectable({
  providedIn: 'any'
})

export class GridList1Service {
  //private dataUrl = '/assets/data/grid-list-1.json';

  constructor(private http: HttpClient) { }

  getGridList1Data(dataUrl: string): Observable<GridList1Model[]> {
    return this.http.get<GridList1Model[]>(dataUrl);
  }
}
