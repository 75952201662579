import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { GridList4Component } from "../../components/grid-list-4/grid-list-4.component";
import { ListFiltersComponent } from "../../components/list-filters/list-filters.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { BannerCarousel2Model, GridList3Model, GridList4Model, ImageBanner2Model } from '../../models';
import { LanguageService } from '../../services/language.service';
import { Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { BannerCarousel2Component } from "../../components/banner-carousel-2/banner-carousel-2.component";
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-competitions',
  standalone: true,
  templateUrl: './competitions.component.html',
  styleUrl: './competitions.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, CommonModule, ImageBanner2Component, GridList4Component, ListFiltersComponent, GridList3Component, TranslateModule, RouterLink, BannerCarousel2Component]
})
export class CompetitionsComponent implements OnInit {
  competitionDataUnitTranslate: string = 'video';
  competitionSteeringCommitteeTitle: string = 'Meet the Competition Steering Committee';
  competitionInformationContentType: string = 'SBUXCompetitionInformation';
  competitionYearFilter: string = 'SbuxCompetitionYearFilter';
  competitionHomeBanner: string = 'SbuxCompetitionHomeBanner';
  competitionSteeringCommitteeBanner: string = 'SbuxCompetitionSteeringCommitteeBanner';
  competitionContentType = 'SbuxCompetition';
  competitionFeaturedVideoType: string = 'SbuxCompetitionFeaturedVideo';
  competitionData: GridList3Model[] = [];
  filteredCompetitionData: GridList3Model[] = [];
  competitionHomeBannerData: ImageBanner2Model = {
    "content_id": "competition-home-banner",
    "image_src": {
      "sm": "",
      "lg": ""
    }
  }
  competitionSteeringCommitteeBannerData = {
    "headline": "",
    "content_type": "",
    "content_id": "",
    "image_src": {
      "lg": "",
      "sm": ""
    }
  }
  competitionFeaturedVideoData: BannerCarousel2Model[] = [];

  retrievedNameFilter: boolean = false;
  retrievedYearFilter: boolean = false;
  filterField: { [key: string]: string } = { competition_year: "year", competition_name: "name" }; // Manual for now. Ask Bilal
  timeShortform: { hour: string, minute: string } = {
    hour: 'h',
    minute: 'm'
  }

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Competitions"
    }
  ]

  listFiltersData = {
    "select_menu_group": [
      {
        "name": "competition_year",
        "id": "competition_year",
        "options": [
          {
            "label": "All",
            "value": "",
            "is_selected": true
          }
        ]
      },
      {
        "name": "competition_name",
        "id": "competition_name",
        "options": [
          {
            "label": "All",
            "value": "",
            "is_selected": true
          }
        ]
      }
    ]
  };

  competitionInformationStaticData = [
    {
      "content_type": "competition-information",
      "content_id": "content-id-1",
      "image_src": "/assets/images/competitions/img-barista-championships-logo.webp",
      "image_link": "/informations/barista-championship",
      "image_alt": "Barista Championship",
      "headline": "Barista Championship"
    },
    {
      "content_type": "competition-information",
      "content_id": "content-id-2",
      "image_src": "/assets/images/competitions/img-coffee-stories-championships-logo.webp",
      "image_link": "/informations/coffee-stories-championship",
      "image_alt": "Coffee Stories Championship",
      "headline": "Coffee Stories Championship"
    },
    {
      "content_type": "competition-information",
      "content_id": "content-id-3",
      "image_src": "/assets/images/competitions/img-latte-art-championships-logo.webp",
      "image_link": "/informations/latte-art-championship",
      "image_alt": "Latte Art Championship",
      "headline": "Latte Art Championship"
    },
    {
      "content_type": "competition-information",
      "content_id": "content-id-4",
      "image_src": "/assets/images/competitions/img-manual-espresso-competition-logo.webp",
      "image_link": "/informations/manual-espresso-competition",
      "image_alt": "Manual Espresso Competition",
      "headline": "Manual Espresso Competition"
    },
    {
      "content_type": "competition-information",
      "content_id": "content-id-5",
      "image_src": "/assets/images/competitions/img-ultimate-barista-team-challenge-logo.webp",
      "image_link": "/informations/ultimate-barista-team-challenge",
      "image_alt": "Ultimate Barista Team Challenge",
      "headline": "Ultimate Barista Team Challenge"
    },
    {
      "content_type": "competition-information",
      "content_id": "content-id-6",
      "image_src": "/assets/images/competitions/img-manual-brew-competition-logo.webp",
      "image_link": "/informations/manual-brew-competition",
      "image_alt": "Manual Brew Competition",
      "headline": "Manual Brew Competition"
    }
  ]

  constructor(
    private contentService: ContentService,
    private translation: TranslateService,
    private languageService: LanguageService,
    private titleService: Title,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get([
      'GENERAL.MENU.HOME',
      'HOME.SHORTCUT.COMPETITIONS',
      'GENERAL.ALL',
      'GENERAL.TIME.HOUR_SHORTFORM',
      'GENERAL.TIME.MINUTE_SHORTFORM',
      'HOME.EXPLORE.COUNT_VIDEO',
      'COMPETITIONS.INFORMATIONS.BARISTA.TITLE',
      'COMPETITIONS.INFORMATIONS.COFFEE_STORIES.TITLE',
      'COMPETITIONS.INFORMATIONS.LATTE_ART.TITLE',
      'COMPETITIONS.INFORMATIONS.MANUAL_ESPRESSO.TITLE',
      'COMPETITIONS.INFORMATIONS.ULTIMATE_BARISTA_TEAM.TITLE',
      'COMPETITIONS.INFORMATIONS.MANUAL_BREW.TITLE',
      'COMPETITIONS.COMMITTEE.HEADER'
    ]).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.COMPETITIONS'];
        this.listFiltersData.select_menu_group[0].options[0].label = res['GENERAL.ALL'];
        this.listFiltersData.select_menu_group[1].options[0].label = res['GENERAL.ALL'];

        this.timeShortform.hour = res['GENERAL.TIME.HOUR_SHORTFORM'];
        this.timeShortform.minute = res['GENERAL.TIME.MINUTE_SHORTFORM'];

        this.competitionDataUnitTranslate = res['HOME.EXPLORE.COUNT_VIDEO'];

        this.titleService.setTitle(res['HOME.SHORTCUT.COMPETITIONS']);

        this.competitionInformationStaticData[0].headline = res['COMPETITIONS.INFORMATIONS.BARISTA.TITLE'];
        this.competitionInformationStaticData[1].headline = res['COMPETITIONS.INFORMATIONS.COFFEE_STORIES.TITLE'];
        this.competitionInformationStaticData[2].headline = res['COMPETITIONS.INFORMATIONS.LATTE_ART.TITLE'];
        this.competitionInformationStaticData[3].headline = res['COMPETITIONS.INFORMATIONS.MANUAL_ESPRESSO.TITLE'];
        this.competitionInformationStaticData[4].headline = res['COMPETITIONS.INFORMATIONS.ULTIMATE_BARISTA_TEAM.TITLE'];
        this.competitionInformationStaticData[5].headline = res['COMPETITIONS.INFORMATIONS.MANUAL_BREW.TITLE'];

        this.competitionSteeringCommitteeTitle = res['COMPETITIONS.COMMITTEE.HEADER'];
      }
    })

    // Learning Centre Home Banner POST Request
    this.contentService.getPartnerContent(this.competitionHomeBanner).subscribe({
      next: res => {
        this.competitionHomeBannerData.image_src.lg = res[0].bannerImage;
        this.competitionHomeBannerData.image_src.sm = res[0].bannerImageMobile;
      }
    })

    // Competition Information
    this.contentService.getPartnerContent(this.competitionInformationContentType).subscribe({
      next: res => {
        // Competition Name Filter
        const nameObj = this.listFiltersData.select_menu_group.find(obj => obj.name === 'competition_name');
        if (nameObj) {
          res.forEach((item: any) => {
            nameObj.options.push({
              label: item.title,
              value: item.identifier,
              is_selected: false
            })
          });
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.retrievedNameFilter = true;
      },
    });

    // Competition Featured Video POST Request
    this.contentService.getPartnerContent(this.competitionFeaturedVideoType).subscribe({
      next: res => {
        console.log(res);
        res.forEach((item: any) => {
          this.competitionFeaturedVideoData.push({
            content_type: item.contentType,
            content_id: item.identifier,
            image_src: {
              sm: item.bannerImageMobile,
              lg: item.bannerImage
            },
            link: `/competitions/videos/${item.video[0].identifier}`,
            headline: item.title,
            subheadline: item.bottomSubtitle
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Competition Steering Committee Banner POST Request
    this.contentService.getPartnerContent(this.competitionSteeringCommitteeBanner).subscribe({
      next: res => {
        console.log(res);
        const filteredRes = res.filter((item: any) => item.type.toUpperCase() === 'ENTRY BANNER');
        if (filteredRes.length > 0) {
          this.competitionSteeringCommitteeBannerData.headline = filteredRes[0].title ?? '';
          this.competitionSteeringCommitteeBannerData.content_type = filteredRes[0].contentType;
          this.competitionSteeringCommitteeBannerData.content_id = filteredRes[0].identifier;
          this.competitionSteeringCommitteeBannerData.image_src.lg = filteredRes[0].bannerImage;
          this.competitionSteeringCommitteeBannerData.image_src.sm = filteredRes[0].bannerImageMobile;
        }
      }
    })

    // Competition Video
    this.getCompetitionVideo(this.competitionContentType);

    // Competition Year Filter Data
    this.contentService.getPartnerContent(this.competitionYearFilter).subscribe({
      next: res => {
        const nameObj = this.listFiltersData.select_menu_group.find(obj => obj.name === 'competition_year');

        if (nameObj) {
          res.forEach((item: any) => {
            nameObj.options.push({
              label: item.title,
              value: item.identifier,
              is_selected: false
            })
          });
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.retrievedYearFilter = true;
      }
    });
  }

  // Competition Video Function for Resuable Purpose (Draft; To be update to new API for "related" feature)
  getCompetitionVideo(contentType: string, related: string = '',) {
    // Competition Video
    this.contentService.getPartnerContent(contentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          // Get Competition Total Duration
          let duration: number = 0;
          item.competitionVideos.forEach((childItem: any) => {
            duration += Number.parseInt(childItem.videoDuration ?? 0);
          });

          // Competition Data
          this.competitionData.push({
            content_type: item.contentType,
            content_id: item.identifier,
            image_src: item.coverImage,
            link: `/competitions/${item.identifier}`,
            headline: item.title,
            curated_data: {
              label: `${item.competitionVideos.length} ${this.competitionDataUnitTranslate}${this.languageService.getLanguage() === '1' && item.competitionVideos.length > 1 ? "s" : ""}`,
              duration: this.contentService.formatSecToHM(duration, this.timeShortform)
            },
            mark_as_new: this.contentService.calcIsNewContent(item.publishStartDate),
            // is_video: false, // TBC
            name: item.competitionInfo.identifier,
            year: item.year.identifier,
          },)
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }

  getSteeringCommitteeImageSrc(): string {
    return `/assets/images/competitions/meet-steering-committee/meet-competition-steering-committee-${this.languageService.languageMap(this.languageService.getLanguage() ?? '1')}`
  }

  filterTopicData(data: { name: string, value: string }[]) {
    this.filteredCompetitionData = this.contentService.filterContent(data, this.competitionData, this.filterField);
  }
}