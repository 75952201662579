import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventVideoListModel } from '../models';

@Injectable({
  providedIn: 'any'
})

export class EventVideoListService {
  //private dataUrl = '/assets/data/banner-carousel-1.json';

  constructor(private http: HttpClient) { }

  getEventVideoListData(dataUrl: string): Observable<EventVideoListModel[]> {
    return this.http.get<EventVideoListModel[]>(dataUrl);
  }
}
