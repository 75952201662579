<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-3-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-3-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>

  <div class="modal-inner-body">
    <div *ngIf="isLoading" class="loading-wrapper">
      <figure class="spinner">
        <icon-spinner></icon-spinner>
      </figure>
    </div>
    <div *ngIf="!isLoading" class="step-indicator">
      <div class="data-label">{{"ONBOARDING.QUESTION.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2"></span>
        <span class="step step-3 current"></span>
        <span class="step step-4"></span>
      </div>
    </div>

    <div *ngIf="!isLoading" class="inner-body-heading">
      <h2>{{"ONBOARDING.QUESTION.HEADER" | translate}} #{{currentIndex+1}}</h2>
      <p>{{questions![currentIndex]['question']}}</p>
    </div>

    <div *ngIf="!isLoading" class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="moreQuestions">
        <div class="radio-group">
          <div *ngIf="isModalOpen">
            <div *ngFor="let opt of questions![currentIndex]['answers']; let i = index">
              <div class="form-check">
                <input class="form-check-input" type="radio" [name]="questions![currentIndex]['id']" [id]="'opt-'+i"
                  [value]="opt['code']" [checked]="opt" [formControlName]="questions![currentIndex]['id']">
                <label class="form-check-label" [for]="'opt-'+i">
                  {{opt['label']}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div *ngIf="!isLoading" class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()"
        [disabled]="!moreQuestions.controls[questions![currentIndex]['id']].valid">
        {{"ONBOARDING.QUESTION.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.QUESTION.BACK" | translate}}
      </button>
    </div>
  </div>
</div>