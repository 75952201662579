<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <section *ngIf="currentStep === 'LANDING'">
    <div class="modal-inner-wrapper" [@fadeInOut]>
      <div class="modal-inner-body px-4 px-lg-8">

        <div class="inner-body-heading mb-3">
          <h2>{{"MY_PROFILE.CREATE_AVATAR.HEADER"| translate}}</h2>
          <!-- <p>Time for a personal touch! Use our fun avatar generator or upload a cool photo from your phone. 📸✨</p> -->
        </div>

        <div class="inner-body-contents">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-lg-6 mb-4 mb-lg-0">
                <button type="button" class="btn btn-avatar-options btn-avatar-creator p-0"
                  (click)="goToAvatarCreator()">
                  <img [src]="getImageLanguageSrc()">
                </button>
              </div>
              <div class="col-12 col-lg-6">
                <button type="button" class="btn btn-avatar-options py-3" (click)="goToUploadProfilePicture()">
                  <span class="icon-img">
                    <icon-image-landscape></icon-image-landscape>
                  </span>
                  <div class="mt-2 text-center">{{"ONBOARDING.PROFILE_PICTURE.UPLOAD_PROFILE_PICTURE" | translate}}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="currentStep === 'AVATAR_CREATOR'">
    <app-profile-picture-avatar-creator (goToReviewProfileClick)="goToReviewProfile()"
      (goToChangeProfilePictureClick)="goToChangeProfilePicture()"
      (selectedProfileImage)="selectedProfileImageUrl = $event"></app-profile-picture-avatar-creator>
  </section>

  <section *ngIf="currentStep === 'UPLOAD_PICTURE'">
    <app-profile-picture-upload (goToReviewProfileClick)="goToReviewProfile()"
      (goToChangeProfilePictureClick)="goToChangeProfilePicture()"
      (selectedProfileImage)="selectedProfileImageUrl = $event"
      [disableSubmitButton]="isSubmitting"></app-profile-picture-upload>
  </section>

  <section *ngIf="currentStep === 'REVIEW_PROFILE_PICTURE'">
    <app-review-profile-picture (dismissModalClick)="dismissModal()"
      (goToChangeProfilePictureClick)="goToChangeProfilePicture()"
      [input]="profilePictureData"></app-review-profile-picture>
  </section>
</div>