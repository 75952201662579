import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerIcon } from '../../../icons';
import { AuthService } from '../../../services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-secret-code',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SpinnerIcon, TranslateModule],
  templateUrl: './secret-code.component.html',
  styleUrl: './secret-code.component.scss',
  animations: [fadeInOut],
})

export class SecretCodeComponent implements OnInit {
  @Input() input?: { secretCode: string };
  @Output() onCompleted = new EventEmitter<{ secretCode: string }>();

  secretForm!: FormGroup;
  serverSecretCodeError: boolean = false;
  serverSecretCodeErrorMessage!: string;
  isSubmitting: boolean = false;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.secretForm = new FormGroup({
      secretCode: new FormControl<string>(this.input?.secretCode ?? '', [Validators.required])
    });
  }

  onSubmit() {
    this.secretForm.disable();
    this.isSubmitting = true;

    this.authService.validateSecretCode(this.secretForm.controls['secretCode'].value).subscribe({
      next: result => {
        if (!result.data) {
          this.serverSecretCodeError = true;
          this.serverSecretCodeErrorMessage = this.errorService.getServerErrorMapping(result.error_code);
          return;
        };
        this.onCompleted.emit({ secretCode: this.secretForm.controls['secretCode'].value });
      },
      error: err => {
        this.serverSecretCodeError = true;
        this.isSubmitting = false;
        this.secretForm.enable();
      },
      complete: () => {
        this.isSubmitting = false;
        this.secretForm.enable();
      }
    });
  }
}
