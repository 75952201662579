<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="content-heading mb-5">
    <div class="container">
      <h1>{{"MY_BOOKMARK.HEADER" | translate}}</h1>
      <p>{{"MY_BOOKMARK.DESC" | translate}}</p>
    </div>
  </section>

  <section *ngIf="bookmarkData.length > 0" class="content-body">
    <div class="container">
      <app-grid-list-3 [data]="bookmarkData"></app-grid-list-3>
    </div>
  </section>


</div>