<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="home-hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="workshopDetailsBannerData"></app-image-banner-1>
    </div>
  </section>

  <section class="content-heading mb-5 text-center">
    <div class=" container mw-lg">
      <h1 class="headline mb-3">{{currentPageData.title}}</h1>
      <div class="descriptions ">
        {{currentPageData.descriptions}}
      </div>
    </div>
  </section>

  <section class="content-body mb-5">
    <div class="container mw-lg">
      <!-- Comment out for now -->
      <!-- <app-html-contents [dataUrl]="HTMLContentsDataUrl"></app-html-contents> -->
      <div [innerHTML]="workshopContent"></div>
    </div>
  </section>

  <section *ngIf="isEnrolled !== undefined" class="enrollment">
    <div class="container mw-lg">
      <h2 class="mb-4">{{"WORKSHOPS.ENROLL_NOW" | translate}}</h2>

      <div class="workshop-enrollment d-flex flex-wrap">
        <figure class="mt-1">
          <span class="icon-img">
            <icon-workshop></icon-workshop>
          </span>
        </figure>
        <div *ngIf="!isEnrolled" class="content">
          <h4 class="fw-normal text-dark">{{currentPageData.title}}
            <br>→<span [innerHTML]="getFormatDate(currentPageData.start_date)"></span>
          </h4>
          <div class="row">
            <div class="col-12 col-md-9 mb-3 mb-md-0">
              <!-- <div class="form-group">
                <label for="partner-email" class="form-label">→ Email Address</label>
                <input type="email" class="form-control" id="partner-email" placeholder="Enter your email address">
                <div class="invalid-message">
                  System error message
                </div>
              </div> -->
            </div>
            <div class="col-12 col-md-3 call-to-action">
              <button type="button" class="btn btn-primary btn-sm w-100 w-md-auto" (click)="toggleEnrollment()">
                {{"WORKSHOPS.ENROLL_NOW" | translate}}
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isEnrolled" class="content">
          <h4 class="fw-normal text-dark">{{"WORKSHOPS.UNENROLL_DESC" | translate}}</h4>
          <h4 class="fw-normal text-dark">{{currentPageData.title}}<br>→ <span
              [innerHTML]="getFormatDate(currentPageData.start_date)"></span></h4>
          <div class="call-to-action text-end mt-2">
            <button type="button" class="btn btn-primary btn-sm mt-0 ms-auto"
              (click)="toggleEnrollment()">{{"WORKSHOPS.UNENROLL_BUTTON" | translate}}</button>
          </div>
        </div>
      </div>
    </div>


  </section>
</div>