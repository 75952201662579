import { Component, Input, OnInit } from '@angular/core';
import { LikeIcon } from "../../icons/icon-like";
import { AccountService } from '../../services/account.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-like',
  standalone: true,
  templateUrl: './like.component.html',
  styleUrl: './like.component.scss',
  imports: [LikeIcon]
})
export class LikeComponent implements OnInit {
  @Input({ required: true }) data!: string;
  isActive = false;
  likedCount!: number;

  constructor(
    private accountService: AccountService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.getLikedCount()
  }

  toggleLike(event: Event) {
    event?.preventDefault();

    if (this.isActive) {
      this.accountService.removeLikes(this.data).subscribe({
        next: res => {
          this.isActive = false;
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          this.getLikedCount();
        }
      });
    } else {
      this.accountService.createLikes(this.data).subscribe({
        next: res => {
          this.isActive = true;
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          this.getLikedCount();
        }
      });
    }
  }

  getLikedCount(): void {
    this.accountService.getTotalLikes(this.data).subscribe({
      next: res => {
        if (!this.configService.isPreview) { // Admin Preview start with not liked.
          this.isActive = res.liked;
        }
        this.likedCount = res.total_likes;
      }
    })
  }
}
