import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { HtmlContentsComponent } from "../../components/html-contents/html-contents.component";
import { BookmarkComponent } from "../../components/bookmark/bookmark.component";
import { LikeComponent } from "../../components/like/like.component";
import { FolderIcon } from "../../icons";
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { HTMLContentsModel, ImageBanner1Model } from '../../models';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-learning-article',
  standalone: true,
  templateUrl: './learning-article.component.html',
  styleUrl: './learning-article.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, ImageBanner1Component, HtmlContentsComponent, BookmarkComponent, LikeComponent, FolderIcon, TranslateModule, RouterLink],
})
export class LearningArticleComponent implements OnInit {
  contentHTML: string = '';
  articleIdentifier!: string;
  parentIdentifier!: string;
  parentContentId: string = '';
  articleBannerData: ImageBanner1Model = {
    content_id: '',
    image_src: {
      sm: '',
      lg: ''
    }
  };
  articleContentData: HTMLContentsModel = {
    content_id: '',
    contents: '',
    pdfDocument: []
  };
  articleDocuments: { link: string, display_name: string }[] = [];
  articleContent!: SafeHtml;
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/learn',
      label: 'Learning Centre'
    }
  ]

  learningTopicContentType = 'SbuxLearningTopic';
  currentPageData = { title: '' };
  learningLessonContentType = 'SbuxLearningLesson';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private sanitizer: DomSanitizer,
    private translation: TranslateService,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) {
    this.translation.get(['HOME.EXPLORE.LEARNING_TOPIC']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['HOME.EXPLORE.LEARNING_TOPIC'];
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.articleIdentifier = params['contentId'];
      this.parentIdentifier = params['pId'];

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.articleIdentifier, this.learningLessonContentType, this.router.url);
      }

      const currentUrl = this.router.url;
      const segmentUrl = currentUrl.split('/');

      // remove last 2 path in array (/article/:contentId)
      segmentUrl.pop();
      segmentUrl.pop();

      // Construct return url
      if (currentUrl.toUpperCase().split('/').includes('MY-BOOKMARK')) {
        segmentUrl.pop()
        this.breadcrumbsData[0].label = 'My Bookmark';
        this.breadcrumbsData[0].link = '/my-bookmark';
      } else {
        const updatedUrl = segmentUrl.join('/');
        this.breadcrumbsData[0].link = updatedUrl;
      }


      if (this.parentIdentifier) {
        this.breadcrumbsData[0].label = 'Learning Topic';

        this.contentService.getPartnerContent(this.learningTopicContentType, this.parentIdentifier).subscribe({
          next: res => {
            // Breadcrumb label
            this.breadcrumbsData[0].label = res[0].title;
          },
          error: err => {
            console.log(err);
          }
        });
      }
    });

    this.contentService.getPartnerContent(this.learningLessonContentType, this.articleIdentifier).subscribe({
      next: res => {
        if (res.length === 0) {
          this.router.navigate(['/404']);

        }

        // Page Title
        this.titleService.setTitle(res[0].title);

        // Category Array
        let categoryName: string[] = [];
        res[0].category.forEach((item: any) => {
          categoryName.push(item.title);
        });

        // Hero Banner
        this.articleBannerData.content_id = res[0].identifier;
        this.articleBannerData.image_src.sm = res[0].heroBannerMobile;
        this.articleBannerData.image_src.lg = res[0].heroBanner;

        // Content
        this.articleContentData.content_id = res[0].identifier;
        // this.articleContentData.contents = res[0].contentEditor;
        this.articleContentData.headline = res[0].title;
        this.articleContentData.category = categoryName ?? [];
        // this.articleContentData.pdfDocument = res[0].pdfDocuments ? res[0].pdfDocuments.split(',') : [];

        this.articleContent = this.sanitizeStyle(res[0].contentEditor ?? '')

        // Document Data
        if (res[0].pdfDocuments) {
          const pdfArr = JSON.parse(res[0].pdfDocuments);
          pdfArr.forEach((item: any) => {
            this.articleDocuments.push({
              link: item.src, // Manual for now. TBC
              display_name: item.name
            })
          });
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  sanitizeStyle(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }
}
