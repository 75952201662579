import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoListModel } from '../../models';
import { PlayIcon } from "../../icons/icon-play";
import { BookmarkComponent } from "../bookmark/bookmark.component";
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-video-list',
  standalone: true,
  templateUrl: './video-list.component.html',
  styleUrl: './video-list.component.scss',
  imports: [CommonModule, PlayIcon, BookmarkComponent, TranslateModule, RouterLink]
})
export class VideoListComponent implements OnInit {
  @Input({ required: true }) data!: VideoListModel[];
  currentUrl!: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }
}
