import { Component } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-500',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './500.component.html',
  styleUrl: './500.component.scss',
  animations: [fadeInOut],
})
export class Error500Component {
  reload() {
    window.location.reload();
  }
}
