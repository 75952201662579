import { AfterViewInit, Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { CircleStarIcon, CoffeeBeanBagIcon, BapStarFilledIcon, BapStarIcon, EditIcon } from "../../icons";
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeProfilePictureModalComponent } from '../../components/change-profile-picture-modal/change-profile-picture-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { AccountService } from '../../services/account.service';
import { ProfileModel } from '../../models/profile.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StarUpgradedNoticeModalComponent } from '../../components/star-upgraded-notice-modal/star-upgraded-notice-modal.component';
import { TitleEarnedNoticeModalComponent } from '../../components/title-earned-notice-modal/title-earned-notice-modal.component';
import { VerifyUpgradeNoticeComponent } from '../../components/verify-upgrade-notice/verify-upgrade-notice.component';
import { StarPathComponent } from "../../components/star-path/star-path.component";
import { TitlePathComponent } from "../../components/title-path/title-path.component";
import { BapActivityHistoryComponent } from "../../components/bap-activity-history/bap-activity-history.component";
import { ClaimTitlePathModalComponent } from '../../components/claim-title-path-modal/claim-title-path-modal.component';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, CircleStarIcon, CarouselModule, CoffeeBeanBagIcon, TranslateModule, VerifyUpgradeNoticeComponent, StarPathComponent, TitlePathComponent, BapActivityHistoryComponent, ClaimTitlePathModalComponent, BapStarFilledIcon, BapStarIcon, EditIcon]
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  data!: ProfileModel;
  email!: string;
  encodedEmail!: string;
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "My Profile"
    }
  ]
  profilePictureUrl: string = '';

  // profileCompletenessLevel = '25';
  // activityData = [
  //   {
  //     "action_name": "Action Item 1",
  //     "description": "Tell us more about your coffee preference",
  //     "button": {
  //       "label": "Start now",
  //       "link": "/home",
  //     }
  //   },
  //   {
  //     "action_name": "Action Item 2",
  //     "description": "Tell us more about your coffee preference",
  //     "button": {
  //       "label": "Start now",
  //       "link": "/home",
  //     }
  //   }
  // ];

  // Owl carousel config
  activityCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    nav: true,
    margin: 25,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 5000,
    responsive: {
      0: {
        items: 1,
        loop: false,
        rewind: true
      }
    },
  }

  greenToScaData = {
    currentTier: 'GREEN_APRON',
    upgradeType: 'SCA'
  }

  verifyScaData = {
    currentTier: 'SCA_CERTIFIED_COFFEE_MASTER',
    remainingDays: 30,
    upgradeType: 'SCA'
  }

  verifyStarPathData = {
    currentTier: 'STAR_3',
    upgradeType: 'STAR_PATH'
  }

  starPathProgressData = {
    currentPoints: 30000,
    progress: 80
  };

  starPathConfig = [
    {
      points: 10000,
      label: '1 Star'
    },
    {
      points: 20000,
      label: '2 Stars'
    },
    {
      points: 40000,
      label: '3 Stars'
    }
  ];

  titlePathProgressData = [
    {
      title: 'Origin & Ethical Sourcing',
      points: 12000,
      totalPoints: 12000,
      progress: 100,
      theme: 'YELLOW',
      iconURL: 'assets/images/bap/ico-bap-title-oes.png',
      isClaimable: true
    },
    {
      title: 'Brew & Craft',
      points: 4000,
      totalPoints: 12000,
      progress: 33,
      theme: 'GREEN',
      iconURL: 'assets/images/bap/ico-bap-title-bc.png',
      isClaimable: false
    },
    {
      title: 'Roast & Blend',
      points: 12000,
      totalPoints: 12000,
      progress: 100,
      theme: 'RED',
      iconURL: 'assets/images/bap/ico-bap-title-rb.png',
      isClaimable: true
    },
    {
      title: 'Coffee & Leadership',
      points: 5000,
      totalPoints: 12000,
      progress: 42,
      theme: 'BLUE',
      iconURL: 'assets/images/bap/ico-bap-title-cl.png',
      isClaimable: false
    }
  ];

  activityHistory = [
    {
      date: 'Today',
      activities: [
        {
          title: 'Origin & Ethical Sourcing',
          theme: 'YELLOW',
          status: 'FINISHED',
          code: 'OES1',
          points: '150PTS',
          name: 'Advanced Coffee Brewing Mastery & Excellence'
        },
        {
          title: 'Brew & Craft',
          theme: 'GREEN',
          status: 'FINISHED',
          code: 'BC1',
          points: '150PTS',
          name: 'Brewing Excellence'
        },
        {
          title: 'Roast & Blend',
          theme: 'RED',
          status: 'IN_PROGRESS',
          code: 'RB1',
          points: '150PTS',
          name: 'The Roastery Adventure'
        },
        {
          title: 'Coffee & Leadership',
          theme: 'BLUE',
          status: 'FINISHED',
          code: 'CL1',
          points: '150PTS',
          name: 'Elevate the Extraordinary'
        }
      ]
    },
    {
      date: '7 days ago',
      activities: [
        {
          title: 'Origin & Ethical Sourcing',
          theme: 'YELLOW',
          status: 'FINISHED',
          code: 'OES1',
          points: '150PTS',
          name: 'Coffee Terrior'
        },
        {
          title: 'Origin & Ethical Sourcing',
          theme: 'YELLOW',
          status: 'FINISHED',
          code: 'OES1',
          points: '150PTS',
          name: 'Coffee Terrior'
        }
      ]
    }
  ];

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    private accountService: AccountService,
    private translation: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private titleService: Title,
    public analyticsService: AnalyticsService
  ) { }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['t'] && params['t'].toUpperCase() === 'AVATARCREATION') {
        this.openChangeProfilePictureModal();
      }
    })
  }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'MY_PROFILE.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['MY_PROFILE.HEADER'];

        this.titleService.setTitle(res['MY_PROFILE.HEADER']);
      }
    })

    // Profile GET Request
    this.accountService.getMyProfile().subscribe({
      next: res => {
        this.data = res;
        this.encodedEmail = btoa(res.email);
        this.profilePictureUrl = res.photo;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  async downloadProfilePicture() {
    this.analyticsService.sendClickEventAnalytic('Profile', undefined, undefined, undefined, 'DOWNLOAD_PROFILE_PICTURE');

    if (this.profilePictureUrl) {
      await this.contentService.imageUrlToBase64(this.profilePictureUrl)
        .then(res => {
          const imageName = 'profile-picture';

          // Create an anchor element
          const link = document.createElement('a');
          link.href = res;
          link.download = imageName;
          link.click();
        });
    } else {
      this.toastr.info('No profile picture to be download!', '')
    }
  }

  formatDate(timestamp: string): string {
    const date = new Date(timestamp);
    return this.contentService.formatDate2(date.getTime());
  }

  // Check Object is empty
  isObjectEmpty(objectName: ProfileModel): boolean {
    if (objectName !== undefined || null) {
      return Object.keys(objectName).length !== 0
    }
    return false;
  }

  openChangeProfilePictureModal() {
    const modalRef = this.modalService.open(ChangeProfilePictureModalComponent, {
      centered: true,
      windowClass: 'change-profile-picuture-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  openStarUpgradedNoticeModal() {
    const modalRef = this.modalService.open(StarUpgradedNoticeModalComponent, {
      centered: true,
      windowClass: 'star-upgraded-notice-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  openTitleEarnedNoticeModal() {
    const modalRef = this.modalService.open(TitleEarnedNoticeModalComponent, {
      centered: true,
      windowClass: 'title-earned-notice-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  openClaimTitlePathModal() {
    const modalRef = this.modalService.open(ClaimTitlePathModalComponent, {
      centered: true,
      windowClass: 'claim-title-path-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  // getTitleCase(marketName: string) {
  //   return this.contentService.formatTitleCase(marketName);
  // }
}
