<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="body-container d-flex flex-wrap">
    <div class="profile">
      <figure class="person-avatar" [title]="personName">
        <img [src]="personAvatarImageSrc" alt="{{personName}}-img" />
      </figure>
      <div class="person-info">
        <h5 class="person-name text-dark">{{personName}}</h5>
        <div *ngIf="personTitle" class="person-title">{{personTitle}}</div>
        <div *ngIf="personMarket" class="person-title">{{personMarket}}</div>
      </div>
    </div>

    <div [innerHTML]="personTextBio" class="bio custom-scrollbar "></div>
  </div>
</div>