import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [BreadcrumbsComponent, TranslateModule],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
  animations: [fadeInOut]
})
export class TermsComponent implements OnInit {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "About us"
    }
  ]

  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'GENERAL.FOOTER.TERMS_OF_USE']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['GENERAL.FOOTER.TERMS_OF_USE'];

        this.titleService.setTitle(res['GENERAL.FOOTER.TERMS_OF_USE']);
      }
    })
  }
}
