import { Component, Renderer2, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { LanguageService } from './services/language.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environment/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AccountService } from './services/account.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule, RouterOutlet],
})

export class AppComponent implements OnInit {
  isLoaded: boolean = false;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private translate: TranslateService,
    private authService: AuthService,
    private languageService: LanguageService,
    private toastr: ToastrService,
    private gaService: GoogleAnalyticsService,
    private accountService: AccountService
  ) { }

  async ngOnInit(): Promise<void> {
    if (environment.clientAuthEnabled) {
      this.authService.clientAuth(this.authService.userIsLoggedIn())
        .then(res => this.isLoaded = res)
        .catch((err) => {
          this.toastr.warning('Unable to reach the server, please refresh your browser.');
        });
    } else {
      this.isLoaded = true;
    }

    // Load locale language (default to EN)
    const lanugageId = this.languageService.getLanguage();
    if (lanugageId === null) {
      localStorage.setItem('languageId', '1');
    } else {
      this.translate.use(this.languageService.languageMapping[Number(lanugageId)]);
    }

    // Update index.html lang attribute
    document.documentElement.lang = this.languageService.languageHTMLMap(lanugageId ?? '1');

    // Set initial user properties
    if (this.authService.userIsLoggedIn()) {
      this.accountService.getMyProfile().pipe(
        tap(user => {
          if (user) {
            this.gaService.gtag('set', 'user_properties', {
              'user_id': user.id,
              'tier': user.tier,
              'page_language': user.language_name
            });
          }
        })
      ).subscribe({
        next: () => console.log('Profile fetched, user state updated, and GA properties set'),
        error: (error) => console.error('Error in profile chain:', error)
      });
    } else {
      var language = this.languageService.languageName[this.languageService.getLanguage()];
      this.gaService.gtag('set', 'user_properties', {
        'user_id': null,
        'tier': null,
        'page_language': language
      })
    }

    window.addEventListener('storage', (event) => {
      if (event.key === 'languageId') {
        this.languageService.switchLanguage(event.newValue ?? '1');
      }
    })

    // Listen to avatar module onload (Onboarding & Change Profile Picture)
    window.addEventListener('message', (event: MessageEvent) => {
      // Pass language preference to avatar module
      if (event.data.type === 'isReady') {
        const languageId = this.languageService.getLanguage() ?? '1';
        const languageCode = this.languageService.languageMapping[Number(languageId)];
        const frame = document.querySelector("iframe");
        frame!.contentWindow!.postMessage({ type: 'languageCode', data: languageCode }, "*"); // Send languageCode to Avatar Module
      }
    })

    this.renderer.addClass(this.el.nativeElement, 'app-root');
  }

  title = 'Starbucks Coffee Community';
}
