<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <div class="partner-profile-card">
      <div class="d-flex flex-wrap h-100">
        <figure class="flex-auto profile-picture">
          <img *ngIf="input.photo" [src]="input.photo" alt="">
          <img *ngIf="!input.photo" src="/assets/images/avatar/img-avatar-placeholder.png">
        </figure>
        <div class="flex-1 d-flex flex-column profile-info h-100 ps-4">
          <h2 class="nickname">{{input.nick_name}}</h2>
          <h5 class="name">{{input.first_name }} {{input.last_name}}</h5>
          <div class="market">{{selectedMarket}}</div>
          <div class="email">{{input.email}}</div>
          <div class="join-date mt-auto">
            {{"MY_PROFILE.JOINED_ON_1" | translate}}{{currentDate}}{{"MY_PROFILE.JOINED_ON_2" | translate}}
          </div>
        </div>
        <div class="logo">
          <img src="/assets/images/global/img-logo-sbx-white.svg" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-inner-body">

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.REVIEW.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.REVIEW.DESC" | translate}}</p>

      <!-- Submit sign up form server error -->
      <div *ngIf="serverError" class="invalid-message">
        {{serverErrorMessage | translate}}
      </div>
    </div>

    <div class="captcha-container mb-3">
      <div id="recaptcha"></div>

      <!-- Captcha error -->
      <div *ngIf="captchaError" class="invalid-message">
        Error {{captchaErrorCode}}: {{captchaErrorMessage}}
      </div>
    </div>

    <div class="inner-body-contents">
    </div>
    <div class="inner-body-footer">
      <div class="mb-3">
        <small>{{"ONBOARDING.REVIEW.POLICY_DESC_1" | translate}}
          <a href="/privacy" target="_blank" class="underline">
            {{"ONBOARDING.REVIEW.POLICY" | translate}}
          </a>{{"ONBOARDING.REVIEW.POLICY_DESC_2" | translate}}
        </small>
      </div>
      <button type="button" *ngIf="!isSubmitting" [disabled]="!input.captcha_token"
        class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()">
        {{"ONBOARDING.REVIEW.REGISTER" | translate}}
      </button>
      <button type="button" *ngIf="!isSubmitting" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.REVIEW.BACK" | translate}}
      </button>
      <button type="button" *ngIf="isSubmitting" class="btn btn-spinner w-100 w-lg-auto" disabled>
        <span class="icon-img">
          <icon-spinner></icon-spinner>
        </span>
      </button>
    </div>
  </div>
</div>