import { Component, OnInit } from '@angular/core';
import { SpinnerIcon } from '../../icons';
import { ResetPasswordUnsuccessfulComponent } from '../reset-password-unsuccessful/reset-password-unsuccessful.component';
import { SetupNewPasswordComponent } from '../setup-new-password/setup-new-password.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { fadeInOut } from '../../animation';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [SetupNewPasswordComponent, ResetPasswordUnsuccessfulComponent, SpinnerIcon, CommonModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  animations: [fadeInOut]
})
export class ResetPasswordComponent implements OnInit {
  tokenParam!: string;
  isValidating: boolean = true;
  email!: string;
  serverError: boolean = false;
  serverErrorMessage!: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!params['t']) {
        this.serverErrorMessage = this.errorService.getServerErrorMapping('1009')
        this.serverError = true;
        this.isValidating = false;
        return;
      }
      this.tokenParam = params['t'];

      // Validate reset password email link
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const body = {
        'token': this.tokenParam
      };

      this.authService.verifyResetPassword(body, reqHeader).subscribe({
        next: res => {
          if (!res.data) {
            this.serverErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
            this.serverError = true;
            return;
          }
          this.email = res.data;
        },
        error: err => {
          console.log(err)
        },
        complete: () => {
          this.isValidating = false;
        }
      })
    })
  }
}
