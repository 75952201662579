<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <section *ngIf="currentStep === 'SIGN_IN'">
    <app-sign-in (onSignUp)="onSignUp()" (onCompleted)="onSignIn()"
      (onForgetPassword)="goToForgotPassword()"></app-sign-in>
  </section>

  <section *ngIf="currentStep === 'FORGOT_PASSWORD'">
    <app-forgot-password (onCompleted)="onResetPassword($event)"></app-forgot-password>
  </section>

  <section *ngIf=" currentStep==='RESET_PASSWORD_REQUESTED'">
    <app-reset-password-requested [input]=" {resetPassEmail: resetPasswordEmail}"
      (onCompleted)="dismissModal()"></app-reset-password-requested>
  </section>
</div>