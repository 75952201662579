<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 [data]="imageBanner2Data"></app-image-banner-2>
    </div>
  </section>

  <section *ngIf="partnersData.length > 0" class="mb-5 text-center">
    <div class="container">
      <h6 class="text-dark text-uppercase mb-3">{{currentPageData.title}}</h6>
      <h1 class="headline mb-3">{{"COMPETITIONS.MEET_BARISTA_JUDGES.COMPETITOR.HEADER" | translate}}</h1>
      <div class="descriptions ">
        <!-- {{"COMPETITIONS.MEET_BARISTA_JUDGES.COMPETITOR.DESC_1" | translate}}{{partnersData.length}}{{"COMPETITIONS.MEET_BARISTA_JUDGES.COMPETITOR.DESC_2" | translate}}{{currentPageData.title}}{{"COMPETITIONS.MEET_BARISTA_JUDGES.COMPETITOR.DESC_3" | translate}} -->
        {{currentPageData.descriptions}}
      </div>
      <div class="mt-5">
        <app-person-grid-list [data]="partnersData"></app-person-grid-list>
      </div>
    </div>
  </section>

  <section *ngIf="judgesData.length > 0" class="mb-5 text-center">
    <div class="container">
      <h1 class="headline mb-3">{{"COMPETITIONS.MEET_BARISTA_JUDGES.JUDGE.HEADER" | translate}}</h1>
      <!-- <div class="descriptions">
        A group of professional judges and committee with fruitful knowledge in the coffee industry were there evaluated
        competitor's skills.
        Learn more about them now!
      </div> -->
      <div class="mt-5">
        <app-person-grid-list [data]="judgesData"></app-person-grid-list>
      </div>
    </div>
  </section>
</div>