import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../animation';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activation-unsuccessful',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './activation-unsuccessful.component.html',
  styleUrl: './activation-unsuccessful.component.scss',
  animations: [fadeInOut]
})
export class ActivationUnsuccessfulComponent {
  @Input() errMessage!: string;

  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) {
    this.translation.get(['ONBOARDING.ACTIVATE_FAIL.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['ONBOARDING.ACTIVATE_FAIL.HEADER']);
      }
    })
  }
}
