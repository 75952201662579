<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <!-- <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="imageBanner1Data"></app-image-banner-1>
    </div>
  </section> -->

  <div class="container mw-lg">
    <h1 class="mb-4 text-center">{{"GENERAL.FOOTER.ABOUT_US" | translate}}</h1>
    <div [innerHTML]="'GENERAL.ABOUT_US' | translate"></div>
    <!-- KEEP FOR NOW -->
    <!-- <h1 class="mb-4 text-center">About us</h1>

    <h2 class="fw-light text-center mb-5">"Welcome to our vibrant Starbucks coffee community – where partners unite to
      elevate their
      passion, skills, and connection to coffee. Join us on a journey of discovery and collaboration as we celebrate the
      essence of coffee and shape its future together."</h2>

    <p>This first-ever Starbucks coffee community website was created by the CAP Coffee & Partner Engagement Team and
      the CAP Regional Coffee Leadership Team. They collaborated to establish a network of partners across the region
      with the primary goal of fostering an interactive experience. This experience aims to enhance the knowledge,
      skills, and pride of all partners in the region, while also fostering connections within the Starbucks Coffee
      Community.
    </p>

    <p>In order to achieve these objectives, the website will offer a wide range of coffee learning content, engaging
      special events, informative workshops, exciting competitions, and access to the prestigious Black Apron Program.
    </p>

    <div class="row py-4">
      <div class="col-12 col-md-6">
        <figure class="rounded-4 overflow-hidden">
          <img src="/assets/images/about-us/img-about-us-1.webp">
        </figure>
      </div>

      <div class="col-12 col-md-6">
        <figure class="rounded-4 overflow-hidden">
          <img src="/assets/images/about-us/img-about-us-2.webp">
        </figure>
      </div>
    </div>

    <h3 class="fw-light mb-3">Partnering for a Global Coffee Renaissance</h3>
    <p>This platform serves as a jubilant celebration of coffee and the invaluable contributions made by partners like
      you, who have helped make Starbucks the extraordinary brand it is today. We express our heartfelt gratitude for
      your unwavering passion and dedication to coffee, which has paved the way for a steadfast commitment to coffee
      quality, exceptional customer service, and continuous learning. By harnessing the power of collaboration and
      collective effort, we strive to leverage our extensive reach and positively impact the future of coffee on a
      global scale.</p>

    <div class="py-4">
      <figure class="rounded-4 overflow-hidden">
        <img src="/assets/images/about-us/img-about-us-3.webp">
      </figure>
    </div>

    <h3 class="fw-light mb-3">Coffee Adventure Awaits: Share, Learn, and Sustain Together!</h3>
    <p>Embark on your virtual coffee journey and extend the experience to our physical stores by sharing your newfound
      knowledge with both partners and customers alike. Together, let us immerse ourselves in the fascinating world of
      coffee and work towards a sustainable future that benefits all coffee lovers.</p> -->
  </div>
</div>