import { Component, OnInit, OnDestroy, Renderer2, Inject, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { AnalyticsService } from '../../services/analytics.service';
import { GridList3Model, ImageBanner2Model } from '../../models';
import { ConfigService } from '../../services/config.service';
import { UnlockBapContentModalComponent } from '../../components/unlock-bap-content-modal/unlock-bap-content-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BapFlashCardModalComponent } from '../../components/bap-flash-card-modal/bap-flash-card-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ListFiltersComponent } from "../../components/list-filters/list-filters.component";
import { ListFiltersModel } from "../../models/list-filters.model";
import { BapNewFlashCardModalComponent } from '../../components/bap-new-flash-card-modal/bap-new-flash-card-modal.component';
import { BapQuestionnaireModalComponent } from '../../components/bap-questionnaire-modal/bap-questionnaire-modal.component';
import { BapQuizModalComponent } from '../../components/bap-quiz-modal/bap-quiz-modal.component';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';

@Component({
  selector: 'app-bap-topic',
  standalone: true,
  templateUrl: './bap-topic.component.html',
  styleUrl: './bap-topic.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner2Component, GridList3Component, CommonModule, TranslateModule, ListFiltersComponent, ClickableEnlargeImageDirective]
})

export class BapTopicComponent implements OnInit, OnDestroy, AfterViewInit {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "/bap",
      "label": "Black Apron Program"
    },
    {
      "link": "",
      "label": "BAP Topic"
    }
  ]

  imageBanner2Data !: ImageBanner2Model;
  gridList3Data: GridList3Model[] = [];
  bapTopic1 = [
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-1",
      "type": "FLASHCARD",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027600113-OES%201%20Coffee%20Journal%202.jpg",
      "link": "/bap-article",
      "headline": "Flash Card - Completed - Yellow",
      "descriptions": "Get Creative! Create your own Black Apron Coffee Journal to kickstart your next journey in coffee. Once completed, take a picture of it and earn your first points!",
      "activity_details": {
        "code": "OES 1",
        "points": "140PTS",
        "status": "COMPLETED",
        "duration": "46m",
        "is_mandatory": true,
        "theme": "YELLOW"
      },
      "mark_as_new": true
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-2",
      "type": "FLASHCARD",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027576923-OES%202%20Hacienda%20-%20Terroir.jpg",
      "link": "/bap-article",
      "headline": "Flash Card - In Progress - Blue",
      "descriptions": "Coffee Terroir. Terroir is a fascinating concept in the world of coffee, as it encompasses the environmental factors that shape and influence the flavor profile of our beloved beverage.",
      "activity_details": {
        "code": "OES 2",
        "points": "110PTS",
        "status": "IN_PROGRESS",
        "duration": "46m",
        "theme": "BLUE"
      }
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-3",
      "type": "FLASHCARD",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027574858-OES%203%20Cafe%20Practices.jpeg",
      "link": "/bap-article",
      "headline": "Flash card - No status - Green",
      "descriptions": "C.A.F.E. Practices! C.A.F.E. Practices is made up of four components, all vital aspects of ensuring sustainable coffee: Quality, Economic Transparency, Social Responsibility and Environmental Leadership.",
      "activity_details": {
        "code": "OES 3",
        "points": "110PTS",
        "status": "",
        "duration": "46m",
        "theme": "GREEN"
      }
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-4",
      "type": "FLASHCARD",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027574858-OES%203%20Cafe%20Practices.jpeg",
      "link": "/bap-article",
      "headline": "Flash card - No status - Pink",
      "descriptions": "C.A.F.E. Practices! C.A.F.E. Practices is made up of four components, all vital aspects of ensuring sustainable coffee: Quality, Economic Transparency, Social Responsibility and Environmental Leadership.",
      "activity_details": {
        "code": "OES 3",
        "points": "110PTS",
        "status": "",
        "duration": "46m",
        "theme": "PINK"
      }
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-5",
      "type": "FLASHCARD",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027574858-OES%203%20Cafe%20Practices.jpeg",
      "link": "/bap-article",
      "headline": "Flash card - No status - Default",
      "descriptions": "C.A.F.E. Practices! C.A.F.E. Practices is made up of four components, all vital aspects of ensuring sustainable coffee: Quality, Economic Transparency, Social Responsibility and Environmental Leadership.",
      "activity_details": {
        "code": "OES 3",
        "points": "110PTS",
        "status": "",
        "duration": "46m",
        "theme": ""
      }
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-6",
      "type": "QUESTIONNAIRE",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Questionnaire Demo",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "",
        "duration": "46m",
        "theme": ""
      }
    },
    {
      "content_type": "static-bap-topic-item",
      "content_id": "content-id-7",
      "type": "QUIZ",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Quiz demo",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "",
        "duration": "46m"
      }
    }
  ];

  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicIdentifier!: string;
  bapTopicTitle!: string;
  bapTopicDescription!: string;

  activityTypeFilterData: ListFiltersModel = {
    select_menu_group: [
      {
        id: 'activity_filter',
        name: 'Activity',
        options: [
          {
            label: 'All Activities',
            value: 'all',
            is_selected: true
          },
          {
            label: 'Show Completed',
            value: 'completed',
            is_selected: false
          },
          {
            label: 'Hide Completed',
            value: 'hide_completed',
            is_selected: false
          }
        ]
      }
    ]
  };

  sortingFilterData: ListFiltersModel = {
    select_menu_group: [
      {
        id: 'sort_by',
        name: 'Sort By',
        options: [
          {
            label: 'Date (Newest - Oldest)',
            value: 'date_desc',
            is_selected: true
          },
          {
            label: 'Time Taken (Longest - Shortest)',
            value: 'time_desc',
            is_selected: false
          },
          {
            label: 'Points Earned (Highest to Lowest)',
            value: 'points_desc',
            is_selected: false
          }
        ]
      }
    ]
  };

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translation: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private title: Title,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Translation
    this.translation.get(['GENERAL.MENU.HOME', 'BAP.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['BAP.HEADER'];
      }
    });

    this.route.params.subscribe(params => {
      this.bapTopicIdentifier = params['contentId'];

      // Get BAP Topic Data
      this.contentService.getPartnerContent(this.bapTopicContentType, this.bapTopicIdentifier).subscribe({
        next: res => {
          if (res.length === 0) {
            this.router.navigate(['/404']);
          }

          const topic = res[0];

          // Set Breadcrumb Data
          this.breadcrumbsData[2].label = topic.title;

          // Filter Active Topic Item
          const currentDate = new Date();
          topic.topicItem = topic.topicItem.filter((item: any) => {
            const publishStartDate = new Date(item.publishStartDate);
            const publishEndDate = new Date(item.publishEndDate);
            return (!publishStartDate || publishStartDate <= currentDate) &&
              (!publishEndDate || currentDate <= publishEndDate);
          });

          // Set BAP Topic Item Data
          this.bapTopicTitle = topic.title;
          this.bapTopicDescription = topic.descriptions;

          // Set BAP Banner Data
          this.imageBanner2Data = {
            content_id: `${topic.title}-bap-topic-banner`,
            image_src: {
              lg: topic.bannerImage,
              sm: topic.bannerImageMobile,
            }
          }

          // Set title
          this.title.setTitle(topic.title);

          // Set BAP Topic Item Data
          topic.topicItem.forEach(async (topicItem: any) => {
            const bapType = topicItem.type || topicItem.type1;
            const tier = topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase();
            const canView = this.configService.isPreview ? true : await this.contentService.canViewContent(tier);

            this.gridList3Data.push({
              content_type: topicItem.contentType,
              content_id: `${topicItem.title}-content-id`,
              identifier: topicItem.identifier,
              type: bapType.toUpperCase() === "FLASH CARD" ? "FLASHCARD" : "REGULAR",
              image_src: topicItem.coverImage,
              link: `${this.router.url}/${this.bapTopicItemNavigationUrl(bapType, topicItem.identifier)}`,
              headline: topicItem.title,
              descriptions: topicItem.descriptions,
              level_requirement: topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase(),
              disabled: !canView
            })
          });
        },
        error: err => {
          console.log(err);
        }
      });

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.bapTopicIdentifier, this.bapTopicContentType, this.router.url);
      }
    })
  }

  bapTopicItemNavigationUrl(type: string, identifier: string) {
    switch (type.toUpperCase()) {
      case 'ARTICLE':
        return `bap-articles/${identifier}`;
      case 'WORKSHOP':
        return `bap-workshop-details/${identifier}`;
      case 'FLASH CARD':
      default:
        return '#';
    }
  }

  openBapFlashCardModal(data: { identifier: string, tier: string }) {
    // Check tier access
    const canViewContent = this.contentService.canViewContent(data.tier);
    if (!canViewContent && !this.configService.isPreview) {
      this.toastr.error('Partner does not have tier required to access the content.');
      return;
    }

    // *** CHANGE TO BAP NEW FLASH CARD MODAL
    // const modalRef = this.modalService.open(BapFlashCardModalComponent, {
    //   centered: true,
    //   windowClass: 'bap-flash-card-modal',
    //   size: 'xl'
    // });

    const item = this.bapTopic1.find(item => item.content_id === data.identifier); // Use to stimulate the activity status only, remove after integration

    const modalRef = this.modalService.open(BapNewFlashCardModalComponent, {
      centered: true,
      windowClass: 'bap-new-flash-card-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
    modalRef.componentInstance.activityStatus = item?.activity_details?.status || ''; // Use to stimulate the activity status only, remove after integration
    modalRef.componentInstance.theme = item?.activity_details?.theme || ''; // Use to stimulate the activity status only, remove after integration
  }

  openBapQuizModal(data: { identifier: string, tier: string }) {
    const modalRef = this.modalService.open(BapQuizModalComponent, {
      centered: true,
      windowClass: 'bap-quiz-modal',
      size: 'xl'
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openBapQuestionnaireModal(data: { identifier: string, tier: string }) {
    const modalRef = this.modalService.open(BapQuestionnaireModalComponent, {
      centered: true,
      windowClass: 'bap-questionnaire-modal',
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openUnlockBAPContentModal() {
    const modalRef = this.modalService.open(UnlockBapContentModalComponent, {
      centered: true,
      windowClass: 'unlock-bap-content-modal',
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  handleFilterChange(filters: { name: string, value: string }[]) {
    // Handle filter changes here
    console.log('Filters changed:', filters);
  }

  ngAfterViewInit() {
    const directive = new ClickableEnlargeImageDirective(this.elementRef, this.ngZone);
    directive.ngOnInit();
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }
}
