// @ts-ignore Import module
// import { turnstile } from "https://challenges.cloudflare.com/turnstile/v0/api.js";

import { Component, Output, EventEmitter, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { CommonModule } from '@angular/common';
import { SpinnerIcon } from '../../../icons';
import { AuthService } from '../../../services/auth.service';
import { ContentService } from '../../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorService } from '../../../services/error.service';
import { SignUpModel } from '../../../models/sign-up-modal.model';
import { environment } from '../../../../environment/environment';

declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (
        idOrContainer: string | HTMLElement,
        options: any,
      ) => string;
      reset: (widgetIdOrContainer: string | HTMLElement) => void;
      getResponse: (
        widgetIdOrContainer: string | HTMLElement,
      ) => string | undefined;
      remove: (widgetIdOrContainer: string | HTMLElement) => void;
    };
  }
}
@Component({
  selector: 'app-review-profile',
  standalone: true,
  imports: [CommonModule, SpinnerIcon, TranslateModule, CommonModule],
  templateUrl: './review-profile.component.html',
  styleUrl: './review-profile.component.scss',
  animations: [fadeInOut],
})
export class ReviewProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCancelled = new EventEmitter<void>();
  @Output() onCompleted = new EventEmitter<void>();
  @Input() selectedMarket!: string;
  @Input() input!: SignUpModel;
  isSubmitting: boolean = false;
  currentDate!: string;
  serverError: boolean = false;
  serverErrorMessage!: string;

  // Captcha
  captchaSuccess: boolean = false;
  captchaSuccessToken!: string;
  captchaError: boolean = false;
  captchaErrorCode!: string;
  captchaErrorMessage!: string;
  captchaErrorMap: { [key: string]: string } = {
    "100": "Error Code Family Initialization Problems: There was a problem initializing Turnstile before a challenge could be started.",
    "105": "Error Code Family: Turnstile API Compatibility: Turnstile was invoked in a deprecated or invalid way.",
    "120": "Error Code Family: Internal Errors for Cloudflare Employees.",
    "110100": "Invalid sitekey",
    "110110": "Invalid sitekey",
    "110200": "Domain not allowed.",
    "110420": "This error occurs when an unsupported or incorrectly formatted action is submitted.",
    "110430": "This error occurs when an unsupported or incorrectly formatted action is submitted. The “Invalid cData” error in Turnstile refers to an issue encountered when processing Custom Data (cData). This error occurs when the cData provided does not adhere to the expected format or contains invalid characters.",
    "110500": "The visitor is using an unsupported browser.",
    "110510": "The visitor provided an inconsistent user-agent throughout the process of solving Turnstile.",
    "200010": "Invalid Caching: Some portion of Turnstile was accidentally cached.",
    "200100": "Time Problem: The visitor’s clock is incorrect.",
  }

  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private errorService: ErrorService
  ) { }

  ngAfterViewInit(): void {
    window.onloadTurnstileCallback = function () {
      window.turnstile.render('#recaptcha', {
        sitekey: environment.captchaSitekey,
        callback: (token: any) => {
          window.postMessage(`captchaSuccess ${token}`);
        },
        "before-interactive-callback": (msg: any) => {
          window.postMessage(`captchaInteractive`);
        },
        "error-callback": (err: any) => {
          window.postMessage(`captchaFail ${err}`);
        },
        theme: 'light',
        retry: 'auto',
        "retry-interval": '3000',
        appearance: 'execute'
      });
    };

    // Import CloudFlare Turnstile during run-time
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
    script.async = true;
    script.defer = true;
    script.id = 'recaptchaImport';
    document.head.appendChild(script);
  }

  ngOnDestroy(): void {
    const captchaScript = document.getElementById('recaptchaImport');
    captchaScript?.remove();
  }

  ngOnInit(): void {
    this.currentDate = this.contentService.formatDate2(new Date().getTime());

    // Captcha Event Listener
    window.addEventListener('message', (event) => {
      // Captcha Success
      if (typeof event.data === 'string' && event.data.includes('captchaSuccess')) {
        this.captchaSuccess = true;
        this.captchaError = false;
        const messageArr = event.data.split(' ', 2);
        this.captchaSuccessToken = messageArr[1];
        this.input.captcha_token = this.captchaSuccessToken;
      }

      // Captcha Enter Interactive Mode
      if (typeof event.data === 'string' && event.data.includes('captchaInteractive')) {
        this.captchaSuccess = false;
        this.captchaError = false;
      }

      // Captcha Fail (Only for those non-retryable)
      if (typeof event.data === 'string' && event.data.includes('captchaFail')) {
        this.captchaSuccess = false;
        const messageArr = event.data.split(' ', 2);
        this.captchaErrorCode = messageArr[1];

        if (this.captchaErrorMap[this.captchaErrorCode]) { // Match exact error code
          this.captchaErrorMessage = this.captchaErrorMap[this.captchaErrorCode];
          this.captchaError = true;

        } else { // No match
          switch (this.captchaErrorCode.slice(0, 3)) { // Error code start with..
            case '100':
              this.captchaErrorMessage = this.captchaErrorMap['100'];
              break;
            case '105':
              this.captchaErrorMessage = this.captchaErrorMap['105'];
              break;
            case '120':
              this.captchaErrorMessage = this.captchaErrorMap['120'];
              break;
            default:
              // this.captchaErrorCode = '';
              this.captchaErrorMessage = 'Retry in progress..';
              break;
          }

          this.captchaError = true;
        }
      }
    })
  }

  onBack() {
    this.input.captcha_token = '';
    this.onCancelled.emit();
  }

  onSubmit() {
    this.isSubmitting = true;

    // ========================== Submit Sign Up Form ==================================
    this.authService.signUp(this.input).subscribe({
      next: res => {
        if (!res.data) {
          this.serverErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
          this.serverError = true;
        } else {
          window.turnstile.remove('#recaptcha');
          this.onCompleted.emit();
        }
      },
      complete: () => {
        this.isSubmitting = false;

        if (this.serverError === true) {
          this.input.captcha_token = '';
          window.turnstile.reset('#recaptcha'); // Get new captcha token
        }
      }
    })
  }

  getFormattedDate() {
    const now = new Date().getTime();
    return this.contentService.formatDate2(now);
  }
}
