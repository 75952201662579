
import { Component } from '@angular/core';

@Component({
  selector: 'icon-yahoo',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M223.7 141.1 167 284.2 111 141.1H14.9L120.8 390.2 82.2 480h94.2L317.3 141.1zm105.4 135.8a58.2 58.2 0 1 0 58.2 58.2A58.2 58.2 0 0 0 329.1 276.9zM394.7 32l-93 223.5H406.4L499.1 32z"/></svg>
  `,
  styles: [],
})
export class YahooIcon { }