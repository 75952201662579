import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { fadeInOut } from '../../animation';
import { ImageBanner1Model } from '../../models';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { HtmlContentsComponent } from "../../components/html-contents/html-contents.component";
import { WorkshopIcon } from "../../icons";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterWorkshopModalComponent } from '../../components/register-workshop-modal/register-workshop-modal.component';
import { UnregisterWorkshopModalComponent } from '../../components/unregister-workshop-modal/unregister-workshop-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-workshop-details',
  standalone: true,
  templateUrl: './workshop-details.component.html',
  styleUrl: './workshop-details.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, HtmlContentsComponent, WorkshopIcon, ImageBanner1Component, WorkshopIcon, TranslateModule, ClickableEnlargeImageDirective]
})
export class WorkshopDetailsComponent implements OnInit, AfterViewInit {
  currentPageData: { title: string, descriptions: string, content_type: string, content_id: string, content: string, start_date: number } = {
    title: '',
    descriptions: '',
    content_type: '',
    content_id: '',
    content: '',
    start_date: 0,
  };
  queryParamVal: string = '';
  workshopIdentifier!: string;
  workshopArticleContentType: string = 'SbuxWorkshop';
  workshopDetailsBannerData: ImageBanner1Model = {
    content_id: '',
    image_src: {
      sm: '',
      lg: ''
    }
  };
  workshopContent !: SafeHtml;

  isEnrolled: boolean = false;

  toastrErrorHeader!: string;
  toastrErrorDesc!: string;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private sanitizer: DomSanitizer,
    private accountService: AccountService,
    private toastr: ToastrService,
    private translation: TranslateService,
    public analyticsService: AnalyticsService,
    public configService: ConfigService,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }
  // Need to set page title in future

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/workshops',
      label: 'Workshop'
    }
  ]

  ngOnInit(): void {
    this.translation.get(['GENERAL.ERROR_HEADER', 'GENERAL.ERROR_DESC']).subscribe({
      next: res => {
        this.toastrErrorHeader = res['GENERAL.ERROR_HEADER'];
        this.toastrErrorDesc = res['GENERAL.ERROR_DESC'];
      }
    })

    this.route.params.subscribe(params => {
      this.workshopIdentifier = params['contentId'];

      // From My Workshop
      const currentUrl = this.router.url;
      if (currentUrl.toUpperCase().split('/').includes('MY-WORKSHOP')) {
        this.breadcrumbsData[0].link = '/my-workshop';
        this.breadcrumbsData[0].label = 'My Workshop';
      }

      // Check workshop is registered
      if (!this.configService.isPreview) {
        this.accountService.checkRegisteredWorkshop(this.workshopIdentifier).subscribe({
          next: res => {
            this.isEnrolled = res;
          }
        });
      }

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.workshopIdentifier, this.workshopArticleContentType, this.router.url);
      }

      // Workshop Details Data
      this.contentService.getPartnerContent(this.workshopArticleContentType, this.workshopIdentifier).subscribe({
        next: res => {
          if (res.length === 0) {
            this.router.navigate(['/404']);
          }

          const data = res[0];
          // Workshop data
          this.currentPageData.title = data.title;
          this.currentPageData.descriptions = data.descriptions;
          this.currentPageData.content_type = data.contentType;
          this.currentPageData.content_id = data.identifier;
          this.currentPageData.content = data.bodyContents;
          this.currentPageData.start_date = data.startDateTime;

          // Workshop Banner data
          this.workshopDetailsBannerData.content_id = data.content_id;
          this.workshopDetailsBannerData.image_src.sm = data.heroBannerMobile;
          this.workshopDetailsBannerData.image_src.lg = data.heroBanner;

          const content = (data.bodyContents ?? '').replace(/<img/g, '<img class="clickable-enlarge-image"');
          this.workshopContent = this.sanitizeStyle(content);
        },
        error: err => {
          console.log(err);
        }
      });
    })
  }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }

  toggleEnrollment() {
    if (this.configService.isPreview) {
      this.isEnrolled = !this.isEnrolled;
      this.isEnrolled ? this.openRegisteredWorkshopModal('') : this.openUnregisteredWorkshopModal('');
      return;
    }

    if (this.isEnrolled === false) {

      this.accountService.registerWorkshop(this.workshopIdentifier).subscribe({
        next: res => {
          this.isEnrolled = true;
          this.openRegisteredWorkshopModal(this.workshopIdentifier);
          this.analyticsService.sendClickEventAnalytic('CONTENT', this.currentPageData.content_type, this.currentPageData.content_id, this.currentPageData.title, 'REGISTER');
        },
        error: err => {
          console.log(err);
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      })
    } else {
      this.accountService.unregisterWorkshop(this.workshopIdentifier).subscribe({
        next: res => {
          this.isEnrolled = false;
          this.openUnregisteredWorkshopModal(this.workshopIdentifier);
          this.analyticsService.sendClickEventAnalytic('CONTENT', this.currentPageData.content_type, this.currentPageData.content_id, this.currentPageData.title, 'UNREGISTER');
        },
        error: err => {
          console.log(err);
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      })
    }
  }

  openRegisteredWorkshopModal(workshop_id: string) {
    const modalRef = this.modalService.open(RegisterWorkshopModalComponent, {
      centered: true,
      windowClass: 'register-workshop-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopID = workshop_id;
  }

  openUnregisteredWorkshopModal(workshop_id: string) {
    const modalRef = this.modalService.open(UnregisterWorkshopModalComponent, {
      centered: true,
      windowClass: 'unregister-workshop-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopID = workshop_id;
  }

  sanitizeStyle(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }

  ngAfterViewInit() {
    const directive = new ClickableEnlargeImageDirective(this.elementRef, this.ngZone);
    directive.ngOnInit();
  }
}
