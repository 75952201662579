<div class="star-path">
  <div class="wrapper">
    <div class="d-md-flex flex-wrap align-items-start mb-4">
      <div class="headings flex-md-grow-1 pe-4">
        <h2 class="text-dark">Star Path</h2>
        <p>
          Complete activities trom aittereni specialty areas to earn points.
        </p>
      </div>
      <div class="current-points mt-3 mt-md-0 text-md-end">
        <div class="current-points-label">Current points:</div>
        <div class="current-points-value">
          <span class="current-points-value-number">{{ partnerStarPathProgressData.currentPoints | number }}</span>
          <span class="current-points-value-label">pts</span>
        </div>
      </div>
    </div>

    <div class="star-path-progress">
      <div class="star-path-progress-top-label mb-2">
        <div class="row">
          <div class="col-progress col-progress-start">
            Start!
          </div>
          <div class="col-progress text-center">
            1 Star
          </div>
          <div class="col-progress text-center">
            2 Stars
          </div>
          <div class="col-progress text-end">
            3 Stars
          </div>
        </div>
      </div>

      <div class="star-path-progress-bar">
        <div class="star-path-progress-bar-fill" [style.width.%]="partnerStarPathProgressData.progress"></div>
      </div>

      <div class="star-path-progress-bottom-label mt-3">
        <div class="row">
          <div class="col-progress col-progress-start">

          </div>
          <div class="col-progress">
            <div class="d-flex flex-column align-items-center">
              <div class="star-icon d-flex align-items-center justify-content-center gap-1">
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
              </div>
              <div class="mt-2 text-center d-md-flex justify-content-center">
                <div class="points-value me-md-1">
                  {{ starPathPointsConfig[0].points | number }}
                </div>
                <div class="points-label">
                  PTS
                </div>
              </div>
              <div class="actions mt-2">
                <button class="btn btn-primary btn-sm btn-claim-star-path">
                  Claim Star
                </button>
              </div>
            </div>

          </div>
          <div class="col-progress">
            <div class="d-flex flex-column align-items-center">
              <div class="star-icon d-flex align-items-center justify-content-center gap-1">
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
              </div>
              <div class="mt-2 text-center d-md-flex justify-content-center">
                <div class="points-value me-md-1">
                  {{ starPathPointsConfig[1].points | number }}
                </div>
                <div class="points-label">
                  PTS
                </div>
              </div>
              <div class="actions mt-2">
                <button class="btn btn-primary btn-sm btn-claim-star-path">
                  Claim Star
                </button>
              </div>
            </div>
          </div>
          <div class="col-progress d-flex justify-content-end">
            <div class="d-flex flex-column align-items-center">
              <div class="star-icon d-flex align-items-center justify-content-center gap-1">
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
                <span class="icon-img">
                  <icon-bap-star-circled></icon-bap-star-circled>
                </span>
              </div>
              <div class="mt-2 text-center d-md-flex justify-content-center">
                <div class="points-value me-md-1">
                  {{ starPathPointsConfig[2].points | number }}
                </div>
                <div class="points-label">
                  PTS
                </div>
              </div>
              <div class="actions mt-2">
                <button class="btn btn-primary btn-sm btn-claim-star-path">
                  Claim Star
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>