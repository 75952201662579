import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { XmarkIcon, SpinnerIcon } from "../../icons";

@Component({
  selector: 'app-bap-questionnaire-modal',
  standalone: true,
  imports: [XmarkIcon, CommonModule, FormsModule, SpinnerIcon],
  templateUrl: './bap-questionnaire-modal.component.html',
  styleUrl: './bap-questionnaire-modal.component.scss'
})
export class BapQuestionnaireModalComponent implements OnInit {
  @Input() modal: any;
  @Input() id: string = '';
  @Input() activityStatus: string = ''; // Use to stimulate the activity status only, remove after integration

  contentData: any;
  isLoading: boolean = false;

  questionsData = [
    {
      id: 'q1',
      type: 'SINGLE_SELECTION',
      question: 'How would you describe C.A.F.E. Practices to a friend, family member, or customer?',
      options: [
        {
          id: 'q1_opt1',
          text: 'A coffee quality verification program that ensures Starbucks sources high-quality coffee beans'
        },
        {
          id: 'q1_opt2',
          text: 'A comprehensive set of social, environmental, and economic guidelines for coffee farming'
        },
        {
          id: 'q1_opt3',
          text: 'A sustainability program focused exclusively on environmental conservation in coffee-growing regions'
        },
        {
          id: 'q1_opt4',
          text: 'A certification program that only addresses fair wages for coffee farmers'
        }
      ]
    },
    {
      id: 'q2',
      type: 'MULTIPLE_SELECTION',
      question: 'Which of the following are components of C.A.F.E. Practices? (Select all that apply)',
      options: [
        {
          id: 'q2_opt1',
          text: 'Quality'
        },
        {
          id: 'q2_opt2',
          text: 'Economic Transparency'
        },
        {
          id: 'q2_opt3',
          text: 'Social Responsibility'
        },
        {
          id: 'q2_opt4',
          text: 'Environmental Leadership'
        },
        {
          id: 'q2_opt5',
          text: 'Marketing Strategy'
        }
      ]
    },
    {
      id: 'q3',
      type: 'SINGLE_SELECTION',
      question: 'What percentage of Starbucks coffee is ethically sourced through C.A.F.E. Practices?',
      options: [
        {
          id: 'q3_opt1',
          text: '50%'
        },
        {
          id: 'q3_opt2',
          text: '75%'
        },
        {
          id: 'q3_opt3',
          text: '99%'
        },
        {
          id: 'q3_opt4',
          text: '25%'
        }
      ]
    },
    {
      id: 'q4',
      type: 'MULTIPLE_SELECTION',
      question: 'Which environmental practices are encouraged by C.A.F.E. Practices? (Select all that apply)',
      options: [
        {
          id: 'q4_opt1',
          text: 'Soil management'
        },
        {
          id: 'q4_opt2',
          text: 'Water conservation'
        },
        {
          id: 'q4_opt3',
          text: 'Biodiversity conservation'
        },
        {
          id: 'q4_opt4',
          text: 'Energy efficiency'
        },
        {
          id: 'q4_opt5',
          text: 'Waste management'
        }
      ]
    },
    {
      id: 'q5',
      type: 'SINGLE_SELECTION',
      question: 'When was C.A.F.E. Practices first implemented by Starbucks?',
      options: [
        {
          id: 'q5_opt1',
          text: '1995'
        },
        {
          id: 'q5_opt2',
          text: '2004'
        },
        {
          id: 'q5_opt3',
          text: '2010'
        },
        {
          id: 'q5_opt4',
          text: '2015'
        }
      ]
    }
  ];

  // Add a property to track user answers
  userAnswers: { [key: string]: string | string[] } = {};

  constructor() { }

  ngOnInit(): void {
    this.contentData = {
      card_image_src: {
        lg: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741836569780-61a163e8bb0386b6a5295736067a29e0.jpeg',
        sm: '', // optional
      },
      topic: 'Origin & Ethical Sourcing',
      headline: 'C.A.F.E. Practices: Questionnaire Demo',
      activity_details: {
        code: "OES 1",
        points: "140PTS",
        status: this.activityStatus,
        duration: "46m",
      },
      introduction_content: `
        <p>In 2001, we created a set of guidelines to ensure the coffee we buy is ethically grown and responsibly traded. We call our verification program C.A.F.E. Practices, which stands for Coffee and Farmer Equity.</p>

        <p><strong>Activity:</strong><br>
        How would you describe C.A.F.E. Practices to a friend, family member, or customer?</p>

        <p><strong>Tips:</strong><br>Try describing C.A.F.E. Practices from memory at first. If you need help, turn the card over for a few talking points.</p>

        <p><strong>Journal</strong><br>Take a few minutes to write down how you would describe C.A.F.E. Practices.</p>
      `,
      document_url: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/documents/1741847863277-sample-card-doc.pdf'
    };

    // Initialize empty answers for each question
    this.questionsData.forEach(question => {
      this.userAnswers[question.id] = question.type === 'SINGLE_SELECTION' ? '' : [];
    });
  }

  dismissModal() {
    this.modal.dismiss();
  }

  // Handle single selection change
  onSingleSelectionChange(questionId: string, optionId: string): void {
    this.userAnswers[questionId] = optionId;
  }

  // Handle multiple selection change
  onMultipleSelectionChange(questionId: string, optionId: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    const answers = this.userAnswers[questionId] as string[];

    if (isChecked) {
      if (!answers.includes(optionId)) {
        this.userAnswers[questionId] = [...answers, optionId];
      }
    } else {
      this.userAnswers[questionId] = answers.filter(id => id !== optionId);
    }
  }

  // Check if option is selected for single selection
  isSingleOptionSelected(questionId: string, optionId: string): boolean {
    return this.userAnswers[questionId] === optionId;
  }

  // Check if option is selected for multiple selection
  isMultipleOptionSelected(questionId: string, optionId: string): boolean {
    return (this.userAnswers[questionId] as string[]).includes(optionId);
  }

  submitQuestionnaire(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.contentData.activity_details.status = 'COMPLETED';
      this.isLoading = false;
    }, 1000);
  }
}
