<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-2-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-2-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="step-indicator">
      <div class="data-label">{{"ONBOARDING.NAME.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2 current"></span>
        <span class="step step-3"></span>
        <!-- <span class="step step-4"></span> -->
      </div>
    </div>

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.NAME.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.NAME.DESC" | translate}}</p>
    </div>

    <div class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="regForm2">
        <div class="form-group">
          <label for="partner-first-name" class="form-label d-flex">
            {{"ONBOARDING.NAME.FIRST_NAME" | translate}}
            <span class="ms-auto">
              ({{regForm2.controls['firstName'].value.length}}/50)
            </span>
          </label>
          <input type="text" class="form-control" id="partner-first-name"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="firstName" maxlength="50">
        </div>

        <div class="form-group">
          <label for="partner-last-name" class="form-label d-flex">
            {{"ONBOARDING.NAME.LAST_NAME" | translate}}
            <span class="ms-auto">
              ({{regForm2.controls['lastName'].value.length}}/50)
            </span>
          </label>
          <input type="text" class="form-control" id="partner-last-name"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="lastName" maxlength="50">
        </div>

        <div class="form-group">
          <label for="partner-nickname" class="form-label d-flex">
            {{"ONBOARDING.NAME.NICKNAME" | translate}}
            <span class="ms-auto">
              ({{regForm2.controls['nickName'].value.length}}/20)
            </span>
          </label>
          <input type="text" class="form-control" id="partner-nickname" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="nickName" maxlength="20">
        </div>
      </fieldset>
    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" [disabled]="!regForm2.valid" (click)="onSubmit()">
        {{"ONBOARDING.NAME.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.NAME.BACK" | translate}}
      </button>
    </div>
  </div>
</div>