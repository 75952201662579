import { Directive, ElementRef, HostListener, OnInit, NgZone } from '@angular/core';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';

@Directive({
  selector: 'img.clickable-enlarge-image',
  standalone: true
})
export class ClickableEnlargeImageDirective implements OnInit {
  private isPhotoSwipeOpen = false;

  constructor(private el: ElementRef, private ngZone: NgZone) { }

  ngOnInit() {
    // Bind click event to the current element
    const htmlEl = this.el.nativeElement as HTMLElement;
    if (!htmlEl.dataset['pswpBound']) {
      htmlEl.dataset['pswpBound'] = 'true';
      htmlEl.addEventListener('click', (event) => this.openPhotoSwipe(event, this.el.nativeElement));
    }

    // Find the closest container to observe (either modal-content or the parent component)
    const containerToObserve = htmlEl.closest('.modal-body') || htmlEl.closest('[class*="component"]') || htmlEl.parentElement;

    if (containerToObserve) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              const images = node.querySelectorAll('img.clickable-enlarge-image');
              images.forEach((img) => {
                const htmlImg = img as HTMLElement;
                if (!htmlImg.dataset['pswpBound']) {
                  htmlImg.dataset['pswpBound'] = 'true';
                  htmlImg.addEventListener('click', (event) => this.openPhotoSwipe(event, img as HTMLImageElement));
                }
              });
            }
          });
        });
      });

      observer.observe(containerToObserve, { childList: true, subtree: true });
    }
  }

  private openPhotoSwipe(event: Event, img: HTMLImageElement) {
    if (this.isPhotoSwipeOpen) return;

    this.ngZone.runOutsideAngular(() => {
      event.preventDefault();
      event.stopPropagation();

      const previousFocus = document.activeElement;
      this.isPhotoSwipeOpen = true;

      // Ensure the image is loaded before opening PhotoSwipe
      if (img.complete) {
        this.initPhotoSwipe(img, previousFocus);
      } else {
        img.onload = () => this.initPhotoSwipe(img, previousFocus);
      }
    });
  }

  private initPhotoSwipe(img: HTMLImageElement, previousFocus: Element | null) {
    const pswp = new PhotoSwipe({
      dataSource: [{
        src: img.src,
        width: img.naturalWidth,
        height: img.naturalHeight
      }],
      index: 0,
      closeOnVerticalDrag: true,
    });

    pswp.init();

    pswp.on('close', () => {
      this.isPhotoSwipeOpen = false;
      setTimeout(() => {
        if (previousFocus instanceof HTMLElement) {
          previousFocus.focus();
        }
      }, 10);
    });
  }
} 