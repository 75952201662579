<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <figure class="thank-you-image mb-5">
      <img src="/assets/images/sign-up/img-coffee-cup-exclaimation.webp">
    </figure>
    <h1 class="mb-4">
      {{"ONBOARDING.ACTIVATE_FAIL.HEADER" | translate}}
    </h1>
    <div class="mb-5">
      <p>
        {{errMessage | translate}} 
      </p>
      <p>
        {{"ONBOARDING.ACTIVATE_FAIL.DESC_2" | translate}}
      </p>
    </div>

    <div class="call-to-actions">
      <a href="mailto:starbuckscoffeecommunity@starbucks.com" class="btn btn-primary w-100 w-lg-auto">
        {{"ONBOARDING.ACTIVATE_FAIL.NEXT" | translate}}
      </a>
    </div>

  </div>
</div>