
import { Component } from '@angular/core';

@Component({
  selector: 'icon-bookmark',
  standalone: true,
  template: `
 <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 25 25" style="enable-background:new 0 0 25 25;" xml:space="preserve">
<path class="border" id="Path_375" d="M17.7,2.1H7.3c-0.6,0-1,0.5-1,1v18.8c0,0.6,0.5,1,1,1c0.1,0,0.3,0,0.4-0.1l4.8-1.9l4.8,1.9
	c0.1,0.1,0.3,0.1,0.4,0.1c0.6,0,1-0.5,1-1V3.1C18.8,2.5,18.3,2.1,17.7,2.1 M16.7,20.3l-3.8-1.5c-0.2-0.1-0.5-0.1-0.8,0l-3.8,1.5V4.2
	h8.3V20.3z"/>
<path class="body" d="M16.7,20.3l-3.8-1.5c-0.2-0.1-0.5-0.1-0.8,0l-3.8,1.5V4.2h8.3V20.3z"/>
</svg>
  `,
  styles: [],
})
export class BookmarkIcon { }


