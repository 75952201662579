<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 *ngIf="imageBanner2Data" [data]="imageBanner2Data"></app-image-banner-2>
    </div>
  </section>

  <section class="mb-5">
    <div class="container mw-lg">
      <div class="mb-4 text-center">
        <h6 class="text-dark text-uppercase">{{"BAP.HEADER" | translate}}</h6>
        <h2 *ngIf="bapTopicTitle">{{bapTopicTitle}}</h2>
        <p *ngIf="bapTopicDescription">{{bapTopicDescription}}
      </div>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <div class="filters mb-4 d-md-flex justify-content-md-between">
        <div class="filter-item mb-3">
          <app-list-filters [data]="activityTypeFilterData" (onCompleted)="handleFilterChange($event)">
          </app-list-filters>
        </div>
        <div class="filter-item mb-3">
          <app-list-filters [data]="sortingFilterData" (onCompleted)="handleFilterChange($event)">
          </app-list-filters>
        </div>
      </div>

      <!-- THIS IS EXAMPLE -->
      <app-grid-list-3 [data]="bapTopic1" (onFlashCardClicked)="openBapFlashCardModal($event)"
        (onQuizClicked)="openBapQuizModal($event)" (onQuestionnaireClicked)="openBapQuestionnaireModal($event)"
        (onUnlockBAPContentClicked)="openUnlockBAPContentModal()"></app-grid-list-3>
      <!-- END OF EXAMPLE -->

      <app-grid-list-3 [data]="gridList3Data" (onFlashCardClicked)="openBapFlashCardModal($event)"
        (onUnlockBAPContentClicked)="openUnlockBAPContentModal()"></app-grid-list-3>
    </div>
  </section>
</div>