import { Component, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-more-questions',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './more-questions.component.html',
  styleUrl: './more-questions.component.scss',
  animations: [fadeInOut],
})
export class MoreQuestionsComponent {
  @Output() onCompleted = new EventEmitter<{ ansQuestion: boolean }>();

  onSubmit(data: { answerMore: boolean }) {
    this.onCompleted.emit({ ansQuestion: data.answerMore });
  }
}
