import { Component, Input } from '@angular/core';
import { BannerCarousel2Service } from '../../services';
import { CommonModule } from '@angular/common';
import { BannerCarousel2Model } from '../../models';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-banner-carousel-2',
  standalone: true,
  templateUrl: './banner-carousel-2.component.html',
  styleUrl: './banner-carousel-2.component.scss',
  imports: [CommonModule, CarouselModule],
  providers: [BannerCarousel2Service]
})

export class BannerCarousel2Component {
  @Input({ required: true }) data!: BannerCarousel2Model[];
  @Input() slideHeaderCopy?: string;

  // Owl carousel config
  bannerCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    nav: true,
    responsive: {
      0: {
        items: 1,
        loop: false
      }
    },
  }
}

