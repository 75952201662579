import { Component, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { ImageLandscapeIcon } from "../../../icons/icon-image-landscape";
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-profile-picture',
  standalone: true,
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.scss',
  animations: [fadeInOut],
  imports: [ImageLandscapeIcon, TranslateModule]
})
export class ProfilePictureComponent {
  @Output() onSkip = new EventEmitter<void>();
  @Output() onCancelled = new EventEmitter<void>();
  @Output() onUpload = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  constructor(
    private languageService: LanguageService
  ) { }

  onCreateAction() {
    this.onCreate.emit();
  }

  onBack() {
    this.onCancelled.emit();
  }

  onSkipAction() {
    this.onSkip.emit();
  }

  onUploadAction() {
    this.onUpload.emit();
  }

  getImageLanguageSrc(): string {
    return `/assets/images/sign-up/img-try-avatar-creator-${this.languageService.languageMap(this.languageService.getLanguage() ?? '1')}.png`
  }
}
