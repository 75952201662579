<header>
  <section *ngIf="configService.isPreview"
    class="preview-notice d-flex flex-wrap align-items-center justify-content-center"><span class="icon-img me-3">
      <icon-exclamation-circle-solid></icon-exclamation-circle-solid>
    </span> Temporary Preview Page
  </section>
  <div class="header-top">
    <div class="container d-flex flex-wrap align-items-center">
      <div class="header-menu-toggle">
        <button type="button" class="btn btn-menu-toggle" (click)="open(offcanvasMainMenu)">
          <span class="icon-img">
            <icon-menu-bars></icon-menu-bars>
          </span>
        </button>
      </div>
      <div class="header-logo">
        <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'LOGO')"
          routerLink="/" class="header-logo-link">
          <logo-scc></logo-scc>
        </a>
      </div>
      <ul class="header-tools list-unstyled">
        <!-- <li *ngIf="isPublic"><a (click)="openSignInModal()">Sign in</a></li> -->
        <li class="tool-search">
          <button type="button" class="btn btn-circle btn-circle-outline"
            (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'SEARCH'); isSearchCollapsed=!isSearchCollapsed"
            [attr.aria-expanded]="!isSearchCollapsed" aria-controls="searchFieldContainer">
            <span class="icon-img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </span>
          </button>
        </li>
        <li *ngIf="!configService.isPreview" class="tool-language d-none d-lg-block">
          <div ngbDropdown #languageDropDown="ngbDropdown" placement="bottom-start" class="custom-dropdown">
            <button type="button" class="btn btn-circle btn-circle-outline" id="languageDropDown" ngbDropdownToggle>
              <strong>{{languageShortForm.toUpperCase()}}</strong>
            </button>
            <div ngbDropdownMenu aria-labelledby="languageDropDown">
              <button ngbDropdownItem (click)="serverSwitchLanguage('1')">English</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('4852764')">繁體中文</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('5017431')">日本語</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('5028697')">한국어</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('4561158')">Bahasa Indonesia</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('5146990')">Tiếng Việt</button>
              <button ngbDropdownItem (click)="serverSwitchLanguage('4938438')">ภาษาไทย</button>
            </div>
          </div>
        </li>
        <li class="tool-notification">
          <button
            (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'NOTIFICATION')"
            routerLink="/partner-notifications" type="button"
            class="btn btn-circle btn-circle-outline btn-header-notification">
            <span class="icon-img">
              <icon-notification></icon-notification>
            </span>
            <span class="unread-badge" *ngIf="hasUnreadNotification"></span>
          </button>
        </li>
        <li class="tool-profile">
          <div ngbDropdown #profileDropDown="ngbDropdown" placement="bottom-start"
            class="custom-dropdown profile-dropdown">
            <button type="button" class="btn btn-circle btn-circle-outline btn-header-profile" id="profileDropDown"
              ngbDropdownToggle>
              <img [src]="profilePicture ? profilePicture: '/assets/images/avatar/img-avatar-placeholder.png'" />
            </button>
            <div ngbDropdownMenu aria-labelledby="profileDropDown">
              <div class="partner-info">
                <div class="partner-nickname truncate-line-clamp lc-2">{{partnerNickname}}</div>
                <div class="partner-email truncate-line-clamp lc-2 break-all mt-1">{{partnerEmail}}</div>
                <div class="partner-tier mt-3">
                  <div class="partner-tier-label d-inline-flex align-items-center justify-content-center"
                    [ngClass]="{'black-apron': isBlackApron()}">
                    {{ isBlackApron() ? 'Black Apron' : 'Green Apron' }}
                  </div>

                  <ng-container *ngIf="showStarsAndPoints()">
                    <div class="partner-tier-star d-flex align-items-center justify-content-center mt-2">
                      <ng-container *ngFor="let star of [1,2,3]">
                        <span class="icon-img">
                          <icon-bap-star-filled *ngIf="star <= getStarCount()"></icon-bap-star-filled>
                          <icon-bap-star *ngIf="star > getStarCount()"></icon-bap-star>
                        </span>
                      </ng-container>
                    </div>
                    <div class="partner-points mt-2">8,000 points</div>
                  </ng-container>
                </div>
              </div>
              <div class="dropdown-link-wrapper">
                <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'MY_PROFILE')"
                  *ngIf="!configService.isPreview" routerLink="/my-profile"
                  ngbDropdownItem>{{"MY_PROFILE.HEADER" | translate}}</a>
                <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'MY_BOOKMARK')"
                  routerLink="/my-bookmark" ngbDropdownItem>{{"MY_BOOKMARK.HEADER" | translate}}</a>
                <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'MY_WORKSHOP')"
                  routerLink="/my-workshop" ngbDropdownItem>{{"MY_WORKSHOP.HEADER" | translate}}</a>
                <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'LOGOUT'); logout()"
                  *ngIf="!configService.isPreview" class="dropdown-item-logout"
                  ngbDropdownItem>{{"GENERAL.MENU.LOGOUT" | translate}}</a>
              </div>

            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
  <div class="header-search" #searchFieldContainer="ngbCollapse" [(ngbCollapse)]="isSearchCollapsed">
    <div class="container d-flex flex-wrap py-3">
      <div class="input-group flex-1">
        <span class="input-group-text">
          <span class="icon-img">
            <icon-search></icon-search>
          </span>
        </span>
        <input type="search" class="form-control" name="header-search-input"
          [placeholder]="'GENERAL.SEARCH' | translate" aria-label="Search" (keyup.enter)="onSearchContent($event)">
      </div>
      <button type="button" class="btn btn-ghost btn-close-lg btn-close-search" (click)="searchFieldContainer.toggle()">
        <span class="icon-img">
          <icon-xmark></icon-xmark>
        </span>
      </button>
    </div>
  </div>

  <ng-template #offcanvasMainMenu let-offcanvasMainMenu>
    <div class="offcanvas-main-menu d-flex flex-column pb-1">
      <div class="offcanvas-header">
        <button type="button" class="btn btn-ghost btn-close-lg btn-close-main-menu ms-auto" aria-label="Close"
          (click)="offcanvasMainMenu.dismiss('Cross click')">
          <span class="icon-img">
            <icon-xmark></icon-xmark>
          </span></button>
      </div>
      <div class="offcanvas-body flex-1 custom-scrollbar">
        <ul *ngIf="!isInCanvasLanguageMenuVisible" class="menu-list list-unstyled mb-0">
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'HOME'); closeOffcanvas()"
              routerLink="/" class="menu-list-item" i18n="@@header-menu-home">
              {{"GENERAL.MENU.HOME" | translate}}
            </a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'LEARNING_CENTRE'); closeOffcanvas()"
              routerLink="/learn" class="menu-list-item">
              {{"GENERAL.MENU.LEARNING_CENTRE" | translate}}
            </a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'COMPETITIONS'); closeOffcanvas()"
              routerLink="/competitions" class="menu-list-item" i18n="@@header-menu-competitions">
              {{"GENERAL.MENU.COMPETITIONS" | translate}}
            </a>
          </li>
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'EVENTS'); closeOffcanvas()"
              routerLink="/events" class="menu-list-item">
              {{"GENERAL.MENU.EVENTS" | translate}}
            </a>
          </li>
          <!-- <li>
						<a 
            (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'WORKSHOPS'); closeOffcanvas()"
            routerLink="/workshops" class="menu-list-item">
							{{"GENERAL.MENU.WORKSHOPS" | translate}}
						</a>
					</li> -->
          <li>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'BAP'); closeOffcanvas()"
              routerLink="/bap" class="menu-list-item">
              {{"GENERAL.MENU.BAP" | translate}}
            </a>
          </li>
          <li class="current-event mt-3">
            <div class="label">{{"GENERAL.MENU.CURRENT_EVENT" | translate}}</div>
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'CURRENT_EVENT'); closeOffcanvas()"
              [routerLink]="currentEventUrl" class="menu-list-item d-flex align-items-center pt-0">
              <span class="flex-1 pe-2">{{"GENERAL.MENU.CURRENT_EVENT_2024" | translate}}</span>
              <span class="icon-img">
                <icon-arrow-right></icon-arrow-right>
              </span>
            </a>
          </li>
        </ul>
        <ul *ngIf="!isInCanvasLanguageMenuVisible && !configService.isPreview"
          class="menu-tools list-unstyled mt-5 d-lg-none">
          <li>
            <button type="button" class="btn btn-circle btn-circle-outline bg-white"
              (click)="toggleInCanvasLanguageMenu()">
              <strong>{{languageShortForm.toUpperCase()}}</strong>
            </button>
          </li>
        </ul>

        <div id="in-canvas-language-menu" class="in-canvas-menu in-canvas-language-menu"
          [ngClass]="{'show': isInCanvasLanguageMenuVisible}">
          <div class="in-canvas-menu-nav d-flex align-items-center">
            <button type="button" class="btn btn-ghost btn-close-lg me-1" (click)="toggleInCanvasLanguageMenu()">
              <span class="icon-img">
                <icon-arrow-left></icon-arrow-left>
              </span>
            </button>
            <div class="nav-label">{{"GENERAL.LANGUAGE" | translate}}</div>
          </div>
          <ul class="menu-list list-unstyled">
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'1')">English</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'4852764')">繁體中文</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'5017431')">日本語</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'5028697')">한국어</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'4561158')">Bahasa
                Indonesia</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'5146990')">Tiếng
                Việt</a>
            </li>
            <li>
              <a href="#" class="menu-list-item" (click)="handleClick($event,'4938438')">ภาษาไทย</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="offcanvas-footer pb-4">
        <div class="offcanvas-footer-wrapper">
          <div class="offcanvas-footer-social-media mb-4">
            <ul class="list-unstyled mb-0">
              <li>
                <a href="https://www.instagram.com/partnercoffeestories/" class="btn btn-ghost btn-social">
                  <span class="icon-img me-2">
                    <icon-instagram></icon-instagram>
                  </span>
                  &#64;partnercoffeestories
                </a>
              </li>
            </ul>
          </div>
          <div class="offcanvas-footer-link d-flex flex-wrap">
            <!-- <a routerLink="#">{{"GENERAL.MENU.FAQ" | translate}}</a> -->
            <a (click)="this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'CONTACT_US')"
              href="mailto:starbuckscoffeecommunity@starbucks.com"
              target="_blank">{{"GENERAL.MENU.CONTACT_US" | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</header>