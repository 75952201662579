<div class="event-schedule-list">
  <div *ngFor="let month of eventScheduleByMonth" class="mb-5">
    <div class="list-wrapper">
      <h6 class="text-dark text-uppercase text-center mb-4">{{month.month}} {{month.year}}</h6>
      <ul class="list-unstyled list-header fs-small d-flex flex-wrap">
        <!-- <li class="download-schedule">
          <button class="btn btn-white btn-download-schedule btn-sm fw-bold"
            (click)="downloadSchedule(month.download_link)">
            DOWNLOAD
          </button>
        </li> -->
        <li class="date">{{"EVENT.SCHEDULE.TABLE_HEADER.DATE" | translate}}</li>
        <li class="time">{{"EVENT.SCHEDULE.TABLE_HEADER.TIME" | translate}} ({{month.timezone}})</li>
        <li class="event d-none d-lg-flex">{{"HOME.EXPLORE.EVENT"| translate}}</li>
      </ul>

      <ul class="list-unstyled list-body d-flex flex-wrap">
        <li *ngFor="let item of month.event" class="list-body-item d-flex flex-wrap">
          <div class="date fw-bold">{{item.date}}</div>
          <div class="time fw-bold">{{item.time}}</div>
          <div class="event-name fs-5">
            <div class="fs-smaller fw-bold d-lg-none">{{"HOME.EXPLORE.EVENT"| translate}}</div>
            {{item.name}}
          </div>
          <div class="call-to-action">
            <a *ngIf="item.type === 'LIVE'"
              (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.name, 'LIVE')"
              [href]="item.button.link"
              class="btn btn-primary-live w-100 w-md-auto btn-sm">{{"EVENTS.BUTTON.LIVE_NOW" | translate}}</a>

            <a *ngIf="item.type === 'REGULAR' && item.button.recap_link"
              (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.name, 'REGULAR')"
              [href]="item.button.recap_link"
              class="btn btn-primary-recap w-100 w-md-auto btn-sm">{{item.button.label ?? 'EVENTS.BUTTON.WATCH_RECAPS' | translate}}</a>

            <span ngbDropdown #addToCalendarDropDown="ngbDropdown" placement="bottom-start"
              class="custom-dropdown add-to-calendar-dropdown">
              <button
                (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.name, 'UPCOMING')"
                type="button" *ngIf="item.type === 'UPCOMING'"
                class="btn btn-primary btn-add-to-calendar w-100 w-md-auto btn-sm" id="addToCalendarDropDown"
                ngbDropdownToggle>{{"GENERAL.BUTTON.ADD_TO_CALENDAR" | translate}}</button>
              <div ngbDropdownMenu aria-labelledby="addToCalendarDropDown">
                <a ngbDropdownItem (click)="onCalendarClick($event,'apple',item.content_id)">
                  <span class="icon-img">
                    <icon-apple></icon-apple>
                  </span>
                  Apple Calendar
                </a>
                <a ngbDropdownItem (click)="onCalendarClick($event,'google',item.content_id)">
                  <span class="icon-img">
                    <icon-google></icon-google>
                  </span>
                  Google Calendar
                </a>
                <!-- <a ngbDropdownItem (click)="onCalendarClick($event,'ical',item.content_id)">
                  <span class="icon-img">
                    <icon-calendar></icon-calendar>
                  </span>
                  iCal</a> -->
                <a ngbDropdownItem (click)="onCalendarClick($event,'microsoft',item.content_id)">
                  <span class="icon-img">
                    <icon-microsoft-365></icon-microsoft-365>
                  </span>
                  Microsoft Outlook Calendar</a>
                <a ngbDropdownItem (click)="onCalendarClick($event,'yahoo',item.content_id)">
                  <span class="icon-img">
                    <icon-yahoo></icon-yahoo>
                  </span>
                  Yahoo Calendar</a>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>