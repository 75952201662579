export default function passwordValidatorCriteria(password: string, usernameList: string[]): any {
    let errorList: string[] = [];
    const charLengthPattern = /^.{8,25}$/;
    const lowerCasePattern = /(?=.*[a-z])/;
    const upperCasePattern = /(?=.*[A-Z])/;
    const numberPattern = /(?=.*[0-9])/;
    const specialCharPattern = /(?=.*[^A-Za-z0-9])/;

    // Password length from 8 to 25 characters long
    if (!charLengthPattern.test(password)) {
        errorList.push('invalidCharLength');
    }

    //At least one lower case letter (a-z)
    if (!lowerCasePattern.test(password)) {
        errorList.push('invalidLowercase');
    }

    //At least one upper case letter (A-Z)
    if (!upperCasePattern.test(password)) {
        errorList.push('invalidUppercase');
    }

    //At least one number (0-9)
    if (!numberPattern.test(password)) {
        errorList.push('invalidNumber');
    }

    //At least one special character
    if (!specialCharPattern.test(password)) {
        errorList.push('invalidSpecialChar');
    }

    //Password should not contain the username
    // for (let i = 0; i < usernameList.length; i++) {
    //     if (password.toLowerCase().includes(usernameList[i])) {
    //         errorList.push('invalidUsername');
    //         break;
    //     }
    // }

    return errorList;
}