import { Component } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-404',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './404.component.html',
  styleUrl: './404.component.scss',
  animations: [fadeInOut],
})
export class Error404Component {

}
