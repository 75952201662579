import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { PersonGridListComponent } from "../../components/person-grid-list/person-grid-list.component";
import { ContentService } from '../../services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonListModel } from '../../models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-meet-the-barista-and-judges',
  standalone: true,
  templateUrl: './meet-the-barista-and-judges.component.html',
  styleUrl: './meet-the-barista-and-judges.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner2Component, PersonGridListComponent, TranslateModule, CommonModule]
})
export class MeetTheBaristaAndJudgesComponent implements OnInit {
  currentPageData: { title: string, year: string, descriptions: string } = { title: '', year: '', descriptions: '' };
  judgesData: PersonListModel[] = [];
  partnersData: PersonListModel[] = [];
  parentIdentifier!: string;
  personContentType!: string;

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/event-activity',
      label: 'Regional Barista Competition 2024'
    }
  ]

  imageBanner2Data = {
    "content_id": "content-id-1",
    "image_src": {
      "sm": "",
      "lg": ""
    },
    "logo_image_src": ""
  }

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE']).subscribe({
      next: res => {
        titleService.setTitle(res['COMPETITIONS.MEET_BARISTA_JUDGES.TITLE']);
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.parentIdentifier = params['pId'];

      const currentUrl = this.router.url;
      const segmentUrl = currentUrl.split('/');
      segmentUrl.pop();
      const updatedUrl = segmentUrl.join('/');

      switch (true) {
        case currentUrl.toUpperCase().split('/').includes('COMPETITIONS'):
          this.personContentType = 'SbuxCompetition';
          break;
        case currentUrl.toUpperCase().split('/').includes('EVENTS'):
          this.personContentType = 'SbuxEventActivity';
          break;
        default:
          break;
      }


      this.contentService.getPartnerContent(this.personContentType, this.parentIdentifier).subscribe({
        next: res => {
          const data = res[0];

          // Current Page Data
          this.currentPageData.title = data.title;
          this.currentPageData.year = data.year;
          this.currentPageData.descriptions = data.meetBaristaDescriptions ?? "";

          // Image Banner
          this.imageBanner2Data.content_id = `${data.title}-banner`
          this.imageBanner2Data.logo_image_src = data.icon;
          this.imageBanner2Data.image_src.lg = data.meetBaristaBannerImage ?? "";
          this.imageBanner2Data.image_src.sm = data.meetBaristaBannerImageMobile ?? "";

          // Partners
          data.partners.forEach((item: any) => {
            const partnersObj: PersonListModel = {
              content_id: item.identifier,
              content_type: item.contentType,
              image_src: item.profileImage,
              name: item.title,
              type: 'partners',
              title: item.personTitle ?? '',
              market: item.market.toUpperCase() !== 'NOT APPLICABLE' ? item.market : '',
              bio_type: item.bioType
            };

            // Text Bio
            if (item.bioType.toUpperCase() === 'TEXT') {
              partnersObj.bio_text = item.bioText;
            }

            // Video Bio
            if (item.bioType.toUpperCase() === 'VIDEO') {
              partnersObj.bio_video_link = item.bioVideo;
            }

            this.partnersData.push(partnersObj);
          });

          // Judges
          data.judges.forEach((item: any) => {
            const judgesObj: PersonListModel = {
              content_id: item.identifier,
              content_type: item.contentType,
              image_src: item.profileImage,
              name: item.title,
              type: 'judges',
              title: item.personTitle ?? '',
              market: item.market.toUpperCase() !== 'NOT APPLICABLE' ? item.market : '',
              bio_type: item.bioType
            };

            // Text
            if (item.bioType.toUpperCase() === 'TEXT') {
              judgesObj.bio_text = item.bioText;
            }

            // Video Bio
            if (item.bioType.toUpperCase() === 'VIDEO') {
              judgesObj.bio_video_link = item.bioVideo;
            }

            this.judgesData.push(judgesObj);
          });

          // Breadcrumb label
          this.breadcrumbsData[0].label = data.title;
          this.breadcrumbsData[0].link = updatedUrl;
        },
        error: err => {
          console.log(err);
        }
      });
    })
  }
}
