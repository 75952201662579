import { Component } from '@angular/core';
import { fadeInOut } from '../../animation';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-partners',
  standalone: true,
  imports: [],
  templateUrl: './email-partners.component.html',
  styleUrl: './email-partners.component.scss',
  animations: [fadeInOut]
})
export class EmailPartnersComponent {

  constructor(
    private titleService: Title
  ) {
    titleService.setTitle('Email Partners');
  }
}
