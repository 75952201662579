<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 [dataUrl]="imageBanner2DataUrl"></app-image-banner-2>
    </div>
  </section>

  <section class="content-heading mb-5 text-center">
    <div class="container">
      <h6 class="text-dark text-uppercase mb-3">{{currentPageData.headline}}</h6>
      <h1 class="headline mb-3">{{"EVENT_SCHEDULE.HEADER" | translate}}</h1>
      <div class="descriptions ">{{"EVENT_SCHEDULE.DESC" | translate}}</div>
    </div>
  </section>

  <section *ngIf="eventScheduleData.length > 0" class="content-body">
    <div class="container">
      <app-event-schedule-list [data]="eventScheduleData"></app-event-schedule-list>
    </div>

  </section>

</div>