<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <ng-container *ngIf="isUploadCompleted">
      <div class="modal-inner-header">
        <figure>
          <img *ngIf="upgradeType === 'SCA'" src="/assets/images/profile/img-banner-sca-upload.webp">
          <img *ngIf="upgradeType === 'STAR_PATH'" src="/assets/images/profile/img-banner-star-upload.webp">
        </figure>
      </div>
      <div class="modal-inner-body">

        <div class="inner-body-heading">
          <h2>Thank You!</h2>
        </div>

        <div class="inner-body-contents ">
          <p>We have received your submission. </p>
          <p>Our team will review it and notify you if there are any issues.</p>
        </div>

        <div class="inner-body-footer mt-3">
          <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="dismissModal()">OK</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isUploadCompleted">
      <div class="modal-inner-body" [ngClass]="{'theme-black': upgradeType === 'STAR_PATH'}">
        <div class="inner-body-heading">
          <h2 *ngIf="upgradeType === 'SCA'">Verify your SCA level 300 and Coffee Master identity:</h2>
          <h2 *ngIf="upgradeType === 'STAR_PATH'">Verify your Star Path Level</h2>
        </div>

        <div class="inner-body-contents ">
          <div class="mb-4">
            <p *ngIf="upgradeType === 'SCA'">Complete your verification by uploading 2 to 5 proof documents, such as:
              SCA
              certifcates, participate
              record</p>
            <p *ngIf="upgradeType === 'STAR_PATH'">Complete your verification by uploading the achievement card with
              store
              manager signature.</p>
          </div>


          <div class="upload-container">
            <div class="upload-preview-wrapper mb-3">
              <ul class="list-unstyled file-list">
                <li *ngFor="let file of files" class="file-list-item d-flex flex-wrap align-items-center mb-3">
                  <span class="icon-img">
                    <icon-document></icon-document>
                  </span>
                  <div class="file-name flex-1 px-3 px-lg-4 truncate">
                    {{ file.file.name }}
                  </div>
                  <!-- <div class="status pe-2">
                    <span class="icon-img" *ngIf="file.uploading">
                      <icon-spinner-green></icon-spinner-green>
                    </span>
                  </div> -->
                  <button type="button" class="btn btn-circle btn-remove-file" (click)="removeFile(file.id)">
                    <span class="icon-img">
                      <icon-xmark></icon-xmark>
                    </span>
                  </button>
                </li>
              </ul>
              <div *ngIf="hasReachedMaxFiles" class="max-files-notice text-center mt-2">
                <p class="text-black"><strong>You have selected {{maxFiles}} documents, reaching the maximum
                    limit.</strong></p>
                <p class="text-primary fs-small">If you need to replace a document, please remove an existing file
                  before
                  uploading a new
                  one.</p>
              </div>

              <div *ngIf="failedUpload" class="failed-upload-notice text-center mt-2">
                <p class="text-danger"><strong>There was an error uploading your documents.</strong></p>
                <p class="fs-small text-black">Please try again.</p>
              </div>
            </div>

            <div class="upload-input-wrapper">
              <input type="file" #fileInput hidden (change)="onFileSelected($event)" multiple
                accept="image/jpeg,image/png,application/pdf,image/heic">

              <button type="button" class="btn btn-white w-100 d-lg-none" (click)="fileInput.click()"
                (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                <span class="icon-img me-2">
                  <icon-upload></icon-upload>
                </span>
                <span class="flex-1">UPLOAD</span>

              </button>

              <div class="notice text-center d-lg-none mt-2">
                <small>PDF, PNG, JPG, HEIC are accepted. File Size must not exceed 10MB</small>
              </div>

              <button type="button" class="btn btn-file-upload-toggle d-none d-lg-block" (click)="fileInput.click()"
                (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">

                <span class="icon-img">
                  <icon-upload-cloud></icon-upload-cloud>
                </span>
                <div class="mt-2 heading">Drag & Drop Files Here, <a>or browse</a>
                </div>
                <div class="sub fs-small mt-1">PDF, PNG, JPG, HEIC are accepted. File Size must not exceed 10MB</div>
              </button>
            </div>


          </div>
        </div>

        <div class="inner-body-footer mt-3 text-center">
          <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()" [disabled]="isLoading">
            <span *ngIf="!isLoading">Submit</span>
            <span *ngIf="isLoading">
              <icon-spinner-green></icon-spinner-green>
            </span>
          </button>

          <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onStimulateFailedUpload()"
            [disabled]="isLoading">
            <span *ngIf="!isLoading">Submit (Stimulate Failed Upload)</span>
            <span *ngIf="isLoading">
              <icon-spinner-green></icon-spinner-green>
            </span>
          </button>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<div *ngIf="isLoading" class="spinner-container d-flex flex-column justify-content-center align-items-center">
  <span class="icon-img">
    <icon-spinner-green></icon-spinner-green>
  </span>
  <div class="spinner-text mt-4 text-primary text-center px-4">
    Uploading files... Please do not close or refresh this window.
  </div>
</div>