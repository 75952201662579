<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <figure class="thank-you-image mb-5">
      <img src="/assets/images/sign-up/img-coffee-cup-tick.webp">
    </figure>
    <h1 class="mb-4">
      {{"ONBOARDING.ACTIVATE_SUCCESS.HEADER" | translate}}
    </h1>
    <div class="mb-5">
      <p>
        {{"ONBOARDING.ACTIVATE_SUCCESS.DESC_1" | translate}}
      </p>
      <p>
        {{"ONBOARDING.ACTIVATE_SUCCESS.DESC_2" | translate}}
      </p>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto" [routerLink]="['/home']">
        {{"ONBOARDING.ACTIVATE_SUCCESS.NEXT" | translate}}
      </button>
    </div>

  </div>
</div>