<div class="component-grid-list-with-date-time">
  <div class="container">
    <div class="row">
      <div *ngFor="let item of data" class="col-12 col-md-6 col-lg-4">
        <div class="list-item">
          <figure [title]="item.headline">
            <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline)"
              [routerLink]="[item.link]">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
            </a>
          </figure>
          <div class="info">
            <div *ngIf="item.top_label" class="top-subtitle fs-smaller fw-bold text-uppercase mb-3">{{item.top_label}}
            </div>
            <h3 class="headline fw-normal text-dark pe-3" [innerHTML]="item.headline"></h3>

            <h5 *ngIf="item.start_date" [innerHTML]="getFormatDate(item.start_date)" class="text-dark">
            </h5>

            <div *ngIf="item.descriptions" class="descriptions fs-small pe-3 " [innerHTML]="item.descriptions"></div>

            <div *ngIf="item.unregister_button" class="call-to-action mt-4">
              <button class="btn btn-primary btn-sm w-100 w-md-auto" (click)="
                this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline, 'UNREGISTER'); 
                unregisterWorkshop(item.unregister_button.workshop_id)">
                {{"WORKSHOPS.UNENROLL_BUTTON" | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>