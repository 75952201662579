import { Component, inject, TemplateRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbOffcanvas, NgbOffcanvasRef, NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ArrowLeftIcon, ArrowRightIcon, XmarkIcon, SearchIcon, MenuBarsIcon, InstagramIcon, SCCLogo, PageviewIcon, ExclamationCircleSolidIcon, BapStarFilledIcon, BapStarIcon, NotificationIcon } from '../../icons';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { LanguageService } from '../../services/language.service';
import { AuthService } from '../../services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { environment } from '../../../environment/environment';
import { ConfigService } from '../../services/config.service';
import { RouterLink } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    NgbCollapseModule,
    NgbDropdownModule,
    ArrowLeftIcon,
    ArrowRightIcon,
    XmarkIcon,
    SearchIcon,
    MenuBarsIcon,
    InstagramIcon,
    SCCLogo,
    CommonModule,
    TranslateModule,
    RouterLink,
    PageviewIcon,
    ExclamationCircleSolidIcon,
    BapStarFilledIcon,
    BapStarIcon,
    NotificationIcon
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  private offcanvasService = inject(NgbOffcanvas);
  closeResult = '';
  languageShortForm: string = '';
  profilePicture!: string;
  partnerNickname!: string;
  partnerEmail!: string;
  partnerTier!: string;
  isSearchCollapsed = true;
  isInCanvasLanguageMenuVisible: boolean = false;
  currentEventUrl: string = environment.RBC2024;
  hasUnreadNotification: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private modalService: NgbModal,
    private languageService: LanguageService,
    private authService: AuthService,
    private accountService: AccountService,
    public configService: ConfigService,
    private contentService: ContentService,
    public analyticsService: AnalyticsService
  ) {
    const breakpointLG = this.breakpointObserver.observe('(min-width: 992px)');
    // Subscribe to window width changes
    breakpointLG.subscribe(result => {
      if (result.matches) {
        // If the screen width is greater than or equal to 992 pixels
        this.isInCanvasLanguageMenuVisible = false;
      }
    });
  }

  ngOnInit(): void {
    if (!this.configService.isPreview) {
      this.accountService.data$.subscribe(updatedValue => {
        this.hasUnreadNotification = updatedValue;
      });

      this.accountService.getMyProfile().subscribe({
        next: res => {
          this.contentService.partnerRank = res.tier;
          this.profilePicture = res.photo;
          this.partnerNickname = res.nick_name;
          this.partnerEmail = res.email;
          this.partnerTier = res.tier;
          localStorage.setItem('languageId', res.cms_language_id);
          this.languageShortForm = this.languageService.languageMap(res.cms_language_id);
        }
      })

      // check unread partner notification
      this.accountService.checkHasUnreadPartnerNotification().subscribe();
    }
  }

  handleClick(event: MouseEvent, languageId: string) {
    event.preventDefault(); // Prevent <a> redirect
    this.serverSwitchLanguage(languageId);
  }

  logout() {
    this.authService.userServerLogout();
  }

  open(offcanvasContent: TemplateRef<any>) {
    const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(offcanvasContent);

    // Subscribe to the 'hidden' event to perform actions when the offcanvas is hidden
    offcanvasRef.hidden.subscribe(() => {
      this.isInCanvasLanguageMenuVisible = false;
      // Perform actions when offcanvas is hidden
    });
  }

  closeOffcanvas() {
    this.offcanvasService.dismiss();
  }

  openSignInModal() {
    const modalRef = this.modalService.open(SignInModalComponent, {
      centered: true,
      windowClass: 'sign-in-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  async onSearchContent(event: Event) {
    if (!(event.target as HTMLInputElement).value) {
      return;
    }

    // await search term event analytics complete before navigate
    const promise = new Promise<void>(resolve => {
      this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, (event.target as HTMLInputElement).value, 'SEARCH');
      resolve();
    })

    promise.then(() => {
      console.log((event.target as HTMLInputElement).value);
      window.location.href = `/search-results?s=${(event.target as HTMLInputElement).value}`;
    })
  }

  serverSwitchLanguage(language: string): void {
    this.analyticsService.sendClickEventAnalytic('HEADER', undefined, undefined, undefined, 'LANGUAGE');
    this.languageService.serverSwitchLanguage(language);
  }

  toggleInCanvasLanguageMenu() {
    this.isInCanvasLanguageMenuVisible = !this.isInCanvasLanguageMenuVisible;
  }

  isBlackApron(): boolean {
    return ['SCA_CERTIFIED_COFFEE_MASTER', 'STAR_1', 'STAR_2', 'STAR_3'].includes(this.partnerTier);
  }

  showStarsAndPoints(): boolean {
    return this.partnerTier !== 'GREEN_APRON';
  }

  getStarCount(): number {
    switch (this.partnerTier) {
      case 'STAR_1': return 1;
      case 'STAR_2': return 2;
      case 'STAR_3': return 3;
      case 'SCA_CERTIFIED_COFFEE_MASTER': return 0;
      default: return 0;
    }
  }
}
