import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { EventScheduleListComponent } from "../../components/event-schedule-list/event-schedule-list.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';
import { ImageBanner2Model } from '../../models';

@Component({
  selector: 'app-event-schedule',
  standalone: true,
  templateUrl: './event-schedule.component.html',
  styleUrl: './event-schedule.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, EventScheduleListComponent, ImageBanner2Component, TranslateModule, CommonModule]
})
export class EventScheduleComponent implements OnInit {
  currentUrl!: string;
  eventContentType: string = 'SbuxEvent';
  eventScheduleContentType: string = 'SbuxEventSchedule';
  eventScheduleHomeBannerContentType: string = 'SbuxEventScheduleHomeBanner';
  eventScheduleData: any = [];
  eventScheduleHomeBannerData: ImageBanner2Model = {
    "content_id": "event-schedule-home-banner",
    "image_src": {
      "sm": "",
      "lg": ""
    }
  }
  parentIdentifier!: string;

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/events',
      label: 'Events'
    }
  ]
  currentPageData = {
    headline: '',
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    private configService: ConfigService
  ) {
    this.translation.get(['GENERAL.MENU.EVENTS', 'EVENT_SCHEDULE.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.EVENTS'];
        this.titleService.setTitle(res['EVENT_SCHEDULE.HEADER']);
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['pId']) {
        this.parentIdentifier = params['pId'];
      }

      // Construct return url
      this.currentUrl = this.router.url;
      const segmentUrl = this.currentUrl.split('/');
      segmentUrl.pop();
      const updatedUrl = segmentUrl.join('/');
      this.breadcrumbsData[0].link = updatedUrl;
    })

    // Learning Centre Home Banner POST Request
    this.contentService.getPartnerContent(this.eventScheduleHomeBannerContentType).subscribe({
      next: res => {
        this.eventScheduleHomeBannerData.image_src.lg = res[0].bannerImage;
        this.eventScheduleHomeBannerData.image_src.sm = res[0].bannerImageMobile;
      }
    })

    this.contentService.getPartnerContent(this.configService.isPreview ? this.eventScheduleContentType : this.eventContentType, this.configService.isPreview ? undefined : this.parentIdentifier).subscribe({
      next: res => {

        // only if got data
        if (res.length > 0) {

          // For preview page
          if (!this.configService.isPreview) {
            const data = res[0];
            // Breadcrumb label
            this.breadcrumbsData[0].label = data.title;

            // Current Page Data
            this.currentPageData = {
              headline: data.title,
            };
          }

          // set raw event schedule data
          this.eventScheduleData = this.configService.isPreview ? res : res[0].schedule;
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
