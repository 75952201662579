import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environment/environment";
import { createContentAnalyticBodyModel, createVideoAnalyticBodyModel } from "../models/analytics.model";
import { VideoPlayState } from "../enum/video-playState.enum";
import { LanguageService } from "./language.service";

@Injectable({
    providedIn: 'root',
})

export class AnalyticsService {
    constructor(
        private http: HttpClient,
        private languageService: LanguageService
    ) { }

    // Send analytic data for content.
    createContentAnalytic(contentId: string, contentType: string, contentUrl: string): void {
        const languageId: number = Number(this.languageService.getLanguage()) ?? 1;
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        const body: createContentAnalyticBodyModel = {
            content_id: contentId,
            content_type: contentType,
            language_id: languageId,
            url: contentUrl
        }

        this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.analyticsPath}/content`, body, { headers: reqHeader }).subscribe();
    }

    // Send analytic data for video.
    createVideoAnalytic(contentId: string, contentType: string, playEvent: VideoPlayState, videoUrl: string, videoCurrentTime: number, videoDuration: number, videoCurrentTimeLastUpdated: number): void {
        const languageId: number = Number(this.languageService.getLanguage()) ?? 1;
        let playState = '';
        switch (playEvent) {
            case VideoPlayState.UNSTARTED:
                playState = 'UNSTARTED'
                break;
            case VideoPlayState.ENDED:
                playState = 'ENDED'
                break;
            case VideoPlayState.PLAYING:
                playState = 'PLAYING'
                break;
            case VideoPlayState.PAUSED:
                playState = 'PAUSED'
                break;
            case VideoPlayState.BUFFERING:
                playState = 'BUFFERING'
                break;
            case VideoPlayState.CUED:
                playState = 'CUED'
                break;
            default:
                break;
        }

        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body: createVideoAnalyticBodyModel = {
            content_id: contentId,
            content_type: contentType,
            current_time: videoCurrentTime,
            current_time_last_updated: videoCurrentTimeLastUpdated,
            language_id: `${languageId}`,
            player_state: playState,
            video_url: videoUrl,
            duration: videoDuration
        }

        this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.analyticsPath}/video`, body, { headers: reqHeader }).subscribe();
    }
}