<div class="breadcrumbs">
  <div class="breadcrumbs-wrapper">
    <div *ngFor="let item of data" class="breadcrumb-item">
      <a *ngIf="item.link && item.type !== 'IS_BACK_LINK'" [routerLink]="[item.link]">{{item.label}}</a>
      <span *ngIf="!item.link && item.type !== 'IS_BACK_LINK'">{{item.label}}</span>
      <!-- <a *ngIf="item.type === 'IS_BACK_LINK'" [routerLink]="[item.link]" class="back-link"> -->
      <a *ngIf="item.type === 'IS_BACK_LINK'" [routerLink]="[item.link]" class="back-link">
        <span class="icon-img me-1">
          <icon-arrow-left></icon-arrow-left>
        </span>
        {{"GENERAL.BACK_TO_1" | translate}}{{item.label}}{{"GENERAL.BACK_TO_2" | translate}}</a>
    </div>
  </div>
</div>