<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="imageBanner1Data"></app-image-banner-1>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <div class="mb-4 text-center">
        <h2 class="text-dark">{{"BAP.HEADER" | translate}}</h2>
        <p>{{"BAP.DESC_1" | translate}} {{"BAP.DESC_2" | translate}} {{"BAP.DESC_3" | translate}}</p>
      </div>
      <app-grid-list-1 [data]="bapTopicData" [styleVariant]="3"></app-grid-list-1>
    </div>
  </section>

  <ng-container *ngIf="bapTopicSection.length > 0">
    <ng-container *ngFor="let item of bapTopicSection">
      <section *ngIf="item.topic.length > 0" class="topic mb-5">
        <div class="container">
          <h2 *ngIf="item.title" class="mb-3 pe-8 text-dark">{{item.title}}</h2>
          <app-list-carousel-1 [data]="item.topic" (onFlashCardClicked)="openBapFlashCardModal($event)"
            (onUnlockBAPContentClicked)="openUnlockBAPContentModal()"></app-list-carousel-1>
        </div>
      </section>
    </ng-container>
  </ng-container>

  <!-- <section class="topic mb-5">
    <div class="container">
      <h2 class="mb-3 pe-8 text-dark">{{"BAP.BREW_CRAFT" | translate}}</h2>
      <app-list-carousel-1 [data]="bapTopic1"
        (onFlashCardClicked)="openBapFlashCardModal($event)"></app-list-carousel-1>
    </div>
  </section>

  <section class="topic mb-5">
    <div class="container">
      <h2 class="mb-3 pe-8 text-dark">{{"BAP.ROAST_BLEND" | translate}}</h2>
      <app-list-carousel-1 [dataUrl]="bapTopic1"></app-list-carousel-1>
    </div>
  </section>

  <section class="topic mb-5">
    <div class="container">
      <h2 class="mb-3 pe-8 text-dark">{{"BAP.ORIGIN_ETHICAL_SOURCING" | translate}}</h2>
      <app-list-carousel-1 [dataUrl]="bapTopic1"></app-list-carousel-1>
    </div>
  </section>

  <section class="topic mb-5">
    <div class="container">
      <h2 class="mb-3 pe-8 text-dark">{{"BAP.COFFEE_LEADERSHIP" | translate}}</h2>
      <app-list-carousel-1 [dataUrl]="bapTopic1"></app-list-carousel-1>
    </div>
  </section> -->
</div>