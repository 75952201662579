<div class="like-component d-flex flex-wrap align-items-center">
  <button type="button" class="btn btn-ghost btn-like" [class.active]="isActive"
    (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', data.content_type, data.content_id, data.content_name, 'LIKE'); configService.isPreview ? isActive = !isActive : toggleLike($event)">
    <span class="icon-img me-1">
      <icon-like></icon-like>
    </span>
    <div class="like-count">
      <strong>{{likedCount}}</strong>
    </div>
  </button>
</div>