import { Component } from '@angular/core';

@Component({
  selector: 'icon-spinner-green',
  standalone: true,
  template: `
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 100 100" class="rotating-spinner">
      <style>
        .rotating-spinner {
          animation: rotate 1s linear infinite;
        }
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      </style>
      <defs>
        <mask id="mask" x="-14.98" y="-15" width="129.96" height="130" maskUnits="userSpaceOnUse">
          <g id="path-2-inside-1_2544_7209" data-name="path-2-inside-1 2544 7209">
            <path d="M99.986,50c0-10.547-3.336-20.824-9.532-29.36-6.195-8.536-14.932-14.893-24.96-18.163-10.027-3.269-20.833-3.283-30.868-.038-10.036,3.244-18.788,9.58-25.005,18.101C3.405,29.061.043,39.329.017,49.876c-.026,10.547,3.285,20.832,9.459,29.384,6.174,8.551,14.895,14.93,24.915,18.225,10.02,3.294,20.825,3.334,30.868.115l-4.275-13.337c-7.23,2.317-15.007,2.288-22.219-.083-7.212-2.371-13.49-6.963-17.934-13.118-4.444-6.156-6.827-13.559-6.808-21.151.019-7.592,2.439-14.983,6.913-21.117,4.475-6.133,10.775-10.694,17.999-13.029,7.224-2.335,15.001-2.326,22.219.028,7.218,2.353,13.507,6.929,17.966,13.074,4.459,6.144,6.861,13.542,6.861,21.134h14.005Z" fill="#fff" stroke-width="0"/>
          </g>
        </mask>
      </defs>
      <g mask="url(#mask)">
        <path d="M49.993,115c-6.829,0-13.655-1.089-20.287-3.27-13.109-4.31-24.309-12.501-32.387-23.694-8.079-11.183-12.33-24.392-12.298-38.194.034-13.804,4.354-26.991,12.489-38.14C5.644.55,16.883-7.584,30.014-11.829c13.138-4.247,27.006-4.227,40.132.051,13.111,4.271,24.328,12.435,32.446,23.604,8.108,11.176,12.389,24.372,12.389,38.174v14.995h-43.995v-14.995c0-4.457-1.381-8.718-4.003-12.323-2.616-3.61-6.243-6.246-10.48-7.629-4.227-1.384-8.708-1.389-12.955-.015-4.242,1.372-7.871,4-10.5,7.6-2.626,3.6-4.02,7.856-4.03,12.311-.012,4.464,1.362,8.73,3.969,12.342,2.607,3.607,6.226,6.253,10.463,7.649,4.237,1.396,8.713,1.406,12.955.049l14.278-4.584,13.433,41.896-14.278,4.579c-6.497,2.084-13.17,3.124-19.845,3.124Z" fill="#006241" stroke-width="0"/>
      </g>
      <g opacity=".6">
        <path d="M50,99.985C22.438,99.985.015,77.56.015,50S22.438.016,50,.016s49.985,22.422,49.985,49.985-22.425,49.985-49.985,49.985ZM50,15.011c-19.293,0-34.989,15.696-34.989,34.989s15.696,34.989,34.989,34.989,34.989-15.698,34.989-34.989S69.291,15.011,50,15.011Z" fill="#00754a" stroke-width="0"/>
      </g>
    </svg>
  `
})
export class SpinnerGreenIcon { }