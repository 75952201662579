<div class="component-list-carousel-1">
  <owl-carousel-o *ngIf="data.length>0" [options]="listCarouselOptions">
    <ng-container *ngFor="let item of data">
      <ng-template carouselSlide>
        <div class="list-item" [ngClass]="{
          'item-live': item.type?.toUpperCase() === 'LIVE',
          'item-upcoming': item.type?.toUpperCase() === 'UPCOMING',
          'item-regular': item.type?.toUpperCase() === 'REGULAR',
          'item-flashcard': item.type?.toUpperCase() === 'FLASHCARD',
          'item-quiz': item.type?.toUpperCase() === 'QUIZ',
          'item-questionnaire': item.type?.toUpperCase() === 'QUESTIONNAIRE'
        }">
          <figure [title]="item.headline">
            <a *ngIf="item.type?.toUpperCase() !== 'UPCOMING'"
              [href]=" item.type?.toUpperCase()==='FLASHCARD' ? '#' : item.link" (click)="item.disabled
                ? onDisabledContent($event)
                : this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.type?.toUpperCase(), item.headline);
                item.type?.toUpperCase()==='FLASHCARD' 
                  ? onFlashCard($event,item.identifier ?? '' ,item.level_requirement?? '' )
                  : item.type?.toUpperCase() === 'QUIZ'
                    ? onQuiz($event,item.identifier ?? '',item.level_requirement ?? '')
                    : item.type?.toUpperCase() === 'QUESTIONNAIRE'
                      ? onQuestionnaire($event,item.identifier ?? '',item.level_requirement ?? '')
                      : null ">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
              <div *ngIf="playButton" class="label label-play-icon">
                <span class="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                    <path
                      d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Z" />
                  </svg>
                </span>
              </div>

              <div *ngIf="item.mark_as_completed || (item.activity_details?.status === 'COMPLETED')"
                class="overlay-completed d-flex align-items-center justify-content-center">
                Completed
              </div>

            </a>
            <img *ngIf="item.type?.toUpperCase() === 'UPCOMING'" [src]="item.image_src" alt="{{item.headline}}-img">

            <!-- <div *ngIf="item.mark_as_completed" class="label label-completed">
              {{"GENERAL.CONTENT.COMPLETED" | translate}}
            </div> -->

            <div *ngIf="item.mark_as_completed || (item.activity_details?.status === 'COMPLETED')"
              class="flag-completed">
              <img src="assets/images/global/img-flag-completed.png" alt="Completed">
            </div>

            <div *ngIf="item.level_requirement && item.disabled"
              class="tier-overlay d-flex flex-wrap flex-column align-items-center justify-content-center p-3 text-center">
              <div class="fs-5 mb-3 px-3">
                {{"BAP.BAP_REQUIREMENT." + item.level_requirement | translate}}
              </div>
              <!-- <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                (click)="onUnlockBAPContentClick()">
                {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
              </button> -->
            </div>

            <div *ngIf="item.mark_as_new" class="label label-new">{{"GENERAL.CONTENT.NEW" | translate}}</div>

          </figure>
          <div class="info">
            <div *ngIf="item.top_label" class="label label-category top-label mb-3">{{item.top_label}}</div>
            <div class="title">
              <h4 class="headline fw-normal">{{item.headline}}</h4>
            </div>
            <div *ngIf="item.subheadline">
              <h6 class="sub-headline">{{item.subheadline}}</h6>
            </div>
            <div *ngIf="!item.subheadline && item.start_date">
              <h6 class="sub-headline" [innerHTML]="getFormatDate(item.start_date)"></h6>
            </div>
            <div *ngIf="item.descriptions" class="descriptions pe-2 ">
              {{item.descriptions}}
            </div>

            <div *ngIf="item.activity_details" class="activity-details d-flex align-items-center flex-wrap">
              <div *ngIf="item.activity_details.code" class="code label label-bap-topic-item label-bap-topic-item-code">
                {{item.activity_details.code}}
              </div>
              <div *ngIf="item.activity_details.points"
                class="points label label-bap-topic-item label-bap-topic-item-points">
                {{item.activity_details.points}}
              </div>
              <div *ngIf="item.activity_details.status"
                class="status label label-bap-topic-item label-bap-topic-item-status" [ngClass]="{
                  'status-completed': item.activity_details.status === 'COMPLETED',
                  'status-in-progress': item.activity_details.status === 'IN_PROGRESS'
                }">
                {{ item.activity_details.status === 'COMPLETED' ? 'Completed' : 
                   item.activity_details.status === 'IN_PROGRESS' ? 'In Progress' : 
                   item.activity_details.status }}
              </div>

              <div *ngIf="item.activity_details.duration || item.activity_details.is_mandatory"
                class="activity-labels d-flex align-items-center flex-100 gap-2 mt-2">
                <div *ngIf="item.activity_details.duration" class="label label-duration">
                  {{item.activity_details.duration}}
                </div>
                <div *ngIf="item.activity_details.is_mandatory" class="label label-mandatory">
                  Mandatory
                </div>
              </div>
            </div>

            <div *ngIf="item.curated_data" class="curated-data d-inline-flex">
              <div *ngIf="item.curated_data.label" class="data-label label label-curated-data">
                {{item.curated_data.label}}
              </div>
              <div *ngIf="item.curated_data.duration" class="time label label-curated-data">
                {{item.curated_data.duration}}
              </div>
            </div>

            <div class="call-to-action"
              *ngIf="(item.type?.toUpperCase() === 'REGULAR' && item.button) || item.type?.toUpperCase() === 'LIVE' || item.type?.toUpperCase() === 'UPCOMING'">
              <a *ngIf="item.type?.toUpperCase() === 'LIVE'" [href]="item.button?.link"
                class="btn btn-primary-live w-100 w-md-auto btn-sm">
                {{"GENERAL.BUTTON.JOIN_NOW" | translate}}
              </a>

              <a *ngIf="item.type?.toUpperCase() === 'REGULAR'" [href]="item.button?.link"
                class="btn btn-primary-recap w-100 w-md-auto btn-sm">{{item.button?.label ?? "EVENTS.BUTTON.WATCH_RECAPS" | translate}}</a>

              <span *ngIf="item.type?.toUpperCase() === 'UPCOMING'" ngbDropdown #addToCalendarDropDown="ngbDropdown"
                placement="bottom-start" class="custom-dropdown add-to-calendar-dropdown">
                <button type="button" class="btn btn-primary btn-add-to-calendar w-100 w-md-auto btn-sm"
                  id="addToCalendarDropDown" ngbDropdownToggle>{{"GENERAL.BUTTON.ADD_TO_CALENDAR" | translate}}</button>
                <div ngbDropdownMenu aria-labelledby="addToCalendarDropDown">
                  <a ngbDropdownItem (click)="onCalendarClick($event,'apple',item.content_id)">
                    <span class="icon-img">
                      <icon-apple></icon-apple>
                    </span>
                    Apple Calendar
                  </a>
                  <a ngbDropdownItem (click)="onCalendarClick($event,'google',item.content_id)">
                    <span class="icon-img">
                      <icon-google></icon-google>
                    </span>
                    Google Calendar
                  </a>
                  <!-- <a ngbDropdownItem (click)="onCalendarClick($event,'ical',item.content_id)">
                    <span class="icon-img">
                      <icon-calendar></icon-calendar>
                    </span>
                    iCal</a> -->
                  <a ngbDropdownItem (click)="onCalendarClick($event,'microsoft',item.content_id)">
                    <span class="icon-img">
                      <icon-microsoft-365></icon-microsoft-365>
                    </span>
                    Microsoft Outlook Calendar</a>
                  <a ngbDropdownItem (click)="onCalendarClick($event,'yahoo',item.content_id)">
                    <span class="icon-img">
                      <icon-yahoo></icon-yahoo>
                    </span>
                    Yahoo Calendar</a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </ng-template>

    </ng-container>
  </owl-carousel-o>

</div>