import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerIcon } from '../../../icons';
import { PublicService } from '../../../services/public.service';
import { AuthService } from '../../../services/auth.service';
import { ContentService } from '../../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, forkJoin, take } from 'rxjs';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-registration-form-1',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SpinnerIcon, TranslateModule],
  templateUrl: './registration-form-1.component.html',
  styleUrl: './registration-form-1.component.scss',
  animations: [fadeInOut]
})
export class RegistrationForm1Component implements OnInit {
  @Input() input?: { market: string, partnerId: string, email: string };
  @Output() onCompleted = new EventEmitter<{ market: string, partnerId: string, email: string }>();
  @Output() onCancelled = new EventEmitter<void>();
  @Output() selectedMarket = new EventEmitter<string>();

  regForm1!: FormGroup;
  markets!: { label: string, code: string }[];
  // isEmail: boolean = true;
  isLoading: boolean = true;
  isSubmitting: boolean = false;
  emailPrevValue: string = '';

  serverPartnerIdError: boolean = true;
  serverPartnerIdErrorMessage!: string;
  serverEmailError: boolean = false;
  serverEmailErrorMessage!: string;
  frontendEmailError?: boolean = true;
  frontendEmailErrorMessage!: string;

  constructor(
    private publicService: PublicService,
    private authService: AuthService,
    private contentService: ContentService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.regForm1 = new FormGroup({
      market: new FormControl<string>(this.input?.market ?? 'au', [Validators.required]),
      partnerId: new FormControl<string>(this.input?.partnerId ?? '', [Validators.required]),
      email: new FormControl<string>(this.input?.email ?? '', [Validators.required])
    });

    this.regForm1.controls['email'].valueChanges
      .pipe(
        debounceTime(500),
        // take(1)
      )
      .subscribe(
        (val: string) => {
          // same value, do not trigger event
          if (val === this.emailPrevValue) {
            return;
          }

          // different value, trigger event
          this.regForm1.controls['email'].patchValue(val.replace(/\s/g, "").toLowerCase(), { emitEvent: false });
          this.emailPrevValue = this.regForm1.controls['email'].value;
        }
      )

    this.publicService.getMarket().subscribe({
      next: res => {
        this.markets = res;
        this.markets.sort((a, b) => a.label.localeCompare(b.label));
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.isLoading = false;
      }
    })
  }

  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.regForm1.disable();
    this.isSubmitting = true;

    // ========================= Validate Email (Frontend) ========================= 
    let isEmail = this.authService.validateEmail(this.regForm1.controls['email'].value)

    if (!isEmail) {
      this.frontendEmailError = true;
      this.frontendEmailErrorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.isSubmitting = false;
      this.regForm1.enable();
      return;
    }

    // ========================= Validation Requests ========================= 
    const validatePartnerIdRequest = this.authService.validatePartnerId(this.regForm1.controls['partnerId'].value, this.regForm1.controls['market'].value);
    const validateEmailExistedRequest = this.authService.checkEmailExist(this.regForm1.controls['email'].value);


    forkJoin({ validatePartnerIdRequest, validateEmailExistedRequest }).subscribe({
      next: ({ validatePartnerIdRequest, validateEmailExistedRequest }) => {

        // Partner ID validation fail
        if (!validatePartnerIdRequest.data) {
          this.serverPartnerIdErrorMessage = this.errorService.getServerErrorMapping(validatePartnerIdRequest.error_code);
          this.serverPartnerIdError = true;
        }

        // Email validation fail
        if (!validateEmailExistedRequest.data) {
          this.serverEmailErrorMessage = this.errorService.getServerErrorMapping(validateEmailExistedRequest.error_code);
          this.serverEmailError = true;
        }

        if (!validatePartnerIdRequest.data || !validateEmailExistedRequest.data) {
          return;
        } else {
          // Get selected market label
          const selectedObj = this.markets.findIndex((lang: { label: string, code: string }) => lang.code === this.regForm1.controls['market'].value);
          this.selectedMarket.emit(this.markets[selectedObj]['label']);

          this.onCompleted.emit({
            market: this.regForm1.controls['market'].value,
            partnerId: this.regForm1.controls['partnerId'].value,
            email: this.regForm1.controls['email'].value
          });
        }
      },
      complete: () => {
        this.isSubmitting = false;
        this.regForm1.enable();
      }
    })
  }
}
