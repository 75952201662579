
import { Component } from '@angular/core';

@Component({
  selector: 'icon-caret-left',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13.995">
  <path id="Shape" d="M7.712,7.69,1.678,13.708a.981.981,0,0,1-1.39-1.386L5.627,7,.288,1.673A.981.981,0,0,1,1.678.287L7.712,6.3a.978.978,0,0,1,0,1.386Z" transform="translate(8 13.995) rotate(180)"/>
</svg>

  `,
  styles: [],
})
export class CaretLeftIcon { }