import { Component, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { ImageLandscapeIcon } from "../../../icons/icon-image-landscape";
import { XmarkIcon } from "../../../icons/icon-xmark";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-profile-picture',
  standalone: true,
  templateUrl: './upload-profile-picture.component.html',
  styleUrl: './upload-profile-picture.component.scss',
  animations: [fadeInOut],
  imports: [ImageLandscapeIcon, CommonModule, XmarkIcon, TranslateModule]
})
export class UploadProfilePictureComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() onCompleted = new EventEmitter<void>();
  @Output() onCancelled = new EventEmitter<void>();
  @Output() selectedProfileImage = new EventEmitter<string>();
  selectedProfileImageUrl!: string;
  uploaded: boolean = false;

  onBack() {
    this.onCancelled.emit();
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }

  onDrop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.readFile(file);
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.readFile(file);
  }

  onSubmit() {
    this.selectedProfileImage.emit(this.selectedProfileImageUrl);
    this.onCompleted.emit();
  }

  readFile(file: any) {
    if (file.size > 10 * 1024 * 1024) { // 10MB
      alert('File is too large!');
      return;
    }

    if (file) {
      this.uploaded = true;
    }
    const reader = new FileReader();
    reader.onload = e => {
      this.selectedProfileImageUrl = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  removeImage() {
    this.selectedProfileImageUrl = '';
    this.fileInput.nativeElement.value = '';
    this.uploaded = false;
  }
}
