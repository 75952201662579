import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridList2Model } from '../models';

@Injectable({
  providedIn: 'any'
})

export class GridList2Service {
  //private dataUrl = '/assets/data/grid-list-2.json';

  constructor(private http: HttpClient) { }

  getGridList2Data(dataUrl: string): Observable<GridList2Model[]> {
    return this.http.get<GridList2Model[]>(dataUrl);
  }
}
