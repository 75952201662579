<div [@fadeInOut] class="inner-body">
  <section *ngIf="noticeData.length > 0" class="ticker-section mb-4">
    <div class="container">
      <div class="ticker-wrapper">
        <div class="ticker">
          <div *ngFor="let item of noticeData"
            (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.title);"
            class="ticker-item" [innerHTML]="item.title"></div>
        </div>
      </div>
    </div>
  </section>


  <section *ngIf="bannerData.length > 0" class="home-hero-banner mb-5">
    <div class="container">
      <app-banner-carousel-1 [data]="bannerData"></app-banner-carousel-1>
    </div>
  </section>

  <section *ngIf="featuredMenuData.length > 0" class="home-main-category mb-2">
    <div class="container">
      <h2 class="mb-4">{{"HOME.SHORTCUT.HEADER" | translate}}</h2>
      <app-grid-list-1 [data]="featuredMenuData"></app-grid-list-1>
    </div>
  </section>

  <section *ngIf="recommendedData.length > 0" class="home-recommended mb-2">
    <div class="container">
      <h2 class="mb-4">{{"HOME.RECOMMENDED" | translate}}</h2>
      <app-grid-list-2 [data]="recommendedData"></app-grid-list-2>
    </div>
  </section>

  <section *ngIf="specialTopicData.length > 0" class="home-special-topic mb-6">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"HOME.SPECIAL_TOPIC" | translate}}</h2>
      <app-banner-carousel-2 [data]="specialTopicData"></app-banner-carousel-2>
    </div>
  </section>

  <section *ngIf="exploreData.length > 0" class="home-explore mb-2">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"HOME.EXPLORE.HEADER" | translate}}</h2>
      <app-list-carousel-1 [data]="exploreData"></app-list-carousel-1>
    </div>
  </section>
</div>