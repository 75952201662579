import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";

@Component({
  selector: 'app-claim-title-path-modal',
  standalone: true,
  imports: [XmarkIcon],
  templateUrl: './claim-title-path-modal.component.html',
  styleUrl: './claim-title-path-modal.component.scss'
})
export class ClaimTitlePathModalComponent {
  @Input() modal: any;

  dismissModal() {
    this.modal.dismiss();
  }
}
