import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridList4Model } from '../../models';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-grid-list-4',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './grid-list-4.component.html',
  styleUrl: './grid-list-4.component.scss',
})
export class GridList4Component {
  @Input({ required: true }) data!: GridList4Model[];
  currentUrl!: string;

  constructor(
    private router: Router
  ) { }
  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }
}
