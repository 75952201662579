import { Component, Input } from '@angular/core';
import { ImageBanner2Service } from '../../services';
import { CommonModule } from '@angular/common';
import { ImageBanner2Model } from '../../models';

@Component({
  selector: 'app-image-banner-2',
  standalone: true,
  templateUrl: './image-banner-2.component.html',
  styleUrl: './image-banner-2.component.scss',
  imports: [CommonModule],
  providers: [ImageBanner2Service]
})

export class ImageBanner2Component {
  @Input({ required: true }) data!: ImageBanner2Model;

  constructor(
    private imageBanner2Service: ImageBanner2Service
  ) { }
}
