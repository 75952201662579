import { Component, OnInit } from '@angular/core';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { fadeInOut } from '../../animation';
import { ListFiltersComponent } from "../../components/list-filters/list-filters.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GridList3Model } from '../../models';
import { ContentService } from '../../services/content.service';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-learning-centre',
  standalone: true,
  templateUrl: './learning-centre.component.html',
  styleUrl: './learning-centre.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner2Component, ListFiltersComponent, GridList3Component, TranslateModule, CommonModule]
})
export class LearningCentreComponent implements OnInit {
  filteredTopicData: GridList3Model[] = [];
  filterField: { [key: string]: string } = { learning_category: "category" };
  filterTopicContentType = 'SbuxLearningFilter';
  retrievedFilter: boolean = false;
  learningTopicContentType = 'SbuxLearningTopic';
  learningTopicData: GridList3Model[] = [];
  learningTopicDataUnitTranslate: string = 'lesson';

  gridList3DataUrl = '/assets/data/learning-centre-topic-list.json';
  timeShortform: { hour: string, minute: string } = {
    hour: 'h',
    minute: 'm'
  }

  imageBanner2DataUrl = '/assets/data/learning-centre-image-banner-2.json';
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Learning Centre"
    }
  ];

  listFiltersData = {
    "select_menu_group": [
      {
        "name": "learning_category",
        "id": "learning_category",
        "options": [
          {
            "label": "All",
            "value": "",
            "is_selected": true
          }
        ]
      }
    ]
  };

  constructor(
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get([
      'GENERAL.MENU.HOME',
      'HOME.SHORTCUT.LEARNINGS',
      'GENERAL.ALL',
      'GENERAL.TIME.HOUR_SHORTFORM',
      'GENERAL.TIME.MINUTE_SHORTFORM',
      'HOME.EXPLORE.COUNT_LESSON',
    ]).subscribe({
      next: res => {
        this.titleService.setTitle(res['HOME.SHORTCUT.LEARNINGS']);

        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.LEARNINGS'];
        this.listFiltersData.select_menu_group[0].options[0].label = res['GENERAL.ALL'];

        this.timeShortform.hour = res['GENERAL.TIME.HOUR_SHORTFORM'];
        this.timeShortform.minute = res['GENERAL.TIME.MINUTE_SHORTFORM'];

        this.learningTopicDataUnitTranslate = res['HOME.EXPLORE.COUNT_LESSON'];
      }
    })

    // Learning Topic POST Request
    this.contentService.getPartnerContent(this.learningTopicContentType, '', undefined, 2).subscribe({
      next: res => {
        res.forEach((item: any) => {
          let categoryList: string[] = [];
          let durationCount: number = 0;
          item.lessonList.forEach((childItem: any) => {
            // For Category Filter
            childItem.category.forEach((val: any) => {
              if (!categoryList.includes(val)) {
                categoryList.push(val);
              }
            });

            // For Content Total Duration
            const typeDuration = childItem.type.toUpperCase() === 'ARTICLE' ? childItem.contentDuration : childItem.videoDuration;

            durationCount += Number.parseInt(typeDuration ?? 0);
          });

          this.learningTopicData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            link: `/learn/${item.identifier}`, // Manual for now
            headline: item.title,
            descriptions: item.descriptions,
            curated_data: {
              label: `${item.lessonList.length} ${this.learningTopicDataUnitTranslate}${this.languageService.getLanguage() === '1' && item.lessonList.length > 1 ? "s" : ""}`,
              duration: this.contentService.formatSecToHM(durationCount, this.timeShortform)
            },
            mark_as_new: this.contentService.calcIsNewContent(item.publishStartDate),
            category: categoryList
            // is_video: false // Manual for now. Ask Bilal
          });
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Learning Topic Filter Data Request
    this.contentService.getPartnerContent(this.filterTopicContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.listFiltersData.select_menu_group[0].options.push({
            label: item.title,
            value: item.identifier,
            is_selected: false
          })
        });
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.retrievedFilter = true;
      }
    });
  }


  filterTopicData(data: { name: string, value: string }[]) {
    this.filteredTopicData = this.contentService.filterContent(data, this.learningTopicData, this.filterField);
  }
}                   