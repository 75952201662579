import { Component } from '@angular/core';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { fadeInOut } from '../../animation';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bap-static',
  standalone: true,
  templateUrl: './bap-static.component.html',
  styleUrl: './bap-static.component.scss',
  imports: [BreadcrumbsComponent, ImageBanner1Component, TranslateModule],
  animations: [fadeInOut]
})
export class BapStaticComponent {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Black Apron Program"
    }
  ]

  imageBanner1DataUrl = {
    "content_id": "content-id-1",
    "image_src": {
      "sm": "/assets/images/bap/img-banner-bap-sm.webp",
      "lg": "/assets/images/bap/img-banner-bap-lg.webp"
    }
  }

  constructor(
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'GENERAL.FOOTER.BAP']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['GENERAL.FOOTER.BAP'];

        this.titleService.setTitle(res['GENERAL.FOOTER.BAP']);
      }
    })
  }
}
