<div class="component-list-carousel-2">
  <owl-carousel-o *ngIf="data" [options]="listCarouselOptions">
    <ng-container *ngFor="let item of data">
      <ng-template carouselSlide>
        <div class="list-item">
          <figure [title]="item.headline">
            <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline)"
              [routerLink]="[item.link]">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
            </a>
            <div *ngIf="item.mark_as_completed" class="label label-completed">
              {{"GENERAL.CONTENT.COMPLETED" | translate}}
            </div>
          </figure>
          <div class="info">
            <div class="title">
              <h4 class="headline text-uppercase">{{item.headline}}</h4>
            </div>
            <h5 *ngIf="item.start_date" class="sub-headline" [innerHTML]="getFormatDate(item.start_date)"></h5>
            <div *ngIf="item.descriptions" class="descriptions pe-2 ">
              {{item.descriptions}}
            </div>
          </div>
        </div>
      </ng-template>

    </ng-container>
  </owl-carousel-o>

</div>