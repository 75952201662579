import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-unlock-bap-content-modal',
  standalone: true,
  imports: [XmarkIcon, TranslateModule],
  templateUrl: './unlock-bap-content-modal.component.html',
  styleUrl: './unlock-bap-content-modal.component.scss'
})
export class UnlockBapContentModalComponent {
  @Input() modal: any;

  dismissModal() {
    this.modal.dismiss();
  }

  openRegisterForm(): void {
    this.modal.dismiss();
    const url = 'https://forms.gle/QvTvDPjjz1mXC5157';
    window.open(url, '_blank');
  }
}
