import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class NgbModalFocusService {
  private lastFocusedElement: HTMLElement | null = null;
  private observer: MutationObserver | null = null;

  constructor(private modalService: NgbModal) {
    this.overrideModalOpen();
  }

  /** Override NgbModal.open() globally */
  private overrideModalOpen() {
    const originalOpen = this.modalService.open.bind(this.modalService);

    this.modalService.open = (content: any, options: any = {}) => {
      // Save focus before opening modal
      this.lastFocusedElement = document.activeElement as HTMLElement;

      // Ensure modal is focusable
      options.ariaLabelledBy = options.ariaLabelledBy || 'modal-title';
      options.beforeDismiss = () => {
        this.restoreFocus();
        return true; // Allow modal to close
      };

      const modalRef = originalOpen(content, options);

      setTimeout(() => {
        this.enforceModalFocus();
      }, 50); // Small delay ensures modal is fully rendered

      return modalRef;
    };
  }

  /** Ensure modal is focused and prevent hidden aria issues */
  private enforceModalFocus() {
    setTimeout(() => {
      const modalElement = document.querySelector('.modal.show') as HTMLElement;
      if (modalElement) {
        modalElement.setAttribute('tabindex', '-1'); // Ensure focusability
        modalElement.focus(); // Move focus to modal

        // Watch for unwanted aria-hidden changes
        this.observeAriaHidden();
      }
    }, 100); // Extra delay ensures modal is fully loaded
  }

  /** Detect and fix incorrect aria-hidden behavior */
  private observeAriaHidden() {
    const appRoot = document.querySelector('app-root');
    if (!appRoot) return;

    // Stop previous observer if running
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.attributeName === 'aria-hidden' &&
          appRoot.getAttribute('aria-hidden') === 'true'
        ) {
          console.warn('Fixing aria-hidden issue on <app-root>');
          appRoot.removeAttribute('aria-hidden');
        }
      });
    });

    // Start observing changes to aria-hidden
    this.observer.observe(appRoot, { attributes: true });
  }

  /** Restore focus when the modal closes */
  private restoreFocus() {
    setTimeout(() => {
      if (this.lastFocusedElement) {
        this.lastFocusedElement.focus();
      }
      if (this.observer) {
        this.observer.disconnect(); // Stop observing when modal closes
      }
    }, 50);
  }
}