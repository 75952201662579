import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services/auth.service';
import { SpinnerIcon } from '../../../icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../../services/account.service';
import { LanguageService } from '../../../services/language.service';
import { debounceTime } from 'rxjs';
import { ErrorService } from '../../../services/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SpinnerIcon, TranslateModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
  animations: [fadeInOut]
})

export class SignInComponent implements OnInit {
  @Output() onSignUp = new EventEmitter<void>();
  @Output() onForgetPassword = new EventEmitter<void>();
  @Output() onCompleted = new EventEmitter<void>();

  loginForm!: FormGroup;
  isSubmitting: boolean = false;
  emailPrevValue: string = '';

  frontendEmailError: boolean = false;
  frontendEmailErrorMessage!: string;
  serverPasswordError: boolean = false;
  serverPasswordErrorMessage!: string;


  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })


    this.loginForm.controls['email'].valueChanges
      .pipe(
        debounceTime(500),
        // take(1)
      )
      .subscribe(
        (val: string) => {
          // same value, do not trigger event
          if (val === this.emailPrevValue) {
            return;
          }

          // different value, trigger event
          this.loginForm.controls['email'].patchValue(val.replace(/\s/g, "").toLowerCase(), { emitEvent: false });
          this.emailPrevValue = this.loginForm.controls['email'].value;
        }
      )
  }

  onForgetPasswordAction() {
    this.onForgetPassword.emit();
  }

  onSignUpAction() {
    this.onSignUp.emit();
  }

  onSubmit() {
    this.loginForm.disable();
    this.isSubmitting = true;

    // ========================= Validate Email ========================= 
    let isEmail = this.authService.validateEmail(this.loginForm.controls['email'].value)

    if (!isEmail) {
      this.frontendEmailError = true;
      this.frontendEmailErrorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.isSubmitting = false;
      this.loginForm.enable();
      return;
    }

    this.onLogin();
  }

  onLogin(): void {
    this.authService.userAuth(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value).subscribe({
      next: res => {
        if (res && res.error) {
          console.log(res);

          this.serverPasswordError = true;
          this.isSubmitting = false;
          this.loginForm.enable();
          return;
        }

        // Set localstorage
        localStorage.setItem('loggedIn', 'true');

        // Get user prefer language & profile picture
        this.accountService.getMyProfile().subscribe({
          next: profileRes => {

            // Set language
            const preferredLanguage = profileRes.cms_language_id;
            this.languageService.switchLanguage(preferredLanguage)
          },
          complete: () => {
            this.onCompleted.emit();
          }
        });

      },
      error: (err: HttpErrorResponse) => {
        console.log(`[SignInComponent] error`, err);
        var errCode = "0";
        if (err.error?.error_description) {
          errCode = err.error.error_description.slice(0, 4);
        } else {
          if (err.headers?.has("WWW-Authenticate")) {
            const authHeader = err.headers.get('WWW-Authenticate');
            const errorInfo: any = {};
            const regex = /(\w+)="([^"]+)"/g;
            let match;
            while ((match = regex.exec(authHeader!)) !== null) {
              errorInfo[match[1]] = match[2];
            }
            errCode = errorInfo.error_description.slice(0, 4);
          }
        }

        this.serverPasswordErrorMessage = this.errorService.getServerErrorMapping(errCode) ?? 'Unable to reach the server, please try again.';
        this.serverPasswordError = true;

        this.isSubmitting = false;
        this.loginForm.enable();
      }
    })
  }
}

