import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { SingleBannerComponent } from "../../components/single-banner/single-banner.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { GridList1Component } from "../../components/grid-list-1/grid-list-1.component";
import { ListCarousel1Component } from "../../components/list-carousel-1/list-carousel-1.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { EventModel } from '../../models/event.model';
import { GridList1Model, ImageBanner1Model, ListCarousel1Model } from '../../models';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-event-activity',
  standalone: true,
  templateUrl: './event.component.html',
  styleUrl: './event.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, SingleBannerComponent, ImageBanner1Component, GridList1Component, ListCarousel1Component, TranslateModule]
})
export class EventComponent implements OnInit {
  currentUrl!: string;
  eventIdentifier!: string;
  eventContentType: string = 'SbuxEvent';
  eventData: EventModel = {
    content_id: '',
    title: '',
    descriptions: ''
  };

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/events',
      label: 'Events'
    }
  ]

  eventBannerData: ImageBanner1Model = {
    content_id: '',
    image_src: {
      sm: '',
      lg: ''
    }
  };


  eventActivityData: GridList1Model[] = [
    { // Default
      "content_id": "event-schedule-content-id",
      "image_src": "/assets/images/events/img-event-schedule.webp",
      "link": `/event-schedule`,
      "headline": 'Event Schedule'
    }
  ];
  eventHighlightActivitiesData: ListCarousel1Model[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.translation.get(['GENERAL.MENU.EVENTS', 'EVENT_SCHEDULE.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.EVENTS'];
        this.eventActivityData[0].headline = res['EVENT_SCHEDULE.HEADER'];
      }
    })

    this.route.params.subscribe(params => {
      this.eventIdentifier = params['pId'];
      this.currentUrl = this.router.url;

      // Update breadcrumb link
      this.eventActivityData[0].link = `${this.currentUrl}/event-schedule`;

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.eventIdentifier, this.eventContentType, this.router.url);
      }
    })

    this.contentService.getPartnerContent(this.eventContentType, this.eventIdentifier).subscribe({
      next: res => {
        if (res.length === 0) {
          this.router.navigate(['/404']);
        }

        const data = res[0];

        // Event Banner
        this.eventBannerData.content_id = data.identifier + `-img`;
        this.eventBannerData.image_src.lg = data.bannerImage;
        this.eventBannerData.image_src.sm = data.bannerImageMobile;

        // Event title & description
        this.eventData.title = data.title;
        this.eventData.descriptions = data.descriptions;
        this.titleService.setTitle(data.title);

        // Even Activity
        data.activity.forEach((item: any) => {
          this.eventActivityData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            link: `${this.currentUrl}/activities/${item.identifier}`,
            headline: item.title
          })
        });

        // Highlight Activities
        data.videoHighlight.forEach((item: any) => {
          const timestampNow = Math.floor(Date.now() / 1000);
          const timestampStart = new Date(item.startDateTime).getTime() / 1000;
          const timestampEnd = new Date(item.endDateTime).getTime() / 1000;
          let eventType = "";

          if (timestampNow > timestampStart && timestampNow < timestampEnd) {
            eventType = "Live"
          };
          if (timestampNow < timestampStart) {
            eventType = "Upcoming"
          };

          if (timestampNow > timestampEnd) {
            eventType = "Regular"
          };

          var dataObject: ListCarousel1Model = {
            content_id: item.identifier,
            image_src: item.coverImage,
            headline: item.title,
            type: eventType,
            descriptions: item.coverDescriptions,
            start_date: item.startDateTime,
            end_date: item.endDateTime,
          }

          if (eventType.toUpperCase() === "REGULAR" || eventType.toUpperCase() === "LIVE") {
            dataObject.link = `${this.currentUrl}/videos/${item.identifier}`
            dataObject.button = {
              link: `${this.currentUrl}/videos/${item.identifier}`,
            }
          }

          this.eventHighlightActivitiesData.push(dataObject)
        });
      },
      error: err => {
        console.log(err);
      }
    });


  }

}
