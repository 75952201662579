
import { Component } from '@angular/core';

@Component({
  selector: 'icon-pageview',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path d="M16,72V456H496V72ZM464,424H48V104H464Z"/>
  <path d="M239.861,335.723a95.321,95.321,0,0,0,53.625-16.439l68.029,68.029,22.627-22.626-67.5-67.5a95.831,95.831,0,1,0-76.785,38.533Zm0-159.723A63.862,63.862,0,1,1,176,239.861,63.933,63.933,0,0,1,239.861,176Z"/>
</svg>
  `,
  styles: [],
})
export class PageviewIcon { }