<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 *ngIf="imageBanner2Data" [dataUrl]="imageBanner2Data"></app-image-banner-2>
    </div>
  </section>

  <section class="mb-5">
    <div class="container mw-lg">
      <div class="mb-4 text-center">
        <h6 class="text-dark text-uppercase">{{"BAP.HEADER" | translate}}</h6>
        <h2 *ngIf="bapTopicTitle">{{bapTopicTitle}}</h2>
        <p *ngIf="bapTopicDescription">{{bapTopicDescription}}
      </div>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <app-grid-list-3 [data]="gridList3Data" (onFlashCardClicked)="openBapFlashCardModal($event)"
        (onUnlockBAPContentClicked)="openUnlockBAPContentModal()"></app-grid-list-3>
    </div>
  </section>
</div>