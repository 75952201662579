import { Component, Input, OnInit } from '@angular/core';
import { EventScheduleListModel } from '../../models';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon } from "../../icons";
import { ContentService } from '../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModel } from '../../models/calendar.model';

// Create type references to 'event' field within EventScheduleListModel
type eventScheduleListModelEventType = EventScheduleListModel['event'][number];

@Component({
  selector: 'app-event-schedule-list',
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, TranslateModule],
  templateUrl: './event-schedule-list.component.html',
  styleUrl: './event-schedule-list.component.scss'
})
export class EventScheduleListComponent implements OnInit {
  @Input({ required: true }) data!: any;
  @Input() isFullWidth!: boolean;

  private dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  private monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  private monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  eventScheduleByMonth: EventScheduleListModel[] = [];

  eventScheduleContentType: string = 'SbuxEventSchedule';
  calendarUrl!: string;
  calendarField: CalendarModel = {
    title: '',
    startDateTime: '',
    endDateTime: '',
    location: ''
  }

  constructor(
    private contentService: ContentService,
  ) { }

  ngOnInit(): void {
    // Sort by Publish Start Date
    this.data.sort((a: any, b: any) => {
      // Compare start dates
      if (a.startDateTime < b.startDateTime) {
        return -1; // a should come before b
      } else if (a.startDateTime > b.startDateTime) {
        return 1; // a should come after b
      } else {
        return 0; // start dates are equal
      }
    });

    // For Schedule
    this.data.forEach((item: any) => {
      const startTime = new Date(item.startDateTime).getHours().toString().padStart(2, '0') + ":" + new Date(item.startDateTime).getMinutes().toString().padStart(2, '0');
      const endTime = new Date(item.endDateTime).getHours().toString().padStart(2, '0') + ":" + new Date(item.endDateTime).getMinutes().toString().padStart(2, '0');
      const startDate = `${new Date(item.startDateTime).getDate()} ${this.monthShortNames[new Date(item.startDateTime).getMonth()]} (${this.dayNames[new Date(item.startDateTime).getDay()]})`;
      const month = this.monthNames[new Date(item.startDateTime).getMonth()];
      const year = new Date(item.startDateTime).getFullYear();
      const startEndTime = `${startTime} - ${endTime}`;

      // Find if 'Month' and 'Year' object exist.
      const objectMonth = this.eventScheduleByMonth.find(obj => obj.month === this.monthNames[new Date(item.startDateTime).getMonth()] && obj.year === new Date(item.startDateTime).getFullYear());

      const obj: eventScheduleListModelEventType = {
        content_id: item.identifier,
        type: this.contentService.getEventStatus(item.startDateTime, item.endDateTime),
        date: startDate,
        time: startEndTime,
        name: item.title,
        button: {
          link: item.link,
          recap_link: item.recapLink ?? ''
        }
      }

      if (objectMonth) {
        // If exist
        objectMonth.event.push(obj)
      } else {
        // If does not exist
        this.eventScheduleByMonth.push({
          content_id: `${month}-content`,
          month: month,
          year: year,
          timezone: 'GMT +8', // Manual for now, ask Bilal.
          download_link: 'https://www.google.com', // Manual for now, ask Bilal.
          event: [obj]
        })
      }
    });
  }

  downloadSchedule(downloadLink: string) {
    window.open(downloadLink, '_blank');
  }

  onCalendarClick(event: Event, type: string, contentId: string) {
    event.preventDefault();
    const selectedBanner = this.data.find((obj: any) => obj.identifier === contentId);

    this.calendarField.title = selectedBanner.title;
    this.calendarField.description = selectedBanner.descriptions;
    this.calendarField.startDateTime = this.contentService.formatDate3(selectedBanner.startDateTime, type);
    this.calendarField.endDateTime = this.contentService.formatDate3(selectedBanner.endDateTime, type);
    this.calendarField.link = selectedBanner.link;

    this.contentService.createCalendarDeepLink(this.calendarField, type);
  }

}
