import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-registration-form-2',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './registration-form-2.component.html',
  styleUrl: './registration-form-2.component.scss',
  animations: [fadeInOut],
})
export class RegistrationForm2Component implements OnInit {
  @Input() input?: { firstName: string, lastName: string, nickName: string };
  @Output() onCompleted = new EventEmitter<{ firstName: string, lastName: string, nickName: string }>();
  @Output() onCancelled = new EventEmitter<void>();

  regForm2!: FormGroup;

  ngOnInit(): void {
    this.regForm2 = new FormGroup({
      firstName: new FormControl<string>(this.input?.firstName ?? '', [Validators.required]),
      lastName: new FormControl<string>(this.input?.lastName ?? '', [Validators.required]),
      nickName: new FormControl<string>(this.input?.nickName ?? '', [Validators.required])
    })
  }

  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.onCompleted.emit({
      firstName: this.regForm2.controls['firstName'].value,
      lastName: this.regForm2.controls['lastName'].value,
      nickName: this.regForm2.controls['nickName'].value
    });
  }
}
