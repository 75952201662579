<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-2-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-2-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>

  <div class="modal-inner-body">
    <div *ngIf="isLoading" class="loading-wrapper">
      <figure class="spinner">
        <icon-spinner></icon-spinner>
      </figure>
    </div>
    <div *ngIf="!isLoading" class="step-indicator">
      <div class="data-label">{{"ONBOARDING.NEW_ACC.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2 current"></span>
        <span class="step step-3"></span>
        <!-- <span class="step step-4"></span> -->
      </div>
    </div>

    <div *ngIf="!isLoading" class="inner-body-heading">
      <h2>{{"ONBOARDING.NEW_ACC.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.NEW_ACC.DESC" | translate}}</p>
    </div>

    <div *ngIf="!isLoading" class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="regForm1">
        <div class="form-group">
          <label for="partner-market-input" class="form-label">{{"ONBOARDING.NEW_ACC.MARKET" | translate}}</label>
          <select id="partner-market-input" class="form-select" formControlName="market">
            <option *ngFor="let market of markets" [value]="market['code']">
              {{market['label']}}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="partner-id-input" class="form-label">{{"ONBOARDING.NEW_ACC.PARTNER_ID" | translate}}</label>
          <input type="text" class="form-control" id="partner-id-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="partnerId" (ngModelChange)="serverPartnerIdError = false;">

          <!-- Server partner ID error -->
          <div *ngIf="serverPartnerIdError" class="invalid-message">
            {{serverPartnerIdErrorMessage | translate}}
          </div>
        </div>

        <div class="form-group">
          <label for="partner-email-input" class="form-label">{{"ONBOARDING.NEW_ACC.EMAIL" | translate}}</label>
          <input type="email" class="form-control" id="partner-email-input"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="email"
            (ngModelChange)="frontendEmailError = false; serverEmailError = false;">

          <!-- Invalid email format -->
          <div *ngIf="frontendEmailError" class="invalid-message">
            {{frontendEmailErrorMessage | translate}}
          </div>

          <!-- Server email error -->
          <div *ngIf="serverEmailError" class="invalid-message">
            {{serverEmailErrorMessage | translate}}
          </div>
        </div>
      </fieldset>
    </div>
    <div *ngIf="!isLoading" class="inner-body-footer">
      <button type="button" *ngIf="!isSubmitting" class="btn btn-primary w-100 w-lg-auto" [disabled]="!regForm1.valid"
        (click)="onSubmit()">
        {{"ONBOARDING.NEW_ACC.NEXT" | translate}}
      </button>
      <button type="button" *ngIf="!isSubmitting" class="btn btn-secondary w-100 w-lg-auto"
        (click)="onBack()">{{"ONBOARDING.NEW_ACC.BACK" | translate}}
      </button>
      <button type="button" *ngIf="isSubmitting" class="btn btn-spinner w-100 w-lg-auto" disabled>
        <span class="icon-img">
          <icon-spinner></icon-spinner>
        </span>
      </button>
    </div>
  </div>
</div>