<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <div class="container">
    <h1 class="mb-3">Search Results</h1>
    <div *ngIf="isLoading" class="text-center mt-4">
      <span class="icon-img">
        <icon-spinner></icon-spinner>
      </span>
    </div>
    <div *ngIf="!isLoading && searchResultsData.length === 0" class="no-results">
      No results found, please change your search terms and try again.
    </div>

    <div *ngIf="!isLoading && searchResultsData.length > 0">
      <div class="row">
        <div class="col-12 col-lg-7">
          Total <strong>{{totalSearchResults}}</strong> results found for: <strong>{{searchTerm}}</strong>
        </div>

        <div *ngIf="searchResultsData.length > 0" class="results-wrapper col-12 mt-4">
          <app-grid-list-3 [data]="searchResultsData" (onFlashCardClicked)="openFlashCardModal($event)"
            (onUnlockBAPContentClicked)="openUnlockBAPContentModal()"></app-grid-list-3>
        </div>

        <div *ngIf="!noMoreResults" class="d-flex align-items-center justify-content-center">
          <button type="button" [ngClass]="{'btn-white':!isLoadingMore, 'btn-spinner':isLoadingMore}" class="btn btn-sm"
            (click)="isLoadingMore = true; getSearchResults()" [disabled]="isLoadingMore">
            <span *ngIf="isLoadingMore" class="icon-img h-100">
              <icon-spinner></icon-spinner>
            </span>
            <span *ngIf="!isLoadingMore">Load more</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>