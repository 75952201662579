import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { PublicHeaderComponent } from '../../components/public-header/public-header.component';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-conditional-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, PublicHeaderComponent, HeaderComponent, FooterComponent],
  templateUrl: './conditional-layout.component.html',
  styleUrl: './conditional-layout.component.scss'
})
export class ConditionalLayoutComponent implements OnInit {
  isLoggedIn!: boolean;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    if (this.authService.userIsLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.renderer.addClass(this.el.nativeElement, 'app-layout-container');
  }
}
