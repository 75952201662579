<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 [dataUrl]="imageBanner2DataUrl"></app-image-banner-2>
    </div>
  </section>

  <section class="content-heading mb-5 text-center">
    <div class="container">
      <h1 class="headline mb-3 px-4">{{"LEARNING_CENTRE.HEADER" | translate}}</h1>
      <div class="descriptions px-4 px-lg-6 ">{{"LEARNING_CENTRE.DESC" | translate}}</div>
    </div>
  </section>

  <section *ngIf="learningTopicData.length > 0 && retrievedFilter">
    <div class="container">
      <div class="filters mb-6">
        <app-list-filters [data]="listFiltersData" (onCompleted)="filterTopicData($event)"></app-list-filters>
      </div>

      <div class="topic-list">
        <app-grid-list-3 [data]="filteredTopicData"></app-grid-list-3>
      </div>
    </div>

  </section>

</div>