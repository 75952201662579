import { AfterViewInit, Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { CircleStarIcon, CoffeeBeanBagIcon } from "../../icons";
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeProfilePictureModalComponent } from '../../components/change-profile-picture-modal/change-profile-picture-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { AccountService } from '../../services/account.service';
import { ProfileModel } from '../../models/profile.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, CircleStarIcon, CarouselModule, CoffeeBeanBagIcon, TranslateModule]
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  data!: ProfileModel;
  email!: string;
  encodedEmail!: string;
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "My Profile"
    }
  ]
  profilePictureUrl: string = '';

  // profileCompletenessLevel = '25';
  // activityData = [
  //   {
  //     "action_name": "Action Item 1",
  //     "description": "Tell us more about your coffee preference",
  //     "button": {
  //       "label": "Start now",
  //       "link": "/home",
  //     }
  //   },
  //   {
  //     "action_name": "Action Item 2",
  //     "description": "Tell us more about your coffee preference",
  //     "button": {
  //       "label": "Start now",
  //       "link": "/home",
  //     }
  //   }
  // ];

  // Owl carousel config
  activityCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    nav: true,
    margin: 25,
    responsive: {
      0: {
        items: 1,
        loop: false
      }
    },
  }

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    private accountService: AccountService,
    private translation: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private titleService: Title
  ) { }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['t'] && params['t'].toUpperCase() === 'AVATARCREATION') {
        this.openChangeProfilePictureModal();
      }
    })
  }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'MY_PROFILE.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['MY_PROFILE.HEADER'];

        this.titleService.setTitle(res['MY_PROFILE.HEADER']);
      }
    })

    // Profile GET Request
    this.accountService.getMyProfile().subscribe({
      next: res => {
        this.data = res;
        this.encodedEmail = btoa(res.email);
        this.profilePictureUrl = res.photo;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  async downloadProfilePicture() {
    if (this.profilePictureUrl) {
      await this.contentService.imageUrlToBase64(this.profilePictureUrl)
        .then(res => {
          const imageName = 'profile-picture';

          // Create an anchor element
          const link = document.createElement('a');
          link.href = res;
          link.download = imageName;
          link.click();
        });
    } else {
      this.toastr.info('No profile picture to be download!', '')
    }
  }

  formatDate(timestamp: string): string {
    const date = new Date(timestamp);
    return this.contentService.formatDate2(date.getTime());
  }

  // Check Object is empty
  isObjectEmpty(objectName: ProfileModel): boolean {
    if (objectName !== undefined || null) {
      return Object.keys(objectName).length !== 0
    }
    return false;
  }

  openChangeProfilePictureModal() {
    const modalRef = this.modalService.open(ChangeProfilePictureModalComponent, {
      centered: true,
      windowClass: 'change-profile-picuture-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  // getTitleCase(marketName: string) {
  //   return this.contentService.formatTitleCase(marketName);
  // }
}
