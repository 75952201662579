<div class="component-banner-carousel-2">
  <h2 class="mb-4">{{slideHeaderCopy}}</h2>

  <owl-carousel-o *ngIf="data" [options]="bannerCarouselOptions"
    [ngClass]="{'single-slide-carousel': data && data.length === 1}">
    <ng-container *ngFor="let slide of data">
      <ng-template carouselSlide>
        <div class="banner-wrapper" [title]="slide.headline">
          <a [href]="slide.link">
            <figure class="banner-image">
              <img *ngIf="slide.image_src.sm" [src]="slide.image_src.sm" class="d-md-none"
                alt="{{slide.headline}}-img" />
              <img [src]="slide.image_src.lg" [ngClass]="{'d-none d-md-block': slide.image_src.sm}"
                alt="{{slide.headline}}-img" />
            </figure>
            <div class="carousel-slide-inner-wrapper">
              <h2 class="banner-headline">{{slide.headline}}</h2>
              <div class="banner-subheadline">{{slide.subheadline}}
              </div>
            </div>
          </a>
        </div>
      </ng-template>

    </ng-container>
  </owl-carousel-o>
</div>