<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div class="modal-inner-header">
      <figure>
        <img src="/assets/images/profile/img-banner-claim-title.webp">
      </figure>
    </div>
    <div class="modal-inner-body">

      <div class="inner-body-heading">
        <h2>Congratulations!</h2>
      </div>

      <div class="inner-body-contents ">
        <p>You have successfully claimed your [Title Path: Roast & Blend Lead / Origin & Ethical Sourcing Lead / Coffee
          Leadership Lead / Brew & Craft Lead]!</p>
        <p>✨ Your journey as a Black Apron Programme continues—keep inspiring, learning, and elevating your coffee
          expertise!</p>
      </div>

      <div class="inner-body-footer mt-3">
        <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="dismissModal()">OK</button>
      </div>
    </div>
  </div>
</div>