
import { Component } from '@angular/core';

@Component({
  selector: 'icon-workshop',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 1200 1200">
    <path fill-rule="evenodd" d="M840.066 119.897C785.267 47.045 698.203-.2 600-.2S414.605 46.917 359.934 119.897H59.82c-15.876 0-31.241 6.274-42.38 17.541A59.89 59.89 0 0 0-.1 179.817v384.105c0 13.828 4.737 27.271 13.572 37.898 8.706 10.755 20.87 18.053 34.441 20.87v338.525c-13.572 2.817-25.735 10.115-34.441 20.87C4.765 992.839-.1 1006.155-.1 1019.983v120.097c0 15.876 6.274 31.241 17.541 42.38A59.89 59.89 0 0 0 59.82 1200h1080.36c15.876 0 31.241-6.274 42.38-17.541a59.887 59.887 0 0 0 17.541-42.38v-120.097c0-13.828-4.737-27.271-13.572-37.898-8.706-10.755-20.87-18.053-34.441-20.87V622.818c13.572-2.817 25.735-10.115 34.441-20.87 8.706-10.755 13.572-24.071 13.572-37.898V179.945c0-15.876-6.274-31.241-17.541-42.38a59.887 59.887 0 0 0-42.38-17.541H840.066v-.127zm-35.978 180.017c0 112.671-91.417 204.088-204.088 204.088s-204.088-91.417-204.088-204.088S487.329 95.826 600 95.826s204.088 91.417 204.088 204.088zm-9.091 228.03h309.077V215.923H888.207c7.938 27.271 11.907 55.567 11.907 83.991 0 91.289-40.843 173.103-105.117 228.03zM311.793 215.923H95.926v312.149h309.077c-64.274-55.055-104.989-136.741-104.989-228.03-.128-29.32 4.097-57.488 11.779-84.119zm-167.854 744.14V623.971h912.25v336.092H852.101V912.05h108.062c15.876 0 31.241-6.274 42.38-17.541a59.887 59.887 0 0 0 17.541-42.38V719.997c0-15.876-6.274-31.241-17.541-42.38a59.887 59.887 0 0 0-42.38-17.541H239.965c-15.876 0-31.241 6.274-42.38 17.541a59.887 59.887 0 0 0-17.541 42.38v132.004c0 15.876 6.274 31.241 17.541 42.38a59.887 59.887 0 0 0 42.38 17.541h108.062v48.013H143.939v.128zm-48.013 96.026v48.013h1008.276v-48.013H95.926zm347.999-96.026h108.062v-203.96H443.925v203.96zm204.088 0h108.062v-203.96H648.013v203.96zm-372.07-203.96h71.956v60.048h-71.956v-60.048zm576.158 0v60.048h71.956v-60.048h-71.956z" clip-rule="evenodd"/>
    <path d="M600 136.669c-65.298 0-118.176 67.09-118.176 149.673S534.702 436.015 600 436.015s118.176-67.09 118.176-149.673S665.298 136.669 600 136.669m-4.993 215.867-11.011 33.033c-30.856-10.627-54.799-51.598-54.799-99.227-.896-31.625 10.755-62.353 32.521-85.399-12.932 35.466-4.481 75.157 21.766 102.172 12.803 12.803 17.412 32.009 11.523 49.421m43.532 19.206c12.932-35.338 4.481-75.029-21.766-102.044-12.932-12.932-17.541-32.137-11.651-49.55l11.011-33.161c30.856 10.755 54.799 51.726 54.799 99.227 1.28 31.753-10.499 62.609-32.393 85.528"/>
</svg>
  `,
  styles: [],
})
export class WorkshopIcon { }