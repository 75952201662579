
import { Component } from '@angular/core';

@Component({
  selector: 'icon-document',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 17 20"><path d="M0 19.2c0 .4.3.8.8.8h14.7c.4 0 .8-.3.8-.8V6.7c0-.1 0-.3-.1-.4l-.2-.2L8.6.2C8.5.1 8.3 0 8.1 0H.8C.4 0 0 .3 0 .8v18.5ZM13.3 5.9H8.9V2.3l4.4 3.6ZM1.5 1.5h5.8v5.2c0 .4.3.8.8.8h6.6v11H1.5v-17Z" style="stroke-width:0"/></svg>
  `,
  styles: [],
})
export class DocumentIcon { }