<div [@fadeInOut] class="inner-body pt-0">
    <div class="breadcrumbs-container">
        <div class="container">
            <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
        </div>
    </div>

    <section class="mb-5 text-center">
        <div class="container">
            <div class="mt-5">
                <app-person-grid-list [data]="personData"></app-person-grid-list>
            </div>
        </div>
    </section>
</div>