import { Component, Inject } from '@angular/core';
import { ArrowUpIcon, SCCLogo } from "../../icons";
import { CommonModule, DOCUMENT } from '@angular/common';
import { Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate(300)),
    ])
  ],
  imports: [ArrowUpIcon, CommonModule, SCCLogo, TranslateModule, RouterLink]
})
export class FooterComponent {
  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) { }

  showScrollToTopButton = false;

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  ngOnInit() {
    this.renderer.listen('window', 'scroll', () => {
      const totalPageHeight = this.document.body.offsetHeight;
      const scrollPosition = window.scrollY;

      this.showScrollToTopButton = scrollPosition > totalPageHeight * 0.25;
    });
  }
}
