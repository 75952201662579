import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { ConfigService } from "../services/config.service";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "../services/language.service";
import { environment } from "../../environment/environment";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {
    constructor(
        private router: Router,
        private configService: ConfigService,
        private authService: AuthService,
        private translate: TranslateService,
        private languageService: LanguageService
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // To make sure localstorage got token
        if (!this.authService.userIsLoggedIn() && environment.clientAuthEnabled) {
            this.authService.clientAuth(this.authService.userIsLoggedIn());
        }

        if (state.url.startsWith('/preview')) {// Admin preview page

            if (!next.queryParams['l'] && !this.configService.isPreview) {
                this.router.navigate(['/home']);
                return false;
            }

            this.configService.setIsPreview(true);
            if (next.queryParams['l']) {
                this.configService.setPreviewLanguage(next.queryParams['l']);
                this.translate.use(this.languageService.languageMapping[Number(next.queryParams['l'])]);
                document.documentElement.lang = this.languageService.languageHTMLMap(next.queryParams['l'] ?? '1');

                // Remove only the 'l' query parameter (language)
                const urlTree = this.router.parseUrl(state.url);
                delete urlTree.queryParams['l'];
                this.router.navigateByUrl(urlTree);
            }

        } else {
            if (this.configService.isPreview) {
                this.router.navigate([`/preview/${state.url}`]);
            }
        }

        return true;
    }
}

export const previewAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
};