import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonListModel } from '../../models';
import { PersonListService } from '../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonVideoBioModalComponent } from '../person-video-bio-modal/person-video-bio-modal.component';
import { PersonTextBioModalComponent } from '../person-text-bio-modal/person-text-bio-modal.component';
import { AnalyticsService } from '../../services/analytics.service';
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-person-list',
  standalone: true,
  imports: [CommonModule, ClickableEnlargeImageDirective],
  templateUrl: './person-list.component.html',
  styleUrl: './person-list.component.scss',
  providers: [PersonListService]
})
export class PersonListComponent implements AfterViewInit {
  @Input({ required: true }) data!: PersonListModel[];
  @Input() modal: any;

  constructor(
    private modalService: NgbModal,
    public analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private ngZone: NgZone
  ) { }

  openPersonVideoBioModal(personVideoBioUrl: string, personId: string, personContentType: string) {
    const modalRef = this.modalService.open(PersonVideoBioModalComponent, {
      centered: true,
      windowClass: 'person-bio-modal person-video-bio-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.personVideoBioUrl = personVideoBioUrl;
    modalRef.componentInstance.personId = personId;
    modalRef.componentInstance.personContentType = personContentType;
  }

  openPersonTextBioModal(personTextBio: string, personAvatarImageSrc: string, personName: string, personTitle: string, personMarket?: string) {
    const modalRef = this.modalService.open(PersonTextBioModalComponent, {
      centered: true,
      windowClass: 'person-bio-modal person-text-bio-modal',
      size: 'xl',
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.personTextBio = personTextBio;
    modalRef.componentInstance.personAvatarImageSrc = personAvatarImageSrc;
    modalRef.componentInstance.personName = personName;
    modalRef.componentInstance.personTitle = personTitle;
    modalRef.componentInstance.personMarket = personMarket;
  }

  openPersonBioModal(item: PersonListModel) {
    if (item.bio_type!.toUpperCase() === 'VIDEO') {
      if (item.bio_video_link) {
        this.openPersonVideoBioModal(item.bio_video_link, item.content_id, item.content_type);
      } else {
        console.error('No bio video link provided.');
      }
    } else if (item.bio_type!.toUpperCase() === 'TEXT') {
      if (item.bio_text) {
        this.openPersonTextBioModal(item.bio_text, item.image_src, item.name, item.title, item.market);
      } else {
        console.error('No bio text provided.');
      }
    }
  }

  ngAfterViewInit() {
    const directive = new ClickableEnlargeImageDirective(this.elementRef, this.ngZone);
    directive.ngOnInit();
  }
}
