import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridList3Model } from '../models';

@Injectable({
  providedIn: 'any'
})

export class GridList3Service {
  //private dataUrl = '/assets/data/grid-list-3.json';

  constructor(private http: HttpClient) { }

  getGridList3Data(dataUrl: string): Observable<GridList3Model[]> {
    return this.http.get<GridList3Model[]>(dataUrl);
  }
}
