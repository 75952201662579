import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XmarkIcon, ImageLandscapeIcon } from "../../icons";
import { ProfilePictureAvatarCreatorComponent } from "./profile-picture-avatar-creator/profile-picture-avatar-creator.component";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePictureUploadComponent } from "./profile-picture-upload/profile-picture-upload.component";
import { fadeInOut } from '../../animation';
import { ReviewProfilePictureComponent } from "./review-profile-picture/review-profile-picture.component";
import { AccountService } from '../../services/account.service';
import { DeviceDetectorService } from '../../../../node_modules/ngx-device-detector';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-change-profile-picture-modal',
  standalone: true,
  templateUrl: './change-profile-picture-modal.component.html',
  styleUrl: './change-profile-picture-modal.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, XmarkIcon, ProfilePictureAvatarCreatorComponent, ProfilePictureUploadComponent, ImageLandscapeIcon, ReviewProfilePictureComponent, TranslateModule]
})
export class ChangeProfilePictureModalComponent implements OnInit {
  @Input() modal: any;

  currentStep: string = 'LANDING';
  selectedProfileImageUrl!: string;
  isMobile!: boolean;
  profilePictureData!: { data: string, isMobile: boolean };
  isSubmitting: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private deviceService: DeviceDetectorService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
  }

  dismissModal() {
    this.modal.dismiss();
    if (this.selectedProfileImageUrl) {
      window.location.href = 'my-profile';
    }
  }

  getImageLanguageSrc(): string {
    return `/assets/images/sign-up/img-try-avatar-creator-${this.languageService.languageMap(this.languageService.getLanguage() ?? '1')}.png`
  }

  goToAvatarCreator() {
    this.currentStep = 'AVATAR_CREATOR'
  }

  goToUploadProfilePicture() {
    this.currentStep = 'UPLOAD_PICTURE'
  }

  goToReviewProfile() {
    this.isSubmitting = true;
    this.accountService.updateProfilePicture(this.selectedProfileImageUrl).subscribe({
      next: res => {
        // pass URL instead of dataURI if is mobile device
        if (this.isMobile) {
          this.selectedProfileImageUrl = res;
        }

        this.profilePictureData = {
          data: this.selectedProfileImageUrl,
          isMobile: this.isMobile
        }
      },
      error: err => {
        console.log(err);
      },
      complete: () => {
        this.isSubmitting = false;
        this.currentStep = 'REVIEW_PROFILE_PICTURE'
      }
    });
  }

  goToChangeProfilePicture() {
    this.currentStep = 'LANDING'
  }
}
