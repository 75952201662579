<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-3-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-3-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="step-indicator">
      <div class="data-label">{{"ONBOARDING.MORE_QUESTIONS.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2"></span>
        <span class="step step-3 current"></span>
        <span class="step step-4"></span>
      </div>
    </div>

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.MORE_QUESTIONS.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.MORE_QUESTIONS.DESC" | translate}}</p>
    </div>

    <div class="inner-body-contents">
    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit({answerMore: true})">
        {{"ONBOARDING.MORE_QUESTIONS.NEXT" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onSubmit({answerMore: false})">
        {{"ONBOARDING.MORE_QUESTIONS.SKIP" | translate}}
      </button>
    </div>
  </div>
</div>