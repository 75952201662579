import { Component, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from '../../../../animation';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-create-avatar',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './create-avatar.component.html',
  styleUrl: './create-avatar.component.scss',
  animations: [fadeInOut],
})
export class CreateAvatarComponent {
  @Output() onCompleted = new EventEmitter<void>();

  onSubmit() {
    this.onCompleted.emit();
  }
}
