import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridList2Model } from '../../models';

@Component({
  selector: 'app-grid-list-2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './grid-list-2.component.html',
  styleUrl: './grid-list-2.component.scss',
})
export class GridList2Component {
  @Input({ required: true }) data!: GridList2Model[];
}
