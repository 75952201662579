
import { Component } from '@angular/core';

@Component({
  selector: 'icon-edit',
  standalone: true,
  template: `
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20">
  <path d="M19.4,2.1l-1.5-1.5c-.8-.8-2.1-.8-2.9,0l-7.3,7.3c-.1.1-.2.3-.2.4l-.8,4.1c0,.3,0,.6.2.7.2.2.4.2.6.2h.2l4.1-.8c.2,0,.3-.1.4-.2l7.3-7.3c.8-.8.8-2.1,0-2.9h0ZM11.1,11l-2.7.5.5-2.7,5-5,2.1,2.1-5,5ZM18.2,3.9l-1,1-2.1-2.1,1-1c.1-.2.4-.2.6,0,0,0,0,0,0,0l1.5,1.5c.2.1.2.4,0,.6,0,0,0,0,0,0Z"/>
  <path d="M15.2,20H2.9c-1.6,0-2.9-1.3-2.9-2.9V4.8C0,3.2,1.3,1.9,2.9,1.9h5.3c.5,0,.8.4.8.8s-.4.8-.8.8H2.9c-.7,0-1.2.6-1.2,1.2v12.3c0,.7.5,1.2,1.2,1.2h12.3c.7,0,1.2-.6,1.2-1.2v-5.3c0-.5.4-.8.8-.8s.8.4.8.8v5.3c0,1.6-1.3,2.9-2.9,2.9Z"/>
</svg>
  `,
  styles: [],
})
export class EditIcon { }