<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section *ngIf="eventBannerData.length > 0" class="event-hero-banner mb-5">
    <div class="container">
      <app-banner-carousel-1 [data]="eventBannerData"></app-banner-carousel-1>
    </div>
  </section>

  <section *ngIf="featuredEventData.length > 0" class="event-activities mb-2">
    <div class="container">
      <div class="section-heading mb-4 text-center">
        <h2>{{"EVENTS.EVENTS_ACTIVITIES.HEADER" | translate}}</h2>
        <p>{{"EVENTS.EVENTS_ACTIVITIES.DESC" | translate}}</p>
      </div>

      <app-grid-list-2 [data]="featuredEventData"></app-grid-list-2>
    </div>
  </section>

  <section *ngIf="eventHighlightData.length > 0" class="event-highlights mb-6">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"EVENTS.HIGHLIGHT" | translate}}</h2>
      <app-banner-carousel-2 [data]="eventHighlightData"></app-banner-carousel-2>
    </div>
  </section>

  <section *ngIf="eventArchiveData.length > 0" class="event-archive mb-2">
    <div class="container">
      <h2 class="mb-3 pe-8">{{"EVENTS.ARCHIVE" | translate}}</h2>
      <app-list-carousel-1 [data]="eventArchiveData"></app-list-carousel-1>
    </div>
  </section>
</div>