<div [@fadeInOut] class="inner-body">
    <div *ngIf="isValidating" class="vw-100 vh-100 d-flex flew-wrap align-items-center justify-content-center">
        <span class="icon-img">
            <icon-spinner></icon-spinner>
        </span>
    </div>
    <app-reset-password-unsuccessful *ngIf="!isValidating && serverError"
        [errMessage]="serverErrorMessage"></app-reset-password-unsuccessful>

    <app-setup-new-password *ngIf="!isValidating && !serverError" [email]="email"></app-setup-new-password>
</div>