import { Component, Output, EventEmitter, Input } from '@angular/core';
import { fadeInOut } from '../../../animation';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password-requested',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './reset-password-requested.component.html',
  styleUrl: './reset-password-requested.component.scss',
  animations: [fadeInOut]
})
export class ResetPasswordRequestedComponent {
  @Input() input!: { resetPassEmail: string };
  @Output() onCompleted = new EventEmitter<void>();

  onSubmit() {
    this.onCompleted.emit();
  }
}
