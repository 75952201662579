<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <figure class="thank-you-image mb-5">
      <img src="/assets/images/sign-up/img-coffee-cup.webp">
    </figure>
    <h1 class="mb-4" id="thank-you">
      {{ thankYouHeadlineCopy }}
    </h1>
    <div class="mb-5">
      <p>{{"ONBOARDING.THANK_YOU.DESC_1" | translate}}</p>
      <p>({{"ONBOARDING.REQUEST_RESET_PASSWORD.EMAIL" | translate}} {{email}})</p>
      <p>
        {{"ONBOARDING.THANK_YOU.DESC_2" | translate}}
        <a href="mailto:starbuckscoffeecommunity@starbucks.com">starbuckscoffeecommunity&#64;starbucks.com</a>
        {{"ONBOARDING.THANK_YOU.DESC_3" | translate}}
      </p>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto" [routerLink]="['/home']">
        {{"ONBOARDING.THANK_YOU.NEXT" | translate}}
      </button>
    </div>

  </div>
</div>