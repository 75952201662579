<div [@fadeInOut] class="inner-body">
  <div class="container mw-lg">
    <div class="mb-4">
      <h1>{{"UPDATE_NICKNAME.HEADER" | translate}}</h1>
      <p>{{"UPDATE_NICKNAME.DESC" | translate}}</p>
    </div>

    <div class="mb-5">
      <fieldset class="mb-4" [formGroup]="updateNameForm">

        <div class="form-group">
          <label for="partner-new-nickname-input" class="form-label d-flex">
            {{"UPDATE_NICKNAME.NEW_NICKNAME" | translate}}
            <span class="ms-auto">
              ({{updateNameForm.controls['name'].value.length}}/20)
            </span>
          </label>
          <input type="text" class="form-control" id="partner-new-nickname-input" [placeholder]="'GENERAL.ENTER_HERE' | translate"
            formControlName="name" maxlength="20">
          <div class="invalid-feedback">
            {{"UPDATE_NICKNAME.ERROR" | translate}}
          </div>
        </div>
      </fieldset>
    </div>

    <div class="call-to-actions">
      <button class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()">{{"UPDATE_NICKNAME.SUBMIT" | translate}}</button>
    </div>

  </div>
</div>