import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { ActivationSuccessfulComponent } from '../activation-successful/activation-successful.component';
import { ActivationUnsuccessfulComponent } from '../activation-unsuccessful/activation-unsuccessful.component';
import { SpinnerIcon } from '../../icons';
import { CommonModule } from '@angular/common';
import { ErrorService } from '../../services/error.service';
import { Title } from '@angular/platform-browser';
import { fadeInOut } from '../../animation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activation',
  standalone: true,
  imports: [ActivationSuccessfulComponent, ActivationUnsuccessfulComponent, SpinnerIcon, CommonModule],
  templateUrl: './activation.component.html',
  styleUrl: './activation.component.scss',
  animations: [fadeInOut]
})
export class ActivationComponent implements OnInit {
  tokenParam!: string;
  isValidating: boolean = true;
  isValidLink!: boolean;
  serverActivateError: boolean = false;
  serverActivateErrorMessage!: string;
  errorMessage!: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!params['t']) {
        this.serverActivateErrorMessage = this.errorService.getServerErrorMapping('1006');
        this.serverActivateError = true;
        this.isValidating = false;
        return;
      }
      this.tokenParam = params['t'];

      // =============== Validate activate account email link ===============
      const reqHeader = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const body = {
        'token': this.tokenParam
      };

      this.authService.verifyEmail(body, reqHeader).subscribe({
        next: res => {
          if (!res.data) {
            this.serverActivateError = true;
            this.serverActivateErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
          }

          this.isValidating = false;
        },
        error: err => {
          console.log(err);
        }
      })
    })
  }
}
