
import { Component } from '@angular/core';

@Component({
  selector: 'icon-bap-star',
  standalone: true,
  template: `
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
  <path d="M99.879,38.281h-38.19L49.888.086l-11.801,38.195H-.108l30.899,23.609-11.801,38.195,30.896-23.612,30.903,23.612-11.806-38.2,30.896-23.604ZM71.395,86.61l-21.507-16.425-21.505,16.425,8.217-26.585-21.93-16.744h27.102l8.115-26.258,8.12,26.258h27.098l-21.922,16.749,8.212,26.58Z" fill="#bda882" stroke-width="0"/>
  <polygon points="85.105 43.281 58.007 43.281 49.887 17.023 41.772 43.281 14.67 43.281 36.6 60.025 28.383 86.61 49.888 70.185 71.395 86.61 63.183 60.03 85.105 43.281" fill="#fff" stroke-width="0"/>
</svg>
  `,
  styles: [],
})
export class BapStarIcon { }