import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BreadcrumbsModel } from '../../models';
import { BreadcrumbsService } from '../../services';
import { CommonModule } from '@angular/common';
import { ArrowLeftIcon } from "../../icons/icon-arrow-left";
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  standalone: true,
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.scss',
  imports: [CommonModule, ArrowLeftIcon, TranslateModule, RouterLink]
})
export class BreadcrumbsComponent implements OnInit {
  @Input() data!: BreadcrumbsModel[];

  constructor(
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit(): void {
    if (!this.data) {
      console.error('No data provided.');
    }
  }
}
