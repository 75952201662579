import { Component, OnInit, Input } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BannerCarousel1Service } from '../../services';
import { CommonModule } from '@angular/common';
import { BannerCarousel1Model } from '../../models';
import { AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon } from "../../icons";
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { ContentService } from '../../services/content.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModel } from '../../models/calendar.model';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from '../../services/analytics.service';
@Component({
  selector: 'app-banner-carousel-1',
  standalone: true,
  templateUrl: './banner-carousel-1.component.html',
  styleUrl: './banner-carousel-1.component.scss',
  imports: [CommonModule, NgbDropdownModule, AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, CarouselModule, CountdownComponent, TranslateModule, VideoModalComponent],
  providers: [BannerCarousel1Service]
})

export class BannerCarousel1Component implements OnInit {
  @Input({ required: true }) data!: BannerCarousel1Model[];
  countdownConfigArr: CountdownConfig[] = [];

  private CountdownTimeUnits: Array<[string, number]> = [
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1] // million seconds
  ];

  // Owl carousel config
  bannerCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 5000,
    responsive: {
      0: {
        items: 1,
        loop: false,
        rewind: true
      }
    },
  }

  calendarUrl!: string;
  calendarField: CalendarModel = {
    title: '',
    startDateTime: '',
    endDateTime: '',
    location: ''
  }

  constructor(
    private contentService: ContentService,
    private translation: TranslateService,
    private modalService: NgbModal,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    let time: { hour: string, minute: string, second: string } = {
      hour: 'Hour',
      minute: 'Minute',
      second: 'Second'
    }

    // Get Translation (Hour, Minute, Second)
    this.translation.get(['GENERAL.TIME.HOUR', 'GENERAL.TIME.SECOND', 'GENERAL.TIME.MINUTE']).subscribe({
      next: res => {
        time.hour = res['GENERAL.TIME.HOUR'];
        time.minute = res['GENERAL.TIME.MINUTE'];
        time.second = res['GENERAL.TIME.SECOND'];
      }
    })

    this.data.forEach((item: any) => {
      var configObj: CountdownConfig = {}

      if (item.type.toUpperCase() === 'UPCOMING') {
        const timestampNow = Math.floor(Date.now() / 1000);
        const timestampStart = new Date(item.start_date).getTime() / 1000;

        configObj = {
          leftTime: timestampStart - timestampNow,
          formatDate: ({ date, formatStr }) => {
            formatStr = 'HH:mm:ss';
            let duration = Number(date || 0);

            return this.CountdownTimeUnits.reduce((current, [name, unit]) => {
              if (current.indexOf(name) !== -1) {
                const v = Math.floor(duration / unit);
                duration -= v * unit;
                return current.replace(
                  new RegExp(`${name}+`, 'g'),
                  (match: string) => {
                    return v.toString().padStart(match.length, '0');
                  }
                );
              }
              return current;
            }, formatStr);
          },
          prettyText(text: string): string {
            const textArr = text.split(':');
            return `
            <div class="countdown-timer d-flex">
              <div class="d-flex flex-column align-items-center">
                <span class="data hours">${textArr[0]}</span>
                <span class="data-label">${time.hour}</span>
              </div>
              <div class="d-flex flex-column align-items-center">
                <span class="data minutes">${textArr[1]}</span>
                <span class="data-label">${time.minute}</span>
              </div>
              <div class="d-flex flex-column align-items-center">
                <span class="data seconds">${textArr[2]}</span>
                <span class="data-label">${time.second}</span>
              </div>
            </div>
          `;
          }
        };
      }
      this.countdownConfigArr.push(configObj)
    });
  }

  onCalendarClick(event: Event, type: string, contentId: string) {
    event.preventDefault();
    const selectedBanner = this.data.find((obj: any) => obj.content_id === contentId);

    this.calendarField.title = selectedBanner!.headline;
    this.calendarField.description = selectedBanner!.descriptions;
    this.calendarField.startDateTime = this.contentService.formatDate3(selectedBanner!.start_date!.toString(), type);
    this.calendarField.endDateTime = this.contentService.formatDate3(selectedBanner!.end_date!.toString(), type);
    this.calendarField.link = selectedBanner!.button?.link;

    this.contentService.createCalendarDeepLink(this.calendarField, type);
  }

  public handleLink(link: string, identifier: string, contentType: string) {
    if (!link) return;

    const isSameDomain = link.startsWith(window.location.origin) || link.startsWith('/');

    if (this.isYoutubeLink(link)) {
      this.openVideoModal(link, identifier, contentType);
    } else {
      window.open(link, isSameDomain ? '_self' : '_blank');
    }
  }

  private isYoutubeLink(url: string): boolean {
    if (!url) return false;

    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    return youtubeRegex.test(url);
  }

  private openVideoModal(videoUrl: string, contentId: string, contentType: string) {
    const modalRef = this.modalService.open(VideoModalComponent, {
      centered: true,
      windowClass: 'video-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.videoUrl = videoUrl;
    modalRef.componentInstance.videoIdentifier = contentId;
    modalRef.componentInstance.contentType = contentType;
  }
}

