import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XmarkIcon } from "../../icons/icon-xmark";
import { NotificationsCarouselModel } from '../../models';
import { MainCategories } from '../../enum';
import { CaretRightIcon, CaretLeftIcon } from "../../icons";
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-notifications-modal',
  standalone: true,
  templateUrl: './notifications-modal.component.html',
  styleUrl: './notifications-modal.component.scss',
  imports: [XmarkIcon, CommonModule, CaretRightIcon, CaretLeftIcon, CarouselModule, TranslateModule]
})
export class NotificationsModalComponent implements OnInit {
  @Input() modal: any;
  @Input() data!: NotificationsCarouselModel[];
  notificationContentType: string = 'SbuxHomeNotification';
  mainCategories = MainCategories;

  // Owl carousel config
  bannerCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: true,
    autoHeight: false,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 5000,
    responsive: {
      0: {
        items: 1,
        loop: true
      }
    },
  }

  constructor(
    private configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.invalidateNotification(this.data[0].content_id); // invalidate first nofitication item
  }

  dismissModal() {
    this.modal.dismiss();
  }

  getCarouselData(data: SlidesOutputData) {
    this.invalidateNotification(data.slides![0].id);
  }

  invalidateNotification(id: string) {
    let list = localStorage.getItem('notifications') ?? '';

    if ((!list || !list.includes(id)) && !this.configService.isPreview) { // Only invalidate if it's not Admin Preview
      list += `${id},`; // Add to string
      localStorage.setItem('notifications', list); // Set localstorage
    }
  }
}
