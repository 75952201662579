<div class="single-banner">
  <div *ngIf="data" class="banner-wrapper">
    <figure class="banner-image" [title]="data.headline">
      <img *ngIf="data.image_src.sm" [src]="data.image_src.sm" class="d-md-none" alt="{{data.headline}}-img" />
      <img [src]="data.image_src.lg" [ngClass]="{'d-none d-md-block': data.image_src.sm}" alt="{{data.headline}}-img" />
    </figure>
    <div class="inner-wrapper">

      <h2 class="banner-headline">{{data.headline}}</h2>

      <div class="banner-descriptions ">{{data.descriptions}}
      </div>

      <div class="banner-actions d-flex flex-column align-items-start">
        <button type="button" class="btn btn-primary mb-3"
          (click)="openSignUpModal()">{{"ONBOARDING.WELCOME.SIGN_UP" | translate}}</button>

        <button type="button" class="btn btn-white"
          (click)="openSignInModal()">{{"ONBOARDING.WELCOME.LOGIN" | translate}}</button>

      </div>

      <p></p>
    </div>
  </div>
</div>