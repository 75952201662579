import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animation';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-review-profile-picture',
  standalone: true,
  templateUrl: './review-profile-picture.component.html',
  styleUrl: './review-profile-picture.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, TranslateModule]
})
export class ReviewProfilePictureComponent {
  @Output() dismissModalClick = new EventEmitter<void>();
  @Output() goToChangeProfilePictureClick = new EventEmitter<void>();
  @Input() input !: { data: string, isMobile: boolean };

  dismissModal() {
    this.dismissModalClick.emit();
  }

  downloadProfilePicture() {

    if (!this.input) {
      return;
    }


    const anchor = document.createElement('a');
    anchor.href = this.input.data;

    if (this.input.isMobile) {
      anchor.target = '_blank';
    } else {
      anchor.download = 'profile-picture';
    }
    anchor.click();
  }

  goToChangeProfilePicture() {
    this.goToChangeProfilePictureClick.emit();
  }

  getProfilePictureUrl() {
    return this.input ? this.input.data : '/assets/images/avatar/img-avatar-placeholder.png';
  }
}
