<div class="component-grid-list-1" [class.style-variant-2]="styleVariant === 2"
  [class.style-variant-3]="styleVariant === 3">
  <div class="container">
    <div class="row">
      <div *ngFor="let item of data" class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="list-item" [title]="item.headline">
          <figure>
            <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', item.content_type, item.content_id, item.headline);"
              [routerLink]="[item.link]">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
            </a>

          </figure>
          <div class="info">
            <div class="title">
              <h5 class="headline fw-normal">{{item.headline}}</h5>
            </div>
            <div *ngIf="item.descriptions" class="descriptions fs-small ">
              {{item.descriptions}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>