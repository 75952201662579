import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridListWithDateTimeModel } from '../../models';
import { AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon } from "../../icons";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnregisterWorkshopModalComponent } from '../../components/unregister-workshop-modal/unregister-workshop-modal.component';
import { ContentService } from '../../services/content.service';
import { AccountService } from '../../services/account.service';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-grid-list-with-date-time',
  standalone: true,
  templateUrl: './grid-list-with-date-time.component.html',
  styleUrl: './grid-list-with-date-time.component.scss',
  imports: [CommonModule, AppleIcon, GoogleIcon, CalendarIcon, Microsoft365Icon, YahooIcon, TranslateModule, RouterLink],
})
export class GridListWithDateTimeComponent {
  @Input({ required: true }) data!: GridListWithDateTimeModel[];

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    private accountService: AccountService,
    public analyticsService: AnalyticsService
  ) { }

  getFormatDate(timestamp: number): string {
    return this.contentService.formatDate(timestamp);
  }

  unregisterWorkshop(workshop_id: string) {
    this.accountService.unregisterWorkshop(workshop_id).subscribe({
      complete: () => {
        this.openUnregisteredWorkshopModal(workshop_id);
      }
    });
  }

  openUnregisteredWorkshopModal(workshop_id: string) {
    const modalRef = this.modalService.open(UnregisterWorkshopModalComponent, {
      centered: true,
      windowClass: 'unregister-workshop-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopID = workshop_id;
  }
}
