import { Component, Input, OnInit } from '@angular/core';
import { BookmarkIcon } from "../../icons/icon-bookmark";
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';
import { AnalyticsService } from '../../services/analytics.service';
import { BookmarkModel } from '../../models/bookmark.model';

@Component({
  selector: 'app-bookmark',
  standalone: true,
  templateUrl: './bookmark.component.html',
  styleUrl: './bookmark.component.scss',
  imports: [BookmarkIcon, TranslateModule]
})
export class BookmarkComponent implements OnInit {
  @Input({ required: true }) data!: BookmarkModel;
  isActive!: boolean;
  toastrErrorHeader!: string;
  toastrErrorDesc!: string;

  constructor(
    private accountService: AccountService,
    private toastr: ToastrService,
    private translation: TranslateService,
    public configService: ConfigService,
    public analyticsService: AnalyticsService
  ) { }

  toggleBookmark(event: Event) {
    event?.preventDefault();

    if (this.configService.isPreview) {
      return;
    }

    if (this.isActive) {
      this.accountService.removeBookmark(this.data.content_id).subscribe({
        next: res => {
          this.isActive = false;
        },
        error: err => {
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      });
    } else {
      this.accountService.createBookmark(this.data.content_id).subscribe({
        next: res => {
          this.isActive = true;
        },
        error: err => {
          this.toastr.error(this.toastrErrorDesc, this.toastrErrorHeader)
        }
      });
    }
  }

  ngOnInit(): void {
    this.translation.get(['GENERAL.ERROR_HEADER', 'GENERAL.ERROR_DESC']).subscribe({
      next: res => {
        this.toastrErrorHeader = res['GENERAL.ERROR_HEADER'];
        this.toastrErrorDesc = res['GENERAL.ERROR_DESC'];
      }
    })

    if (!this.configService.isPreview) {
      // Get Bookmarked
      this.accountService.checkBookmarked(this.data.content_id).subscribe({
        next: res => {
          this.isActive = res;
        }
      })
    }
  }
}
