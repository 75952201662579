import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class ConfigService {

    public isPreview: boolean = false;
    public previewLanguage: number = 1;

    public setIsPreview(bool: boolean) {
        this.isPreview = bool;
    }

    public setPreviewLanguage(id: string) {
        this.previewLanguage = Number.parseInt(id);
    }
}