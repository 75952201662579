import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-person-text-bio-modal',
  standalone: true,
  imports: [XmarkIcon, CommonModule],
  templateUrl: './person-text-bio-modal.component.html',
  styleUrl: './person-text-bio-modal.component.scss'
})
export class PersonTextBioModalComponent {
  @Input() modal: any;
  @Input() personTextBio!: string;
  @Input() personAvatarImageSrc!: string;
  @Input() personName!: string;
  @Input() personTitle!: string;
  @Input() personMarket!: string;

  dismissModal() {
    this.modal.dismiss();
  }
}
