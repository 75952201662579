<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div *ngIf="isLoading; else content" class="modal-inner-body loading-wrapper">
      <figure class="spinner">
        <icon-spinner></icon-spinner>
      </figure>
    </div>

    <ng-template #content>
      <div *ngIf="bapTopicItemData.image_src.sm || bapTopicItemData.image_src.lg" class="modal-inner-header">
        <figure>
          <img *ngIf="bapTopicItemData.image_src.sm" [src]="bapTopicItemData.image_src.sm" class="d-md-none"
            alt="flash-card-img" />
          <img *ngIf="bapTopicItemData.image_src.lg" [src]="bapTopicItemData.image_src.lg"
            [ngClass]="{'d-none d-md-block': bapTopicItemData.image_src.sm}" alt="flash-card-img" />
        </figure>
      </div>
      <div class="modal-inner-body">
        <div class="inner-body-heading">
          <h6 class="text-dark">{{ "BAP.HEADER" | translate }} / {{bapTopicItemData.title}}</h6>
          <h2>{{bapTopicItemData.title}}</h2>
        </div>

        <div class="inner-body-contents custom-scrollbar">
          <div [innerHTML]="flashCardContent"></div>
        </div>
      </div>
    </ng-template>
  </div>
</div>