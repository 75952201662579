import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridList1Model } from '../../models';
import { RouterLink } from '@angular/router';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-grid-list-1',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './grid-list-1.component.html',
  styleUrl: './grid-list-1.component.scss',
})
export class GridList1Component {
  @Input({ required: true }) data!: GridList1Model[];
  @Input() styleVariant?: number;

  constructor(
    public analyticsService: AnalyticsService
  ) { }
}
