import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XmarkIcon } from "../../icons/icon-xmark";
import { SignInComponent } from "./sign-in/sign-in.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SignUpModalComponent } from '../sign-up-modal/sign-up-modal.component';
import { ResetPasswordRequestedComponent } from './reset-password-requested/reset-password-requested.component';

@Component({
  selector: 'app-sign-in-modal',
  standalone: true,
  templateUrl: './sign-in-modal.component.html',
  styleUrl: './sign-in-modal.component.scss',
  imports: [XmarkIcon, CommonModule, SignInComponent, ForgotPasswordComponent, ResetPasswordRequestedComponent]
})
export class SignInModalComponent {
  resetPasswordEmail: string = "";

  constructor(
    private modalService: NgbModal,
    private router: Router,
    public activeModal: NgbActiveModal
  ) { }

  @Input() modal: any;
  currentStep: string = 'SIGN_IN';

  dismissModal() {
    this.modal.dismiss();
  }

  goToForgotPassword() {
    this.currentStep = 'FORGOT_PASSWORD'
  }

  goToResetPasswordRequested() {
    this.currentStep = 'RESET_PASSWORD_REQUESTED'
  }

  onSignIn() {
    this.modal.close();
    this.modal.hidden.subscribe(() => {
      this.router.navigate(['/home']);
    })
  }

  onResetPassword(data: { email: string }) {
    this.resetPasswordEmail = data.email;
    this.goToResetPasswordRequested();
  }

  onSignUp() {
    this.modal.close();

    const modalRef = this.modalService.open(SignUpModalComponent, {
      centered: true,
      windowClass: 'sign-in-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}
