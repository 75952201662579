import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { Router, RouterModule } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-update-nickname',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './update-nickname.component.html',
  styleUrl: './update-nickname.component.scss',
  animations: [fadeInOut]
})
export class UpdateNicknameComponent implements OnInit {
  updateNameForm!: FormGroup;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['UPDATE_NICKNAME.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['UPDATE_NICKNAME.HEADER']);
      }
    })
  }

  ngOnInit(): void {
    this.updateNameForm = new FormGroup({
      name: new FormControl('', [Validators.required])
    })
  }

  onSubmit() {
    this.accountService.updateNickName(this.updateNameForm.controls['name'].value).subscribe({
      error: err => {
        console.log(err);

      }, complete: () => {
        this.router.navigate(['/my-profile']);
      }
    });
  }
}
