<div class="title-path">
  <div class="wrapper">
    <div class="d-md-flex flex-wrap align-items-start mb-4">
      <div class="headings flex-md-grow-1 pe-4">
        <h2 class="text-dark">Title Path</h2>
        <p>
          Dive into one specialty area, complete activities and earn cool titles
        </p>
      </div>
    </div>

    <div class="title-path-progress">
      <div class="row">
        <div class="col-6 col-md-3 mb-5" *ngFor="let item of partnerTitlePathProgressData">
          <div class="title-path-item d-flex flex-column">
            <div class="progress-wrapper position-relative">
              <svg viewBox="0 0 36 36" class="donut-progress-svg">
                <!-- Background circle -->
                <circle cx="18" cy="18" r="15.91" class="donut-progress-bg" [attr.theme]="item.theme" />
                <!-- Progress circle -->
                <circle cx="18" cy="18" r="15.91" class="donut-progress-fill" stroke-linecap="round"
                  [style.strokeDasharray]="item.progress + ', 100'" [attr.theme]="item.theme" />
              </svg>
              <div class="donut-progress-content">
                <div class="title-path-icon">
                  <img [src]="item.iconURL" alt="Title Path Icon">
                </div>
                <div class="title-path-points d-flex flex-wrap align-items-center justify-content-center">
                  <div class="points-value me-1">{{ item.points | number }}</div>
                  <div class="points-label">pts</div>
                </div>
              </div>
            </div>

            <div
              class="title-path-info text-center d-flex flex-column justify-content-between align-items-center flex-grow-1 mt-3">
              <div class="title-path-title">{{ item.title }}</div>
              <div *ngIf="item.isClaimable" class="title-path-action mt-3">
                <button class="btn btn-sm btn-primary btn-claim-title-path" (click)="claimTitlePath()">
                  Claim Your Title Path
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>