import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environment/environment";
import { Observable, map } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ConfigService } from "./config.service";

@Injectable({
    providedIn: 'root',
})

export class AccountService {
    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private configService: ConfigService
    ) { }

    // ======================================== Bookmark =========================================== //
    // ============================================================================================= //

    // Get ALL bookmark
    getBookmarkContents(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/bookmarks?lang=` + (this.configService.isPreview ? String(this.configService.previewLanguage) : (localStorage.getItem('languageId') ?? '1'))).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // GET isBookmarked
    checkBookmarked(contentId: string): Observable<any> {

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/bookmarks/${contentId}`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // ADD Bookmark
    createBookmark(contentId: string): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/bookmarks/${contentId}`, "").pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return true;
                }
            })
        );
    }

    // DELETE Bookmark
    removeBookmark(contentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/bookmarks/${contentId}`);
    }

    // ======================================== LIKES =========================================== //
    // ========================================================================================== //

    // GET total likes
    getTotalLikes(contentId: string): Observable<any> {

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/likes/${contentId}`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // ADD likes
    createLikes(contentId: string): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/likes/${contentId}`, "").pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return true;
                }
            })
        );
    }

    // DELETE likes
    removeLikes(contentId: string): Observable<any> {
        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/likes/${contentId}`);
    }

    // ======================================== My Profile =========================================== //
    // =============================================================================================== //

    // Get profile data (personal)
    getMyProfile(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.profilePath}`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    updateLanguage(new_language_id: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            cms_language_id: new_language_id
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.profilePath}/language`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // Update Nickname
    updateNickName(new_nickname: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            new_nickname: new_nickname,
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.profilePath}/nickname`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // Update Password
    updatePassword(current_password: string, new_password: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            current_password: current_password,
            new_password: new_password,
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.profilePath}/password`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Update Profile Picture (Avatar OR Upload Profile Picture)
    updateProfilePicture(new_photo: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            photo: new_photo,
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.profilePath}/photo`, body, { headers: reqHeader }).pipe(
            map(res => {
                if (res.error) {
                    this.toastr.error("Something is wrong, please try again!", "Error");
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        )
    }

    // ======================================== My Workshop =========================================== //
    // ================================================================================================ //

    // Get ALL registered workshop
    getRegisteredWorkshops(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.workshopPath}?lang=${localStorage.getItem("languageId") ?? '1'}`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // Check specific workshop is registered.
    checkRegisteredWorkshop(id: string): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.workshopPath}/${id}`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // Register workshop
    registerWorkshop(id: string): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.workshopPath}/${id}`, '').pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    // Unregister workshop
    unregisterWorkshop(id: string): Observable<any> {
        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}${environment.workshopPath}/${id}`);
    }
}