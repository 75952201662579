<div *ngIf="data" class="list-filters">
  <div class="select-menu-group d-flex flex-wrap justify-content-center">
    <div *ngFor="let select_menu of data.select_menu_group" class="form-group">
      <select class="form-select" [name]="select_menu.name" [id]="select_menu.id" (change)="onSelectFilter($event)">
        <option *ngFor="let option of select_menu.options" [value]="option.value">
          {{option.label}}
        </option>
      </select>
    </div>
  </div>
</div>