<div class="person-list">
  <ul class="list-unstyled row">
    <li *ngFor="let item of data" class="col-12 col-sm-6 col-xl-4">
      <div *ngIf="!item.bio_text && !item.bio_video_link" class="person d-flex flex-wrap align-items-center">
        <figure class="person-avatar" [title]="item.name">
          <img [src]="item.image_src" alt="{{item.name}}-img" />
        </figure>
        <div class="person-info">
          <h5 class="person-name text-dark mb-1">{{item.name}}</h5>
          <div *ngIf="item.title" class="person-title">{{item.title}}</div>
          <div *ngIf="item.market" class="person-title">{{item.market}}</div>
        </div>
      </div>

      <a *ngIf="item.bio_text || item.bio_video_link" class="person d-flex flex-wrap align-items-center"
        (click)="item.bio_text || item.bio_video_link ? openPersonBioModal(item):''">

        <figure class="person-avatar" [title]="item.name">
          <img [src]="item.image_src" alt="{{item.name}}-img" />
        </figure>
        <div class="person-info">
          <h5 class="person-name text-dark mb-1">{{item.name}}</h5>
          <div *ngIf="item.title" class="person-title">{{item.title}}</div>
          <div *ngIf="item.market" class="person-title">{{item.market}}</div>
        </div>
      </a>

    </li>
  </ul>
</div>