<div class="modal-header">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <figure class="hero-image">
    <img *ngIf="contentData.card_image_src.sm" [src]="contentData.card_image_src.sm" class="d-md-none"
      alt="{{contentData.headline}}-img" />
    <img [src]="contentData.card_image_src.lg" [ngClass]="{'d-none d-md-block': contentData.card_image_src.sm}"
      alt="{{contentData.headline}}-img" />
  </figure>
</div>
<div class="modal-body">
  <div class="body-header">
    <div class="headings">
      <div class="topic">
        {{contentData.topic}}
      </div>
      <h1>{{contentData.headline}}</h1>
    </div>

    <div *ngIf="contentData.activity_details"
      class="activity-details-top d-flex align-items-center flex-wrap justify-content-lg-end">
      <div *ngIf="contentData.activity_details.code" class="code label label-bap-topic-item label-bap-topic-item-code">
        {{contentData.activity_details.code}}
      </div>
      <div *ngIf="contentData.activity_details.points"
        class="points label label-bap-topic-item label-bap-topic-item-points">
        {{contentData.activity_details.points}}
      </div>
      <div *ngIf="contentData.activity_details.status" class="activity-status">
        <div class="status label label-bap-topic-item label-bap-topic-item-status" [ngClass]="{
          'status-completed': contentData.activity_details.status === 'COMPLETED',
          'status-in-progress': contentData.activity_details.status === 'IN_PROGRESS'
        }">
          {{ contentData.activity_details.status === 'COMPLETED' ? 'Completed' : 
           contentData.activity_details.status === 'IN_PROGRESS' ? 'In Progress' : 
           contentData.activity_details.status }}
        </div>
      </div>
    </div>

    <div *ngIf="contentData.activity_details" class="activity-details-bottom">
      <div *ngIf="contentData.activity_details.duration || contentData.activity_details.is_mandatory"
        class="activity-labels d-flex align-items-center flex-100 gap-2">
        <div *ngIf="contentData.activity_details.duration" class="label label-duration">
          {{contentData.activity_details.duration}}
        </div>
        <div *ngIf="contentData.activity_details.is_mandatory" class="label label-mandatory">
          Mandatory
        </div>
      </div>
    </div>

    <div class="activity-actions d-flex flex-wrap justify-content-lg-end pt-3">
      <button *ngIf="contentData.activity_details.status === 'IN_PROGRESS'" type="button" class="btn btn-white"
        (click)="btnComplete()">
        COMPLETE
      </button>

      <button *ngIf="contentData.activity_details.status === '' || contentData.activity_details.status == null"
        type="button" class="btn btn-primary" (click)="btnInterested()">
        I'M INTERESTED
      </button>
    </div>
  </div>

  <div class="body-content" [innerHTML]="contentData.body_content"></div>

  <div *ngIf="contentData.document_url" class="body-footer">
    <button type="button" class="btn btn-white btn-download" (click)="downloadDocument()">
      <span class="icon-img me-2">
        <icon-download></icon-download>
      </span>
      DOWNLOAD
    </button>
  </div>
</div>