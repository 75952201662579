import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    // TO BE TURN TO JSON TRANSLATE COPY
    public serverErrorMap: { [key: string]: string } = {
        "-1": "Error",
        "1000": "ONBOARDING.NEW_ACC.PARTNER_ID_ERROR_REGISTERED", // Backend: Partner ID has registered
        "1001": "ONBOARDING.PASSWORD.ERROR_OLD_PASSWORD", // Backend: Password has been used before
        "1002": "ONBOARDING.NEW_ACC.EMAIL_ERROR_REGISTERED", // Backend: Email already registered
        "1003": "ONBOARDING.REQUEST_RESET_PASSWORD.ERROR_LOCKED", // Backend: Account has been locked
        "1004": "ONBOARDING.REQUEST_RESET_PASSWORD.ERROR_NOT_ACTIVATED", // Backend: Account has not been activated
        "1005": "ONBOARDING.REQUEST_RESET_PASSWORD.ERROR_NOT_REGISTERED", // Backend: Account not found
        "1006": "ONBOARDING.ACTIVATE_FAIL.DESC_1_INVALID", // Backend: Activation code is invalid
        "1007": "ONBOARDING.ACTIVATE_FAIL.DESC_1_USED", // Backend: Activation code has been used
        "1008": "ONBOARDING.ACTIVATE_FAIL.DESC_1_EXPIRED", // Backend: Activation code has expired
        "1009": "ONBOARDING.RESET_PASSWORD_FAIL.DESC_1_INVALID", // Backend: Reset password code is invalid
        "1010": "ONBOARDING.RESET_PASSWORD_FAIL.DESC_1_USED", // Backend: Reset password code has been used
        "1011": "ONBOARDING.RESET_PASSWORD_FAIL.DESC_1_EXPIRED", // Backend: Reset password code has expired
        "1012": "ONBOARDING.NEW_ACC.PARTNER_ID_ERROR_FORMAT", // Backend: Invalid partner id format
        "1013": "Invalid market code",
        "1014": "ONBOARDING.WELCOME_BACK.ERROR", // Backend: Invalid username and/or password
        "1015": "ONBOARDING.SECRET_CODE.ERROR",
        "1017": "ONBOARDING.WELCOME_BACK.ERROR_EXPIRED",
        "1018": "UPDATE_PASSWORD.CURRENT_PASSWORD_INCORRECT_ERROR"
    }

    public getServerErrorMapping(id: string): string {
        return this.serverErrorMap[id];
    };


    public frontendErrorMap: { [key: string]: string } = {
        "5000": "ONBOARDING.PASSWORD.ERROR_INVALID", // Your password doesn't match one of the requirement, please try a new one
        "5001": "ONBOARDING.PASSWORD.ERROR_NOT_MATCHED",
        "5002": "ONBOARDING.NEW_ACC.EMAIL_ERROR_FORMAT" // Please enter a valid email address. Example: email@starbucks.com
    }

    public getFrontendErrorMapping(id: string): string {
        return this.frontendErrorMap[id];
    };



}
