import { Component, Input } from '@angular/core';
import { SecretCodeComponent } from "./secret-code/secret-code.component";
import { CommonModule } from '@angular/common';
import { LanguagePreferenceComponent } from "./language-preference/language-preference.component";
import { XmarkIcon } from "../../icons/icon-xmark";
import { RegistrationForm1Component } from "./registration-form-1/registration-form-1.component";
import { RegistrationForm2Component } from "./registration-form-2/registration-form-2.component";
import { RegistrationForm3Component } from "./registration-form-3/registration-form-3.component";
import { RegistrationForm4Component } from "./registration-form-4/registration-form-4.component";
import { MoreQuestionsComponent } from "./more-questions/more-questions.component";
import { QuestionComponent } from "./question/question.component";
import { ProfilePictureComponent } from "./profile-picture/profile-picture.component";
import { UploadProfilePictureComponent } from "./upload-profile-picture/upload-profile-picture.component";
import { ReviewProfileComponent } from "./review-profile/review-profile.component";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SignUpModel } from '../../models/sign-up-modal.model';
import { CreateAvatarComponent } from "./avatar/create-avatar/create-avatar.component";
import { EditAvatarComponent } from "./avatar/edit-avatar/edit-avatar.component";
import { ReviewAvatarComponent } from "./avatar/review-avatar/review-avatar.component";

@Component({
  selector: 'app-sign-up-modal',
  standalone: true,
  templateUrl: './sign-up-modal.component.html',
  styleUrl: './sign-up-modal.component.scss',
  imports: [CommonModule, SecretCodeComponent, LanguagePreferenceComponent, XmarkIcon, RegistrationForm1Component, RegistrationForm2Component, RegistrationForm3Component, RegistrationForm4Component, MoreQuestionsComponent, QuestionComponent, ProfilePictureComponent, UploadProfilePictureComponent, ReviewProfileComponent, CreateAvatarComponent, EditAvatarComponent, ReviewAvatarComponent]
})
export class SignUpModalComponent {
  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
  ) { }

  [x: string]: any;
  @Input() modal: any;

  modelData: SignUpModel = {
    captcha_token: '',
    secret_code: '',
    certified_l300: false,
    email: '',
    first_name: '',
    language: 'en', // Default
    last_name: '',
    market: 'ap', // Default
    nick_name: '',
    partner_id: '',
    password: '',
    photo: '',
    questionnaire_answers: []
  };

  currentStep: string = 'SECRET_CODE';
  selectedProfileImageUrl!: string;
  selectedMarketName: string = '';

  closeModal() {
    this.modal.close();
  }

  closeSignUpModal() {
    this.modal.close('Close click');
  }

  dismissModal() {
    this.modal.dismiss();
  }

  getSavedQuestionnairesAnswer(): { 'q': string, 'a': string }[] {
    let inputObject: { q: string, a: string }[] = [];
    const num = this.modelData.questionnaire_answers.length;

    for (let i = 0; i < num; i++) {
      inputObject.push({
        q: this.modelData.questionnaire_answers[i].questionnaire_id,
        a: this.modelData.questionnaire_answers[i].answer_code
      });
    };
    return inputObject;
  }

  goToLanguagePreference() {
    this.currentStep = 'LANGUAGE_PREFERENCE'
  }

  goToSecretCode() {
    this.currentStep = 'SECRET_CODE'
  }

  goToRegForm1() {
    this.currentStep = 'REG_FORM_1'
  }

  goToRegForm2() {
    this.currentStep = 'REG_FORM_2'
  }

  goToRegForm3() {
    this.currentStep = 'REG_FORM_3'
  }

  goToRegForm4() {
    this.currentStep = 'REG_FORM_4'
  }

  goToMoreQuestions() {
    this.currentStep = 'MORE_QUESTIONS'
  }

  goToQuestion() {
    this.currentStep = 'QUESTION'
  }

  goToProfilePicture() {
    this.currentStep = 'PROFILE_PICTURE'
  }

  goToCreateAvatar() {
    this.currentStep = 'CREATE_AVATAR';
  }

  goToEditAvatar() {
    this.currentStep = 'EDIT_AVATAR'
  }

  goToReviewAvatar() {
    this.currentStep = 'REVIEW_AVATAR'
  }

  goToUploadProfilePicture() {
    this.currentStep = 'UPLOAD_PROFILE_PICTURE'
  }

  goToReviewProfile() {
    this.currentStep = 'REVIEW_PROFILE'
  }

  goToRegistrationSuccessfulPage() {
    this.modal.close();
    this.modal.hidden.subscribe(() => {
      this.router.navigate([`/registration-successful`], { queryParams: { e: btoa(this.modelData.email) ?? '' } });
    })
  }

  onAvatarEditSubmit(data: { avatarUri: string }) {
    this.selectedProfileImageUrl = data.avatarUri;
    this.goToReviewAvatar();
  }

  onLanguageSubmit(data: { language: string }) {
    this.modelData.language = data.language;
    this.goToRegForm1();
  }

  onMoreQuestionSubmit(data: { ansQuestion: boolean }) {
    if (data.ansQuestion) {
      this.goToQuestion();
    } else {
      this.modelData.questionnaire_answers = [];
      this.goToProfilePicture();
    }
  }

  onProfilePictureSubmit() {
    this.modelData.photo = this.selectedProfileImageUrl;
    this.goToReviewProfile();
  }

  onQuestionSubmit(data: { q: string, a: string }[]) {
    data.forEach(arrItem => {
      // Find question exist in parentObject or not.
      const answerExist: number = this.modelData.questionnaire_answers.findIndex(
        qId => qId.questionnaire_id === arrItem.q
      );

      // If exist, update answer.
      if (answerExist !== -1) {
        this.modelData.questionnaire_answers[answerExist].answer_code = arrItem.a;

      } else { // create new object
        this.modelData.questionnaire_answers.push({
          questionnaire_id: arrItem.q,
          answer_code: arrItem.a
        })
      }
    })

    this.goToProfilePicture();
  }

  onRegForm1Submit(data: { market: string, partnerId: string, email: string }) {
    this.modelData.market = data.market;
    this.modelData.partner_id = data.partnerId;
    this.modelData.email = data.email;
    this.goToRegForm2();
  }

  onRegForm2Submit(data: { firstName: string, lastName: string, nickName: string }) {
    this.modelData.first_name = data.firstName;
    this.modelData.last_name = data.lastName;
    this.modelData.nick_name = data.nickName;
    this.goToRegForm3();
  }

  onRegForm3Submit(data: { password: string }) {
    this.modelData.password = data.password;
    this.goToRegForm4();
  }

  onRegForm4Submit(data: { lv300: boolean }) {
    this.modelData.certified_l300 = data.lv300;
    // SKIP MORE QUESTIONS
    // if (this.modelData.questionnaire_answers.length === 0) {
    //   this.goToMoreQuestions();
    // } else {
    //   this.goToQuestion();
    // }
    this.goToProfilePicture();
  }

  onSecretCodeSubmit(data: { secretCode: string }) {
    this.modelData.secret_code = data.secretCode;
    this.goToLanguagePreference();
  }

  returnFromProfilePicture() {
    // SKIP MORE QUESTIONS
    // if (this.modelData.questionnaire_answers.length === 0) {
    //   this.goToMoreQuestions();
    // } else {
    //   this.goToQuestion();
    // }

    this.goToRegForm4();
  }
}
