<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-body px-4 px-lg-6 px-xl-8">

    <div class="inner-body-contents">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-12 col-lg-5 mb-4">
            <div class="profile-picture px-2 px-sm-4 px-lg-0">
              <figure>
                <img [src]="getProfilePictureUrl()"
                  onerror="this.src='/assets/images/avatar/img-avatar-placeholder.png';">
              </figure>
            </div>
          </div>
          <div class="col-12 col-lg-5 d-flex flex-column justify-content-center">
            <h3>{{"MY_PROFILE.REVIEW_AVATAR.HEADER"| translate}}</h3>
            <!-- <p>Free feel to use it on the Starbucks website!
              Or you can create your avatar again</p> -->

            <div class="actions mt-4 d-flex flex-wrap">

              <button type="button" class="btn btn-primary w-100" (click)="dismissModal()">
                {{"MY_PROFILE.REVIEW_AVATAR.NEXT"| translate}}
              </button>
              <button *ngIf="input" type="button" class="btn btn-secondary w-100" (click)="downloadProfilePicture()">
                {{"MY_PROFILE.REVIEW_AVATAR.DOWNLOAD" | translate}}
              </button>
              <button type="button" class="btn btn-secondary w-100" (click)="goToChangeProfilePicture()">
                {{"MY_PROFILE.REVIEW_AVATAR.BACK" | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>
</div>