import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../environment/environment";

@Injectable({
    providedIn: 'root',
})

export class PublicService {
    constructor(
        private http: HttpClient
    ) { }

    getLanguage(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}/languages`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }

    getMarket(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}/markets`).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res.data;
                }
            })
        );
    }
}


