<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="content-heading mb-5">
    <div class="container">
      <h1>{{"MY_WORKSHOP.HEADER" | translate}}</h1>
      <p>{{"MY_WORKSHOP.DESC" | translate}}</p>
    </div>
  </section>

  <section class="content-body">

    <div class="container">
      <div class="mb-5">
        <!-- <h2 class="mb-4">{{"MY_WORKSHOP.HEADER1" | translate}}</h2> -->
        <app-grid-list-with-date-time [data]="myWorkshopDatas"></app-grid-list-with-date-time>
      </div>

      <!-- <div class="mb-5">
        <h2 class="mb-4">{{"MY_WORKSHOP.HEADER2" | translate}}</h2>
        <app-grid-list-with-date-time [dataUrl]="gridListWithDateTimeDataUrl2"></app-grid-list-with-date-time>
      </div> -->

    </div>
  </section>


</div>