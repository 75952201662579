
import { Component } from '@angular/core';

@Component({
  selector: 'icon-speaker-sound',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 1134" viewBox="0 0 8.291 6.601">
  <path d="M6.133 1.728a.408.408 0 1 0-.568.585 1.351 1.351 0 0 1 0 1.957.4.4 0 0 0-.009.577.4.4 0 0 0 .3.122.417.417 0 0 0 .288-.114 2.158 2.158 0 0 0 .664-1.572 2.223 2.223 0 0 0-.673-1.555" data-name="Path 70"/>
  <path d="M7.067.557a.409.409 0 0 0-.542.612 2.862 2.862 0 0 1 0 4.263.412.412 0 0 0-.035.577.418.418 0 0 0 .306.14.406.406 0 0 0 .271-.1A3.687 3.687 0 0 0 8.29 3.301 3.625 3.625 0 0 0 7.067.558" data-name="Path 71"/>
  <path d="M4.193.05 1.721 1.893H.245A.244.244 0 0 0 0 2.138v2.315a.244.244 0 0 0 .245.247h1.476l2.472 1.85a.245.245 0 0 0 .393-.2V.242A.245.245 0 0 0 4.193.05" data-name="Path 72"/>
</svg>
  `,
  styles: [],
})
export class SpeakerSoundIcon { }


