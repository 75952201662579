/// <reference types="@angular/localize" />
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environment/environment';

// disable console log & warn in production
if (environment.production) {
  console.log = () => { };
  console.warn = () => { };
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
