import { Component } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-bap',
  standalone: true,
  templateUrl: './about-bap.component.html',
  styleUrl: './about-bap.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, ImageBanner1Component]
})
export class AboutBapComponent {
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/bap',
      label: 'Black Apron Program'
    }
  ]

  imageBannerData = {
    "content_id": "content-id-1",
    "image_src": {
      "sm": "/assets/images/bap/BAP-1600x600.webp",
      "lg": "/assets/images/bap/BAP-1600x600.webp"
    }
  };

  constructor(
    private titleService: Title
  ) {
    titleService.setTitle('About BAP');
  }
  // Need to set page title in future
}
