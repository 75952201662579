<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-2 [data]="competitionHomeBannerData"></app-image-banner-2>
    </div>
  </section>

  <section *ngIf="competitionInformationStaticData.length > 0" class="mb-5 text-center">
    <div class="container">
      <h1 class="headline mb-3">{{"COMPETITIONS.ABOUT.HEADER" | translate}}</h1>
      <div class="descriptions ">{{"COMPETITIONS.ABOUT.DESC" | translate}}</div>
    </div>
  </section>

  <section *ngIf="competitionInformationStaticData.length > 0" class="mb-3">
    <div class="container">
      <app-grid-list-4 [data]="competitionInformationStaticData"></app-grid-list-4>
    </div>
  </section>


  <section *ngIf="competitionFeaturedVideoData.length > 0" class="mb-5">
    <div class="container">
      <!-- <h2 class="mb-3 pe-8">{{"HOME.SPECIAL_TOPIC" | translate}}</h2> -->
      <h2 class="mb-3 pe-8">Featured Videos</h2> <!--TODO : Translation-->
      <app-banner-carousel-2 [data]="competitionFeaturedVideoData"></app-banner-carousel-2>
    </div>
  </section>


  <section class="mb-5">
    <div class="container">
      <a (click)="this.analyticsService.sendClickEventAnalytic('CONTENT', competitionSteeringCommitteeBannerData.content_type, competitionSteeringCommitteeBannerData.content_id, competitionSteeringCommitteeBannerData.headline)"
        routerLink="competition-steering-committee" class="d-block" [title]="competitionSteeringCommitteeTitle">
        <img [src]="competitionSteeringCommitteeBannerData.image_src.sm" alt="Competition Steering Committee"
          class="d-md-none w-100">
        <img [src]="competitionSteeringCommitteeBannerData.image_src.lg" alt="Competition Steering Committee"
          class="d-none d-md-block w-100">
      </a>
    </div>
  </section>

  <section class="mb-5 ">
    <div class="container">
      <h1 class="headline mb-3 text-center">{{"COMPETITIONS.SEARCH.HEADER" | translate}}</h1>
      <div class="descriptions mb-4 text-center ">{{"COMPETITIONS.SEARCH.DESC" | translate}}</div>

      <div *ngIf="competitionData.length > 0 && retrievedNameFilter && retrievedYearFilter" class="filters mb-6">
        <app-list-filters [data]="listFiltersData" (onCompleted)="filterTopicData($event)"></app-list-filters>
      </div>

      <div *ngIf="competitionData.length > 0 && retrievedNameFilter && retrievedYearFilter" class=" video-list">
        <app-grid-list-3 [data]="filteredCompetitionData"></app-grid-list-3>
      </div>
    </div>
  </section>
</div>