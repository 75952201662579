<div class="video-language">
  <div ngbDropdown #videoLanguageDropDown="ngbDropdown" placement="bottom-start" class="custom-dropdown">
    <button type="button" class="btn btn-white btn-sm btn-video-language" id="videoLanguageDropDown" ngbDropdownToggle>
      <span class="icon-img me-3">
        <icon-speaker-sound></icon-speaker-sound>
      </span>
      {{selectedLanguage}}
    </button>
    <div ngbDropdownMenu aria-labelledby="videoLanguageDropDown">
      <button ngbDropdownItem>English</button>
      <button ngbDropdownItem>繁體中文</button>
      <button ngbDropdownItem>日本語</button>
      <button ngbDropdownItem>한국어</button>
      <button ngbDropdownItem>Bahasa Indonesia</button>
      <button ngbDropdownItem>Tiếng Việt</button>
      <button ngbDropdownItem>ภาษาไทย</button>
    </div>
  </div>
</div>