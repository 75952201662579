
import { Component } from '@angular/core';

@Component({
  selector: 'icon-microsoft-365',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class=""><path d="M320 0 32 112v304l96-32V128l192-32v353.408L32 416l288 96 160-48V48z"></path></svg>
  `,
  styles: [],
})
export class Microsoft365Icon { }