<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-1-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-1-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="inner-body-heading">
      <h2 i18n="@@sign-up-headline">{{"ONBOARDING.SECRET_CODE.HEADER" | translate}}</h2>
      <p i18n="@@sign-up-subheadline">{{"ONBOARDING.SECRET_CODE.DESC" | translate}}</p>
    </div>
    <div class="inner-body-contents">
      <fieldset class="mb-4" [formGroup]="secretForm">
        <div class="form-group">
          <label for="secret-code-input" class="form-label"
            i18n="@@secret-code-input-label">{{"ONBOARDING.SECRET_CODE.SECRET_CODE" | translate}}</label>
          <input type="password" class="form-control" id="secret-code-input"
            [placeholder]="'GENERAL.ENTER_HERE' | translate" formControlName="secretCode"
            (ngModelChange)="serverSecretCodeError = false;">

          <div *ngIf="serverSecretCodeError" class="invalid-message">
            {{serverSecretCodeErrorMessage | translate}}
          </div>
        </div>
      </fieldset>
    </div>
    <div class="inner-body-footer">
      <button type="button" *ngIf="!isSubmitting" class="btn btn-primary w-100 w-lg-auto" [disabled]="!secretForm.valid"
        (click)="onSubmit()" i18n="@@submit-button">{{"ONBOARDING.SECRET_CODE.SUBMIT" | translate}}</button>
      <button type="button" *ngIf="isSubmitting" class="btn btn-spinner w-100 w-lg-auto" disabled>
        <span class="icon-img">
          <icon-spinner></icon-spinner>
        </span>
      </button>
    </div>
  </div>
</div>