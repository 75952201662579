import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password-successful',
  standalone: true,
  imports: [RouterModule, CommonModule, TranslateModule],
  templateUrl: './reset-password-successful.component.html',
  styleUrl: './reset-password-successful.component.scss',
  animations: [fadeInOut],
})
export class ResetPasswordSuccessfulComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(
    // private modalService: NgbModal,
    private authService: AuthService,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['GENERAL.PAGE_TITLE.RESET_PASSWORD_SUCCESSFUL']).subscribe({
      next: res => {
        titleService.setTitle(res['GENERAL.PAGE_TITLE.RESET_PASSWORD_SUCCESSFUL']);
      }
    })
  }



  ngOnInit(): void {
    const userLoggedIn = this.authService.userIsLoggedIn();

    if (userLoggedIn) {
      this.isLoggedIn = true;
    }
  }

  // openSignInModal() {
  //   const modalRef = this.modalService.open(SignInModalComponent, {
  //     centered: true,
  //     windowClass: 'sign-in-modal',
  //     size: 'xl'
  //   });
  //   modalRef.componentInstance.modal = modalRef;
  // }
}
