
import { Component } from '@angular/core';

@Component({
  selector: 'icon-arrow-left',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448.004 256C448.004 264.844 440.848 272 432.004 272H68.174L203.848 421.25C209.785 427.781 209.301 437.906 202.77 443.844C199.707 446.625 195.848 448 192.004 448C187.66 448 183.316 446.25 180.16 442.75L20.16 266.75C14.613 260.656 14.613 251.344 20.16 245.25L180.16 69.25C186.129 62.688 196.254 62.25 202.77 68.156C209.301 74.094 209.785 84.219 203.848 90.75L68.174 240H432.004C440.848 240 448.004 247.156 448.004 256Z"/></svg>
  `,
  styles: [],
})
export class ArrowLeftIcon { }