<div class="modal-header">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <figure class="hero-image">
    <img *ngIf="contentData.card_image_src.sm" [src]="contentData.card_image_src.sm" class="d-md-none"
      alt="{{contentData.headline}}-img" />
    <img [src]="contentData.card_image_src.lg" [ngClass]="{'d-none d-md-block': contentData.card_image_src.sm}"
      alt="{{contentData.headline}}-img" />
  </figure>
</div>
<div class="modal-body" [ngClass]="{'activity-completed': contentData.activity_details.status === 'COMPLETED'}">
  <div class="body-header">
    <div class="headings">
      <div class="topic">
        {{contentData.topic}}
      </div>
      <h1>{{contentData.headline}}</h1>
    </div>

    <div *ngIf="contentData.activity_details"
      class="activity-details-top d-flex align-items-center flex-wrap justify-content-lg-end">
      <div *ngIf="contentData.activity_details.code" class="code label label-bap-topic-item label-bap-topic-item-code">
        {{contentData.activity_details.code}}
      </div>
      <div *ngIf="contentData.activity_details.points"
        class="points label label-bap-topic-item label-bap-topic-item-points">
        {{contentData.activity_details.points}}
      </div>
      <div *ngIf="contentData.activity_details.status" class="activity-status">
        <div class="status label label-bap-topic-item label-bap-topic-item-status" [ngClass]="{
          'status-completed': contentData.activity_details.status === 'COMPLETED',
          'status-in-progress': contentData.activity_details.status === 'IN_PROGRESS'
        }">
          {{ contentData.activity_details.status === 'COMPLETED' ? 'Completed' : 
           contentData.activity_details.status === 'IN_PROGRESS' ? 'In Progress' : 
           contentData.activity_details.status }}
        </div>
      </div>
    </div>

    <div *ngIf="contentData.activity_details" class="activity-details-bottom">
      <div *ngIf="contentData.activity_details.duration || contentData.activity_details.is_mandatory"
        class="activity-labels d-flex align-items-center flex-100 gap-2">
        <div *ngIf="contentData.activity_details.duration" class="label label-duration">
          {{contentData.activity_details.duration}}
        </div>
        <div *ngIf="contentData.activity_details.is_mandatory" class="label label-mandatory">
          Mandatory
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="contentData.activity_details.status !== 'COMPLETED'">
    <div class="body-content">
      <div class="introduction" [innerHTML]="contentData.introduction_content"></div>

      <div class="question-container">
        <form>
          <ul class="question-list list-unstyled">
            <li class="question-item" *ngFor="let question of questionsData; let i = index">
              <div class="form-group radio-group">
                <div class="question" [id]="question.id">
                  <div class="mb-2"><strong>Question {{ i + 1 }}</strong></div>
                  <p class="mb-3">{{ question.question }}</p>
                </div>

                <div class="answer-options">
                  <div class="form-check mb-2" *ngFor="let option of question.options">
                    <input class="form-check-input" type="radio" [name]="question.id" [id]="option.id"
                      [checked]="isOptionSelected(question.id, option.id)"
                      (change)="onAnswerChange(question.id, option.id)">
                    <label class="form-check-label" [for]="option.id">
                      {{ option.text }}
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>

    <div class="body-footer">
      <button type="button" class="btn btn-white" (click)="submitQuiz()" [disabled]="isLoading">
        <ng-container *ngIf="!isLoading">
          Submit
        </ng-container>
        <ng-container *ngIf="isLoading">
          <span class="icon-img me-2">
            <icon-spinner></icon-spinner>
          </span> Loading...
        </ng-container>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="contentData.activity_details.status === 'COMPLETED'">
    <div class="body-content">
      <ul class="question-list list-unstyled">
        <li class="question-item" *ngFor="let question of questionsData; let i = index">
          <div class="question" [id]="question.id">
            <div class="mb-2"><strong>Question {{ i + 1 }}</strong></div>
            <p class="mb-3 text-black-50">{{ question.question }}</p>
          </div>

          <div class="answer">
            <h5 class="text-black">Ans: {{ getCorrectAnswer(question.id)?.text }}</h5>
            <p class="mb-0">{{ getCorrectAnswer(question.id)?.answer_reason }}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="body-footer">
      <button type="button" class="btn btn-white" (click)="dismissModal()">
        OK!
      </button>
    </div>
  </ng-container>
</div>

<div *ngIf="isLoading" class="spinner-container d-flex flex-column justify-content-center align-items-center">
  <span class="icon-img">
    <icon-spinner></icon-spinner>
  </span>
</div>