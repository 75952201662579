import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VideoListModel } from '../models';

@Injectable({
  providedIn: 'any'
})

export class VideoListService {
  //private dataUrl = '/assets/data/banner-carousel-1.json';

  constructor(private http: HttpClient) { }

  getVideoListData(dataUrl: string): Observable<VideoListModel[]> {
    return this.http.get<VideoListModel[]>(dataUrl);
  }
}
