import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

interface Activity {
  title: string;
  theme: string;
  status: string;
  code: string;
  points: string;
  name: string;
}

interface ActivityGroup {
  date: string;
  activities: Activity[];
}

@Component({
  selector: 'app-bap-activity-history',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bap-activity-history.component.html',
  styleUrl: './bap-activity-history.component.scss'
})
export class BapActivityHistoryComponent {
  @Input() activityHistory: ActivityGroup[] = [];
}
