
import { Component } from '@angular/core';

@Component({
  selector: 'icon-like',
  standalone: true,
  template: `
 <svg version="1.1" id="Group_860" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 25 25" style="enable-background:new 0 0 25 25;" xml:space="preserve">
<g>
	<path class="border" d="M22.6,9.7c-0.2-0.2-0.5-0.3-0.8-0.3h-5.2V3.1c0-0.6-0.5-1-1-1h-2.1c-0.4,0-0.8,0.3-1,0.6L9.7,9.4H8.3c0-0.6-0.5-1-1-1H3.1
		c-0.6,0-1,0.5-1,1v12.5c0,0.6,0.5,1,1,1h4.2c0.6,0,1-0.5,1-1h12.5c0.5,0,1-0.4,1-0.9l1-10.4C22.9,10.2,22.8,9.9,22.6,9.7z
		 M6.3,20.8H4.2V10.4h2.1V20.8z M19.9,19.8H8.3v-8.3h2.1c0.4,0,0.8-0.3,1-0.6l2.9-6.7h0.4v6.2c0,0.6,0.5,1,1,1h5.1L19.9,19.8z"/>
	<path class="body" d="M14.7,10.4V4.2h-0.4l-2.9,6.7c-0.2,0.3-0.6,0.6-1,0.6H8.3v8.3h11.6l0.9-8.4h-5.1C15.2,11.4,14.7,11,14.7,10.4z
		"/>
	<rect x="4.2" y="10.4" class="body" width="2.1" height="10.4"/>
</g>
</svg>
  `,
  styles: [],
})
export class LikeIcon { }


