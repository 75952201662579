import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { GridListWithDateTimeComponent } from "../../components/grid-list-with-date-time/grid-list-with-date-time.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { GridListWithDateTimeModel } from '../../models';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-my-workshop',
  standalone: true,
  templateUrl: './my-workshop.component.html',
  styleUrl: './my-workshop.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, GridListWithDateTimeComponent, TranslateModule]
})
export class MyWorkshopComponent implements OnInit {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "My Bookmarks"
    }
  ]

  myWorkshopDatas: GridListWithDateTimeModel[] = [];

  // gridListWithDateTimeDataUrl2 = '/assets/data/my-past-workshops.json'

  constructor(
    private accountService: AccountService,
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'MY_WORKSHOP.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['MY_WORKSHOP.HEADER'];

        this.titleService.setTitle(res['MY_WORKSHOP.HEADER']);
      }
    })

    // Get Registered Workshop Data
    this.accountService.getRegisteredWorkshops().subscribe({
      next: res => {

        res.forEach((item: any) => {
          this.myWorkshopDatas.push({
            content_type: item.contentType,
            content_id: item.content_id,
            image_src: item.cover_photo,
            link: `/my-workshop/${item.content_id}`,
            headline: item.title,
            start_date: item.workshop_start_at,
            end_date: item.workshop_end_at,
            descriptions: item.description,
            unregister_button: {
              workshop_id: item.content_id,
            }
          })
        });
      },
      error: err => {
        console.log(err);
      }
    })
  }
}
