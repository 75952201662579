import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { XmarkIcon, SpinnerIcon } from "../../icons";

@Component({
  selector: 'app-bap-quiz-modal',
  standalone: true,
  imports: [XmarkIcon, CommonModule, FormsModule, SpinnerIcon],
  templateUrl: './bap-quiz-modal.component.html',
  styleUrl: './bap-quiz-modal.component.scss'
})
export class BapQuizModalComponent implements OnInit {
  @Input() modal: any;
  @Input() id: string = '';
  @Input() activityStatus: string = ''; // Use to stimulate the activity status only, remove after integration

  contentData: any;
  isLoading: boolean = false;

  questionsData = [
    {
      id: 'q1',
      question: 'How would you describe C.A.F.E. Practices to a friend, family member, or customer?',
      options: [
        {
          id: 'q1_opt1',
          text: 'A coffee quality verification program that ensures Starbucks sources high-quality coffee beans',
          is_answer: false,
          answer_reason: ''
        },
        {
          id: 'q1_opt2',
          text: 'A comprehensive set of social, environmental, and economic guidelines for coffee farming',
          is_answer: true,
          answer_reason: 'C.A.F.E. Practices is a comprehensive program that covers multiple aspects of sustainable coffee farming, including social responsibility, environmental stewardship, and economic transparency, not just quality.'
        },
        {
          id: 'q1_opt3',
          text: 'A sustainability program focused exclusively on environmental conservation in coffee-growing regions',
          is_answer: false,
          answer_reason: ''
        },
        {
          id: 'q1_opt4',
          text: 'A certification program that only addresses fair wages for coffee farmers',
          is_answer: false,
          answer_reason: ''
        }
      ]
    },
    {
      id: 'q2',
      question: 'Which of the following is the most important component of C.A.F.E. Practices?',
      options: [
        {
          id: 'q2_opt1',
          text: 'Quality',
          is_answer: true,
          answer_reason: 'Quality is the foundation of C.A.F.E. Practices as it ensures that Starbucks maintains its high standards while promoting sustainable practices. Without quality, the other components cannot create value for both farmers and customers.'
        },
        {
          id: 'q2_opt2',
          text: 'Economic Transparency',
          is_answer: false,
          answer_reason: ''
        },
        {
          id: 'q2_opt3',
          text: 'Social Responsibility',
          is_answer: false,
          answer_reason: ''
        },
        {
          id: 'q2_opt4',
          text: 'Environmental Leadership',
          is_answer: false,
          answer_reason: ''
        }
      ]
    }
  ];

  userAnswers: { [key: string]: string } = {};

  constructor() { }

  ngOnInit(): void {
    this.contentData = {
      card_image_src: {
        lg: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741836569780-61a163e8bb0386b6a5295736067a29e0.jpeg',
        sm: '', // optional
      },
      topic: 'Origin & Ethical Sourcing',
      headline: 'C.A.F.E. Practices: Quiz',
      activity_details: {
        code: "OES 1",
        points: "140PTS",
        status: this.activityStatus,
        duration: "46m",
      },
      introduction_content: `
        <p>Test your knowledge about C.A.F.E. Practices and our commitment to ethical sourcing.</p>
        <p><strong>Instructions:</strong><br>
        Select the best answer for each question. You can only choose one answer per question.</p>
      `,
      document_url: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/documents/1741847863277-sample-card-doc.pdf'
    };

    // Initialize empty answers for each question
    this.questionsData.forEach(question => {
      this.userAnswers[question.id] = '';
    });
  }

  dismissModal() {
    this.modal.dismiss();
  }

  onAnswerChange(questionId: string, optionId: string): void {
    this.userAnswers[questionId] = optionId;
  }

  isOptionSelected(questionId: string, optionId: string): boolean {
    return this.userAnswers[questionId] === optionId;
  }

  getCorrectAnswer(questionId: string) {
    const question = this.questionsData.find(q => q.id === questionId);
    return question?.options.find(opt => opt.is_answer);
  }

  submitQuiz(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.contentData.activity_details.status = 'COMPLETED';
      this.isLoading = false;
    }, 1000);
  }
}

