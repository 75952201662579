import { Component, Input, OnInit, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XmarkIcon } from "../../icons/icon-xmark";
import { DownloadIcon } from "../../icons/icon-download";
import { ClickableEnlargeImageDirective } from '../../directives/clickable-enlarge-image.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bap-new-flash-card-modal',
  standalone: true,
  imports: [XmarkIcon, CommonModule, DownloadIcon, ClickableEnlargeImageDirective],
  templateUrl: './bap-new-flash-card-modal.component.html',
  styleUrl: './bap-new-flash-card-modal.component.scss',
  host: {
    '[class]': 'themeClass'
  }
})
export class BapNewFlashCardModalComponent implements OnInit, AfterViewInit {
  @Input() modal: any;
  @Input() activityStatus: string = ''; // Use to stimulate the activity status only, remove after integration
  @Input() theme: string = ''; // Use to stimulate the activity status only, remove after integration
  contentData: any;

  constructor(
    private elementRef: ElementRef,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) { }

  get themeClass(): string {
    return `theme-${(this.theme || this.contentData?.activity_details?.theme || 'default').toLowerCase()}`;
  }

  ngOnInit() {

    this.contentData = {
      card_image_src: {
        lg: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741836569780-61a163e8bb0386b6a5295736067a29e0.jpeg',
        sm: '', // optional
      },
      topic: 'Origin & Ethical Sourcing',
      headline: 'Create Your Coffee Journey',
      activity_details: {
        code: "OES 1",
        points: "140PTS",
        status: this.activityStatus,
        duration: "46m",
        is_mandatory: true,
        theme: this.theme,
      },
      body_content: `
        <h6>What do you need?</h6>

        <ul>
          <li>Coffee Journal</li>
          <li>Color Pen & Markers</li>
          <li>Decorative Items</li>
          <li>Personalized Book (A5 preferred)</li>
        </ul>

        <h6>Action</h6>

        <ol>
          <li class="mb-2"><strong>Gather</strong> the necessary materials like a journal, pens, markers, and any other decorative items you'd like to use.
          <figure>
            <img src="https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1741836569780-61a163e8bb0386b6a5295736067a29e0.jpeg" alt="Coffee Journal" class="clickable-enlarge-image">
          </figure>
          </li>
          <li class="mb-2"><strong>Begin</strong> by designing the cover of your journal. You can draw coffee-related artwork, write a catchy title, or use stickers to make it visually appealing.</li>
          <li class="mb-2"><strong>Flip</strong> to the first page and write an introduction to your coffee journey.</li>
          <li class="mb-2"><strong>Share</strong> your motivations, expectations, and goals for exploring the world of coffee.</li>
          <li class="mb-2"><strong>Organize</strong> the journal into sections: Origin & Ethical Sourcing, Brew & Craft, Roast & Blend and Coffee Leadership.</li>
        </ol>
      `,
      document_url: 'https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/documents/1741847863277-sample-card-doc.pdf'
    };
  }

  dismissModal() {
    this.modal.dismiss();
  }

  btnInterested() {
    console.log('btnInterested');
  }

  btnComplete() {
    console.log('btnComplete');
  }

  downloadDocument() {
    if (this.contentData.document_url) {
      window.open(this.contentData.document_url, '_blank');
    }
  }

  ngAfterViewInit() {
    // Remove any aria-hidden attributes that might be added by the modal
    const modalElement = this.elementRef.nativeElement;
    if (modalElement) {
      modalElement.removeAttribute('aria-hidden');
    }

    // Initialize PhotoSwipe for dynamically added images
    const images = modalElement.querySelectorAll('img.clickable-enlarge-image');
    images.forEach((img: Element) => {
      const directive = new ClickableEnlargeImageDirective(new ElementRef(img), this.ngZone);
      directive.ngOnInit();
    });
  }

}
