
import { Component } from '@angular/core';

@Component({
  selector: 'icon-arrow-right',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M427.844 266.75L267.844 442.75C264.688 446.25 260.344 448 256 448C252.156 448 248.297 446.625 245.234 443.844C238.703 437.906 238.219 427.781 244.156 421.25L379.83 272H16C7.156 272 0 264.844 0 256S7.156 240 16 240H379.83L244.156 90.75C238.219 84.219 238.703 74.094 245.234 68.156C251.781 62.25 261.891 62.688 267.844 69.25L427.844 245.25C433.391 251.344 433.391 260.656 427.844 266.75Z"/></svg>
  `,
  styles: [],
})
export class ArrowRightIcon { }