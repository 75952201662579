import { Component, Input } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-star-upgraded-notice-modal',
  standalone: true,
  imports: [XmarkIcon, TranslateModule],
  templateUrl: './star-upgraded-notice-modal.component.html',
  styleUrl: './star-upgraded-notice-modal.component.scss'
})
export class StarUpgradedNoticeModalComponent {
  @Input() modal: any;

  dismissModal() {
    this.modal.dismiss();
  }
}
