<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="home-hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="imageBannerData"></app-image-banner-1>
    </div>
  </section>

  <section class="content-heading mb-5">
    <div class=" container mw-lg">
      <h1 class="headline mb-3 text-center">About the Black Apron</h1>
      <p>
        As you have been exploring the world of coffee and the role Starbucks plays, you have no doubt heard
        about “ethical sourcing.” It sounds like a big deal—
        and it is Ethical sourcing is something Starbucks has believed in and cared about since our very beginnings as a
        company back in 1971.Simply put, ethical sourcing is the practice of making sure that a product is acquired in a
        responsible,
        sustainable way. In our case, coffee. This includes helping ensure safe, fair working conditions for our farmers
        and taking into account any environmental or social impacts that may result from the sourcing process. Ethical
        sourcing is a big part of who we are — it marries with Our Mission and Values, our culture and the way we
        conduct ourselves as a global company. Though we have always embraced these principles, it was in 1998 that we
        significantly formalized our approach to ethical sourcing through a very important partners.
      </p>

      <div class="py-4">
        <figure class="rounded-4 overflow-hidden">
          <img src="/assets/images/bap/BAP-1600x600.webp">
        </figure>
      </div>

      <p>In 1998, Starbucks started working with Conservation International (CI), a nonprofit environmental
        organization.
        CI works with businesses to protect the earth’s most valuable resources.
        Initiating our relationship with CI was one of the most important steps Starbucks has taken to more fully and
        formally commit our company to social responsibility.</p>

      <div class="row py-4">
        <div class="col-12 col-md-6">
          <figure class="rounded-4 overflow-hidden">
            <img src="/assets/images/bap/BAP-1200x675.webp">
          </figure>
        </div>

        <div class="col-12 col-md-6">
          <figure class="rounded-4 overflow-hidden">
            <img src="/assets/images/bap/BAP-1200x675.webp">
          </figure>
        </div>
      </div>
    </div>
  </section>

  <section class="content-body mb-5">
    <div class="container mw-lg">
    </div>
  </section>
</div>