import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { GridList1Component } from "../../components/grid-list-1/grid-list-1.component";
import { ListCarousel1Component } from "../../components/list-carousel-1/list-carousel-1.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ContentService } from '../../services/content.service';
import { GridList1Model, ImageBanner1Model, ListCarousel1Model } from '../../models';
import { BapFlashCardModalComponent } from '../../components/bap-flash-card-modal/bap-flash-card-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnlockBapContentModalComponent } from '../../components/unlock-bap-content-modal/unlock-bap-content-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { ActivatedRoute } from '@angular/router';
import { BapNewFlashCardModalComponent } from '../../components/bap-new-flash-card-modal/bap-new-flash-card-modal.component';
import { BapQuestionnaireModalComponent } from '../../components/bap-questionnaire-modal/bap-questionnaire-modal.component';
import { BapQuizModalComponent } from '../../components/bap-quiz-modal/bap-quiz-modal.component';

@Component({
  selector: 'app-bap',
  standalone: true,
  templateUrl: './bap.component.html',
  styleUrl: './bap.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, ImageBanner1Component, GridList1Component, ListCarousel1Component, TranslateModule]
})
export class BapComponent implements OnInit, OnDestroy {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Black Apron Program"
    }
  ]

  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicItemContentType: string = 'SbuxBapTopicItem';
  bapHomeBannerContentType: string = 'SbuxBapHomeBanner';
  bapTopicSection: any[] = [];
  bapTopicData: GridList1Model[] = [];
  bapHomeBannerData: ImageBanner1Model = {
    content_id: "bap-home-banner",
    image_src: {
      sm: "",
      lg: ""
    }
  };

  bapTopic1 = [
    {
      "content_type": "static-bap-topic",
      "content_id": "content-id-1",
      "type": "REGULAR",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027600113-OES%201%20Coffee%20Journal%202.jpg",
      "link": "/bap-article",
      "headline": "Create Your Coffee Journal",
      "descriptions": "Get Creative! Create your own Black Apron Coffee Journal to kickstart your next journey in coffee. Once completed, take a picture of it and earn your first points!",
      "activity_details": {
        "code": "OES 1",
        "points": "140PTS",
        "status": "COMPLETED",
        "duration": "46m"
      },
      "mark_as_new": true
    },
    {
      "content_type": "static-bap-topic",
      "content_id": "content-id-4",
      "type": "REGULAR",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Explore Coffee Varieties!",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "",
        "duration": "46m"
      }
    },
    {
      "content_type": "static-bap-topic",
      "content_id": "content-id-5",
      "type": "REGULAR",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Explore Coffee Varieties!",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "IN_PROGRESS",
        "duration": "46m"
      }
    },
    {
      "content_type": "static-bap-topic",
      "content_id": "content-id-6",
      "type": "REGULAR",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Explore Coffee Varieties!",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "COMPLETED",
        "duration": "46m"
      }
    },
    {
      "content_type": "static-bap-topic",
      "content_id": "content-id-7",
      "type": "REGULAR",
      "image_src": "https://stg-blob-gfauhqfxd9auhycw.z02.azurefd.net/stg-container/images/1726027583942-OES%204%20Coffee%20Varietals.jpg",
      "link": "/bap-article",
      "headline": "Explore Coffee Varieties!",
      "descriptions": "Explore Coffee Varieties! Are you a coffee lover looking to expand your knowledge of different coffee varieties? Today, we'll be exploring the wide world of coffee and all the exciting flavors and aromas it has to offer.",
      "activity_details": {
        "code": "OES 4",
        "points": "90PTS",
        "status": "",
        "duration": "46m"
      },
      "mark_as_new": true,
      "level_requirement": "STAR_3",
      "disabled": true
    }
  ];

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translation: TranslateService,
    private titleService: Title,
    private contentService: ContentService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private configService: ConfigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Open Flash Card Modal with queryParam
    this.route.queryParams.subscribe(params => {
      const f = params['f'];
      if (f) {
        this.contentService.getPartnerContent(this.bapTopicItemContentType, f).subscribe({
          next: res => {
            if (res[0] && res[0].type && res[0].type.toUpperCase() === 'FLASH CARD') {
              this.openBapFlashCardModal({ identifier: res[0].identifier, tier: res[0].blackApronExclusive.replace(/ /g, '_').toUpperCase(), });
            }
          }
        })
      }
    });

    // Get breadcrumbs translation
    // this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.BAP', 'BAP.BREW_CRAFT', 'BAP.ROAST_BLEND', 'BAP.ORIGIN_ETHICAL_SOURCING', 'BAP.COFFEE_LEADERSHIP', 'BAP.ABOUT']).subscribe({
    this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.BAP']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.BAP'];
        this.titleService.setTitle(res['HOME.SHORTCUT.BAP']);
      }
    })

    // Banner POST Request
    this.contentService.getPartnerContent(this.bapHomeBannerContentType).subscribe({
      next: res => {
        this.bapHomeBannerData.image_src.lg = res[0].bannerImage;
        this.bapHomeBannerData.image_src.sm = res[0].bannerImageMobile;
      }
    })

    // Topic & Topic Item POST Request
    this.contentService.getPartnerContent(this.bapTopicContentType).subscribe({
      next: res => {
        if (res.length > 0) {
          res.forEach((topic: any) => {

            // Filter topicItem based on publishStartDate & publishEndDate
            if (topic.topicItem && topic.topicItem.length > 0) {
              const currentDate = new Date();
              topic.topicItem = topic.topicItem.filter((item: any) => {
                const publishStartDate = new Date(item.publishStartDate);
                const publishEndDate = new Date(item.publishEndDate);
                return (!publishStartDate || publishStartDate <= currentDate) &&
                  (!publishEndDate || currentDate <= publishEndDate);
              });
            }

            // Assign value after filter
            if (topic.topicItem && topic.topicItem.length > 0) {
              // Set BAP Topic Data (Cover listing)
              this.bapTopicData.push({
                "content_type": topic.contentType,
                "content_id": `${topic.title}-content-id`,
                "image_src": topic.coverImage,
                "link": `/bap/${topic.identifier}`,
                "headline": topic.title
              })

              // Set BAP (Topic section)
              if (topic.topicItem && topic.topicItem.length > 0) {
                let bapTopicItem: ListCarousel1Model[] = [];
                topic.topicItem.forEach(async (topicItem: any) => {
                  let bapTopicItemType: string = topicItem.type ?? topicItem.type1 ?? '';
                  const tier = topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase();
                  const canView = this.configService.isPreview ? true : await this.contentService.canViewContent(tier);

                  bapTopicItem.push({
                    "content_type": topicItem.contentType,
                    "content_id": `${topicItem.title}-content-id`,
                    "identifier": topicItem.identifier,
                    "type": bapTopicItemType.toUpperCase() === "FLASH CARD" ? "FLASHCARD" : "REGULAR",
                    "image_src": topicItem.coverImage,
                    "link": `bap/${topic.identifier}/${this.bapTopicItemNavigationUrl(bapTopicItemType, topicItem.identifier)}`,
                    "headline": topicItem.title,
                    "descriptions": topicItem.descriptions,
                    "level_requirement": topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase(),
                    "disabled": !canView
                  });
                });

                this.bapTopicSection.push({
                  title: topic.title,
                  topic: bapTopicItem
                })
              }
            }

          });
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }

  bapTopicItemNavigationUrl(type: string, identifier: string) {
    switch (type.toUpperCase()) {
      case 'ARTICLE':
        return `bap-articles/${identifier}`;
      case 'WORKSHOP':
        return `bap-workshop-details/${identifier}`;
      case 'FLASH CARD':
      default:
        return '#';
    }
  }

  openBapFlashCardModal(data: { identifier: string, tier: string }) {
    // Check tier access
    const canViewContent = this.contentService.canViewContent(data.tier);
    if (!canViewContent && !this.configService.isPreview) {
      this.toastr.error('Partner does not have tier required to access the content.');
      return;
    }

    // const modalRef = this.modalService.open(BapFlashCardModalComponent, {
    //   centered: true,
    //   windowClass: 'bap-flash-card-modal',
    //   size: 'xl'
    // });
    const modalRef = this.modalService.open(BapNewFlashCardModalComponent, {
      centered: true,
      windowClass: 'bap-flash-card-modal',
      size: 'xl'
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openBapQuizModal(data: { identifier: string, tier: string }) {
    const modalRef = this.modalService.open(BapQuizModalComponent, {
      centered: true,
      windowClass: 'bap-quiz-modal',
      size: 'xl'
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openBapQuestionnaireModal(data: { identifier: string, tier: string }) {
    const modalRef = this.modalService.open(BapQuestionnaireModalComponent, {
      centered: true,
      windowClass: 'bap-questionnaire-modal',
      size: 'xl'
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openUnlockBAPContentModal() {
    const modalRef = this.modalService.open(UnlockBapContentModalComponent, {
      centered: true,
      windowClass: 'unlock-bap-content-modal',
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}