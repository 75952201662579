import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationsCarouselModel } from '../models';

@Injectable({
  providedIn: 'any'
})

export class NotificationsCarouselService {
  dataUrl = '/assets/data/notifications-carousel.json';

  constructor(private http: HttpClient) { }

  getNotificationsCarouselData(): Observable<NotificationsCarouselModel[]> {
    return this.http.get<NotificationsCarouselModel[]>(this.dataUrl);
  }
}
