<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <div class="avatar-image">
      <figure>
        <img [src]="this.avatarUri">
      </figure>
    </div>
  </div>
  <div class="modal-inner-body justify-content-center">

    <div class="inner-body-heading pb-4">
      <h2>{{"ONBOARDING.REVIEW_AVATAR.HEADER" | translate}}</h2>
      <!-- <p>Feel free to use it on the Starbucks website! Or you can create your avatar again</p> -->
    </div>

    <div class="inner-body-footer">
        <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSubmit()">
          {{"ONBOARDING.REVIEW_AVATAR.NEXT" | translate}}
        </button>
        <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
          {{"ONBOARDING.REVIEW_AVATAR.BACK" | translate}}
        </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onDownload()">
        {{"ONBOARDING.REVIEW_AVATAR.DOWNLOAD" | translate}}
      </button>
    </div>
  </div>
</div>