<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-in/img-signin-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-in/img-signin-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.REQUEST_RESET_PASSWORD_SUCCESS.HEADER" | translate}}</h2>
      <p>{{"ONBOARDING.REQUEST_RESET_PASSWORD_SUCCESS.DESC" | translate}}</p>
      <p>({{"ONBOARDING.REQUEST_RESET_PASSWORD.EMAIL" | translate}} {{input.resetPassEmail}})</p>
      <!-- <p>We've sent an email to <strong>{{this.input.resetPassEmail}}</strong>, please follow the instructions in the
        email to reset your password. If you don't see the email, check your spam folder.</p> -->
    </div>

    <div class="inner-body-contents">

    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto"
        (click)="onSubmit()">{{"ONBOARDING.REQUEST_RESET_PASSWORD_SUCCESS.NEXT" | translate}}
      </button>
    </div>
  </div>
</div>