<div class="image-banner-2">
  <div *ngIf="data" class="banner-wrapper">
    <figure class="banner-image">
      <img *ngIf="data.image_src.sm" [src]="data.image_src.sm" class="d-md-none" />
      <img [src]="data.image_src.lg" [ngClass]="{'d-none d-md-block': data.image_src.sm}" />
    </figure>
    <div *ngIf="data.logo_image_src" class="event-logo d-flex align-items-center justify-content-center">
      <img [src]="data.logo_image_src" />
    </div>
  </div>
</div>