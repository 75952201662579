<div class="modal-inner-wrapper" [@fadeInOut]>
  <div class="modal-inner-header">
    <figure>
      <img src="/assets/images/sign-up/img-signup-4-sm.webp" class="d-lg-none">
      <img src="/assets/images/sign-up/img-signup-4-lg.webp" class="d-none d-lg-block">
    </figure>
  </div>
  <div class="modal-inner-body">
    <div class="step-indicator">
      <div class="data-label">{{"ONBOARDING.PROFILE_PICTURE.PART" | translate}}</div>
      <div class="step-bar">
        <span class="step step-1"></span>
        <span class="step step-2"></span>
        <span class="step step-3 current"></span>
        <!-- <span class="step step-4 current"></span> -->
      </div>
    </div>

    <div class="inner-body-heading">
      <h2>{{"ONBOARDING.PROFILE_PICTURE.HEADER" | translate}}</h2>

      <div class="mt-4 mb-3">
        <button type="button" class="btn btn-avatar-options btn-avatar-creator p-0" (click)="onCreateAction()">
          <img [src]="getImageLanguageSrc()">
        </button>
      </div>

      <div>
        <button type="button" class="btn btn-avatar-options py-3" (click)="onUploadAction()">
          <span class="icon-img">
            <icon-image-landscape></icon-image-landscape>
          </span>
          <div class="mt-1 flex-100 text-center">{{"ONBOARDING.PROFILE_PICTURE.UPLOAD_PROFILE_PICTURE" | translate}}
          </div>
        </button>
      </div>

    </div>

    <div class="inner-body-contents">
    </div>
    <div class="inner-body-footer">
      <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="onSkipAction()">
        {{"ONBOARDING.PROFILE_PICTURE.SKIP" | translate}}
      </button>
      <button type="button" class="btn btn-secondary w-100 w-lg-auto" (click)="onBack()">
        {{"ONBOARDING.PROFILE_PICTURE.BACK" | translate}}
      </button>
    </div>
  </div>
</div>