
import { Component } from '@angular/core';

@Component({
  selector: 'icon-image-landscape',
  standalone: true,
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Group_1141" x="0" y="0" style="enable-background:new 0 0 30.9 24.7" version="1.1" viewBox="0 0 30.9 24.7">
    <path d="M18.6 10.7c1.5 0 2.8-1.2 2.8-2.8 0-1.5-1.2-2.8-2.8-2.8-1.5 0-2.8 1.2-2.8 2.8 0 1.5 1.2 2.8 2.8 2.8z"/>
    <path d="M28.3 0H2.6C1.2 0 0 1.2 0 2.6v19.6c0 1.4 1.2 2.6 2.6 2.6h25.8c1.4 0 2.6-1.2 2.6-2.6V2.6C30.9 1.2 29.8 0 28.3 0zm-.8 21.1c0 .3-.2.5-.5.5H3.9c-.3 0-.5-.2-.5-.5V3.6c0-.3.2-.5.5-.5H27c.3 0 .5.2.5.5v17.5z"/>
    <path d="m18.7 14.7-7.4-7.4-6 6v6.5h20.3V17L21 12.4z"/>
  </svg>
  `,
  styles: [],
})
export class ImageLandscapeIcon { }