import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../../animation';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-review-avatar',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './review-avatar.component.html',
  styleUrl: './review-avatar.component.scss',
  animations: [fadeInOut],
})
export class ReviewAvatarComponent {
  @Output() onCancelled = new EventEmitter<void>();
  @Output() onCompleted = new EventEmitter<void>();
  @Input() avatarUri!: string;

  onBack() {
    this.onCancelled.emit();
  }

  onSubmit() {
    this.onCompleted.emit();
  }

  onDownload(): void {
    const blob = this.dataUriToBlob(this.avatarUri);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  dataUriToBlob(dataUri: string): Blob {
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

}
